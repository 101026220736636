import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Table } from "reactstrap";
import Input from "../Input";
import FormattedNumber from "../FormattedNumber";


function CurveLimitsSelector(props) {
  const {
    decimalScale,
    disabled,
    selectionMinimumLimit, onChangeSelectionMinimumLimit,
    selectionMaximumLimit, onChangeSelectionMaximumLimit
  } = props;

  function setMinimumLimit(variable) {
    return (values, sourceInfo) => {
      if(sourceInfo.source === "event") {
        onChangeSelectionMinimumLimit && onChangeSelectionMinimumLimit({ variable, value: values.floatValue });
      }
    };
  }

  function setMaximumLimit(variable) {
    return (values, sourceInfo) => {
      if(sourceInfo.source === "event") {
        onChangeSelectionMaximumLimit && onChangeSelectionMaximumLimit({ variable, value: values.floatValue });
      }
    }
  }

  return <>
    <Table size="sm" className="align-items-center">
      <thead className="thead-light">
        <tr>
          <th></th>
          <th>Min.</th>
          <th>Max.</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>%R</td>
          <td>
            <Input 
              type="number"
              className="form-control-sm"
              disabled={disabled}
              value={selectionMinimumLimit?.efficiencyPercentage ?? ""}
              decimalScale={decimalScale}
              onValueChange={setMinimumLimit("efficiencyPercentage")} />
          </td>
          <td>
            <Input 
                type="number"
                className="form-control-sm"
                disabled={disabled}
                value={selectionMaximumLimit?.efficiencyPercentage ?? ""}
                decimalScale={decimalScale}
                onValueChange={setMaximumLimit("efficiencyPercentage")} />
          </td>
        </tr>
        <tr>
          <td><FormattedMessage id="curves_generator.airflow" defaultMessage="Airflow" /></td>
          <td>
            <Input 
              type="number"
              className="form-control-sm"
              disabled={disabled}
              value={selectionMinimumLimit?.airflow ?? ""}
              decimalScale={decimalScale}
              onValueChange={setMinimumLimit("airflow")} />
          </td>
          <td>
            <Input 
                type="number"
                className="form-control-sm"
                disabled={disabled}
                value={selectionMaximumLimit?.airflow ?? ""}
                decimalScale={decimalScale}
                onValueChange={setMaximumLimit("airflow")} />
          </td>
        </tr>
        <tr>
          <td><FormattedMessage id="curves_generator.static_pressure" defaultMessage="Static pressure" /></td>
          <td>
            <Input 
              type="number"
              className="form-control-sm"
              disabled={disabled}
              value={selectionMinimumLimit?.staticPressure ?? ""}
              decimalScale={decimalScale}
              onValueChange={setMinimumLimit("staticPressure")} />
          </td>
          <td>
            <Input 
                type="number"
                className="form-control-sm"
                disabled={disabled}
                value={selectionMaximumLimit?.staticPressure ?? ""}
                decimalScale={decimalScale}
                onValueChange={setMaximumLimit("staticPressure")} />
          </td>
        </tr>
        <tr>
          <td><FormattedMessage id="curves_generator.total_pressure" defaultMessage="Total pressure" /></td>
          <td>
            <FormattedNumber value={selectionMinimumLimit?.totalPressure} />
          </td>
          <td>
            <FormattedNumber value={selectionMaximumLimit?.totalPressure} />
          </td>
        </tr>
        <tr>
          <td><FormattedMessage id="curves_generator.current" defaultMessage="Current" /></td>
          <td>
            <FormattedNumber value={selectionMinimumLimit?.electricalCurrent} />
          </td>
          <td>
            <FormattedNumber value={selectionMaximumLimit?.electricalCurrent} />
          </td>
        </tr>
        <tr>
          <td><FormattedMessage id="curves_generator.input_power" defaultMessage="Input power" /></td>
          <td>
            <FormattedNumber value={selectionMinimumLimit?.inputPower} />
          </td>
          <td>
            <FormattedNumber value={selectionMaximumLimit?.inputPower} />
          </td>
        </tr>
        <tr>
          <td><FormattedMessage id="curves_generator.speed" defaultMessage="Speed" /></td>
          <td>
            <FormattedNumber value={selectionMinimumLimit?.speed} />
          </td>
          <td>
            <FormattedNumber value={selectionMaximumLimit?.speed} />
          </td>
        </tr>
        <tr>
          <td><FormattedMessage id="curves_generator.specific_fan_power" defaultMessage="Specific fan power" /></td>
          <td>
            <FormattedNumber value={selectionMinimumLimit?.specificFanPower} />
          </td>
          <td>
            <FormattedNumber value={selectionMaximumLimit?.specificFanPower} />
          </td>
        </tr>
        <tr>
          <td><FormattedMessage id="curves_generator.input_static_efficiency" defaultMessage="Input static efficiency" /></td>
          <td>
            <FormattedNumber value={selectionMinimumLimit?.inputStaticEfficiency} />
          </td>
          <td>
            <FormattedNumber value={selectionMaximumLimit?.inputStaticEfficiency} />
          </td>
        </tr>
      </tbody>
    </Table>
  </>
}

export default CurveLimitsSelector;