import React from "react";
import ProductAttributes, { sectionTypes } from "../../../products/ProductAttributes";
import ProductInputContainer from "../ProductInputContainer";

function GeometricalParameters(props) {
  const productAttributes = new ProductAttributes({ object: props.product, attributes: props.productAttributes, update: props.update });

  return (
    <ProductInputContainer {...props} inputs={productAttributes.getAttributes(sectionTypes.GEOMETRICAL)} title="product_information.geometrical_parameters"></ProductInputContainer>
  );
}
export default GeometricalParameters;
