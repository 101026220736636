import React from "react";
import ProductAttributes, { sectionTypes } from "../../../products/ProductAttributes";
import ProductInputContainer from "../ProductInputContainer";

function RegulationParameters(props) {
  const productAttributes = new ProductAttributes({ object: props.product, attributes: props.productAttributes, update: props.update });

  return <ProductInputContainer {...props} inputs={productAttributes.getAttributes(sectionTypes.REGULATIONS)} title="product_information.regulations"></ProductInputContainer>;
}

export default RegulationParameters;
