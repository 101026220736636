import React from "react";
import ProductInputContainer from "../ProductInputContainer";

function GroupOtherParameters({
  children,
  groupAttributes,
  product,
  group,
  update,
  principalCurve,
  disabled,
  checkValidation,
  validationErrorText,
  isSinCurva,
  updateGroup,
  conceptClasses,
}) {
  const inputs = {
    modeloDato: {
      label: "ModeloDato",
      obj: [group, product, principalCurve],
      labelColumns: { sm: 4 },
      valueColumns: { sm: 8 },
    },
    modeloDato_version: {
      label: "modeloDato_version",
    },
    // principalMG: {
    //   label: "PrincipalMG",
    //   labelColumns: { sm: 6, xl: 3 },
    //   valueColumns: { sm: 6, xl: 9 },
    // },
    filterPrincipalMG: {
      label: "FilterPrincipalMG",
      labelColumns: { sm: 4 },
      valueColumns: { sm: 8 },
      visible: !isSinCurva,
      update: updateFilterPrincipalMG,
    },
  };

  function updateFilterPrincipalMG(e) {
    var p = product;
    var block = product.curveGroups.length > 1 && e.target.value == "none";
    var isAHU = group.conceptClassUnic == conceptClasses?.find((c) => c.value.toUpperCase() == "AHU-CONFIGURABLEHRU")?.id;
    updateGroup({
      ...group,
      filterPrincipalMG: !block ? e.target.value : group.filterPrincipalMG,
      curves: group.curves.map((c) => {
        return { ...c, flujoAire: isAHU ? e.target.value : c.flujoAire };
      }),
    });
  }

  return (
    <ProductInputContainer
      productAttributes={groupAttributes}
      product={group}
      update={update}
      inputs={inputs}
      title="product_information.other_parameters"
      disabled={disabled}
      checkValidation={checkValidation}
      validationErrorText={validationErrorText}
    >
      {children}
    </ProductInputContainer>
  );
}

export default GroupOtherParameters;
