import React from "react";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { replaceBlockedCharacters } from "../../../products/ProductValidation";

function ProductListStep({ next, prev, denomination, productList, setProductList, denominationParameter, customDenominationParameter }) {
  function addProduct() {
    setProductList(productList.concat(""));
  }

  function updateProduct(index, value) {
    setProductList(
      productList.map((p, i) => {
        if (index === i) {
          return value;
        } else return p;
      })
    );
  }

  function removeProduct(index) {
    if (productList.length === 1) return;
    setProductList(productList.filter((p, i) => index !== i));
  }

  function handleEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      addProduct();
    }
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderedItems = reorder(productList, result.source.index, result.destination.index);
    setProductList(orderedItems);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getListStyle = (isDraggingOver) => ({
    height: 56 * productList.length,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    height: 56,
    ...draggableStyle,
  });

  return (
    <ValidationForm onSubmit={next}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.product_list" defaultMessage="Product list" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage id="product_wizard.list_all_products" defaultMessage="List all products you want to add:" />
          </p>
          <div style={{ width: 550 }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={`dr`}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                    {productList.map((p, i) => (
                      <Draggable draggableId={`cv-${i}`} index={i} key={i}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            ref={provided.innerRef}
                          >
                            <Row className="d-flex align-items-center">
                              <Col sm="4" className="d-flex pl-5 align-items-center">
                                <div className="pr-3">
                                  <i className="fas fa-grip-vertical"></i>
                                </div>{" "}
                                {denomination}
                              </Col>
                              <Col sm="5">
                                <TextInput
                                  type="text"
                                  id={`product-${i}`}
                                  name={`product-${i}`}
                                  value={p}
                                  onChange={(e) => updateProduct(i, replaceBlockedCharacters(e.target.value))}
                                  placeholder={denominationParameter || customDenominationParameter}
                                  className="form-control"
                                  onKeyDown={handleEnter}
                                  required={true}
                                  errorMessage={""}
                                  autoFocus
                                />
                              </Col>
                              <Col sm="3">
                                <Button type="button" className="btn btn-link text-dark ml-4" onClick={() => removeProduct(i)}>
                                  <i className="fas fa-trash-alt"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <hr></hr>
            <Button type="button" onClick={() => addProduct()}>
              <FormattedMessage id="product_wizard.add_new_product" defaultMessage="Add new product" />
            </Button>
          </div>
        </CardBody>
        <CardFooter>
          <Button className="btn btn-dark float-right">
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default ProductListStep;
