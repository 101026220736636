import CatmullRom from "./CatmullRom";
import SplineReducer from "./SplineReducer";

export default class CatmullRomCurveInterpolator {
    constructor({ data, variable, mappers, columns, min, max }) {
        const self = this;

        this._variable = variable;
        this._columns = columns;
        this._data = data;
        this._mappers = mappers ?? [];
        this._min = min;
        this._max = max;
        
        this._createSplines = () => {
            return new SplineReducer({ 
                data: self._data,
                columns: self._columns,
                smoothingFactory: (xs, ys) => new CatmullRom(xs, ys)
            }).reduce({ variable: self._variable });
        }

        this._splines = this._createSplines(this._data);
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
        this._splines = this._createSplines(this._data);
    }

    set min(value) {
        this._min = value;
        this._splines = this._createSplines(this._data);
    }

    get min() {
        return this._min;
    }

    set max(value) {
        this._max = value;
        this._splines = this._createSplines(this._data);
    }

    get max() {
        return this._max;
    }

    interpolate({ points }) {
        if(this._data.length == 0)
            return [];

        let interpolated = new Array(points);
        for(let column of Object.keys(this._splines)) {
            let spline = this._splines[column];
            let values = spline.getValues(points, this._min, this._max);
            
            for(let i = 0; i < values.xs.length; i++) {
                if(!interpolated[i]) {
                    interpolated[i] = {};
                    interpolated[i][this._variable] = values.xs[i];
                }

                interpolated[i][column] = values.ys[i];
            }
        }

        this._mappers.forEach(mapper => interpolated = interpolated.map((value, index, array) => mapper.map({ value, index, array })));

        return interpolated;
    }
}