import English from "../language/en-GB.json";
import Spanish from "../language/es-ES.json";

const supportedLangauges = {
  "en-GB": {
    locale: "en-GB",
    messages: English,
    name: "English",
  },
  "es-ES": {
    locale: "es-ES",
    messages: Spanish,
    name: "Español",
  },
};

export default supportedLangauges;
