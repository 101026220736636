import eventify from "../eventify";
import Decibel from "./Decibel";
import SoundSample from "./SoundSample";

export class OperatingPoint 
{
  constructor({ pressure, airflow, speed }) {
    this.pressure = pressure;
    this.airflow = airflow;
    this.speed = speed;
  }

  // get pressure() {
  //     return this._pressure;
  // }
  // set pressure(value) {
  //     return this._pressure = value;
  // }

  // get airflow() {
  //     return this._airflow;
  // }

  // get speed() {
  //     return this._speed;
  // }
}

export default class SoundPower {
  constructor(correction, operatingPoint, samples) {
    const self = this;
    const recalculate = () => {
      const [first] = self.samples;
      if (first) {
        self._lwA = Decibel.prototype.add.apply(
          first.soundLevel,
          self.samples.slice(1).map((s) => s.soundLevel)
        );
        self._lpA = new Decibel(self.lwA.value - self._correction.value);
      } else {
        self._lwA = null;
        self._lpA = null;
      }
    };

    eventify(this);

    this._correction = correction;
    this.operatingPoint = operatingPoint;
    this.samples = samples;

    // this.samples.on("change", () => self.fire("change"));

    this._correction.on("change", () => self.fire("change"));
    this.on("change", recalculate);
    recalculate();
  }

  get operatingPoint() {
    return this._operatingPoint;
  }

  set operatingPoint(value) {
    if (value === null) {
      this._operatingPoint = null;
    } else if (value instanceof OperatingPoint) {
      this._operatingPoint = value;
    } else if (typeof value === "object") {
      this._operatingPoint = new OperatingPoint(value);
    } else {
      throw new Error("Argument 'value' must be of type 'OperatingPoint' or 'object'.");
    }

    this.fire("change");
  }

  get samples() {
    return Object.keys(this._samples).map((k) => this._samples[k]);
  }

  set samples(value) {
    this._samples = value
      .map((s) => {
        if (s instanceof SoundSample) {
          return s;
        } else if (s instanceof Decibel || s instanceof Number) {
          return new SoundSample(s);
        } else {
          throw new Error("Argument 'value' must be of type 'Number[]', 'Decibel[]' or 'SoundSample[]'.");
        }
      })
      .reduce((p, s) => ({ ...p, [s.frequency]: s }), {});

    this.fire("change");
  }

  newSamples() {
    return [
      new SoundSample({ frequency: 63, soundLevel: 0 }),
      new SoundSample({ frequency: 125, soundLevel: 0 }),
      new SoundSample({ frequency: 250, soundLevel: 0 }),
      new SoundSample({ frequency: 500, soundLevel: 0 }),
      new SoundSample({ frequency: 1000, soundLevel: 0 }),
      new SoundSample({ frequency: 2000, soundLevel: 0 }),
      new SoundSample({ frequency: 4000, soundLevel: 0 }),
      new SoundSample({ frequency: 8000, soundLevel: 0 }),
    ];
  }

  sampleAt(frequency) {
    return this._samples[frequency];
  }

  get lwA() {
    return this._lwA;
  }

  get lpA() {
    return this._lpA;
  }

  correct(corrections) {
    return new SoundPower(
      this._correction,
      this._operatingPoint,
      this.samples
        .filter((s) => !!corrections.sampleAt(s.frequency)?.soundLevel?.value)
        .map(
          (s) =>
            new SoundSample({
              frequency: s.frequency,
              soundLevel: s.soundLevel.value - corrections.sampleAt(s.frequency).soundLevel.value,
            })
        )
    );
  }
}
