import React from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";
import { ProductProvider } from "../../context/ProductContext";
import ProductWizard from "./ProductWizard";
import SelectedSeries from "../../components/ProductCreation/SelectedSeries";

function Product(props) {
  const { id, series, version } = props.match.params;
  return (
    <ProductProvider id={id} series={series} version={version}>
      <Header>
        <h1>
          <FormattedMessage id="products.new_product.title" defaultMessage="Create a new product" />
        </h1>
      </Header>
      <Container fluid>
        <SelectedSeries></SelectedSeries>
        <Row>
          <Col>
            <ProductWizard {...props}></ProductWizard>
          </Col>
        </Row>
      </Container>
    </ProductProvider>
  );
}

export default Product;
