import { lookupValueInAttributeOptionsById } from "./ProductDefinitions";
import { fireConfirm } from "../utils/alerts";
import Product from "../views/ProductCreation/Product";

export default class ProductAttributes {
  constructor({ object, attributes, update, updateAllProducts, intl }) {
    this._object = object;
    this._attributes = attributes;
    this._update = update;
    this._updateAllProducts = updateAllProducts;
    this._intl = intl;
  }

  availableAttributes = function () {
    return {
      codTension: { update: (e, applyToAll) => this.updateTension(e, applyToAll), section: sectionTypes.ELECTRICAL },
      codFrecuencia: { update: (e, applyToAll) => this.updateFrequencia(e, applyToAll), section: sectionTypes.ELECTRICAL },
      flagProductReg: { section: sectionTypes.ELECTRICAL },
      flagRegulationTension: { section: sectionTypes.ELECTRICAL },
      flagRegulationFrecuency: { section: sectionTypes.ELECTRICAL },
      flagSwitchable: { section: sectionTypes.ELECTRICAL, visible: true },
      flagSwitch: { section: sectionTypes.ELECTRICAL },
      typeCalculationId: { section: sectionTypes.ELECTRICAL },

      flagERPWeb: { section: sectionTypes.AERODYNAMIC },
      idERPType: { section: sectionTypes.AERODYNAMIC, visible: this._object?.series ? this._object?.series.flagErpnorma !== 0 : true },

      //polos: { section: sectionTypes.GEOMETRICAL, hideApplyToAll: true },
      tamany: { section: sectionTypes.GEOMETRICAL },
      tamanyNominal: { section: sectionTypes.GEOMETRICAL, hideApplyToAll: true },
      diametro: { section: sectionTypes.GEOMETRICAL },
      hubDiameter: { section: sectionTypes.GEOMETRICAL },
      tipoPalas: { section: sectionTypes.GEOMETRICAL },
      palas: { section: sectionTypes.GEOMETRICAL },
      grados: { section: sectionTypes.GEOMETRICAL },
      directo: { section: sectionTypes.GEOMETRICAL },
      polea: { section: sectionTypes.GEOMETRICAL },

      idCompanyManufacturer: { section: sectionTypes.OTHER, valueColumns: { sm: 9 } },
      flagOrigen: { section: sectionTypes.OTHER, valueColumns: { sm: 9 } },
      configuracion: { section: sectionTypes.OTHER },
      indProteccion: { section: sectionTypes.OTHER },
      flagRevisadoTecnica: { section: sectionTypes.OTHER },
      flagEspecial: { section: sectionTypes.OTHER },
      flagProduction: { section: sectionTypes.OTHER },
      activo: { section: sectionTypes.OTHER },
      flagObsoleto: { section: sectionTypes.OTHER },
      commercialClassETIM: { section: sectionTypes.OTHER },

      flagErPCompliantFixed: { section: sectionTypes.REGULATIONS, visible: this._object?.series ? this._object?.series.flagErpnorma !== 0 : true },

      modeloProductoKIT: { section: sectionTypes.SELECTOR, valueColumns: { sm: 9 } },
      modeloKIT_ErP: { section: sectionTypes.SELECTOR, valueColumns: { sm: 9 }, visible: this._object?.series ? this._object?.series.flagErpnorma !== 0 : true },
      modeloProductoKit: { section: sectionTypes.SELECTOR, valueColumns: { sm: 9 } },

      estiloRuido: { section: sectionTypes.SOUND },
    };
  };

  getAttributes = (sectionType) => Object.fromEntries(Object.entries(this.availableAttributes()).filter(([k, v]) => v.section === sectionType));

  updateFrequencia(e, applyToAll) {
    const newFrequency = parseInt(e.target ? e.target.value : e);
    const frecuencia = lookupValueInAttributeOptionsById(this._attributes, "codFrecuencia", newFrequency);

    this._update(
      {
        ...this._object,
        codFrecuencia: newFrequency,
        frecuencia: frecuencia,
      },
      applyToAll
    );
  }

  updateTension(e, applyToAll) {
    const newVoltage = applyToAll ? this._object[applyToAll] : parseInt(e.target ? e.target.value : e);
    const newTension = lookupValueInAttributeOptionsById(this._attributes, "codTension", newVoltage, "voltage");

    const updateCurvesTension = (updateCurves) => {
      const phaseNumber = lookupValueInAttributeOptionsById(this._attributes, "codTension", newVoltage, "phaseNumber");
      let codFrecuencia = this._object.codFrecuencia;
      let frequency = this._object.frecuencia;

      //if (this._object.codFrecuencia === undefined || this._object.codFrecuencia === null || this._object.codFrecuencia === "") {
      codFrecuencia = lookupValueInAttributeOptionsById(this._attributes, "codTension", newVoltage, "frequencyCode");
      frequency = lookupValueInAttributeOptionsById(this._attributes, "codTension", newVoltage, "frequency");
      //}

      if (codFrecuencia != undefined) {
        codFrecuencia = parseInt(codFrecuencia);
      }

      if (applyToAll) {
        this._updateAllProducts(
          null,
          null,
          {
            codTension: newVoltage,
            tension: newTension,
            typeCurrent: phaseNumber,
            codFrecuencia: codFrecuencia,
            frecuencia: frequency,
          },
          updateCurves
            ? {
                codTension: newVoltage,
                tension: newTension,
                codFrecuencia: codFrecuencia,
              }
            : null,
          updateCurves
            ? {
                claseTension: newVoltage,
                codTension: newVoltage,
                codFrecuencia: codFrecuencia,
                frecuencia: frequency,
              }
            : null
        );
      } else {
        this._update({
          ...this._object,
          codTension: newVoltage,
          tension: newTension,
          typeCurrent: phaseNumber,
          codFrecuencia: codFrecuencia,
          frecuencia: frequency,
          curveGroups: !updateCurves
            ? this._object.curveGroups
            : this._object.curveGroups.map((cg) => {
                return {
                  ...cg,
                  codTension: newVoltage,
                  tension: newTension,
                  codFrecuencia: codFrecuencia,
                  curves: cg.curves.map((c) => {
                    return {
                      ...c,
                      claseTension: newVoltage,
                      codTension: newVoltage,
                      codFrecuencia: codFrecuencia,
                      frecuencia: frequency,
                    };
                  }),
                };
              }),
        });
      }
    };

    const anyDifferent =
      this._object.curveGroups.length > 0 &&
      this._object.curveGroups.some(
        (cg) => (cg.codTension !== undefined && cg.codTension !== newVoltage) || cg.curves.some((c) => c.codTension !== undefined && c.codTension !== newVoltage)
      );

    if (anyDifferent || applyToAll) {
      fireConfirm(
        this._intl.formatMessage({ id: "warnings.update_curve_voltages", defaultMessage: "Would you like to update the curves voltages?" }),
        this._intl.formatMessage({ id: "warnings.update_curve_voltages_title", defaultMessage: "Update voltages" }),
        "question",
        this._intl.formatMessage({ id: "app.yes", defaultMessage: "Yes" }),
        this._intl.formatMessage({ id: "app.no", defaultMessage: "No" }),
        () => updateCurvesTension(true),
        () => updateCurvesTension(false)
      );
    } else {
      updateCurvesTension(false);
    }
  }
}

export const sectionTypes = {
  GEOMETRICAL: 0,
  MOTOR: 1,
  ELECTRICAL: 2,
  SELECTOR: 3,
  OTHER: 4,
  AERODYNAMIC: 5,
  SOUND: 6,
  REGULATIONS: 7,
};
