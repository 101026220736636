import { MultiAxisSpline } from "./MultiAxisSpline";

export default class CubicSplineCurveInterpolator {
  constructor({ data, variable, generator, mappers, columns }) {
    if (typeof generator === "undefined" || generator === null) {
      generator = new PassthroughGenerator();
    }

    this._createSpline = (data) => {
      return new MultiAxisSpline({
        data,
        variable: this._variable,
        columns: this._columns,
      });
    };
    this._variable = variable;
    this._columns = columns;
    this._data = data;
    this._generator = generator;
    this._mappers = mappers ?? [];
    this._spline = this._createSpline(this._data);    
  }



  get data() {
    return this._data;
  }

  set data(value) {
    this._data = value;
    this._spline = this._createSpline(this._data);
  }

  get spline() {
    return this._spline;
  }

  interpolate({ points }) {
    if (this._data.length == 0) return [];
    const xs = this._data.reduce((res, arr) => [...res, arr[this._variable]], []);
    let interpolated = new Array(points);

    for (let i = 0; i < points; i++) {
      let x = this._generator.generate({ array: xs, index: i, points });

      var values = this._spline.at(x);
      Object.keys(values).forEach((key) => (values[key] = values[key] || 0));

      interpolated[i] = values;
    }

    this._mappers.forEach((mapper) => (interpolated = interpolated.map((value, index, array) => mapper.map({ value, index, array }))));

    return interpolated;
  }
}

export class PassthroughGenerator {
  generate({ array, index }) {
    if (isNaN(index)) throw new Error("Argument `index` must be a number.");

    return array[index];
  }
}

export class StepGenerator {
  constructor({ min, max }) {
    this._min = parseFloat(min);
    this._max = parseFloat(max);

    if (isNaN(this._min)) throw new Error("Argument `min` must be a number.");
    if (isNaN(this._max)) throw new Error("Argument `max` must be a number.");
  }

  get min() {
    return this._min;
  }

  set min(value) {
    this._min = value;
  }

  get max() {
    return this._max;
  }

  set max(value) {
    this._max = value;
  }

  generate({ index, points }) {
    if (isNaN(index)) throw new Error("Argument `index` must be a number.");
    if (isNaN(index)) throw new Error("Argument `points` must be a number.");

    let step = (this._max - this._min) / (points - 1);
    return this._min + step * index;
  }
}
