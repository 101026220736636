import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import Header from "../../components/Header";
import { FormattedMessage, useIntl } from "react-intl";
import Templates from "../../components/DatosPlaca/Templates";
import { datosPlacaModes } from "../../plates/PlateStructures";

function DatosPlacaEdit(props) {
  const { codProductoComercial, codModeloProducto, codModeloProductoVersion, createMode } = props.match.params;
  const intl = useIntl();
  const mode = datosPlacaModes.DEPENDENT;

  const generateTitle = () => {
    // switch (mode) {
    //   case datosPlacaModes.DEPENDENT:
    //     return intl.formatMessage({ id: "datos_placa.title_dependent", defaultMessage: "Create a plate dependent on an idEnsayo" });
    //   case datosPlacaModes.IDENSAYO:
    //     return intl.formatMessage({ id: "datos_placa.title_idensayo", defaultMessage: "Create a plate based on existing idEnsayo" });
    //   case datosPlacaModes.EMPTY:
    //     return intl.formatMessage({ id: "datos_placa.title_empty", defaultMessage: "Create a plate with no idEnsayo" });
    //   case datosPlacaModes.PRODUCT:
    return intl.formatMessage({ id: "datos_placa.title", defaultMessage: "Create or modify a name plate" });
    // }
  };

  return (
    <>
      <Header>
        <h1>{generateTitle()}</h1>
      </Header>
      <Container fluid>
        <Row>
          <Col>
            <Templates
              codModeloProducto={codModeloProducto}
              codModeloProductoVersion={codModeloProductoVersion}
              codProductoComercial={codProductoComercial}
              mode={mode}
              editMode={createMode === "edit"}
              {...props}
            ></Templates>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DatosPlacaEdit;
