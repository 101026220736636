import React from "react";
import { Row, Col } from "reactstrap";

const AppFooter = () => (
  <footer className="footer">
    <Row className="align-items-center justify-content-xl-between">
      <Col>
        <hr></hr>
        <div className="copyright text-center text-xl-left text-muted">
            Product Creation Tool - {process.env.REACT_APP_ENV !== "production" ? process.env.REACT_APP_VERSION : process.env.REACT_APP_VERSION.split('-')[0]}
        </div>
      </Col>
    </Row>
  </footer>
);

export default AppFooter;
