import SoundSample from "./SoundSample";

export default class SoundPowerCorrection {
    constructor(tests) {
        const [first, second] = tests;
        this._first = first;
        this._second = second;
    }

    get tests() {
        return [this._first, this._second];
    }

    get samples() {
        return this._first?.samples?.map(s => this.sampleAt(s.frequency)) ?? [];
    }

    sampleAt(frequency) {
        let firstSoundLevel = this._first?.soundPower?.sampleAt(frequency)?.soundLevel?.value;
        let secondSoundLevel = this._second?.soundPower?.sampleAt(frequency)?.soundLevel?.value;
        
        if(firstSoundLevel && secondSoundLevel) {
            return new SoundSample({
                frequency,
                soundLevel: firstSoundLevel - secondSoundLevel
            });
        } else {
            return undefined;
        }
    }
}