import React from "react";
import d2lIntl from "d2l-intl";
import NumberFormat from "react-number-format";
import { Input as ReactStrapInput } from "reactstrap";
import { useCulture } from "../context/CultureContext";

function Input(props) {
  const { culture } = useCulture();
  const decimalSeperator = d2lIntl.LocaleProvider(culture).number.symbols.decimal;
  return props.type !== "number" ? (
    <ReactStrapInput {...props}></ReactStrapInput>
  ) : (
    <NumberFormat {...props} type="text" className={`form-control ${props.className || ""}`} decimalSeparator={decimalSeperator} thousandSeparator={false}></NumberFormat>
  );
}

export default Input;
