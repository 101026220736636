import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Route, Switch } from "react-router-dom";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";
import { useService } from "../../context/ServiceContext";
import { ProductProvider } from "../../context/ProductContext";
import SimpleProductWizard from "../../components/ProductCreation/Wizard/SimpleProductWizard";
import ProductWizard from "./ProductWizard";
import SimpleProductWizardTabs from "../../components/ProductCreation/Wizard/SimpleProductWizardTabs";
import SelectedSeries from "../../components/ProductCreation/SelectedSeries";

function ProductCreationWizard(props) {
  const { id } = props.match.params;
  const {
    productConceptsProvider,
    productTechnicalAttributesProvider,
    productCurveGroupAttributesProvider,
    productCurveAttributesProvider,
    serieComercialLister,
    neededAttributesProvider,
  } = useService();

  return (
    <ProductProvider isWizard={true} item={id}>
      <Header>
        <h1>
          <FormattedMessage id="products.product_wizard.title" defaultMessage="Product creation wizard" />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col>
            <Switch>
              <Route
                path={"/products/wizard/create"}
                render={(props) => (
                  <SimpleProductWizard
                    {...props}
                    productConceptsProvider={productConceptsProvider}
                    productTechnicalAttributesProvider={productTechnicalAttributesProvider}
                    productCurveGroupAttributesProvider={productCurveGroupAttributesProvider}
                    productCurveAttributesProvider={productCurveAttributesProvider}
                    serieComercialLister={serieComercialLister}
                    neededAttributesProvider={neededAttributesProvider}
                  ></SimpleProductWizard>
                )}
              />
              <Route
                path={"/products/wizard/:id"}
                render={(props) => {
                  return (
                    <>
                      <SelectedSeries></SelectedSeries>
                      <SimpleProductWizardTabs {...props}></SimpleProductWizardTabs>
                      <ProductWizard {...props}></ProductWizard>
                    </>
                  );
                }}
              />
            </Switch>
          </Col>
        </Row>
      </Container>
    </ProductProvider>
  );
}

export default ProductCreationWizard;
