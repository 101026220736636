import React, { useContext, useState, useEffect, useMemo } from "react";
import { useRef } from "react";
import { useIntl } from "react-intl";
import ErrorAlertContent from "../../components/ErrorAlertContent";
import SystemCurve from "../../curves/SystemCurve";
import { fireAlert } from "../../utils/alerts";
import useDidMountEffect from "../../utils/useDidMountEffect";
import { useService } from "../ServiceContext";

const VuErPContext = React.createContext({});

function VuErPProvider(props) {
  const { type, kitErP } = props;
  const { nonResidentialErPService, residentialErPService, localSessionCache } = useService();
  const sessionKey = `Genesis.ErP.${type}.${kitErP}`;
  const cached = localSessionCache.getObject(sessionKey);
  const isResidential = type == "rvu";
  const service = isResidential ? residentialErPService : nonResidentialErPService;
  const intl = useIntl();

  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  // All
  const [details, setDetails] = useState();
  const [curveTestId, setCurveTestId] = useState();
  const [curveTest, setCurveTest] = useState();
  const [labTestReference, setLabTestReference] = useState();
  const [acousticTestReference, setAcousticTestReference] = useState();
  const [identifier, setIdentifier] = useState();
  const [typology, setTypology] = useState();
  const [typeOfDrive, setTypeOfDrive] = useState();
  const [typeOfHrc, setTypeOfHrc] = useState();
  const [thermalEfficiency, setThermalEfficiency] = useState();
  const [lwa, setLwa] = useState();
  const [active, setActive] = useState();
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [evaluationError, setEvaluationError] = useState();
  const [evaluationWarnings, setEvaluationWarnings] = useState([]);

  // Non-residential
  const [base, setBase] = useState();
  const [alto, setAlto] = useState();
  const [deltaPsInt, setDeltaPsInt] = useState();
  const [deltaPsAdd, setDeltaPsAdd] = useState();
  const [staticEfficiencyFans, setStaticEfficiencyFans] = useState();
  const [externalLeakageRate, setExternalLeakageRate] = useState();
  const [internalLeakageRate, setInternalLeakageRate] = useState();
  const [filterPerformance, setFilterPerformance] = useState();
  const [filterWarning, setFilterWarning] = useState();
  const [qnom, setQnom] = useState();
  const [qnomM3S, setQnomM3S] = useState();
  const faceVelocity = useMemo(() => {
    if (base == 0) {
      return null;
    }

    if (alto == 0) {
      return qnomM3S / (Math.pow(base / 1000 / 2, 2) * Math.PI);
    } else {
      return qnomM3S / ((base / 1000) * (alto / 1000));
    }
  }, [base, alto, qnomM3S]);
  const [sfpInternal, setSfpInternal] = useState();
  const [deltaPressureExternal, setDeltaPressureExternal] = useState();
  const [pelec, setPelec] = useState();

  // Residential
  const systemCurve = useRef();
  const [climaticPerformance, setClimaticPerformance] = useState();
  const [performanceCurves, setPerformanceCurves] = useState();
  const [referenceCurve, setReferenceCurve] = useState();
  const [efficiencyType, setEfficiencyType] = useState();
  const [maximumInternalLeakageForBvu, setMaximumInternalLeakageForBvu] = useState();
  const [maximumInternalLeakageForBvuAndUvu, setMaximumInternalLeakageForBvuAndUvu] = useState();
  const [mixingRateForBvuWithoutDuctConnection, setMixingRateForBvuWithoutDuctConnection] = useState();
  const [airflowSensitivityToPressureVariation, setAirflowSensitivityToPressureVariation] = useState();
  const [indoorOutdoorAirTightness, setIndoorOutdoorAirTightness] = useState();
  const [ducting, setDucting] = useState();
  const [ductingFactor, setDuctingFactor] = useState();
  const [driveFactor, setDriveFactor] = useState();
  const [maximumFlowRate, setMaximumFlowRate] = useState();
  const [maximumPressureDifference, setMaximumPressureDifference] = useState();
  const [electricalPowerInputAtMaximumFlowRate, setElectricalPowerInputAtMaximumFlowRate] = useState();
  const [referenceFlowRate, setReferenceFlowRate] = useState();
  const [referencePressureDifference, setReferencePressureDifference] = useState();
  const [electricalPowerInputAtReferenceFlowRate, setElectricalPowerInputAtReferenceFlowRate] = useState();
  const [specificPowerInput, setSpecificPowerInput] = useState();
  const [controlFactor, setControlFactor] = useState();
  const [controlTypology, setControlTypology] = useState();
  const [positionOfFilterWarning, setPositionOfFilterWarning] = useState();
  const [descriptionOfVisualFilterWarning, setDescriptionOfVisualFilterWarning] = useState();
  const [instructionsToInstallSupplyGrilles, setInstructionsToInstallSupplyGrilles] = useState();
  const [instructionsToInstallExhaustGrilles, setInstructionsToInstallExhaustGrilles] = useState();
  const [useEcCalculations, setUseEcCalculations] = useState(false);
  const [useManualCalculation, setUseManualCalculation] = useState(false);
  const [loadErp, setLoadErp] = useState(false);

  const [classification, setClassification] = useState();

  const fields = isResidential
    ? {
        curveTestId,
        setCurveTestId,
        labTestReference,
        setLabTestReference,
        acousticTestReference,
        setAcousticTestReference,
        identifier,
        setIdentifier,
        typology,
        setTypology,
        typeOfDrive,
        setTypeOfDrive,
        typeOfHrc,
        setTypeOfHrc,
        thermalEfficiency,
        setThermalEfficiency,
        maximumInternalLeakageForBvu,
        setMaximumInternalLeakageForBvu,
        maximumInternalLeakageForBvuAndUvu,
        setMaximumInternalLeakageForBvuAndUvu,
        mixingRateForBvuWithoutDuctConnection,
        setMixingRateForBvuWithoutDuctConnection,
        airflowSensitivityToPressureVariation,
        setAirflowSensitivityToPressureVariation,
        indoorOutdoorAirTightness,
        setIndoorOutdoorAirTightness,
        ducting,
        setDucting: (value) => {
          console.trace();
          return setDucting(value);
        },
        ductingFactor,
        driveFactor,
        maximumFlowRate,
        setMaximumFlowRate,
        maximumPressureDifference,
        electricalPowerInputAtMaximumFlowRate,
        setElectricalPowerInputAtMaximumFlowRate,
        referenceFlowRate,
        setReferenceFlowRate,
        referenceCurve,
        referencePressureDifference,
        setReferencePressureDifference,
        electricalPowerInputAtReferenceFlowRate,
        setElectricalPowerInputAtReferenceFlowRate,
        specificPowerInput,
        setSpecificPowerInput,
        controlFactor,
        efficiencyType,
        controlTypology,
        setControlTypology,
        positionOfFilterWarning,
        setPositionOfFilterWarning,
        descriptionOfVisualFilterWarning,
        setDescriptionOfVisualFilterWarning,
        instructionsToInstallSupplyGrilles,
        setInstructionsToInstallSupplyGrilles,
        instructionsToInstallExhaustGrilles,
        setInstructionsToInstallExhaustGrilles,
        lwa,
        setLwa,
        active,
        setActive,
        useEcCalculations,
        setUseEcCalculations,
        useManualCalculation,
        setUseManualCalculation,
      }
    : {
        curveTestId,
        setCurveTestId,
        labTestReference,
        acousticTestReference,
        setAcousticTestReference,
        identifier,
        setIdentifier,
        typology,
        setTypology,
        typeOfDrive,
        setTypeOfDrive,
        typeOfHrc,
        setTypeOfHrc,
        thermalEfficiency,
        setThermalEfficiency,
        faceVelocity,
        base,
        setBase,
        alto,
        setAlto,
        deltaPsInt,
        setDeltaPsInt,
        deltaPsAdd,
        setDeltaPsAdd,
        staticEfficiencyFans,
        setStaticEfficiencyFans,
        externalLeakageRate,
        setExternalLeakageRate,
        internalLeakageRate,
        setInternalLeakageRate,
        filterPerformance,
        setFilterPerformance,
        filterWarning,
        setFilterWarning,
        qnom,
        qnomM3S,
        sfpInternal,
        deltaPressureExternal,
        pelec,
        lwa,
        setLwa,
        active,
        setActive
      };

  function initialise(vuErP) {
    if (vuErP) {
      setDetails(vuErP);

      setLabTestReference(vuErP.curveTest?.laboratoryTestReference);
      setAcousticTestReference(vuErP.acousticTestReference);
      setIdentifier(vuErP.identifier);
      setTypology(vuErP.typology);
      setTypeOfDrive(vuErP.typeOfDrive);
      setTypeOfHrc(vuErP.typeOfHrc);
      setThermalEfficiency(vuErP.thermalEfficiency);
      setLwa(vuErP.lwa);
      setActive(vuErP.active);
      setCurveTest(vuErP.curveTest);
      setCurveTestId(vuErP.curveTest?.id);

      if (isResidential) {
        setEfficiencyType(vuErP.curveTest?.efficiencyType);
        setMaximumInternalLeakageForBvu(vuErP.maximumInternalLeakageForBvu);
        setMaximumInternalLeakageForBvuAndUvu(vuErP.maximumInternalLeakageForBvuAndUvu);
        setMixingRateForBvuWithoutDuctConnection(vuErP.mixingRateForBvuWithoutDuctConnection);
        setAirflowSensitivityToPressureVariation(vuErP.airflowSensitivityToPressureVariation);
        setIndoorOutdoorAirTightness(vuErP.indoorOutdoorAirTightness);
        setControlTypology(vuErP.controlTypology);
        setPositionOfFilterWarning(vuErP.positionOfFilterWarning);
        setDescriptionOfVisualFilterWarning(vuErP.descriptionOfFilterWarning);
        setInstructionsToInstallSupplyGrilles(vuErP.instructionsToInstallSupplyGrilles);
        setInstructionsToInstallExhaustGrilles(vuErP.instructionsToInstallExhaustGrilles);
        setDucting(vuErP.ducting);
        setUseEcCalculations(vuErP.useEcCalculations);
        setUseManualCalculation(vuErP.useManualCalculation);

        if (vuErP.performance) {
          const performance = vuErP.performance;
          const systemConstant = performance.referencePressureDifference / Math.pow(performance.referenceFlowRate, 2);

          setMaximumFlowRate(performance.maximumFlowRate);
          setMaximumPressureDifference(performance.maximumPressureDifference);
          setElectricalPowerInputAtMaximumFlowRate(performance.electricalPowerInputAtMaximumFlowRate);
          setReferenceFlowRate(performance.referenceFlowRate);
          setReferencePressureDifference(performance.referencePressureDifference);
          if (!systemCurve.current) {
            systemCurve.current = new SystemCurve(systemConstant, performance.maximumFlowRate, 14);
          } else {
            systemCurve.current.systemConstant = systemConstant;
            systemCurve.current.maximumFlowRate = performance.maximumFlowRate;
          }
          setReferenceCurve(systemCurve.current.points);
          setElectricalPowerInputAtReferenceFlowRate(performance.electricalPowerInputAtReferenceFlowRate);
          setSpecificPowerInput(performance.specificPowerInput);
          setClimaticPerformance(performance.climatic);
          setPerformanceCurves(performance.curves);
          setEvaluationWarnings(performance.warnings);
        }
      } else {
        setBase(vuErP.base);
        setAlto(vuErP.alto);
        setDeltaPsInt(vuErP.deltaPressureInternal);
        setDeltaPsAdd(vuErP.deltaPressureAdditional);
        setStaticEfficiencyFans(vuErP.staticEfficiencyFans);
        setExternalLeakageRate(vuErP.externalLeakageRate);
        setInternalLeakageRate(vuErP.internalLeakageRate);
        setFilterPerformance(vuErP.filterPerformance);
        setFilterWarning(vuErP.filterWarning);
      }
    }
  }

  useDidMountEffect(() => {
    var header = {
      acousticTestReference,
      identifier,
      typology,
      typeOfDrive,
      typeOfHrc,
      thermalEfficiency,
    };

    if (isResidential) {
      setDetails({
        ...details,
        ...header,
        curveTest,
        maximumInternalLeakageForBvu,
        maximumInternalLeakageForBvuAndUvu,
        mixingRateForBvuWithoutDuctConnection,
        airflowSensitivityToPressureVariation,
        indoorOutdoorAirTightness,
        controlTypology,
        positionOfFilterWarning,
        descriptionOfFilterWarning: descriptionOfVisualFilterWarning,
        instructionsToInstallSupplyGrilles,
        instructionsToInstallExhaustGrilles,
        ducting,
        lwa,
        active,
        useEcCalculations,
        useManualCalculation,
        performance: {
          ...details?.performance,
          ductingFactor,
          driveFactor,
          maximumFlowRate,
          electricalPowerInputAtMaximumFlowRate,
          referenceFlowRate,
          referencePressureDifference,
          electricalPowerInputAtReferenceFlowRate,
          specificPowerInput,
          controlFactor,
          climaticPerformance,
        },
      });
    } else {
      setDetails({
        ...details,
        ...header,
        name: kitErP,
        curveTest,
        faceVelocity,
        base,
        alto,
        deltaPressureInternal: deltaPsInt,
        deltaPressureAdditional: deltaPsAdd,
        staticEfficiencyFans,
        externalLeakageRate,
        internalLeakageRate,
        filterPerformance,
        filterWarning,
        lwa,
        active,
      });
    }
  }, [JSON.stringify(fields)]);

  useEffect(() => {
    if (cached) {
      setLoading(true);
      initialise(cached);
      setLoading(false);
    }

    return () => localSessionCache.setObject(sessionKey, null);
  }, []);

  useDidMountEffect(() => {
    setIsSaved(false);
    localSessionCache.setObject(sessionKey, details);
  }, [details]);

  useEffect(() => {
    if (!cached) {
      load();
    }
  }, [type, kitErP]);

  useDidMountEffect(() => {
    if (!curveTestId && curveTestId !== 0) {
      setCurveTest(undefined);
    } else if (curveTest?.id !== curveTestId) {
      setLoading(true);

      service
        .getCurveTest(curveTestId)
        .then((result) => {
          setLoading(false);
          setCurveTest(result);
        })
        .catch((e) => {
          setLoading(false);
          setError(e);
        });
    }
  }, [curveTestId]);

  useDidMountEffect(() => {
    if (!details) {
      return;
    }

    setLabTestReference(curveTest?.laboratoryTestReference);

    if (isResidential) {
      setDetails({ ...details, curveTestId: curveTest?.id });
      setEfficiencyType(curveTest?.efficiencyType);
    } else {
      setDetails({ ...details, curveTestId: curveTest?.id });
      setQnom(curveTest?.nominalAirflow);
      setQnomM3S(curveTest?.nominalAirflowM3S);
      setSfpInternal(curveTest?.sfpInternal);
      setDeltaPressureExternal(curveTest?.deltaPressureExternal);
      setPelec(curveTest?.electricalPower);
    }
  }, [curveTest]);

  useDidMountEffect(() => {
    if (!loading && !ready) {
      setReady(true);
    }
  }, [loading]);

  function evaluateErPCompliance() {
    setLoading(true);
    service
      .evaluateResidentialErPPerformance({
        name: details.name,
        parameters: {
          typology,
          ducting,
          typeOfDrive,
          typeOfHrc,
          thermalEfficiency,
          controlTypology,
          useEcCalculations,
          curveTestId,
        },
      })
      .then((data) => {
        initialise({
          ...details,
          ducting,
          typeOfDrive,
          typology,
          typeOfHrc,
          thermalEfficiency,
          controlTypology,
          useEcCalculations,
          curveTest,
          performance: data,
        });
        setEvaluationError(undefined);
        setValidationErrors([]);
      })
      .catch((error) => {
        if (error.validation) {
          delete details.performance;
          setClimaticPerformance(undefined);
          setEvaluationError({ id: "erp.performance_evaluation_validation_errors", defaultMessage: "Performance evaluation failed: please check form fields." });
          setValidationErrors(error.validation.errors);
        } else if (error.localization) {
          setEvaluationError(undefined);
          setValidationErrors([]);
          setEvaluationError(error.localization);
        }
      })
      .finally(() => setLoading(false));
  }

  useDidMountEffect(() => {
    if (!useManualCalculation && isResidential && details && !loading && ready) {
      evaluateErPCompliance();
    }
  }, [ducting, typeOfDrive, typology, typeOfHrc, thermalEfficiency, controlTypology, useEcCalculations, curveTest]);

  useEffect(() => {
    switch (ducting) {
      case "D":
        setDuctingFactor(1.1);
        break;
      case "ND":
        setDuctingFactor(1.21);
        break;
      default:
        setDuctingFactor("-");
        break;
    }

    switch (typeOfDrive) {
      case "1V":
      case "MSD":
        setDriveFactor(1);
        break;
      case "2V":
        setDriveFactor(1.2);
        break;
      case "3V":
        setDriveFactor(1.5);
        break;
      case "VSD":
        setDriveFactor(2);
        break;
      default:
        setDriveFactor("-");
        break;
    }

    switch (controlTypology) {
      case "MAN":
        setControlFactor(1);
        break;
      case "CLOCK":
        setControlFactor(0.95);
        break;
      case "CDC":
        setControlFactor(0.85);
        break;
      case "LDC":
        setControlFactor(0.65);
        break;
      default:
        setControlFactor("-");
        break;
    }
  }, [ducting, typeOfDrive, controlTypology]);

  async function load() {
    try {
      setLoading(true);
      await service.getErPKit(kitErP).then((vueErp) => initialise(vueErp));
      setLoadErp(true);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  useDidMountEffect(() => {
    if(isResidential)
    {
      evaluateErPCompliance();
    }
  }, [loadErp]);

  async function save() {
    setLoading(true);
    setIsSaving(true);
    setValidationErrors([]);

    try {
      await service.storeErPKit({
        name: kitErP,
        inputs: { ...details, curveTest: undefined, curveTestId: curveTestId },
      });

      setIsSaved(true);
    } catch (e) {
      if (e.validation) {
        setValidationErrors(e.validation.errors);
        fireAlert(
          <ErrorAlertContent
            message={intl.formatMessage({
              id: "curves_generator.vuerp.cannot_save_form_invalid",
              defaultMessage: "The ModeloKitErP could not be saved, please check form fields.",
            })}
            traceId={e.traceId}
          />,
          intl.formatMessage({ id: "app.error", defaultMessage: "Error" }),
          "error"
        );
      } else {
        fireAlert(
          <ErrorAlertContent message={intl.formatMessage(e.localization, e.values)} traceId={e.traceId} />,
          intl.formatMessage({
            id: "app.error",
            defaultMessage: "Error",
          }),
          "error"
        );
      }
    } finally {
      setLoading(false);
      setIsSaving(false);
    }
  }

  function retry(e) {
    e.preventDefault();
    setError(null);
    load();
  }

  return (
    <VuErPContext.Provider
      value={{
        details,
        isResidential,
        fields,
        setClimaticPerformance,
        climaticPerformance,
        performanceCurves,
        validationErrors,
        evaluationError,
        evaluationWarnings,
        loading,
        save,
        isSaving,
        isSaved,
        error,
        retry,
      }}
      {...props}
    />
  );
}

function useVuErP() {
  const context = useContext(VuErPContext);
  if (context == null) {
    throw new Error("useVuErP must be used within a VuErPProvider");
  }

  return context;
}

export { VuErPProvider, useVuErP };
