import eventify from "../eventify";

class Correction {
    constructor(directivity, distance) {
        this._directivity = directivity ?? null;
        this._distance = distance ?? null;
        this._value = 0;
        eventify(this);
    };

    set directivity(value) {
        this._directivity = value;
        this._calculate(this._directivity, this._distance);
    }

    get directivity() {
        return this._directivity;
    }

    set distance(value) {
        this._distance = value;
        this._calculate(this._directivity, this._distance);
    }

    get distance() {
        return this._distance;
    }

    get value() {
        return this._value;
    }

    _calculate(directivity, distance) {
        if(directivity && distance) {
            this._value = 10 * Math.log10(4 * Math.PI * Math.pow(distance, 2) / directivity);
        } else {
            this._value = 0;
        }

        this.fire("change");
    }
}

export default Correction;