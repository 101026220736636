import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import { BaseFormControl } from "react-bootstrap4-form-validation";
import { Input } from "reactstrap";

function getSuggestionValue(suggestion) {
  return suggestion.value;
}

function shouldRenderSuggestions() {
  return true;
}

function renderSuggestion(suggestion) {
  return <span>{suggestion.value}</span>;
}

function EditableDropdown({ id, options, value, required, onChange, className }) {
  const [suggestions, setSuggestions] = useState([options]);

  const inputProps = {
    value: (value || "").toString(),
    className,
    required,
    onChange: (e, { newValue }) => {
      onChange({
        target: {
          id: id,
          value: newValue,
        },
      });
    },
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={() => setSuggestions(options)}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={getSuggestionValue}
      shouldRenderSuggestions={shouldRenderSuggestions}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      renderInputComponent={(p) => <Input {...p} />}
    />
  );
}
export default EditableDropdown;

/* export default class EditableDropdown extends BaseFormControl {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      value: "",
      suggestions: [this.props.options],
    };
  }

  getInputRef() {
    return this.inputRef.current;
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.props.options,
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onChange = (event, { newValue, method }) => {
    this.checkError();

    this.setState({
      value: newValue,
    });

    if (this.props.onChange)
      this.props.onChange({
        target: {
          id: this.props.id,
          value: newValue,
        },
      });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      value: (value || "").toString(),
      onChange: this.onChange,
      className: "form-control",
      required: true,
    };
    return (
      <>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          shouldRenderSuggestions={shouldRenderSuggestions}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={(p) => {
            return <input type="text" {...p} ref={this.inputRef} />;
          }}
        />
        {this.displayErrorMessage()}
        {this.displaySuccessMessage()}
      </>
    );
  }
} */
