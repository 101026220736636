import Brent from "../optimisation/brent";

export default class CubicSpline {
    constructor(xAxis, yAxis) {
        this.n = 0;
        this.x = [];
        this.y = [];
        this.S = [];
        this.h = [];
        this.B = [];
        this.D = [];
        this.A = [];
        this.C = [];
        this.R = 0;
        if (typeof xAxis !== "undefined" && typeof yAxis !== "undefined") {
            this.create(xAxis, yAxis);
        }
    }
    create(xAxis, yAxis) {
        let i;
        let j;
        let ntdma;
        let tx, ty;
        let currentPoints = xAxis.length;
        this.x = new Array(currentPoints + 1).fill(0);
        this.y = new Array(currentPoints + 1).fill(0);
        this.h = new Array(currentPoints + 1).fill(0);
        this.S = new Array(currentPoints + 1).fill(0);
        this.B = new Array(currentPoints + 1).fill(0);
        this.D = new Array(currentPoints + 1).fill(0);
        this.A = new Array(currentPoints + 1).fill(0);
        this.C = new Array(currentPoints + 1).fill(0);
        tx = [...xAxis];
        ty = [...yAxis];
        this.quickSort(tx, ty, 0, tx.length - 1);
        this.n = currentPoints;
        for (i = 1; i <= tx.length; i++) {
            this.x[i] = tx[i - 1];
            this.y[i] = ty[i - 1];
        }
        for (i = 1; i <= (this.n - 1); i++) {
            this.h[i] = this.x[i + 1] - this.x[i];
        }
        for (i = 2; i <= (this.n - 1); i++) {
            j = i - 1;
            this.D[j] = 2 * (this.h[i - 1] + this.h[i]);
            this.A[j] = this.h[i];
            this.B[j] = this.h[i - 1];
        }
        for (i = 2; i <= (this.n - 1); i++) {
            j = i - 1;
            this.C[j] = 6 * ((this.y[i + 1] - this.y[i]) / this.h[i] - (this.y[i] - this.y[i - 1]) / this.h[i - 1]);
        }
        ntdma = this.n - 2;
        for (i = 2; i <= ntdma; i++) {
            this.R = this.B[i] / this.D[i - 1];
            this.D[i] = this.D[i] - this.R * this.A[i - 1];
            this.C[i] = this.C[i] - this.R * this.C[i - 1];
        }
        this.C[ntdma] = this.C[ntdma] / this.D[ntdma];
        for (i = (ntdma - 1); i >= 1; i += (-1)) {
            this.C[i] = (this.C[i] - this.A[i] * this.C[i + 1]) / this.D[i];
        }
        for (i = 2; i <= (this.n - 1); i++) {
            j = i - 1;
            this.S[i] = this.C[j];
        }
        this.S[1] = 0;
        this.S[this.n] = 0;
        for (i = 1; i <= (this.n - 1); i++) {
            this.A[i] = (this.S[i + 1] - this.S[i]) / (6 * this.h[i]);
            this.B[i] = this.S[i] / 2;
            this.C[i] = (this.y[i + 1] - this.y[i]) / this.h[i] - (2 * this.h[i] * this.S[i] + this.h[i] * this.S[i + 1]) / 6;
            this.D[i] = this.y[i];
        }
    }
    getValue(xVal) {
        let dx;
        if (xVal <= this.x[1]) {
            return (((this.y[2] - this.y[1]) / (this.x[2] - this.x[1])) * (xVal - this.x[1]) + this.y[1]);
        }
        if (xVal >= this.x[this.n]) {
            return (((this.y[this.n] - this.y[this.n - 1]) / (this.x[this.n] - this.x[this.n - 1])) * (xVal - this.x[this.n - 1]) + this.y[this.n - 1]);
        }
        let i = 1;
        let j = this.n + 1;
        do {
            let k = Math.floor((i + j) / 2);
            if (xVal < this.x[k]) {
                j = k;
            }
            else {
                i = k;
            }
        } while (j > i + 1);
        dx = xVal - this.x[i];
        return this.A[i] * Math.pow((dx), 3) + this.B[i] * Math.pow((dx), 2) + this.C[i] * (dx) + this.D[i];
    }
    setValues(xAxis, yAxis) {
        this.create(xAxis, yAxis);
    }
    quickSort(list, list2, min, max) {
        let med_value, med_value2;
        let hi, lo, i;
        if (min >= max) {
            return;
        }
        i = Math.floor(Math.random() * (max - min) + min);
        med_value = list[i];
        med_value2 = list2[i];
        list[i] = list[min];
        list2[i] = list2[min];
        lo = min;
        hi = max;
        do {
            while (list[hi] >= med_value) {
                hi = hi - 1;
                if (hi <= lo) {
                    break;
                }
            }
            if (hi <= lo) {
                list[lo] = med_value;
                list2[lo] = med_value2;
                break;
            }
            list[lo] = list[hi];
            list2[lo] = list2[hi];
            lo = lo + 1;
            while (list[lo] < med_value) {
                lo = lo + 1;
                if (lo >= hi) {
                    break;
                }
            }
            if (lo >= hi) {
                lo = hi;
                list[hi] = med_value;
                list2[hi] = med_value2;
                break;
            }
            list[hi] = list[lo];
            list2[hi] = list2[lo];
        } while (true);
        this.quickSort(list, list2, min, lo - 1);
        this.quickSort(list, list2, lo + 1, max);
    }
}
export class SolerPalauCubicSpline extends CubicSpline {
    constructor(xAxis, yAxis) {
        super(xAxis, yAxis);
    }
    extrapolateValueInverse(yVal) {
        let E = 3 * this.A[this.n - 1] * Math.pow(this.x[this.n] - this.x[this.n - 1], 2) + 2 * this.B[this.n - 1] * (this.x[this.n] - this.x[this.n - 1]) + this.C[this.n - 1];
        return ((yVal - this.y[this.n]) / E) + this.x[this.n];
    }
    // getValueInverse(yVal) {
    //     let difference, percentage, xs, dx;
    //     let iteration, i, R, sign;
    //     sign = 0;
    //     if (yVal >= this.y[1])
    //         return this.x[1];
    //     if (yVal <= this.y[this.n])
    //         return this.extrapolateValueInverse(yVal);
    //     R = 1;
    //     i = 1;
    //     while (this.y[R] > yVal) {
    //         i = R;
    //         R = R + 1;
    //     }
    //     iteration = 0;
    //     dx = this.x[i];
    //     xs = this.A[i] * Math.pow(dx - this.x[i], 3) + this.B[i] * Math.pow(dx - this.x[i], 2) + this.C[i] * (dx - this.x[i]) + this.D[i];
    //     difference = xs - yVal;
    //     percentage = Math.round(this.x[this.n] / 100);
    //     while (Math.abs(difference) > 0.0005) {
    //         if (difference > 0 && sign == 1) {
    //             percentage /= 2;
    //             sign = 1;
    //         }
    //         if (difference < 0 && sign == 0) {
    //             percentage /= 2;
    //             sign = 0;
    //         }
    //         if (difference > 0) {
    //             dx += percentage;
    //         }
    //         else {
    //             dx -= percentage;
    //         }
    //         xs = this.A[i] * Math.pow(dx - this.x[i], 3) + this.B[i] * Math.pow(dx - this.x[i], 2) + this.C[i] * (dx - this.x[i]) + this.D[i];
    //         difference = xs - yVal;
    //         iteration += 1;
    //         if (iteration > 50000)
    //             break;
    //         if (dx > this.x[i + 1] || dx < this.x[i - 1])
    //             break;
    //     }
    //     return dx;
    // }

    // Was getValueInverseReverse
    getValueInverse(yVal) {
        let self = this;
        let xmax = Math.max.apply(Math, self.x);
        let ymax = self.y.indexOf(Math.max.apply(Math, self.y));
        let left = self.x[ymax];//-Math.abs(xmax);
        let right = Math.abs(2 * xmax);

        try {
            return Brent(x => this.getValue(x), left, right, 1e-6, yVal);
        } catch {
            return undefined;
        }
    }
}