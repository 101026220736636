import React, { useState, useEffect, Fragment, useRef } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Wizard, Step, Steps } from "react-albus";
import { FormattedMessage, useIntl } from "react-intl";
import ListerTable from "../Tables/ListerTable";
import { useService } from "../../context/ServiceContext";

function SeriesLoadingsResults({ onRequestClick }) {
  const { serieComercialLoadingsLister } = useService();
  const intl = useIntl();

  let refreshRef = useRef();
  const refreshTable = () => refreshRef.current();

  async function handleQueueSelection(value) {
    if (onRequestClick) {
      await onRequestClick(value);
      refreshTable();
    }
  }

  function renderSmallTableHeader({ column, children }) {
    return (
      <th {...column.getHeaderProps()} style={{ width: "1%" }}>
        {children}
      </th>
    );
  }

  let seriesColumns = [
    { Header: intl.formatMessage({ id: "manage_loadings.series_id", defaultMessage: "Series id" }), accessor: "id" },
    { Header: intl.formatMessage({ id: "manage_loadings.series_name", defaultMessage: "Series name" }), accessor: "serieComercial" },
    { Header: intl.formatMessage({ id: "manage_loadings.status", defaultMessage: "Status" }), accessor: "status" },
  ];

  seriesColumns.push({
    id: "select",
    TableHeader: renderSmallTableHeader,
    Cell: ({ cell }) => {
      if (cell.row.original.statusId != 0 && cell.row.original.statusId != 1) {
        return (
          <Button className="btn btn-danger float-right" onClick={() => handleQueueSelection(cell.row.original.id)}>
            <FormattedMessage id="manage_loadings.request" defaultMessage="Request" />
          </Button>
        );
      } else {
        return <></>;
      }
    },
  });

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <FormattedMessage id="manage_loadings.series_to_load" defaultMessage="Series to load" />
          </CardHeader>
          <CardBody className="p-0">
            <ListerTable className={"align-items-center table-flush"} pageSize={5} refreshRef={refreshRef} columns={seriesColumns} lister={serieComercialLoadingsLister} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default SeriesLoadingsResults;
