class ListerSuggester {
  constructor(lister, filterGenerator, valueResolver, limit = 5) {
    var self = this;
    this._lister = lister;
    this._filterGenerator = filterGenerator;
    this._valueResolver = valueResolver;
    this._limit = limit;

    this.resolveSuggestionValue = (suggestion) => {
      return self._valueResolver(suggestion);
    }
  }

  async getSuggestions(fragment) {
    let result = await this._lister.getList([ this._filterGenerator(fragment) ], 1, this._limit);
    return result.items;
  }

  
}

export default ListerSuggester;