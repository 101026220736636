export default class SplineReducer {
    constructor({ data, columns, smoothingFactory }) {
        this._data = [...data];
        this._columns = columns;
        this._smoothingFactory = smoothingFactory;
    }

    reduce({ variable }) {
        let splines = {};
        if(this._data && this._data.length) {
            if(this._data[0][variable] < this._data[this._data.length - 1][variable]) {
                this._data.reverse();
            }
            
            const xs = this._data.reduce((res, arr) => [...res, arr[variable]], []);
            const columns = this._columns;

            for(let i = 0; i < columns.length; i++) {
                const column = columns[i];
                const ys = this._data.reduce((res, arr) => [...res, arr[column]], []);
                splines[column] = this._smoothingFactory(xs, ys);
            }
        }

        return splines;
    }
}