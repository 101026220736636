import React from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { FormattedMessage } from "react-intl";

function PlateSummary({ data }) {
  const { principalCurve, motor1, motor2, voltage } = data;

  return (
    <Row>
      <Col md={6}>
        <Card>
          <CardHeader>
            <FormattedMessage id="datos_placa.principal_curve_erp" defaultMessage="Principal curve ERP" />
          </CardHeader>
          <CardBody>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="datos_placa.max_electrical_current" defaultMessage="Max electrical current" />
                  </th>
                  <th>
                    <FormattedMessage id="datos_placa.max_power" defaultMessage="Max power" />
                  </th>
                  <th>
                    <FormattedMessage id="datos_placa.max_rpm" defaultMessage="Max RPM" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{principalCurve.electricalCurrent}</td>
                  <td>{principalCurve.power}</td>
                  <td>{principalCurve.speed}</td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
      {(motor1.electricalCurrent > 0 || motor1.power > 0 || motor1.speed > 0) && (
        <Col md={6}>
          <Card>
            <CardHeader>
              <FormattedMessage id="datos_placa.max_nominals" defaultMessage="Motor nominals" />
            </CardHeader>
            <CardBody>
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <FormattedMessage id="datos_placa.nominal_electrical_current" defaultMessage="Nominal electrical current" />
                    </th>
                    <th>
                      <FormattedMessage id="datos_placa.nominal_power" defaultMessage="Nominal power" />
                    </th>
                    <th>
                      <FormattedMessage id="datos_placa.nominal_rpm" defaultMessage="Nominal RPM" />
                    </th>
                    <th>
                      <FormattedMessage id="datos_placa.voltage" defaultMessage="Voltage" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <FormattedMessage id="datos_placa.speed_1" defaultMessage="Speed 1." />
                    </th>
                    <td>{motor1.electricalCurrent}</td>
                    <td>{motor1.power}</td>
                    <td>{motor1.speed}</td>
                    <td>{voltage}</td>
                  </tr>
                  {(motor2.electricalCurrent > 0 || motor2.power > 0 || motor2.speed > 0) && (
                    <tr>
                      <th scope="row">
                        <FormattedMessage id="datos_placa.speed_2" defaultMessage="Speed 2." />
                      </th>
                      <td>{motor2.electricalCurrent}</td>
                      <td>{motor2.power}</td>
                      <td>{motor2.speed}</td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  );
}

export default PlateSummary;
