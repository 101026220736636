import React, { Fragment, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Button, CustomInput } from "reactstrap";

function CurveOptionList({ groups, selectedOptions, optionsAppendText, products, setProducts, optionsFieldName }) {
  const [selectedGroup, setSelectedGroup] = useState(groups[0]);

  useEffect(() => {
    if (!products.some((p) => p[optionsFieldName].length > 0)) {
      let pr = [...products];
      selectedOptions.forEach((so) => {
        pr.forEach((p) => {
          p[optionsFieldName].push({ ...so });
        });
      });
      setProducts(pr);
    }
  }, []);

  function updateProduct(id, option, state, optionsFieldName) {
    let product = { ...products.find((p) => p.id === id) };
    let productOption = product[optionsFieldName].find((o) => o.id === option.id);
    if (state && !productOption) {
      product[optionsFieldName].push({ ...option, principal: !product[optionsFieldName].some((f) => f.principal) });
    } else if (!state) {
      if (productOption?.principal) {
        let idx = product[optionsFieldName].findIndex((f) => f.id === option.id) + 1;
        if (product[optionsFieldName].length - 1 < idx) {
          idx = Math.max(idx - 2, 0);
        }
        product[optionsFieldName][idx].principal = true;
      }
      product[optionsFieldName] = product[optionsFieldName].filter((f) => f.id !== option.id);
    }
    setProducts(
      products.map((p) => {
        if (p.id === product.id) {
          return product;
        } else return p;
      })
    );
  }

  function updatePrincipal(product, value) {
    setProducts(
      products.map((p) => {
        if (p.id === product.id) {
          return {
            ...product,
            [optionsFieldName]: product[optionsFieldName].map((v) => {
              return { ...v, principal: v.id == value.id };
            }),
          };
        } else return p;
      })
    );
  }

  return (
    <>
      <div>
        {groups.map((group, i) => (
          <Button color={selectedGroup === group ? "primary" : "secondary"} onClick={(e) => setSelectedGroup(group)} key={i}>
            {group.name}
          </Button>
        ))}
      </div>
      <div style={{ minWidth: 600, display: "inline-block" }}>
        <table className="table mt-3">
          <thead className="thead-light">
            <tr>
              <th></th>
              {selectedOptions.map((o, i) => (
                <Fragment key={i}>
                  <th>
                    {o.name}
                    {optionsAppendText}
                  </th>
                </Fragment>
              ))}
            </tr>
          </thead>
          {products
            .filter((p) => p.group === selectedGroup)
            .map((p, i) => (
              <tr key={i}>
                <td>{p.name}</td>
                {selectedOptions.map((o, j) => {
                  let a = p[optionsFieldName].find((o2) => o2.id === o.id);
                  return (
                    <Fragment key={j}>
                      <td className="pt-0">
                        <div className="d-flex justify-content-start">
                          <CustomInput type="switch" id={`options-${i}-${j}`} checked={a ?? false} onChange={(e) => updateProduct(p.id, o, e.target.checked, optionsFieldName)} />
                          <button type="button" disabled={!a ?? true} className="btn btn-link px-0 no-focus" onClick={() => updatePrincipal(p, o)}>
                            <i className={`${(a ? a.principal : false) ? "fas text-success" : "far text-dark"} fa-circle`}></i>
                          </button>
                        </div>
                      </td>
                    </Fragment>
                  );
                })}
              </tr>
            ))}
        </table>
        <div className="text-right py-3">
          <i className="fas text-success fa-circle"></i> <FormattedMessage id="product_wizard.primary_curve" defaultMessage="Primary curve" />
        </div>
      </div>
    </>
  );
}

export default CurveOptionList;
