import React from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media,
} from "reactstrap";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";
import { FormattedMessage } from "react-intl";

function UserDropdown() {
  const { user } = useUser();
  const { logout } = useAuth();

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle className="pr-0" nav>
        <Media className="align-items-center">
          <i className="fas fa-user-circle fa-2x mr-1"></i>
          <Media className="ml-1 d-none d-lg-block">
            <span className="mb-0 text-sm font-weight-bold">
              {user.username}
            </span>
          </Media>
        </Media>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem className="noti-title" header tag="div">
          <h6 className="text-overflow m-0">
            <FormattedMessage id="user.welcome" defaultMessage="Welcome" />
          </h6>
        </DropdownItem>
        <DropdownItem to="/admin/user-profile" tag={Link}>
          <i className="ni ni-settings-gear-65" />
          <span>
            <FormattedMessage id="user.settings" defaultMessage="Settings" />
          </span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem href="#pablo" onClick={handleLogout}>
          <i className="ni ni-user-run" />
          <span>
            <FormattedMessage id="user.logout" defaultMessage="Logout" />
          </span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default UserDropdown;
