import HttpAccessor from "./HttpAccessor";

class HttpStore extends HttpAccessor {
  constructor(http, path, paramReducer) {
    super(http, path, paramReducer);
  }

  async store(obj, headers) {
    let response = await this._http.request({
      method: "post",
      url: this._path,
      data: obj,
      headers: headers,
    });

    return response.data;
  }
}

export default HttpStore;
