import React, { useContext } from "react";

const ServiceContext = React.createContext({});

function ServiceProvider(props) {
    return (
        <ServiceContext.Provider value={props.services} {...props} />
    );
}

function useService() {
    const context = useContext(ServiceContext);
    if(context == null) {
        throw new Error("useService must be used within a ServiceProvider.");
    }
    return context;
}

export { ServiceProvider, useService };