import React from "react";
import ProductAttributes, { sectionTypes } from "../../../products/ProductAttributes";
import ProductInputContainer from "../ProductInputContainer";

function AerodynamicParameters(props) {
  const productAttributes = new ProductAttributes({ object: props.product, attributes: props.productAttributes, update: props.update });

  return (
    <ProductInputContainer {...props} inputs={productAttributes.getAttributes(sectionTypes.AERODYNAMIC)} title="product_information.aerodynamic_parameters"></ProductInputContainer>
  );
}

export default AerodynamicParameters;
