function dBLog(x) {
  return 10 * Math.log10(x);
}

function antidBLog(x) {
  return Math.pow(10, x / 10);
}

export default class Decibel {
  constructor(value) {
    if (value instanceof Decibel) {
      this.value = value.value;
    } else if (typeof value === "number") {
      this.value = value;
    } else {
      throw new Error("Argument 'value' must be an instance of 'Number' or 'Decibel'.");
    }
  }

//   get value() {
//     return this._value;
//   }

//   set value(value) {
//     this._value = value;
//   }

  add() {
    const args = [].slice.call(arguments);
    let value = dBLog(
      args
        .map((d) => d.value)
        .map(antidBLog)
        .reduce((p, v) => p + v, antidBLog(this.value))
    );

    return new Decibel(value);
  }

  subtract() {
    const args = [].slice.call(arguments);
    let value = dBLog(
      args
        .map((d) => d.value)
        .map(antidBLog)
        .reduce((p, v) => p - v, antidBLog(this.value))
    );

    return new Decibel(value);
  }
}
