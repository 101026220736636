import paths from "../paths";

class CurveTestService {
    constructor(http) {
        this._http = http;
    }

    async createCurveTest({ inputs }) {
        let response = await this._http.request({
            method: "post",
            url: `${paths.CURVE_PATH}/test`,
            data: inputs
        });

        return response.data;
    }

    async updateCurveTest({ id, inputs }) {
        let response = await this._http.request({
            method: "put",
            url: `${paths.CURVE_PATH}/test/${id.value}/${id.version}`,
            data: inputs
        });

        return response.data;
    }

    async originateCurveTest({ testId, curveId }) {
        let response = await this._http.request({
            method: "get",
            url: `${paths.CURVE_PATH}/test/${testId}/${curveId}`
        });

        return response.data;
    }

    async getCurveTest(curveTestId) {
        let response = await this._http.request({
            method: "get",
            url: `${paths.CURVE_PATH}/test/${curveTestId}`
        });

        return response.data;
    }

    async evaluateErPCompliance({ id, inputs }) {
        let url = `${paths.CURVE_PATH}/test`;
        if(id !== null) {
            url += `/${id.value}/${id.version}`;
        }
        url += "/erp/compliance";

        let response = await this._http.request({
            method: "post",
            url,
            data: inputs
        });

        return response.data;
    }

    async createDependentCurveTest({ id: { value, version }, specification }) {
        let response = await this._http.request({
            method: "post",
            url: `${paths.CURVE_PATH}/test/${value}/${version}/dependent`,
            data: specification
        });

        return response.data;
    }

    async getSubCurveDetails({id}) {
        let response = await this._http.request({
            method: "get",
            url: `${paths.CURVE_PATH}/test/${id}/subcurve`,
        });

        return response.data;
    }
}

export default CurveTestService;