import eventify from "../eventify";
import { CubicSplineAdaptor } from "./Spline";

export default class TransmissionOutputPowerMapper {
  constructor({ motor, enabled }) {
    this._motor = motor;
    if(this._motor) {
      this._spline = new CubicSplineAdaptor(this._motor.points.map(p => p.rMotor), this._motor.points.map(p => p.coefficient));
    }
    this._enabled = enabled;
    eventify(this);
  }

  get enabled() {
    return this._enabled;
  }

  set enabled(value) {
    if(this._enabled !== value) {
      this._enabled = value;
      this.fire("change");
    }
  }

  map({ value, index, array }) {
    if(this._enabled && this._motor && array.slice(0, index).filter(v => v.outputPower == 0).length == 0) {
      let power = value.outputPower;
      let point = this._motor.points[1];
      let step = 0.1;
      let flag = false;
      let efficiency = 0;
      while(efficiency <= 1.5 && efficiency > -0.5) {
        efficiency = efficiency + step;
        let y = this._spline.at(efficiency);
        let ptr = efficiency == 0 ? 0 : point.power1 * 1000 * efficiency / y;

        if(power == ptr) {
          value.outputPower = value.outputPower * y;
          return value;
        }

        if(power < ptr && !flag) {
          step = step / 2 * -1;
          flag = true;
        }

        if(power > ptr && flag) {
          step = step / 2 * -1;
          flag = false;
        }
      }

      return value;
    } else {
      return value;
    }
  }
}