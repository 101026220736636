import React, { useEffect, Fragment } from "react";
import { useTable, useFilters, usePagination, useExpanded } from "react-table";
import { Table } from "reactstrap";

import Filter from "./Filter";
import DefaultColumnFilter from "./DefaultColumnFilter";
import Pager from "./Pager";
import LoadedContent from "../LoadedContent";

function AppTable(props) {
  const {
    columns,
    data,
    manualFilters,
    autoResetFilters,
    onFilterChange,
    enablePagination,
    manualPagination,
    currentPage,
    pageSize,
    pageCount,
    onPageChange,
    loading,
    className,
    initialFilter,
    renderSubComponent,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canNextPage,
    canPreviousPage,
    gotoPage,
    state: { filters, pageIndex },
  } = useTable(
    {
      columns,
      data,
      manualFilters: manualFilters ?? false,
      autoResetFilters: autoResetFilters ?? false,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: currentPage ? currentPage - 1 : 0,
        pageSize: pageSize,
        filters: initialFilter
          ? Object.keys(initialFilter).map((f) => {
              return { id: f, value: initialFilter[f] };
            })
          : [],
      },
      manualPagination: manualPagination ?? false,
      pageCount,
    },
    useFilters,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    // reduce filters into single object
    onFilterChange && onFilterChange(filters.reduce((f, c) => ({ ...f, [c.id]: c.value }), {}));
  }, [filters]);

  useEffect(() => {
    handlePageChange(currentPage);
  }, [currentPage]);

  function handlePageChange(page) {
    gotoPage(page - 1);
    onPageChange && onPageChange(page);
  }

  function renderTableHeader(column) {
    return column.TableHeader ? (props) => column.render("TableHeader", props) : (props) => <th {...column.getHeaderProps(props)} />;
  }

  let renderRow = props.renderRow;
  if (!renderRow) {
    renderRow = function (row) {
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell) => {
            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
          })}
        </tr>
      );
    };
  }

  return (
    <LoadedContent loading={loading} overlay={true} style={{ height: "100%" }} className="d-flex flex-column">
      <div className="table-responsive flex-grow-1">
        <Table className={className} {...getTableProps()}>
          <thead className={"thead-light"}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <Fragment key={index}>
                    {renderTableHeader(column)({
                      children: (
                        <>
                          {column.render("Header")}
                          <Filter column={column} key={index} />
                        </>
                      ),
                    })}
                  </Fragment>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <Fragment key={row.id}>
                  {renderRow(row)}
                  {row.isExpanded && renderSubComponent && (
                    <tr>
                      <td colSpan={row.cells.length}>{renderSubComponent({ row })}</td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      {enablePagination && !loading ? (
        <div className={"ml-auto m-3"}>
          <Pager pageCount={pageCount} currentPage={pageIndex + 1} canNextPage={canNextPage} canPreviousPage={canPreviousPage} onPageChange={handlePageChange} />
        </div>
      ) : (
        <></>
      )}
    </LoadedContent>
  );
}

export default AppTable;
