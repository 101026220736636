import trackify from "../trackify";
import { ProductSettings } from "./ProductSettings";

export default class ProductCharacteristics {
    constructor(state, changes) {
        trackify(this, state, null);
        this._settings = new ProductSettings({ state: state.settings, changes, defaultSection: this.section });
    }

    get state() {
        return { ...this._state, settings: this._settings.state };
    }

    get changes() {
        return { ...this._settings.changes };
    }

    get settings() {
        return this._settings;
    }

    get section() {
        return this._get("section");
    }

    get impellerDiameter() {
        return this._get("impellerDiameter");
    }
}