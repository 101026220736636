import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, CustomInput, Row } from "reactstrap";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import ListerTableSelectionModal from "../Modals/ListerTableSelectionModal";
import { useService } from "../../context/ServiceContext";
import { createEmptyTest, datosPlacaModes } from "../../plates/PlateStructures";

function TestSelector({ testList, selectedTests, setSelectedTests, maxTests, /* mode, updateMode, */ updateProductTests }) {
  const [testModalOpen, setTestModalOpen] = useState(false);
  const [testMode, setTestMode] = useState();

  const intl = useIntl();
  const { plateProductTestLister, productTestsProvider, plateProductTestsProvider } = useService();

  function toggleTestModal(mode) {
    setTestMode(mode);
    setTestModalOpen(!testModalOpen);
  }

  function toggleTest(test, state) {
    if (state) {
      setSelectedTests(selectedTests.concat({ ...test }));
    } else {
      setSelectedTests(selectedTests.filter((t) => t.id !== test.id));
    }
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderedItems = reorder(selectedTests, result.source.index, result.destination.index);
    setSelectedTests(orderedItems);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getListStyle = (isDraggingOver) => ({
    height: 65 * selectedTests.length,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    height: 65,
    ...draggableStyle,
  });

  // Create empty test

  function createEmpty() {
    //updateMode(datosPlacaModes.EMPTY);
    //setSelectedTests(selectedTests.concat(createEmptyTest(selectedTests.length > 0 ? selectedTests.reduce((max, n) => (n.id > max.id ? n : max)).id + 1 : 0)));
    let customTest = selectedTests.filter(t => t.custom == true);
    setSelectedTests(selectedTests.concat(createEmptyTest(customTest.length > 0 ? customTest.reduce((min, n) => (n.id > min.id ? n : min)).id - 1 : -1)));
  }

  // Dependent and idEnsayo

  function handleTestSelection(selection) {
    if (selection) {
      //updateMode(testMode);
      getIdEnsayo([selection.id], testMode);
      toggleTestModal();
    }
  }

  async function getIdEnsayo(idEnsayos, mode) {
    var tests = [...selectedTests];
    var productTests = [];

    for (let i = 0; i < idEnsayos.length; i++) {
      const idEnsayo = idEnsayos[i];
      const productTest = await productTestsProvider.get({ idEnsayo: idEnsayo });
      if (productTest.tests?.length > 0) {
        const test = { ...productTest.tests[0], mode: mode };

        if (mode === datosPlacaModes.DEPENDENT)
          tests.push({ ...test, reasonForChange: { connection: test.connection, voltage: test.voltage?.value, subFrequency: test.frequency?.value, motor: test.codModeloMotor } });
        else tests.push(test);

        productTests = [...productTests, ...productTest.tests];
      }
    }

    setSelectedTests(tests);
    updateProductTests(productTests);

    /* // get attributes
    let attributes = await getProductAndTestAttibutes(template.templateDescription, tests);
    const testAttributes = attributes.tests;
    let t = {
      ...template,
      selectedTests: tests.map((t, i) => {
        return {
          ...t,
          attributes: testAttributes[i].attributes.map((a) => {
            return {
              name: a.name,
              unit: a.unit,
              inputType: a.inputType,
              value: a.value || a.originalValue,
              originalValue: a.originalValue,
            };
          }),
        };
      }),
    };

    updateTemplate(t);
    updateProductTests(productTests); */
  }

  function isDisabled(modeOfButton) {
    if (selectedTests.length > 0 && selectedTests.length == maxTests) return true;
    if (selectedTests.length == 0) return false;

    return false;

    // if (modeOfButton == datosPlacaModes.PRODUCT) return mode != datosPlacaModes.PRODUCT;
    // else if (modeOfButton == datosPlacaModes.DEPENDENT) return mode != datosPlacaModes.DEPENDENT;
    // else if (modeOfButton == datosPlacaModes.IDENSAYO) return mode != datosPlacaModes.IDENSAYO;
    // else if (modeOfButton == datosPlacaModes.EMPTY) return mode != datosPlacaModes.EMPTY;
  }

  function removeTest(test) {
    setSelectedTests(selectedTests.filter((t) => t.id !== test.id));
  }

  return (
    <Row className="mx-0">
      <Col className="py-3">
        <h2>
          <FormattedMessage id="datos_placa.selected_tests" defaultMessage="Selected tests" />
        </h2>
        <p>
          <FormattedMessage id="datos_placa.click_and_drag_to_reorder_tests" defaultMessage="Click and drag to reorder tests" />
        </p>
        {selectedTests.length == 0 && (
          <div className="rounded-lg bg-secondary text-center p-3">
            <FormattedMessage id="datos_placa.please_select" defaultMessage="Please select" /> {maxTests} <FormattedMessage id="datos_placa.tests" defaultMessage="tests" />
          </div>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`dr`}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                {selectedTests.map((t, i) => (
                  <Draggable draggableId={`cv-${i}`} index={i} key={i}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        ref={provided.innerRef}
                      >
                        <Row className="d-flex align-items-center border-bottom py-2">
                          <Col sm="9" className="d-flex pl-3 align-items-center">
                            <div className="pr-3">
                              <i className="fas fa-grip-vertical"></i>
                            </div>
                            {t.description}
                          </Col>
                          <Col sm="3">
                            <Button type="button" className="btn btn-link text-dark ml-4" onClick={() => removeTest(t)}>
                              <i className="fas fa-trash-alt"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="pt-2">
          <Button className="btn btn-danger btn-block mb-2 mx-0" disabled={isDisabled(datosPlacaModes.DEPENDENT)} onClick={() => toggleTestModal(datosPlacaModes.DEPENDENT)}>
            <FormattedMessage id="datos_placa.dependent_test" defaultMessage="Dependent idEnsayo" />
          </Button>
          <Button className="btn btn-danger btn-block mb-2 mx-0" disabled={isDisabled(datosPlacaModes.IDENSAYO)} onClick={() => toggleTestModal(datosPlacaModes.IDENSAYO)}>
            <FormattedMessage id="datos_placa.existing_idensayo" defaultMessage="Uses existing idEnsayo" />
          </Button>
          <Button className="btn btn-danger btn-block mb-2 mx-0" disabled={isDisabled(datosPlacaModes.EMPTY)} onClick={createEmpty}>
            <FormattedMessage id="datos_placa.blank" defaultMessage="No idEnsayo" />
          </Button>
        </div>
      </Col>
      <Col className="py-3 bg-secondary border-left" style={{ borderRadius: "0 0.4375rem 0.4375rem 0" }}>
        <h2>
          <FormattedMessage id="datos_placa.available_tests" defaultMessage="Available tests" />
        </h2>
        {testList
          .filter((t) => t.testType == datosPlacaModes.PRODUCT)
          .map((t, i) => (
            <Row className="d-flex align-items-center" key={i}>
              <Col sm="12" className="d-flex align-items-center">
                <CustomInput
                  type="switch"
                  disabled={isDisabled(datosPlacaModes.PRODUCT) && !selectedTests.some((st) => st.id === t.id)}
                  label={
                    <div>
                      <div>{t.description}</div>
                      <div>
                        <div className="d-inline mr-2">
                          <small>
                            <b>
                              <FormattedMessage id="attribute.IdEnsayo" defaultMessage="idEnsayo" />
                            </b>
                          </small>{" "}
                          {t.id}
                        </div>
                        <div className="d-inline mr-2">
                          <small>
                            <b>
                              <FormattedMessage id="datos_placa.labs_test" defaultMessage="labs test" />
                            </b>
                          </small>{" "}
                          {t.limS_Test_Id}
                        </div>
                      </div>
                      {t.principal && (
                        <div>
                          <span className="badge badge-danger">
                            <FormattedMessage id="product_information.principal" defaultMessage="Principal" />
                          </span>
                        </div>
                      )}
                    </div>
                  }
                  id={`custominput-test-${t.id}`}
                  checked={selectedTests.some((st) => st.id == t.id) || false}
                  onChange={(e) => toggleTest(t, e.target.checked)}
                />
              </Col>
            </Row>
          ))}
      </Col>
      <ListerTableSelectionModal
        title={intl.formatMessage({ id: "datos_placa.select_an_idensayo", defaultMessage: "Select an idEnsayo" })}
        columns={[
          { Header: intl.formatMessage({ id: "datos_placa.cod_producto_comercial", defaultMessage: "CodProductoComercial" }), accessor: "codProductoComercial" },
          { Header: intl.formatMessage({ id: "datos_placa.producto_comercial", defaultMessage: "ProductoComercial" }), accessor: "productoComercial" },
          { Header: intl.formatMessage({ id: "datos_placa.modelo_producto", defaultMessage: "ModeloProducto" }), accessor: "modeloProducto" },
          { Header: intl.formatMessage({ id: "datos_placa.modelo_curva", defaultMessage: "ModeloCurva" }), accessor: "modeloCurva" },
          { Header: intl.formatMessage({ id: "datos_placa.idensayo", defaultMessage: "idensayo" }), accessor: "id" },
          { Header: intl.formatMessage({ id: "datos_placa.codigo_ensayo", defaultMessage: "CodigoEnsayo" }), accessor: "codigoEnsayo" },
          { Header: intl.formatMessage({ id: "datos_placa.cod_modelo_producto", defaultMessage: "CodModeloProducto" }), accessor: "codModeloProducto" },
        ]}
        isOpen={testModalOpen}
        toggle={toggleTestModal}
        lister={plateProductTestLister}
        onSelect={handleTestSelection}
      />
    </Row>
  );
}

export default TestSelector;
