import React, { useState } from "react";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardFooter, Container, Progress } from "reactstrap";
import Header from "../../components/Header";
import LoadedContent from "../../components/LoadedContent";
import SubmitButton from "../../components/SubmitButton";
import { useService } from "../../context/ServiceContext";

function BulkDependents(props) {
    const { dependentGenerationService } = useService();
    const [loaded, setLoaded] = useState(false);
    const [started, setStarted] = useState(false);
    const [failed, setFailed] = useState(false);
    const [finished, setFinished] = useState(false);
    const [completedCount, setCompletedCount] = useState(0);
    const [count, setCount] = useState(0);
    const [percentageCompleted, setPercentageCompleted] = useState(0);
    const [messages, setMessages] = useState();

    useEffect(() => {
        (async () => {
            let progress = await dependentGenerationService.getProgress();
            
            handleProgressUpdate(progress);
            if(progress) {
                await dependentGenerationService.monitorProgress(handleProgressUpdate);
            }
        })();
    }, []);

    async function startProcess() {
        await dependentGenerationService.process(handleProgressUpdate);
    }

    function handleProgressUpdate(progress) {
        if(progress) {
            setFinished(progress.isFinished);
            setFailed(progress.isFailed);
            setCount(progress.count);
            setCompletedCount(progress.completedCount);
            setPercentageCompleted(progress.percentageCompleted);
            setMessages(progress.messages);
            setStarted(true);
        }

        setLoaded(true);
    }

    function renderProgress(renderRunButton) {
        const renderIcon = () => {
            if(failed) {
                return <i className="fas fa-times-circle text-danger"></i>
            } else if(finished) {
                return <i className="fas fa-check-circle"></i>;
            } else {
                return <i className="fas fa-spinner fa-pulse"></i>;
            }
        };

        const renderMessage = () => {
            if(failed) {
                return <FormattedMessage id="dependents.failed_x_of_y" defaultMessage="Failed processing {0} of {1}" values={[completedCount, count]} />;
            } else if(finished) {
                return <FormattedMessage id="dependents.finished_x_of_y" defaultMessage="Finished processing {0} of {1}." values={[completedCount, count]} />;
            } else {
                return <FormattedMessage id="dependents.processing_x_of_y" defaultMessage="Processing {0} of {1}." values={[completedCount, count]} />
            }
        };

        return <>
            <h2 className="text-center">
                {finished ? 
                    <FormattedMessage id="dependents.finished_generating" defaultMessage="Finished generating" />
                    : <FormattedMessage id="dependents.generating_dependent_tests" defaultMessage="Generating dependent idEnsayos..." />
                }
            </h2>
            
            {renderIcon()}
            
            <span className="ml-1">
                {renderMessage()}
            </span>
            
            <Progress value={percentageCompleted} className="mt-1" color={finished ? "success" : "primary"} />

            {messages && messages.map(m => <FormattedMessage id={m.id} defaultMessage={m.defaultMessage} />)}
        </>;
    }

    function renderStart(renderRunButton) {
        return <>
            <h2 className="text-center"><FormattedMessage id="dependents.generate_dependent_tests" defaultMessage="Generate dependent idEnsayos" /></h2>
            
            <p><FormattedMessage id="dependents.click_run_to_start" defaultMessage="Click 'Run' to generate new dependent idEnsayos." /></p>
            
        </>;
    }

    function renderRunButton() {
        const renderIcon = () => {
            if(finished) {
                return <i className="fas fa-redo-alt"></i>;
            } else if(started) {
                return <i className="fas fa-spinner fa-pulse"></i>
            } else {
                return <i className="far fa-play-circle"></i>
            }
        }

        return <Button
            className="btn-success float-right"
            onClick={() => startProcess()}
            disabled={started && !finished}>
            {renderIcon()} <FormattedMessage id="dependents.run" defaultMessage="Run" />
        </Button>
    }

    return <>
        <Header>
            <h1>
                <FormattedMessage id="dependents.bulk_generator" defaultMessage="Bulk Generator" />
            </h1>
        </Header>

        <Container fluid>
            <LoadedContent loading={!loaded}>
                <Card>
                    <CardBody className="px-4">
                        <div className="px-4">
                            {started ?
                                renderProgress() :
                                renderStart() }
                        </div>
                    </CardBody>

                    <CardFooter>
                        <div className="px-4">
                            {renderRunButton()}
                        </div>
                    </CardFooter>
                </Card>
            </LoadedContent>
        </Container>
    </>
}

export default BulkDependents;