import React from "react";
import { AuthProvider } from "./AuthContext";
import { UserProvider } from "./UserContext";
import { LanguageProvider } from "./LanguageContext";
import { ServiceProvider } from "./ServiceContext";
import { ConfigProvider } from "./ConfigContext";
import { CultureProvider } from "./CultureContext";
import config from "../config";
import Startup from "../Startup";

function AppProviders({ children }) {
  return (
    <ConfigProvider config={config}>
      <ServiceProvider services={Startup(config)} >
        <UserProvider>
          <LanguageProvider>
            <CultureProvider>
              <AuthProvider>{children}</AuthProvider>
            </CultureProvider>
          </LanguageProvider>
        </UserProvider>
      </ServiceProvider>
    </ConfigProvider>
  );
}

export default AppProviders;
