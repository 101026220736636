import React from "react";
import { useIntl } from "react-intl";
import { useService } from "../../context/ServiceContext";

import ListerTable from "../Tables/ListerTable";

export default function UnassignedProducts() {
    const { dashboardUnassignedProductsLister } = useService();
    const intl = useIntl();
    const columns =
        [
            { Header: intl.formatMessage({ id: "dashboard.productocomercial", defaultMessage: "Producto Comercial" }), accessor: "productoComercial" },
            { Header: intl.formatMessage({ id: "dashboard.modeloProductoGenerico", defaultMessage: "Modelo Producto Generico" }), accessor: "modeloProductoGenerico" },
            { Header: intl.formatMessage({ id: "dashboard.series", defaultMessage: "Series" }), accessor: "series" }
        ];
    return (
        <ListerTable
            className={"align-items-center table-flush"}
            columns={columns}
            lister={dashboardUnassignedProductsLister}
            //prefilter={prefilter}
            //initialFilter={initialFilter}
            pageSize={10}
            //onLoad={() => onTableLoad && onTableLoad()}
        />
    );
}