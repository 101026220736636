class MemoryCache {
    constructor() {
        this._storage = {};
    }

    setString(key, value) {
        if(value !== null) {
            this._storage[key] = value;
        } else {
            delete this._storage[key];
        }
    }

    getString(key) {
        return this._storage[key];
    }

    setObject(key, value) {
        this.setString(key, JSON.stringify(value));
    }

    getObject(key) {
        var s = this.getString(key);
        return s ? JSON.parse(s) : null;
    }
}

export default MemoryCache;