import React from "react";
import ProductAttributes, { sectionTypes } from "../../../products/ProductAttributes";
import ProductInputContainer from "../ProductInputContainer";

function SelectorParameters(props) {
  const productAttributes = new ProductAttributes({ object: props.product, attributes: props.productAttributes, update: props.update });
  return <ProductInputContainer {...props} inputs={productAttributes.getAttributes(sectionTypes.SELECTOR)} title="product_information.selector_parameters"></ProductInputContainer>;
}

export default SelectorParameters;
