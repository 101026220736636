import React from "react";
import { valueTypes } from "./ProductAttributeValueTypes";
import { blockedCharacters, setErrorType } from "./ProductValidation";

export const isUndefinedOrNullOrEmpty = (val) => val === undefined || val === null || val === "";

export async function setDefinitions(products, productTechnicalAttributesProvider, productCurveGroupAttributesProvider, productCurveAttributesProvider) {
  for (let p = 0; p < products.length; p++) {
    const product = products[p];

    let productAttributes = await productTechnicalAttributesProvider.get(product.conceptClassUnic);
    productAttributes = Object.values(productAttributes);

    if (productAttributes.some((a) => a.value?.type === "DefNameConcept")) {
      let attributes = [productAttributes];
      let objs = [product];
      if (product.curveGroups?.length > 0) {
        let group = product.curveGroups.find((g) => g.principalMG) || product.curveGroups[0];
        let groupAttributes = await productCurveGroupAttributesProvider.get(group.conceptClassUnic);
        let curveAttributes = await productCurveAttributesProvider.get(group.conceptClassUnic);
        attributes.push(Object.values(groupAttributes));
        attributes.push(Object.values(curveAttributes));
        objs.push(group);
        let curve = getPrincipalCurve(group);
        if (curve) objs.push(curve);
      }
      setAttributes(product, productAttributes, objs, attributes);
    }

    let groups = product.curveGroups;
    if (groups) {
      for (let g = 0; g < groups.length; g++) {
        const group = groups[g];

        let groupAttributes = await productCurveGroupAttributesProvider.get(group.conceptClassUnic);
        let curveAttributes = await productCurveAttributesProvider.get(group.conceptClassUnic);
        groupAttributes = Object.values(groupAttributes);
        curveAttributes = Object.values(curveAttributes);

        let attributes = [groupAttributes, productAttributes, curveAttributes];
        let objs = [group, product];

        let curve = getPrincipalCurve(group);
        if (curve) objs.push(curve);

        setAttributes(group, groupAttributes, objs, attributes);

        let curves = group.curves;
        if (curves) {
          curves.forEach((curve) => {
            let attributes = [curveAttributes];
            let objs = [curve, product];

            setAttributes(curve, curveAttributes, objs, attributes);
          });
        }
      }
    }
  }

  function getPrincipalCurve(group) {
    return group.curves?.length > 0 ? group.curves.find((c) => c.principal) || group.curves[0] : null;
  }

  function setAttributes(obj, attributes, supportingObjs, supportingAttributes) {
    const referenceObj = Array.isArray(obj) ? obj[0] : obj;

    attributes
      .filter((a) => a.value?.type === "DefNameConcept")
      .forEach((a) => {
        const attributeName = a.name;
        const existing =
          ((typeof referenceObj.id === "object" && referenceObj.id !== null && referenceObj.id.value > 0) || referenceObj.id > 0) &&
          !isUndefinedOrNullOrEmpty(referenceObj[attributeName]);
        const val = existing ? referenceObj[attributeName] : replaceTags(a.value.format, supportingObjs, supportingAttributes);
        const preserveFields = ["MODELOGRUPOCURVA", "MODELOCURVA"];
        if (!preserveFields.includes(attributeName.toUpperCase()) || !obj.id || obj.id.value === 0) {
          obj[a.name] = val;
        }
      });
  }

  return products;
}

export async function getGroupName(group, attributeProvider, conceptClassUnic) {
  const attributes = await attributeProvider.get(conceptClassUnic);
  return replaceTags("[CODTENSION]-[FILTERPRINCIPALMG]", group, attributes);
}

export async function checkForRequiredAttributes(attributeProvider, conceptClassUnic, object, errors) {
  const attributes = await attributeProvider.get(conceptClassUnic);

  if (object.series?.flagErpnorma === 0) {
    attributes["flagErPCompliantFixed"].isVisible = false;
    attributes["modeloKIT_ErP"].isVisible = false;
    attributes["idERPType"].isVisible = false;
  }

  function setDefaults(a, object) {
    if (a.value.default != "" && a.value.default != null) {
      let defaultValue = a.value.default;
      if (a.value.type === valueTypes.BOOL) {
        if (defaultValue == "0") defaultValue = false;
        if (defaultValue == "1") defaultValue = true;
      }
      object[a.name] = defaultValue;
    } else if (a.value.type === valueTypes.BOOL) {
      object[a.name] = a.isInverted ? true : false;
    }
  }

  function getMissingAttributes() {
    return Object.values(attributes).filter(
      (a) => a.required && a.isVisible && a.value.type !== valueTypes.DEFINITION && (object[a.name] === undefined || object[a.name] === "" || object[a.name] === -1)
    );
  }

  // Set all empty editable fields to defaults
  Object.values(attributes)
    .filter(
      (a) =>
        a.value.type !== valueTypes.FORMULA && (a.isVisible === false || object[a.name] === undefined || object[a.name] === null || object[a.name] === "" || object[a.name] === -1)
    )
    .forEach((a) => setDefaults(a, object));

  Object.values(attributes)
    .filter((a) => a.value.type === valueTypes.FORMULA)
    .forEach((a) => (object[a.name] = getFormulaValues(a.name, object, attributes)));

  let missingAttributes = getMissingAttributes();

  if (missingAttributes.length > 0) {
    // check for defaults
    missingAttributes.forEach((a) => {
      setDefaults(a, object);
    });

    // refresh missing attributes
    missingAttributes = getMissingAttributes();

    missingAttributes.forEach((a) => {
      errors[a.name] = { ...setErrorType("error.missing_attribute"), formattedMessage: a.labelFormattedMessage };
    });
  }

  let freeTextAttributes = Object.values(attributes).filter((a) => a.value.type === valueTypes.TEXT || a.value.type === valueTypes.DEFINITION);
  freeTextAttributes.forEach((a) => {
    if ((!object.id || object.id.value === 0) && object[a.name] && [...object[a.name].toString()].some((c) => blockedCharacters.includes(c))) {
      errors[a.name] = { ...setErrorType("error.invalid_attribute_character"), formattedMessage: a.labelFormattedMessage };
    }
  });
}

export function getFormulaValues(attributeClass, obj, attributes, replacingTags) {
  // bit wank but will do for now
  switch (attributeClass.toUpperCase()) {
    case "TYPECURRENT":
      if (obj.claseTension) {
        const phaseNumber = lookupValueInAttributeOptionsById(attributes, "claseTension", obj.claseTension, "phaseNumber");
        return phaseNumber;
      }
      return null;
    case "CODFRECUENCIA":
      if (obj.claseTension) {
        const frequencyCode = lookupValueInAttributeOptionsById(attributes, "claseTension", obj.claseTension, "frequencyCode");
        return frequencyCode;
      }
      return null;
    case "CODTENSION":
      if (obj.claseTension) {
        if (!replacingTags) return obj.claseTension;
        const voltage = lookupValueInAttributeOptionsById(attributes, "claseTension", obj.claseTension, "voltage");
        return voltage;
      } else if (obj.codTension) {
        if (!replacingTags) return obj.codTension;
        const voltage = lookupValueInAttributeOptionsById(attributes, "claseTension", obj.codTension, "voltage");
        return voltage;
      }
      return null;

    case "FLAGPRODUCTREG":
      //IF flagregulationtension=1 OR flagregulationfrequency=1, THEN flagProductReg=1. IF NOT, 0.
      if (obj.flagRegulationTension === true || obj.flagRegulationFrecuency === true) {
        return true;
      } else {
        return attributes[attributeClass].value.default == "1";
      }
    case "DIRECTO":
      return null;
    case "CURVEID":
      if (attributes[attributeClass].value.default != undefined && attributes[attributeClass].value.default !== "") {
        return attributes[attributeClass].value.default;
      } else {
        return parseFloat(obj.tension) || parseFloat(attributes.tension?.value.default) || null;
      }
    case "CODMODELOCURVA_VERSION":
    case "CODMODELOGRUPOCUVA":
    case "ORDEN":
      return 0;
    case "REFERENCEPRODUCT":
      return obj.referenceProduct;
    case "PRINCIPAL":
      return obj.principal || false;
  }
}

export function isRequired(attributeClass, attributes) {
  const attribute = attributes[attributeClass];
  if (!attribute) return;
  return attribute.required ? (
    <>
      {" "}
      <sup>
        <i className="fas fa-asterisk" style={{ fontSize: 8 }} />
      </sup>
    </>
  ) : (
    <></>
  );
}

export function replaceTags(definition, obj, attributes, replaceMissingWithBlankString) {
  if (!definition) return;
  const re = /\[([^\]]+)\]/g;
  const matches = definition.match(re);

  if (!matches) return definition;

  const objs = !Array.isArray(obj) ? [obj] : obj;
  objs.forEach((obj) => {
    const keys = Object.keys(obj);
    matches.forEach((element) => {
      const attributeClass = element.replace("[", "").replace("]", "");
      let fieldValue;
      if (attributes) {
        const attribute = findAttribute(attributes, attributeClass);
        // If formula lookup value
        if (attribute && attribute.value && attribute.value.type === valueTypes.FORMULA) {
          fieldValue = getFormulaValues(attributeClass, obj, attributes, true);
        }

        // Get value from object
        if (!fieldValue) {
          fieldValue = obj[keys.find((k) => k.toUpperCase() === attributeClass)];
        }

        // Lookup textual value from the options
        if (attributeClass.toUpperCase() === "CODTENSION") {
          fieldValue = lookupValueInAttributeOptionsById(attributes, attributeClass, fieldValue, "voltage") || fieldValue;
        } else if (attributeClass.toUpperCase() === "FRECUENCIA" && obj.codFrecuencia) {
          fieldValue =
            lookupValueInAttributeOptionsById(attributes, "codFrecuencia", obj.codFrecuencia) ||
            lookupValueInAttributeOptionsById(attributes, "frecuencia", obj.frecuencia) ||
            fieldValue;
        } else {
          fieldValue = lookupValueInAttributeOptionsById(attributes, attributeClass, fieldValue) || fieldValue;
        }

        if (!fieldValue && attribute && attribute.value.default) {
          fieldValue = lookupValueInAttributeOptionsById(attributes, attributeClass, attribute.value.default) || attribute.value.default;
        }

        definition = definition.replace(element, fieldValue || element);
      }
    });
  });

  const remainingMatches = definition.match(re);
  if (replaceMissingWithBlankString && remainingMatches && remainingMatches.length > 0) {
    objs.forEach((obj) => matches.forEach((element) => (definition = definition.replace(element, ""))));
  }
  return definition;
}

export function findAttribute(attributes, attributeClass) {
  const attrs = !Array.isArray(attributes) ? [attributes] : attributes;
  for (let i = 0; i < attrs.length; i++) {
    const attr = attrs[i];
    const attributesKeys = Object.keys(attr);
    const attribute = attr[attributesKeys.find((k) => k.toUpperCase() === attributeClass.toUpperCase())];
    if (attribute) {
      return attribute;
    }
  }
  return null;
}

export function lookupValueInAttributeOptionsById(attributes, attributeClass, lookupValue, optionalField) {
  const attrs = !Array.isArray(attributes) ? [attributes] : attributes;

  for (let i = 0; i < attrs.length; i++) {
    const attr = attrs[i];
    const attributesKeys = Object.keys(attr);
    const attribute = attr[attributesKeys.find((k) => k.toUpperCase() === attributeClass.toUpperCase())];
    if (attribute && attribute.value) {
      const valueOptions = attribute.value.options;
      if (valueOptions) {
        const detailValue = valueOptions.find((v) => v.id == lookupValue);
        if (detailValue) return optionalField ? detailValue[optionalField] : detailValue.value;
      }
    }
  }

  return null;
}
