import React from "react";
import { ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import CustomList from "./CustomList";

function SubFrequenciesStep({ next, prev, availableSubFrequencies, subFrequencies, setSubFrequencies, customSubFrequencies, setCustomSubFrequencies }) {
  return (
    <ValidationForm onSubmit={next}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.sub_frequencies" defaultMessage="Sub-frequencies" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage id="product_wizard.choose_group_sub_frequencies" defaultMessage="Choose the group of sub-frequencies that you use to distinguish your curves:" />
          </p>
          <div style={{ display: "inline-block", minWidth: 500 }}>
            <CustomList
              list={availableSubFrequencies}
              values={subFrequencies}
              setValues={setSubFrequencies}
              customValues={customSubFrequencies}
              setCustomValues={setCustomSubFrequencies}
              addButtonText="product_wizard.frequency_personalized"
              examplePlaceholder="Example: 30Hz"
            ></CustomList>
          </div>
        </CardBody>
        <CardFooter>
          <Button className="btn btn-dark float-right">
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default SubFrequenciesStep;
