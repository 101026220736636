import React, { useEffect } from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, CardHeader, Col, Form, Nav, NavItem, NavLink, Row } from "reactstrap";
import { useProduct } from "../../context/ProductContext";
import { replaceTags } from "../../products/ProductDefinitions";
import { getErrors } from "../../products/ProductValidation";
import { fireAlert, fireConfirm } from "../../utils/alerts";
import GroupEdit from "./CurvesInformation/GroupEdit";
import ErrorSummary from "./ErrorSummary";
import ProductProgress from "./ProductProgress";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { ValidationForm } from "../ValidationForm";

function ProductCurvesInformation({ onNext, onPrevious, productCurveAttributesProvider, productCurveGroupAttributesProvider, productConceptsProvider, progressItems }) {
  const intl = useIntl();
  const { product, updateProduct, isNewProduct, checkCurveGroupValidity, checkCurveValidity, checkGroupValidity } = useProduct();
  const { curveGroups, series } = product;
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [errors, setErrors] = useState(null);
  const [groupAttributes, setGroupAttributes] = useState();
  const [conceptClasses, setConceptClasses] = useState([]);
  const [dragging, setDragging] = useState(false);

  const isProductReadOnly = product?.isReadOnly || false;

  useEffect(() => {
    getConcepts();
  }, [series]);

  async function getConcepts() {
    if (!series) return;
    setConceptClasses(await productConceptsProvider.get(series.id));
  }

  if (curveGroups.length === 0) addGroup();

  function switchGroup(index) {
    setSelectedGroup(index);
  }

  function newGroup() {
    return {
      index: curveGroups.length > 0 ? curveGroups.reduce((max, n) => (n.index > max.index ? n : max)).index + 1 : 0,
      codModeloDato_Version: 0,
      activo: true,
      conceptClassUnic: curveGroups.length === 0 ? product.conceptClassUnic : curveGroups[0].conceptClassUnic,
      principalMG: curveGroups.length === 0,
      codTension: product.codTension,
      codFrecuencia: product.codFrecuencia,
      tension: product.tension,
      frecuencia: product.frecuencia,
      polos: product.polos,
      curves: [
        {
          index: 0,
          activo: true,
          principal: true,
          claseTension: product.codTension,
          frecuencia: product.frecuencia,
          codFrecuencia: product.codFrecuencia,
          polos: product.polos,
          cuerpo: product.cuerpo,
          tamany: product.tamanyNominal,
          referenceProduct: curveGroups.length === 0 ? 1 : 0,
          diametro: null,
          caudal: null,
        },
      ],
    };
  }

  function addGroup() {
    const curveGroup = newGroup();
    updateProduct({
      ...product,
      curveGroups: curveGroups.concat({ ...curveGroup }),
    });
  }

  function removeGroup(curveGroup) {
    if (curveGroups.length === 1) return;

    fireConfirm(
      intl.formatMessage({ id: "warnings.confirm_group_delete", defaultMessage: "Are you sure you want to remove this group?" }),
      intl.formatMessage({ id: "warnings.confirm_group_delete_title", defaultMessage: "Remove group" }),
      "question",
      intl.formatMessage({ id: "app.yes", defaultMessage: "Yes" }),
      intl.formatMessage({ id: "app.no", defaultMessage: "No" }),
      () => {
        let groupIndex = curveGroups.indexOf(curveGroup);

        updateProduct({
          ...product,
          curveGroups: curveGroups
            .filter((g) => g.index !== curveGroup.index)
            .map((g, i) => {
              if (curveGroup.principalMG && i === 0) {
                return {
                  ...g,
                  principalMG: true,
                };
              } else {
                return {
                  ...g,
                };
              }
            }),
        });

        if (groupIndex > 0) groupIndex--;

        switchGroup(groupIndex);
      },
      () => {}
    );
  }

  function updateGroupPrincipal(e) {
    const checked = e.target.checked;
    if (selectedGroup === 0 && !checked) {
      return;
    }

    updateProduct({
      ...product,
      curveGroups: curveGroups
        .map((g, i) => {
          const isPrincipal = g.index === curveGroups[selectedGroup].index ? checked : !checked && i === 0 ? true : false;
          return {
            ...g,
            principalMG: isPrincipal,
            curves: g.curves.map((c) => {
              if (!isPrincipal) c.referenceProduct = 0;
              else if (g.curves.length == 1 || (g.curves.some((c2) => c2.referenceProduct == 1) == false && c.principal)) c.referenceProduct = 1;
              return c;
            }),
          };
        })
        .sort(function (a, b) {
          return a.principalMG ? -1 : 1;
        }),
    });

    setSelectedGroup(0);
  }

  async function cloneGroup(index) {
    let group = { ...curveGroups.find((c) => c.index === index) };

    const errors = await checkGroupValidity(group);
    if (errors.hasErrors) {
      fireAlert(<ErrorSummary intl={intl} groupErrors={errors}></ErrorSummary>, "Incorrect attributes", "error");
      return;
    }

    group.index = curveGroups.length > 0 ? curveGroups.reduce((max, n) => (n.index > max.index ? n : max)).index + 1 : 0;
    group.principalMG = false;
    group.curves = group.curves.map((c) => {
      return { ...c };
    });

    updateProduct({
      ...product,
      curveGroups: curveGroups.concat({ ...group }),
    });
  }

  async function validate(e) {
    e.preventDefault();

    if (isProductReadOnly) {
      return;
    }

    const errors = await checkCurveGroupValidity(product);
    setErrors(errors);
    if (!errors.hasErrors) {
      onNext();
    } else {
      fireAlert(<ErrorSummary intl={intl} errors={errors}></ErrorSummary>, "Incorrect attributes", "error");
    }
  }

  // Group reordering

  function onDragStart() {
    setDragging(true);
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const selectedGroupIndex = curveGroups[selectedGroup].index;
    const orderedItems = reorder(curveGroups, result.source.index, result.destination.index).map((g, i) => {
      g.principalMG = i == 0;
      return g;
    });

    updateProduct({
      ...product,
      curveGroups: orderedItems,
    });

    setDragging(false);
    setSelectedGroup(orderedItems.findIndex((g) => g.index === selectedGroupIndex));
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  const isSinCurva = conceptClasses?.find((c) => c.id == product.conceptClassUnic)?.value == "SinCurva";

  return (
    <ValidationForm onSubmit={validate}>
      <Row>
        <Col xl={3} className="d-sm-none d-xl-block">
          <ProductProgress onNext={isProductReadOnly ? undefined : () => {}} onPrevious={onPrevious} items={progressItems}></ProductProgress>
        </Col>
        <Col xl={9}>
          <Card>
            <CardHeader>
              <FormattedMessage id="product_curves_information.title" defaultMessage="Please enter the curve information" />
            </CardHeader>
            <CardBody className="p-3">
              <Row>
                <Col>
                  <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onDragStart}>
                    <Droppable droppableId={`gr-dr`} direction="horizontal">
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          <Nav tabs className="pb-2">
                            {curveGroups.map((g, i) => (
                              <Draggable draggableId={`gc-${i}`} index={i} key={i} isDragDisabled={isProductReadOnly}>
                                {(provided, snapshot) => (
                                  <NavItem key={g.index} onClick={() => switchGroup(i)}>
                                    <div
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                      ref={provided.innerRef}
                                    >
                                      <NavLink active={curveGroups[selectedGroup]?.index === g.index} href="#">
                                        {replaceTags("[CODTENSION]-[FILTERPRINCIPALMG]", g, groupAttributes)}{" "}
                                        {g.principalMG && (
                                          <span className={`badge ${curveGroups[selectedGroup]?.index === g.index ? "badge-danger" : "badge-dark"}`}>Principal</span>
                                        )}
                                      </NavLink>
                                    </div>
                                  </NavItem>
                                )}
                              </Draggable>
                            ))}
                            {!dragging && !isProductReadOnly && !isSinCurva && (
                              <li className="nav-item">
                                <Button color="primary" type="button" onClick={addGroup} size="sm">
                                  <i className="fas fa-plus"></i> <FormattedMessage id="product_information.add_group" defaultMessage="Add group" />
                                </Button>
                              </li>
                            )}
                          </Nav>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {curveGroups.map((g, i) => (
                    <GroupEdit
                      key={g.index}
                      conceptClasses={conceptClasses}
                      product={product}
                      isNewProduct={isNewProduct}
                      groupIndex={i}
                      groups={curveGroups}
                      updateProduct={updateProduct}
                      removeGroup={removeGroup}
                      cloneGroup={cloneGroup}
                      updateGroupPrincipal={updateGroupPrincipal}
                      visible={selectedGroup === i}
                      groupAttributes={groupAttributes}
                      setGroupAttributes={setGroupAttributes}
                      productCurveAttributesProvider={productCurveAttributesProvider}
                      productCurveGroupAttributesProvider={productCurveGroupAttributesProvider}
                      productConceptsProvider={productConceptsProvider}
                      validationErrors={errors}
                      checkCurveValidity={checkCurveValidity}
                    ></GroupEdit>
                  ))}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ValidationForm>
  );
}

export default ProductCurvesInformation;
