import eventify from "../eventify";

export default class CoefficientMapper {
  constructor({ coefficient }) {
    this._coefficient = coefficient;
    eventify(this);
  }

  get coefficient() {
    return this._coefficient;
  }

  set coefficient(value) {
    if(this._coefficient !== value) {
      this._coefficient = value;
      this.fire("change");
    }
  }

  map({ value }) {
    const factor = Math.pow(this._coefficient, 1 / 3);
    const squaredFactor = Math.pow(factor, 2);

    return {
        ...value,
        airflow: value.airflow * factor,
        staticPressure: value.staticPressure * squaredFactor,
        dynamicPressure: value.dynamicPressure * squaredFactor,
        totalPressure: value.totalPressure * squaredFactor
    }
  }
}