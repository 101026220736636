import cloneDeep from "lodash.clonedeep";
import merge from "lodash.merge";
import React from "react";
import { Scatter } from "react-chartjs-2";
import { useIntl } from "react-intl";
import { Col, Row } from "reactstrap";

const colors = ["rgba(220,53,69,1)", "rgba(45,206,137,1)", "rgba(255,214,0,1)", "rgba(17,205,239,1)", "rgba(251,99,64,1)", "rgba(94,114,228,1)"];

export default function CurvesChart({ dataSet, onDragUpdate, enableEditing, precision }) {
  const intl = useIntl();
  dataSet = dataSet ?? [];
  const staticPressureChartPoints = dataSet.reduce((res, arr) => [...res, { x: arr.airflow, y: arr.staticPressure }], []);
  const powerChartPoints = dataSet.reduce((res, arr) => [...res, { x: arr.airflow, y: arr.inputPower }], []);
  const staticEffChartPoints = dataSet.reduce((res, arr) => [...res, { x: arr.airflow, y: arr.inputStaticEfficiency }], []);
  const totalEffChartPoints = dataSet.reduce((res, arr) => [...res, { x: arr.airflow, y: arr.inputTotalEfficiency }], []);
  const rpmChartPoints = dataSet.reduce((res, arr) => [...res, { x: arr.airflow, y: arr.speed }], []);
  const currentChartPoints = dataSet.reduce((res, arr) => [...res, { x: arr.airflow, y: arr.electricalCurrent }], []);
  const dynamicMinOptions = (points) => {
    if (points.every((p) => p.y >= 0)) {
      return {};
    } else {
      return {
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
      };
    }
  };
  const dragPointsOptions = (variable) => {
    return {
      dragData: true,
      dragDataRound: 1,
      dragOptions: {
        showTooltip: true,
      },
      onDrag: function (e, datasetIndex, index, value) {
        if (enableEditing) e.target.style.cursor = "grabbing";
      },
      onDragEnd: function (e, datasetIndex, index, value) {
        e.target.style.cursor = "default";
        onDragUpdate(index, variable, value);
      },
      hover: {
        onHover: function (e) {
          const point = this.getElementAtEvent(e);
          if (point.length && enableEditing) e.target.style.cursor = "grab";
          else e.target.style.cursor = "default";
        },
      },
    };
  };

  return (
    <Row>
      <Col lg="6" xl="4">
        <TestChart
          data={staticPressureChartPoints}
          options={{ ...dynamicMinOptions(staticPressureChartPoints), ...dragPointsOptions("staticPressure") }}
          labels={{
            x: intl.formatMessage({ id: "curves_generator.airflow", defaultMessage: "Airflow" }),
            y: intl.formatMessage({ id: "curves_generator.static_pressure", defaultMessage: "Static pressure" }),
          }}
          units={{ x: "m³/h", y: "Pa" }}
          supportsDragging={enableEditing}
          colour={colors[0]}
        ></TestChart>
      </Col>
      <Col lg="6" xl="4">
        <TestChart
          data={powerChartPoints}
          options={dynamicMinOptions(powerChartPoints)}
          colour={colors[1]}
          labels={{
            x: intl.formatMessage({ id: "curves_generator.airflow", defaultMessage: "Airflow" }),
            y: intl.formatMessage({ id: "curves_generator.power", defaultMessage: "Power" }),
          }}
          units={{ x: "m³/h", y: "W" }}
          precision={precision}
        ></TestChart>
      </Col>
      <Col lg="6" xl="4">
        <TestChart
          data={staticEffChartPoints}
          options={dynamicMinOptions(staticEffChartPoints)}
          colour={colors[2]}
          labels={{
            x: intl.formatMessage({ id: "curves_generator.airflow", defaultMessage: "Airflow" }),
            y: intl.formatMessage({ id: "curves_generator.static_efficiency", defaultMessage: "Static efficiency" }),
          }}
          units={{ x: "m³/h", y: "%" }}
          precision={precision}
        ></TestChart>
      </Col>
      <Col lg="6" xl="4">
        <TestChart
          data={totalEffChartPoints}
          options={dynamicMinOptions(totalEffChartPoints)}
          colour={colors[3]}
          labels={{
            x: intl.formatMessage({ id: "curves_generator.airflow", defaultMessage: "Airflow" }),
            y: intl.formatMessage({ id: "curves_generator.total_efficiency", defaultMessage: "Total efficiency" }),
          }}
          units={{ x: "m³/h", y: "%" }}
          precision={precision}
        ></TestChart>
      </Col>
      <Col lg="6" xl="4">
        <TestChart
          data={rpmChartPoints}
          options={dynamicMinOptions(rpmChartPoints)}
          colour={colors[4]}
          labels={{
            x: intl.formatMessage({ id: "curves_generator.airflow", defaultMessage: "Airflow" }),
            y: intl.formatMessage({ id: "curves_generator.speed", defaultMessage: "Speed" }),
          }}
          units={{ x: "m³/h", y: "rpm" }}
          precision={precision}
        ></TestChart>
      </Col>
      <Col lg="6" xl="4">
        <TestChart
          data={currentChartPoints}
          options={dynamicMinOptions(currentChartPoints)}
          colour={colors[5]}
          labels={{
            x: intl.formatMessage({ id: "curves_generator.airflow", defaultMessage: "Airflow" }),
            y: intl.formatMessage({ id: "curves_generator.current", defaultMessage: "Current" }),
          }}
          units={{ x: "m³/h", y: "A" }}
          precision={precision}
        ></TestChart>
      </Col>
    </Row>
  );
}

function TestChart({ data, colour, options, supportsDragging, labels, units, precision }) {
  let defaults = {};
  options = merge(defaults, options);

  if (labels) {
    ["x", "y"].forEach((axis) => {
      if (labels[axis]) {
        options.scales = options.scales ?? {};
        const scales = (options.scales[axis + "Axes"] = options.scales[axis + "Axes"] ?? []);
        let scale = scales[0] ?? {};

        let label = labels[axis];
        if (units[axis]) {
          label += ` (${units[axis]})`;
        }

        scale = merge(
          {
            scaleLabel: {
              display: true,
              labelString: label,
            },
          },
          scale
        );

        scales[0] = scale;
      }
    });
  }

  if (!options.tooltips) {
    options.tooltips = {
      callbacks: {
        label: (tooltipItem, data) => {
          var x = tooltipItem.xLabel.toFixed(precision);
          var y = tooltipItem.yLabel.toFixed(precision);

          if (units.x) {
            x += ` ${units.x}`;
          }

          if (units.y) {
            y += ` ${units.y}`;
          }

          return [x, y];
        },
      },
    };
  }

  return (
    <div className="chart mb-3">
      <Scatter
        data={{
          datasets: [
            {
              dragData: supportsDragging,
              showLine: true,
              fill: false,
              borderColor: colour,
              backgroundColor: "rgba(75,192,192,0.4)",
              pointBorderColor: "rgba(75,192,192,1)",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(75,192,192,1)",
              pointHoverBorderColor: "rgba(220,220F,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 3,
              pointHitRadius: 10,
              data: data,
            },
          ],
        }}
        options={{ ...defaults, ...options }}
      />
    </div>
  );
}
