import eventify from "../eventify";

export default class TransmissionNormalisationMapper {
  constructor({ enabled, normalisation }) {
    this._enabled = enabled;
    this._normalisation = normalisation;
    eventify(this);
  }

  get enabled() {
    return this._enabled;
  }

  set enabled(value) {
    if(this._enabled !== value) {
      this._enabled = value;
      this.fire("change");
    }
  }

  get normalisation() {
    return this._normalisation;
  }

  set normalisation(value) {
    if(this._normalisation !== value) {
      this._normalisation = value;
      this.fire("change");
    }
  }

  map({ value, index, array }) {
    if(this._enabled && index > 0) {
      value.speed = value.speed * this._normalisation / array[index - 1].speed;
    } else {
      return value;
    }
  }
}