import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, FormGroup, Label } from "reactstrap";
import { useProduct } from "../../context/ProductContext";

function SelectedSeries() {
  const { product } = useProduct();
  const { series } = product;

  return (
    <Card className="mb-3 selected-series blur">
      <CardBody className="p-2">
        <FormGroup row className="m-0 d-flex flex-row align-items-center">
          <Label className="my-0 ml-3 mr-5">
            <FormattedMessage id="selected_series.specified_series_title" defaultMessage="Specified series" />
          </Label>
          {series && (
            <div className="d-flex align-items-center mr-3">
              {series.image && (
                <div>
                  <img src={series.image} alt={series.name} />
                </div>
              )}
              <div className="ml-3">
                <small>{series.name}</small>
              </div>
            </div>
          )}
          {product.clone && (
            <>
              <Label className="my-0 ml-5 mr-5">
                <FormattedMessage id="selected_series.cloned_from" defaultMessage="Cloned from" />
              </Label>
              <div className="d-flex align-items-center">
                <div className="ml-3">
                  <small>{product.originalModeloProductoGenerico}</small>
                </div>
              </div>
            </>
          )}
          {product.modeloProductoGenerico && (
            <>
              <Label className="my-0 ml-5 mr-5">
                <FormattedMessage id="selected_series.specified_modelo_producto" defaultMessage="Specified product" />
              </Label>
              <div className="d-flex align-items-center">
                <div className="ml-3">
                  <small>{product.modeloProductoGenerico}</small>
                </div>
              </div>
            </>
          )}
        </FormGroup>
      </CardBody>
    </Card>
  );
}

export default SelectedSeries;
