import React from "react";
import { Route, Switch } from "react-router-dom";
import Certificates from "./Home";

function CertificatesIndex() {
  return (
    <Switch>
      <Route
        exact={true}
        path={"/certificates"}
        render={(props) => {
          return <Certificates {...props} />;
        }}
      />
    </Switch>
  );
}

export default CertificatesIndex;
