import React from "react";
import { lookupValueInAttributeOptionsById } from "../../../products/ProductDefinitions";
import ProductInputContainer from "../ProductInputContainer";

function ElectricalParameters({ productAttributes, curve, updateCurve, updateCurveByObj, disabled }) {
  const inputs = {
    tension: {
      label: "Tension",
      labelMessageId: "product_information.tension",
    },
    frecuencia: {
      label: "frecuencia",
      labelMessageId: "product_information.frecuencia",
    },
    bobinado: {
      label: "bobinado",
      labelMessageId: "product_information.bobinado",
    },
    bobinado2: {
      label: "bobinado2",
      labelMessageId: "product_information.bobinado2",
    },
    configuracion: {
      label: "configuracion",
      labelMessageId: "product_information.configuracion",
    },
    claseTension: {
      label: "claseTension",
      labelMessageId: "product_information.claseTension",
      update: claseTensionUpdate,
    },
    typeCurrent: {
      label: "typeCurrent",
      labelMessageId: "product_information.typeCurrent",
    },
    conexion: {
      label: "conexion",
      labelMessageId: "product_information.conexion",
    },
  };

  function claseTensionUpdate(e) {
    //const typeCurrent = lookupValueInAttributeOptionsById(productAttributes, "claseTension", e.target.value, "typeCurrent");
    //const phaseNumber = lookupValueInAttributeOptionsById(productAttributes, "claseTension", e.target.value, "phaseNumber");
    updateCurveByObj({
      ...curve,
      claseTension: e.target.value,
      frecuencia:
        curve.frecuencia === undefined || curve.frecuencia === ""
          ? lookupValueInAttributeOptionsById(productAttributes, "claseTension", e.target.value, "frequency")
          : curve.frecuencia,
    });
  }

  return (
    <ProductInputContainer
      productAttributes={productAttributes}
      product={curve}
      update={updateCurve}
      inputs={inputs}
      title="product_information.electrical_parameters"
      disabled={disabled}
    ></ProductInputContainer>
  );
}

export default ElectricalParameters;
