import eventify from "../eventify";

export default class PointCalculationMapper {
  constructor(outletArea) {
    this._outletArea = outletArea;

    eventify(this);
  }

  get outletArea() {
    return this._outletArea;
  }

  set outletArea(value) {
    this._outletArea = value;
    this.fire("change");
  }

  map({ value }) {
    let velocity = (value.airflow / 3600) / this.outletArea;
    let dynamicPressure = value.dynamicPressure ?? 0.5 * 1.2 * Math.pow(velocity, 2);
    let totalPressure = value.staticPressure + dynamicPressure;
    let calculated = {
      dynamicPressure,
      totalPressure,
      inputStaticEfficiency: calculateEfficiency(value.inputPower, value.airflow, value.staticPressure),
      inputTotalEfficiency: calculateEfficiency(value.inputPower, value.airflow, totalPressure),
      outputStaticEfficiency: calculateEfficiency(value.outputPower, value.airflow, value.staticPressure),
      outputTotalEfficiency: calculateEfficiency(value.outputPower, value.airflow, totalPressure),
      specificFanPower: value.airflow > 0 ? value.inputPower / (value.airflow / 3600) : 0
    };

    calculated = Object.keys(calculated).reduce((values, key) => ({ ...values, [key]: values[key] }), calculated);

    return { ...value, ...calculated };
  }
}

function calculateEfficiency(power, airflow, pressure) {
  const efficiency = power > 0 ? airflow / 3600 * pressure * 100 / power : 0;

  if (isNaN(efficiency)) {
    return 0;
  } else {
    return efficiency;
  }
}