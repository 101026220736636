import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, CardHeader, FormGroup } from "reactstrap";
import ProductInput from "./ProductInput";

function ProductInputContainer({ children, childrenIndex, productAttributes, product, update, isWizard, inputs, title, disabled, checkValidation, validationErrorText }) {
  const keys = Object.keys(inputs);
  const attributeCheck = Object.keys(productAttributes).some((a) => keys.includes(a) && productAttributes[a].isVisible);

  return attributeCheck ? (
    <Card className="mt-2">
      <CardHeader>
        <FormattedMessage id={title} defaultMessage={title} />
      </CardHeader>
      <CardBody>
        <FormGroup row>
          {keys.map((a, i) => (
            <Fragment key={a}>
              {childrenIndex == i && children}
              {inputs[a].break && <div className="w-100"></div>}
              {(inputs[a].visible == undefined || inputs[a].visible) && (
                <ProductInput
                  attributeClass={a}
                  attributes={productAttributes}
                  obj={inputs[a].obj || product}
                  update={inputs[a].update || update}
                  labelProps={inputs[a].labelColumns || { sm: 6, xl: 3 }}
                  columnProps={inputs[a].valueColumns || { sm: 6, xl: 3 }}
                  isWizard={!inputs[a].hideApplyToAll && isWizard}
                  disabled={disabled}
                  customOptions={inputs[a].customOptions}
                  className={checkValidation ? checkValidation(a) : undefined}
                  tooltipText={validationErrorText ? validationErrorText(a) : undefined}
                ></ProductInput>
              )}
            </Fragment>
          ))}
          {childrenIndex == undefined && children}
        </FormGroup>
      </CardBody>
    </Card>
  ) : (
    <></>
  );
}

export default ProductInputContainer;
