import React, { Fragment, useEffect, useRef, useState } from "react";
import { TextInput } from "react-bootstrap4-form-validation";
import { Button, Col, CustomInput, FormGroup, Label, Row } from "reactstrap";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { useIntl } from "react-intl";
import { replaceBlockedCharacters } from "../../../products/ProductValidation";

function CustomList({ list, values, setValues, customValues, setCustomValues, addButtonText, examplePlaceholder }) {
  const intl = useIntl();
  const [focused, setFocused] = useState(0);

  function addCustomValue() {
    let cv = {
      id: customValues.length > 0 ? customValues.reduce((max, n) => (n.id > max.id ? n : max)).id + 1 : 0,
      name: "",
      principal: !customValues.some((f) => f.principal),
    };

    setCustomValues(customValues.concat({ ...cv }));
  }

  function updateCustomValue(value, name) {
    setCustomValues(
      customValues.map((cp, i) => {
        if (cp.id === value.id) {
          return { ...value, name: name };
        } else return cp;
      })
    );
  }

  function updatePrincipal(value) {
    setCustomValues(
      customValues.map((cp) => {
        return { ...cp, principal: cp.id === value.id };
      })
    );
  }

  function removeCustomValue(value) {
    let cvs = [...customValues];
    if (cvs.length === 1) return;

    if (value.principal) {
      let idx = cvs.findIndex((f) => f.id === value.id) + 1;
      if (cvs.length - 1 < idx) {
        idx = Math.max(idx - 2, 0);
      }
      cvs[idx].principal = true;
    }

    setCustomValues(cvs.filter((cp, i) => cp.id !== value.id));
  }

  function handleEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      addCustomValue();
    }
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderedItems = reorder(customValues, result.source.index, result.destination.index);
    setCustomValues(orderedItems);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getListStyle = (isDraggingOver) => ({
    height: 64 * customValues.length,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    height: 64,
    ...draggableStyle,
  });

  return list.map((l) => (
    <Fragment key={l.id}>
      <FormGroup row className="my-1">
        <Label sm={9}>{intl.formatMessage({ id: l.text, defaultMessage: l.text })}</Label>
        <Col sm={3}>
          <CustomInput
            type="switch"
            id={`list-value-${l.id}`}
            checked={values.some((v) => l.values.some((lv) => lv.name === v.name))}
            onChange={(e) =>
              setValues([
                ...l.values.map((v, i) => {
                  return { id: v.value || i, name: v.name, principal: v.principal };
                }),
              ])
            }
          />
        </Col>
      </FormGroup>
      {l.values.some((lv) => lv.name === "custom") && (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`dr-${l.id}`}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                  {customValues.map((cp, i) => (
                    <Draggable draggableId={`cv-${i}`} index={i} key={i} isDragDisabled={!values.some((v) => v.name === "custom")}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          ref={provided.innerRef}
                        >
                          <FocusableRow
                            list={l}
                            index={i}
                            cp={cp}
                            focused={focused === i}
                            setFocused={setFocused}
                            setValues={setValues}
                            disabled={!values.some((v) => v.name === "custom")}
                            showDelete={customValues.length > 1}
                            updatePrincipal={updatePrincipal}
                            updateCustomValue={updateCustomValue}
                            removeCustomValue={removeCustomValue}
                            examplePlaceholder={examplePlaceholder}
                            handleEnter={handleEnter}
                          ></FocusableRow>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Row>
            <Col sm="12" className="ml-5">
              <Button type="button" disabled={!values.some((v) => v.name === "custom")} onClick={() => addCustomValue()}>
                <i className="fas fa-plus"></i> {intl.formatMessage({ id: addButtonText, defaultMessage: addButtonText })}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Fragment>
  ));
}

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

function FocusableRow({
  list,
  index,
  cp,
  focused,
  setFocused,
  disabled,
  setValues,
  updatePrincipal,
  updateCustomValue,
  removeCustomValue,
  examplePlaceholder,
  handleEnter,
  showDelete,
}) {
  const [inputRef, setInputFocus] = useFocus();

  useEffect(
    function () {
      if (focused) setInputFocus();
    },
    [disabled]
  );

  return (
    <FormGroup
      row
      className="mb-0"
      onClick={() => {
        setValues([
          ...list.values.map((v, i) => {
            return { id: i, name: v.name, principal: v.principal };
          }),
        ]);
        setFocused(index);
      }}
    >
      <Col sm="12" className="d-flex pl-5 align-items-center">
        <button type="button" className="btn btn-link px-0 no-focus" disabled={disabled} onClick={(e) => updatePrincipal(cp)}>
          <i className={`${cp.principal ? "fas text-success" : "far text-dark"} fa-circle`}></i>
        </button>
        <div className="pr-3">
          <i className="fas fa-grip-vertical"></i>
        </div>
        <input
          type="text"
          id={`custom-value-${index}`}
          name={`custom-value-${index}`}
          value={cp.name}
          onChange={(e) => updateCustomValue(cp, replaceBlockedCharacters(e.target.value.substring(0, 4)))}
          placeholder={examplePlaceholder}
          className="form-control flex-grow-1"
          disabled={disabled}
          style={{ maxWidth: 450 }}
          onKeyDown={handleEnter}
          required={true}
          ref={inputRef}
          autoFocus
        />
        <Button type="button" className="btn btn-link text-dark ml-3" disabled={disabled || !showDelete} onClick={() => removeCustomValue(cp)}>
          <i className="fas fa-trash-alt"></i>
        </Button>
      </Col>
    </FormGroup>
  );
}

export default CustomList;
