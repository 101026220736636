import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { useVuErP } from "../../../context/curves/VuErPContext";
import { useConfig } from "../../../context/ConfigContext";
import FormattedNumber from "../../FormattedNumber";
import Validated from "../../Validated";

function NonResidentialErPKitViewer({ renderFieldIdentifier }) {
  const intl = useIntl();
  const vuErP = useVuErP();
  const columnClass = "mb-4 mt-auto";
  const patterns = useConfig("genesis.validation.patterns");

  return <>
    <Card className="mt-4">
      <CardHeader><FormattedMessage id="curves_generator.vuerp.configuration" defaultMessage="Configuration" /></CardHeader>
      <CardBody>
        <FormGroup row>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.typology" defaultMessage="Typology" /> {renderFieldIdentifier("id005")}</Label>
            <Validated errors={vuErP.validationErrors} name="typology">
              <Input type="select" value={vuErP.fields.typology} onChange={e => vuErP.fields.setTypology(e.target.value)}>
                <option value="">{intl.formatMessage({ id: "app.please_select", defaultMessage: "Please select" })}</option>
                <option value="UVU">UVU</option>
                <option value="BVU">BVU</option>
              </Input>
            </Validated>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.type_of_drive" defaultMessage="Type of drive" /> {renderFieldIdentifier("id006")}</Label>
            <Validated errors={vuErP.validationErrors} name="typeOfDrive" >
              <Input type="select" value={vuErP.fields.typeOfDrive} onChange={e => vuErP.fields.setTypeOfDrive(e.target.value)}>
                <option value="">{intl.formatMessage({ id: "app.please_select", defaultMessage: "Please select" })}</option>
                <option value="1V">1V</option>
                <option value="2V">2V</option>
                <option value="3V">3V</option>
                <option value="MSD">MSD</option>
                <option value="VSD">VSD</option>
                <option value="NA">NA</option>
              </Input>
            </Validated>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.type_of_hrc" defaultMessage="Type of HRC" /> {renderFieldIdentifier("id007")}</Label>
            <Validated errors={vuErP.validationErrors} name="typeOfHrc">
              <Input type="select" value={vuErP.fields.typeOfHrc} onChange={e => vuErP.fields.setTypeOfHrc(e.target.value)}>
                <option value="">{intl.formatMessage({ id: "app.please_select", defaultMessage: "Please select" })}</option>
                <option value="NO">NO</option>
                <option value="RC">RC</option>
                <option value="RG">RG</option>
                <option value="OT">OT</option>
              </Input>
            </Validated>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.thermal_efficiency" defaultMessage="Thermal efficiency" /> (%) {renderFieldIdentifier("id008")}</Label>
            <Validated errors={vuErP.validationErrors} name="thermalEfficiency">
              <Input
                type="text"
                className="w-auto"
                value={vuErP.fields.thermalEfficiency ?? ""}
                onChange={e => vuErP.fields.setThermalEfficiency(e.target.value)}
                pattern={patterns.optionalNumeric} />
            </Validated>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Row>
              <Col xl={6} className="mt-auto">
                <Label><FormattedMessage id="curves_generator.vuerp.face_velocity" defaultMessage="Face velocity" /> (m/s) {renderFieldIdentifier("id013")}</Label>
                <FormattedNumber value={vuErP.fields.faceVelocity} />
              </Col>
              <Col className="mt-auto">
                <Label><FormattedMessage id="curves_generator.vuerp.base" defaultMessage="Base" /> (mm)</Label>
                <Input type="text" value={vuErP.fields.base ?? ""} onChange={e => vuErP.fields.setBase(e.target.value)} />
              </Col>
              <Col className="mt-auto">
                <Label><FormattedMessage id="curves_generator.vuerp.alto" defaultMessage="Alto" /> = 0 ⌀</Label>
                <Input type="text" value={vuErP.fields.alto ?? ""} onChange={e => vuErP.fields.setAlto(e.target.value)} />
              </Col>
            </Row>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.delta_pressure_internal" defaultMessage="Δ ps.int" /> (Pa) {renderFieldIdentifier("id015")}</Label>
            <Validated errors={vuErP.validationErrors} name="deltaPressureInternal">
              <Input
                  type="text"
                  className="w-auto"
                  value={vuErP.fields.deltaPsInt ?? ""}
                  onChange={e => vuErP.fields.setDeltaPsInt(e.target.value)}
                  pattern={patterns.optionalNumeric} />
            </Validated>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.delta_pressure_additional" defaultMessage="Δ ps.add" /> (Pa) {renderFieldIdentifier("id016")}</Label>
            <Validated errors={vuErP.validationErrors} name="deltaPressureAdditional">
              <Input
                  type="text"
                  className="w-auto"
                  value={vuErP.fields.deltaPsAdd ?? ""}
                  onChange={e => vuErP.fields.setDeltaPsAdd(e.target.value)}
                  pattern={patterns.optionalNumeric} />
            </Validated>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.static_efficiency_fans" defaultMessage="Static efficiency fans" /> (%) {renderFieldIdentifier("id017")}</Label>
            <Input
              type="text"
              className="w-auto"
              value={vuErP.fields.staticEfficiencyFans ?? ""}
              onChange={e => vuErP.fields.setStaticEfficiencyFans(e.target.value)} />
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.external_leakage_rate" defaultMessage="External leakage rate" /> (%) {renderFieldIdentifier("id018")}</Label>
            <Validated errors={vuErP.validationErrors} name="externalLeakageRate">
              <Input
                type="text"
                className="w-auto"
                value={vuErP.fields.externalLeakageRate ?? ""}
                onChange={e => vuErP.fields.setExternalLeakageRate(e.target.value)} />
            </Validated>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.internal_leakage rate" defaultMessage="Internal leakage rate" /> (%) {renderFieldIdentifier("id019")}</Label>
            <Validated errors={vuErP.validationErrors} name="internalLeakageRate">
              <Input
                type="text"
                className="w-auto"
                value={vuErP.fields.internalLeakageRate ?? ""}
                onChange={e => vuErP.fields.setInternalLeakageRate(e.target.value)}
                pattern={patterns.optionalNumeric} />
            </Validated>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.filter_performance" defaultMessage="Filter performance" /> {renderFieldIdentifier("id020")}</Label>
            <Validated errors={vuErP.validationErrors} name="filterPerformance">
              <Input
                type="text"
                className="w-auto"
                value={vuErP.fields.filterPerformance ?? ""}
                onChange={e => vuErP.fields.setFilterPerformance(e.target.value)}
                pattern={patterns.optionalNumeric} />
            </Validated>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.filter_warning" defaultMessage="Filter warning" /> {renderFieldIdentifier("RVU_id021")}</Label>
            <Validated errors={vuErP.validationErrors} name="filterWarning">
              <Input type="select" value={vuErP.fields.filterWarning} onChange={e => vuErP.fields.setFilterWarning(e.target.value)}>
                <option value="">{intl.formatMessage({ id: "app.please_select", defaultMessage: "Please select" })}</option>
                <option value="PilotLight">PilotLight</option>
                <option value="NA">NA</option>
                <option value="DPS">DPS</option>
              </Input>
            </Validated>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.lwa" defaultMessage="LWA" /> dB(A) {renderFieldIdentifier("id022")}</Label>
            <Validated errors={vuErP.validationErrors} name="lwa">
              <Input
                type="text"
                className="w-auto"
                value={vuErP.fields.lwa ?? ""}
                onChange={e => vuErP.fields.setLwa(e.target.value)}
              />
            </Validated>
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
    <Card className="mt-4">
      <CardHeader>
        <FormattedMessage id="curves_generator.optimal_point" defaultMessage="Optimal point" />
      </CardHeader>
      <CardBody>
        <FormGroup row>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.flow_norminal" defaultMessage="Qnom" /> (m&sup3;/h) {renderFieldIdentifier("id009")}</Label>
            <p><FormattedNumber value={vuErP.fields.qnom} /></p>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.flow_norminal" defaultMessage="Qnom" /> (m&sup3;/s) {renderFieldIdentifier("id010")}</Label>
            <p><FormattedNumber value={vuErP.fields.qnomM3S} /></p>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.specific_fan_power_internal" defaultMessage="SFPint" /> (W) {renderFieldIdentifier("id012")}</Label>
            <p><FormattedNumber value={vuErP.fields.sfpInternal} /></p>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.delta_pressure_external" defaultMessage="Δ ps.ext" /> (Pa) {renderFieldIdentifier("id014")}</Label>
            <p><FormattedNumber value={vuErP.fields.deltaPressureExternal} /></p>
          </Col>
          <Col xl={4} md={6} className={columnClass}>
            <Label><FormattedMessage id="curves_generator.vuerp.eletrical_power" defaultMessage="Pelec" /> (kW) {renderFieldIdentifier("id011")}</Label>
            <p><FormattedNumber value={vuErP.fields.pelec} /></p>
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  </>;
}

export default NonResidentialErPKitViewer;