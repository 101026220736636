import React from "react";
import { Alert } from "reactstrap";

function AlertWithIcon(props) {
  return (
    <Alert {...props} className={`p-0 d-flex ${props.className || ""}`}>
      <div className="alert-icon m-0 d-flex align-items-center" style={{ padding: ".625rem", fontSize: "inherit" }}>
        {props.icon}
      </div>
      <div
        className="alert-message bg-white d-flex align-items-center"
        style={{ padding: ".625rem", width: "100%", boxSizing: "border-box", borderRadius: "0 0.375rem 0.375rem 0", color: "#8d9cae" }}
      >
        {props.children}
      </div>
      {props.action && (
        <div className="alert-icon m-0 d-flex align-items-center" style={{ padding: ".625rem", fontSize: "inherit" }}>
          {props.action}
        </div>
      )}
    </Alert>
  );
}

export default AlertWithIcon;
