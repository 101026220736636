import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useService } from "./ServiceContext";

const CultureContext = React.createContext();

const defaultCulture = navigator.language;

function CultureProvider({ children }) {
  const storageKey = "Genesis.Culture";
  const [culture, setCulture] = useState(defaultCulture);
  const { localCache } = useService();

  useEffect(() => {
    const stored = localCache.getString(storageKey);
    if(stored) {
      changeCulture(stored);
    } else {
      changeCulture(defaultCulture);
    }
  }, []);

  function changeCulture(selectedCulture) {
    localCache.setString("Genesis.Culture", selectedCulture);
    setCulture(selectedCulture);
  }

  return (
    <CultureContext.Provider value={{ culture, changeCulture }}>
      {children}
    </CultureContext.Provider>
  );
}

function useCulture() {
  const context = useContext(CultureContext);
  if (context === undefined) {
    throw new Error("useCulture must be used within a CultureProvider.");
  }
  return context;
}

export { CultureProvider, useCulture };
