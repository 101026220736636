import React, { useEffect, useState } from "react";
import { Step, Steps, Wizard } from "react-albus";
import { FormattedMessage } from "react-intl";
import { Breadcrumb, BreadcrumbItem, Button } from "reactstrap";
import ListerTable from "../Tables/ListerTable";

function SearchWizard({ steps, search, onSelect, initialState, onStateChange }) {
  const [history, setHistory] = useState(initialState?.history ?? []);
  const [step, setStep] = useState(initialState?.step ?? {});

  useEffect(() => {
    if (search) {
      loadStep({ step: search.step, filter: search.filter });
      setHistory([{ step: search.step, filter: { ...search.filter } }]);
    }
  }, [search]);

  useEffect(() => {
    onStateChange && onStateChange({ history, step });
  }, [history, step]);

  function getPrefilter() {
    var entry = history[history.length - 1];
    return entry.filter;
  }

  function loadStep(entry) {
    setStep({ id: entry.step });
    setHistory(history.concat(entry));
  }

  function revert(index) {
    var entry = history[index];
    setStep({ id: entry.step });
    setHistory(history.slice(0, index + 1));
  }

  function handleNavigateBack() {
    revert(history.length - 2);
  }

  function handleSelection(value) {
    let stepIndex = steps.findIndex((s) => s.id == step.id);
    let current = steps[stepIndex];
    let mapped = current.map(value);
    if (stepIndex == steps.length - 1 && onSelect) {
      onSelect(mapped);
    } else {
      let next = steps[stepIndex + 1];
      loadStep({ step: next.id, filter: mapped });
    }
  }

  function renderBreadcrumbs(history) {
    if (history.length) {
      return (
        <Breadcrumb>
          {history.map((entry, index, array) => (
            <BreadcrumbItem active={index == array.length - 1}>
              <a onClick={() => revert(index)}>{steps.filter((s) => s.id == entry.step)[0].title}</a>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      );
    } else {
      return <></>;
    }
  }

  function renderSmallTableHeader({ column, children }) {
    return (
      <th {...column.getHeaderProps()} style={{ width: "1%" }}>
        {children}
      </th>
    );
  }

  if (step) {
    return (
      <>
        {renderBreadcrumbs(history)}
        <Wizard>
          <Steps step={step}>
            {steps.map((s) => {
              const columns = [
                ...s.columns, 
                {
                  id: "select",
                  TableHeader: renderSmallTableHeader,
                  Cell: ({ cell }) => (
                    <Button type="button" className="btn btn-primary float-right" onClick={() => handleSelection(cell.row.original)}>
                      <FormattedMessage id="app.select" defaultMessage="Select" />
                    </Button>
                  ),
                }
              ];

              return <Step
                key={s.id}
                id={s.id}
                render={() => (
                  <ListerTable
                    className={"align-items-center table-flush"}
                    columns={columns}
                    lister={s.lister}
                    prefilter={getPrefilter()}
                  />
                )}
              />;
            })}
          </Steps>

          {history.length > 1 && (
            <Button className={"btn-outline-danger btn-md mt-4"} onClick={handleNavigateBack}>
              <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
            </Button>
          )}
        </Wizard>
      </>
    );
  } else {
    return <></>;
  }
}

export default SearchWizard;
