import objectResolvePath from "object-resolve-path";
import eventify from "../eventify";
import trackify from "../trackify";
import CoefficientMapper from "./CoefficientMapper";
import CubicSplineCurveInterpolator from "./CubicSplineCurveInterpolator";
import { getPositionOfLineAndCharacter } from "typescript";

export default class ConfiguredInterpolatedCurve {
  constructor({ data, variable, columns, state, changes, mappers }) {
    trackify(this, state, changes);
    eventify(this);

    this._namedMappers = mappers;
    this._data = data;
    this._settings = new CurveInterpolationSettings({
      state: state?.settings,
      changes: this._changes?.settings,
    });
    this._coefficientMapper = new CoefficientMapper({ coefficient: this.settings.coefficient });
    mappers = [this._coefficientMapper, ...Object.values(mappers)];

    var convertedData = this._convertData(data);
    this._interpolator = new CubicSplineCurveInterpolator({
      data: convertedData,
      variable,
      columns,
      mappers,
    });

    const self = this;
    const handleMapperChange = () => {
      self._recalculate();
    };

    const handleSettingsChange = () => {
      self._coefficientMapper.coefficient = self._settings.coefficient;
    };

    for (let mapper of mappers) {
      mapper.on("change", handleMapperChange);
    }
    this.settings.on("change", handleSettingsChange);

    if (!this._get("points")) {
      this._recalculate();
    }
  }

  get state() {
    return { ...this._state, settings: this._settings.state };
  }

  get changes() {
    return { ...this._changes, settings: this._settings.state };
  }

  get settings() {
    return this._settings;
  }

  get points() {
    return [...this._get("points")];
  }

  get isInterpolatedPointsModified() {
    return this._isInterpolatedPointsModified;
  }

  _convertData(data) {
    //offsetting the volume point if two volume point are the same .. so that it does not break the curve
    let convertedData = JSON.parse(JSON.stringify(data));
    const airflowOffset = 0.01;

    const groupedAirflowsWithDuplicatePositions = {};

    convertedData.forEach((v, index) => {
      groupedAirflowsWithDuplicatePositions[v.airflow] = groupedAirflowsWithDuplicatePositions[v.airflow] || [];
      groupedAirflowsWithDuplicatePositions[v.airflow].push(index);
    });

    Object.keys(groupedAirflowsWithDuplicatePositions).forEach(function (value) {
      const posArray = groupedAirflowsWithDuplicatePositions[value];
      if (posArray.length > 1) {
        let offset = airflowOffset;
        for (let i = posArray.length - 2; i >= 0; i--) {
          convertedData[posArray[i]].airflow += offset;
          offset += airflowOffset;
        }
      }
    });

    return convertedData;
  }

  changeDynamicPressure(area) {
    for (let i = 0; i < this._data.length; i++) {
      let p = this._data[i];
      let dynamicPressure = 0.5 * 1.2 * (p.airflow / 3600 / area) ** 2;
      let totalPressure = dynamicPressure + p.staticPressure;
      this.set(i, "dynamicPressure", dynamicPressure);
      this.set(i, "totalPressure", totalPressure);
      //this.set(i, "staticPressure", p.staticPressure);
    }
  }

  _recalculate() {
    this._setChangeOnly("modifications", []);
    this._setStateOnly("points", this._interpolator.interpolate({ points: this._data.length }));

    this.fire("change");
  }

  insert(index) {
    let points = this.points;
    let modifications = [...(this._changes?.modifications ?? [])];

    let newRow = { ...points[index] };
    for (let key of Object.keys(newRow)) {
      if (points.length === 1) {
        newRow[key] += 1;
      } else if (index === 0) {
        const nextRow = points[index + 1];
        newRow[key] = newRow[key] + newRow[key] - nextRow[key];
      } else {
        const previousRow = points[index - 1];
        newRow[key] = (previousRow[key] + newRow[key]) / 2;
      }
    }

    points.splice(index, 0, newRow);
    this._setStateOnly("points", points);

    modifications.push({ operation: "insert", index, update: newRow });
    this._setChangeOnly("modifications", modifications);

    this.fire("change");
  }

  set(index, key, value) {
    let points = this.points;
    let modifications = [...(this._changes?.modifications ?? [])];

    points[index][key] = value;
    let calculated = this._namedMappers.pointCalculation.map({ value: points[index] });
    for (let property of Object.keys(calculated)) {
      if (property !== key) {
        points[index][property] = calculated[property];
      }
    }

    this._setStateOnly("points", points);

    modifications.push({ operation: "set", index, update: { [key]: value } });
    this._setChangeOnly("modifications", modifications);

    this.fire("change");
  }

  remove(index) {
    let points = this.points;
    let modifications = [...(this._changes?.modifications ?? [])];

    points.splice(index, 1);
    this._setStateOnly("points", points);

    modifications.push({ operation: "remove", index });
    this._setChangeOnly("modifications", modifications);

    this.fire("change");
  }
}

export class CurveInterpolationSettings {
  constructor({ state, changes }) {
    trackify(this, state, changes);
    eventify(this);
  }

  get state() {
    return this._state;
  }

  get changes() {
    return this._changes;
  }

  get coefficient() {
    return this._get("coefficient");
  }

  set coefficient(value) {
    this._set("coefficient", value);
    this.fire("change");
  }

  useAutomaticCoefficient() {}
}
