import SplineReducer from "./SplineReducer";
import { CubicSplineAdaptor } from "./Spline";
import { minimize_Powell } from "optimization-js";

export class MultiAxisSpline {
    constructor({ data, variable, columns, smoothingFactory }) {
        if (!smoothingFactory) {
            smoothingFactory = (xs, ys) => new CubicSplineAdaptor(xs, ys);
        }

        this._data = data;
        this._variable = variable;
        this._splines = new SplineReducer({ 
            data,
            columns,
            smoothingFactory
        }).reduce({ variable });
        this._reference = data.map(p => p[variable]);
        this._columns = columns;
    }

    at(x) {
        let points = {};
        const columns = this._columns;
        for(let i = 0; i < columns.length; i++) {
            let column = columns[i];
            let value;
            if(column === this._variable) {
                value = parseFloat(x);
            } else {
                value = this._splines[column].at(x);
            }

            points[column] = value;
        }

        return points;
    }

    find(expression, value, scale) {
        if(typeof expression === "function") {
            return this.minima(point =>  Math.abs(expression(point) - value), scale);
        } else {
            let x = this._splines[expression].find(value);
            return this.at(x);
        }
    }

    _prescan(of) {
        let bestX = null, bestCost = null;
        for(var x of this._reference) {
            let cost = of(this.at(x));
            if(bestCost == null || cost < bestCost) {
                bestX = x;
                bestCost = cost;
            }
        }

        return bestX;
    }

    minima(of, scale) {
        if(typeof scale === "undefined") {
            scale = 1;
        }

        let xref = this._prescan(of);
        let x0 = [xref / scale];
        
        const minimization = vec => {
            let x = vec[0] * scale;
            return of(this.at(x));
        }

        let min = minimize_Powell(minimization, x0);
        return this.at(min.argument[0] * scale);
    }

    maxima(of, scale) {
        if(typeof scale === "undefined") {
            scale = 1000;
        }

        return this.minima(point => 1 / (1 + Math.exp(of(point)/2)), scale);
    }
}