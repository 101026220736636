import eventify from "../eventify";

export default class SoundPowerTest {
    constructor(reference, id, soundPower) {
        this._reference = reference;
        this._soundPower = soundPower;
        this._id = id;

        eventify(this);
    }

    get reference() {
        return this._reference;
    }

    set reference(value) {
        this._reference = value;
        this.fire("change");
    }

    get soundPower() {
        return this._soundPower;
    }

    set id(value){
        this._id = value;
        this.fire("change");
    }

    get id()
    {
        return this._id;
    }
}