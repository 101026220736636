import React from "react";
import { useIntl } from "react-intl";
import { Card } from "reactstrap";
import InlineError from "../components/InlineError";

function LayoutErrorFallback({ error, resetErrorBoundary }) {
    const intl = useIntl();
    const message = intl.formatMessage({ id: error.message || "app.general_error", defaultMessage: intl.formatMessage({ id: "app.general_error", defaultMessage: "Something went wrong. If the problem persists, please contact your administrator." }) });

    return <Card>
        <InlineError message={message} onRetry={resetErrorBoundary} />
    </Card>;
}

export default LayoutErrorFallback;