class AggregateTotalService {
    constructor(http) {
        this._http = http;
    }

    async getCurveTemplate(curveId) {
        let response = await this._http.request({
            method: "get",
            url: `tests/acoustic/aggregate/template/curve/${curveId}`
        });

        return response.data;
    }

    async getAcousticAggregateSummary(acousticReference, testIdUnique) {
        let response = await this._http.request({
            method: "get",
            url: `tests/acoustic/aggregate/${acousticReference}/${testIdUnique}/summary`
        });

        return response.data;
    }

    async createAggregateNoiseTotal(aggregateNoiseTotalOrigin) {
        let response = await this._http.request({
            method: "post",
            url: `tests/acoustic/aggregate/new`,
            data: aggregateNoiseTotalOrigin
        });

        return response.data;
    }

    async createAggregateNoiseTotalManual(aggregateNoiseTotalOrigin) {
        let response = await this._http.request({
            method: "post",
            url: `tests/acoustic/aggregate/new/manual`,
            data: aggregateNoiseTotalOrigin
        });

        return response.data;
    }

    async createDependentNoiseTest({ curveId, specification }) {
        let response = await this._http.request({
            method: "post",
            url: `tests/acoustic/aggregate/${curveId}/dependent`,
            data: specification
        });

        return response.data;
    }
}

export default AggregateTotalService;