import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FormattedMessage, useIntl } from "react-intl";
import SeriesProductSearch from "../../components/ProductCreation/SeriesProductSearch";
import { dismissAlert, fireAlert } from "../../utils/alerts";
import CurveTestSearch from "../../components/CurvesGenerator/CurveTestSearch";
import { useConfig } from "../../context/ConfigContext";
import CertificateProductSelection from "../../components/Certificates/CertificateProductSelection";

function Certificates({ history }) {
  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="certificates.title" defaultMessage="Certificates" />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CertificateProductSelection></CertificateProductSelection>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Certificates;
