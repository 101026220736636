class CachingProviderShim {
    constructor(provider, cache, prefix) {
        this._provider = provider;
        this._cache = cache;
        this._prefix = prefix || "";
        this._pending = {};
    }

    async get(key) {
        let fqk = `${this._prefix}.${key}`;
        let promise = this._pending[fqk];
        if(promise) {
            await promise;
        }

        let value = this._cache.getObject(fqk);
        if(value) {
            return value;
        } else {
            promise = this._provider.get(key);
            this._pending[fqk] = promise;
            value = await promise;
            this._cache.setObject(fqk, value);
            return value;
        }
    }
}

export default CachingProviderShim;