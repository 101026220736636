import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, CardHeader, Col, CustomInput, Form, FormGroup, Label, Row } from "reactstrap";
import Input from "../Input";
import Tooltip from "../Tooltip";
import FormattedNumber from "../FormattedNumber";
import AlertWithIcon from "../AlertWithIcon";

function DataEntry(props) {
  const intl = useIntl();
  const {
    impellerDiameter,
    section,
    isPublished,
    onChangeIsPublished,
    hasErP,
    onChangeHasErP,
    isErPNorm,
    onChangeIsErPNorm,
    isErPTest,
    onChangeIsErPTest,
    efficiencyType,
    onChangeEfficiencyType,
    category,
    onChangeCategory,
    installationType,
    onChangeInstallationType,
    vsdType,
    onChangeVsdType,
    isCatalogue,
    onChangeIsCatalogue,
    isSmokeExtraction,
    onChangeIsSmokeExtraction,
    isReversible,
    onChangeIsReversible,
    isPrincipalErP,
    onChangeIsPrincipalErP,
    motor,
    onBeginMotorSelection,
    onRemoveMotor,
    isOutputPowerCalculated,
    onChangeIsOutputPowerCalculated,
    outletDiameter,
    onChangeOutletDiameter,
    inletDiameter,
    onChangeInletDiameter,
    newSection,
    observations,
    onChangeObservations,
    isTransmissionEnabled,
    onChangeIsTransmissionEnabled,
    transmissionNormalisation,
    onChangeTransmissionNormalisation,
    includeSecurityGuard,
    onChangeIncludeSecurityGuard,
    onChangeGuardFactor,
    guardFactor,
    thrust,
    onChangeThrust,
    electricPower,
    onChangeElectricPower,
    outletArea,
    onChangeOutletArea,
    erPCompliance,
  } = props;

  return (
    <>
      <div className="p-4">
        {erPCompliance?.warnings?.map((w) => (
          <AlertWithIcon icon={<i className="fas fa-exclamation-triangle"></i>} className="alert-warning mt-4">
            <FormattedMessage id={w.id} defaultMessage={w.defaultMessage} values={w.values} />
          </AlertWithIcon>
        ))}
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <FormattedMessage id="curves_generator.input_data" defaultMessage="Input data" />
              </CardHeader>

              <CardBody>
                <Form>
                  <Row form>
                    {category != "G16" && (
                      <Col md={4} lg={3} xl={2}>
                        <FormGroup>
                          <Label>
                            <FormattedMessage id="curves_generator.efficiency_type" defaultMessage="Efficiency type" />
                          </Label>
                          <Input type="select" value={efficiencyType} onChange={(e) => onChangeEfficiencyType(e.target.value)}>
                            <option value="Static">Static</option>
                            <option value="Total">Total</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    )}

                    <Col md={8} lg={9} xl={4}>
                      <FormGroup>
                        <Label>
                          <FormattedMessage id="curves_generator.category" defaultMessage="Category" />
                        </Label>
                        <Input type="select" value={category} onChange={(e) => onChangeCategory(e.target.value)}>
                          <option value="G1">G1 - Axial fan static (A,C)</option>
                          <option value="G2">G2 - Axial fan total (B,D)</option>
                          <option value="G3">G3 - Centrifugal forward curved static (A,C)</option>
                          <option value="G4">G4 - Centrifugal forward curved total (B,D)</option>
                          {/* <option value="G5">G5 - Centrifugal radial bladed static (A,C)</option> */}
                          {/* <option value="G6">G6 - Centrifugal radial bladed total (B,D)</option> */}
                          <option value="G7">G7 - Centrifugal backward curved without housing static (A,C)</option>
                          <option value="G8">G8 - Centrifugal backward curved with housing static (A,C)</option>
                          <option value="G9">G9 - Centrifugal backward curved with housing total (B,D)</option>
                          <option value="G10">G10 - Mixed flow fan static (A,C)</option>
                          <option value="G11">G11 - Mixed flow fan total (B,D)</option>
                          {/* <option value="G12">G12 - Cross flow fan total (B,D)</option> */}
                          <option value="G13">G13 - Ventilation units no residential</option>
                          <option value="G14">G14 - Ventilation units residential</option>
                          <option value="G15">G15 - Ventilation units no residential - heat recovery</option>
                          <option value="G16">G16 - Jet-fans</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    {category != "G16" && (
                      <Col md={4} lg={4} xl={2}>
                        <FormGroup>
                          <Label>
                            <FormattedMessage id="curves_generator.installation" defaultMessage="Installation" />
                          </Label>
                          <Input type="select" value={installationType} onChange={(e) => onChangeInstallationType(e.target.value)}>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="X">X</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    )}
                    {category != "G16" && (
                      <Col md={4} lg={4} xl={2}>
                        <FormGroup>
                          <Label>
                            <FormattedMessage id="curves_generator.include_vsd" defaultMessage="Include VSD" />
                          </Label>
                          <Input type="select" value={vsdType} onChange={(e) => onChangeVsdType(e.target.value)}>
                            <option value="No">{intl.formatMessage({ id: "app.no", defaultMessage: "No" })}</option>
                            <option value="Included">{intl.formatMessage({ id: "curves_generator.integrated", defaultMessage: "Integrated" })}</option>
                            <option value="Required">{intl.formatMessage({ id: "curves_generator.required", defaultMessage: "Required" })}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    )}
                    <Col md={4} lg={4} xl={2}>
                      <FormGroup className="mb-0">
                        <CustomInput
                          type="switch"
                          label={intl.formatMessage({ id: "curves_generator.security_guard", defaultMessage: "Security guard" })}
                          checked={includeSecurityGuard}
                          onChange={(e) => onChangeIncludeSecurityGuard(e.target.checked)}
                          id="securityGuard"
                          className="pt-0 pb-2"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input className="mt-0" type="select" value={guardFactor} onChange={(e) => onChangeGuardFactor(e.target.value)} disabled={!includeSecurityGuard}>
                          <option value="1.15">e ≤ 8</option>
                          <option value="1.09">{"8 < e ≤ 10"}</option>
                          <option value="1.06">{"10 < e ≤ 20"}</option>
                          <option value="1.02">{"20 < e ≤ 30"}</option>
                          <option value="1">{"e > 30"}</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="mt-0"></hr>
                  <Row form>
                    <Col md={6} lg={4} xl={2}>
                      <FormGroup className="mb-0">
                        <CustomInput
                          type="switch"
                          label={intl.formatMessage({ id: "curves_generator.smoke_extraction", defaultMessage: "Smoke extraction" })}
                          checked={isSmokeExtraction}
                          onChange={(e) => onChangeIsSmokeExtraction(e.target.checked)}
                          id="desenfumage"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} lg={4} xl={2}>
                      <FormGroup className="mb-0">
                        <CustomInput
                          type="switch"
                          label={intl.formatMessage({ id: "curves_generator.reversible", defaultMessage: "Reversible" })}
                          checked={isReversible}
                          onChange={(e) => onChangeIsReversible(e.target.checked)}
                          id="reversible"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} lg={4} xl={2}>
                      <FormGroup className="mb-0">
                        <CustomInput
                          type="switch"
                          label={intl.formatMessage({ id: "curves_generator.catalogue", defaultMessage: "Catalogue" })}
                          checked={isCatalogue}
                          onChange={(e) => onChangeIsCatalogue(e.target.checked)}
                          id="catalogo"
                        />
                      </FormGroup>
                    </Col>
                    {category != "G16" && (
                      <>
                        <Col md={6} lg={4} xl={2}>
                          <FormGroup className="mb-0">
                            <CustomInput
                              type="switch"
                              label={intl.formatMessage({ id: "curves_generator.output_power", defaultMessage: "Output power" })}
                              checked={isOutputPowerCalculated}
                              onChange={(e) => onChangeIsOutputPowerCalculated(e.target.checked)}
                              id="potencia_util"
                            />
                          </FormGroup>
                        </Col>
                        <Col xl={4}>
                          <FormGroup className="mb-0">
                            <div className="form-inline">
                              <CustomInput
                                type="switch"
                                checked={isTransmissionEnabled}
                                onChange={(e) => onChangeIsTransmissionEnabled(e.target.checked)}
                                label={intl.formatMessage({ id: "curves_generator.transmission", defaultMessage: "Transmission" })}
                                id="transmission"
                                className="mr-4"
                              />
                              <Input
                                type="number"
                                disabled={!isTransmissionEnabled}
                                step="50"
                                value={transmissionNormalisation ?? ""}
                                onChange={(e) => onChangeTransmissionNormalisation(e.target.value)}
                                placeholder="RPM Norm"
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mt-4">
              <CardHeader>
                <FormattedMessage id="curves_generator.supplementary_data" defaultMessage="Supplementary data" />
              </CardHeader>
              <CardBody>
                <Form>
                  <Row form>
                    {category != "G16" && (
                      <>
                        <Col xs={6} xl={3}>
                          <FormGroup className="mb-0">
                            <Label>
                              <FormattedMessage id="curves_generator.impeller_diameter" defaultMessage="Impeller diameter" /> (m)
                            </Label>
                            <p>
                              <FormattedNumber value={impellerDiameter} />
                            </p>
                          </FormGroup>
                        </Col>
                        <Col xs={6} xl={3}>
                          <FormGroup className="mb-0">
                            <Label>
                              <FormattedMessage id="curves_generator.section" defaultMessage="Section" /> (m²)
                            </Label>
                            <p>
                              <FormattedNumber value={section} options={{ maximumFractionDigits: 3 }} />
                            </p>
                          </FormGroup>
                        </Col>
                        <Col xs={6} xl={2}>
                          <FormGroup className="mb-0">
                            <Label>
                              <FormattedMessage id="curves_generator.outlet_diameter" defaultMessage="Outlet diameter" /> (mm)
                            </Label>
                            <Input type="number" step="1" className="w-75" value={outletDiameter ?? ""} onChange={(e) => onChangeOutletDiameter(e.target.value)} />
                          </FormGroup>
                        </Col>
                        <Col xs={6} xl={2}>
                          <FormGroup className="mb-0">
                            <Label>
                              <FormattedMessage id="curves_generator.new_section" defaultMessage="New section" /> (m²)
                            </Label>
                            <p>
                              <FormattedNumber value={newSection} options={{ maximumFractionDigits: 3 }} />
                            </p>
                          </FormGroup>
                        </Col>
                      </>
                    )}

                    {installationType == "C" && efficiencyType == "Total" && (
                      <Col xs={6} xl={2}>
                        <FormGroup className="mb-0">
                          <Label>
                            <FormattedMessage id="curves_generator.inlet_diameter" defaultMessage="Inlet diameter" /> (mm)
                          </Label>
                          <Input type="number" step="1" className="w-75" value={inletDiameter ?? ""} onBlur={(e) => onChangeInletDiameter(e.target.value)} />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  {category != "G16" && <hr></hr>}
                  {category == "G16" && (
                    <>
                      <Row form>
                        <Col xs={6} xl={3}>
                          <FormGroup className="mb-0">
                            <Label>
                              <FormattedMessage id="curves_generator.thrust" defaultMessage="Thrust - Tm (N) diameter" /> (N)
                            </Label>
                            <Input type="number" step="1" className="w-75" value={thrust} onValueChange={(value) => onChangeThrust(value.floatValue)} />
                          </FormGroup>
                        </Col>
                        <Col xs={6} xl={3}>
                          <FormGroup className="mb-0">
                            <Label>
                              <FormattedMessage id="curves_generator.electric_power" defaultMessage="Electric power - Pe" /> (kW)
                            </Label>
                            <Input type="number" step="1" className="w-75" value={electricPower} onValueChange={(value) => onChangeElectricPower(value.floatValue)} />
                            {/* <Input type="number" step="any" size="sm" className="form-control-sm" value={value ?? ""} onValueChange={(values, sourceInfo) => sourceInfo.source === "event" && onUpdateRow(row, key, values.floatValue) }></Input> */}

                            {/* value={value ?? ""} onValueChange={(values, sourceInfo) => sourceInfo.source === "event" && onUpdateRow(row, key, values.floatValue) } */}
                          </FormGroup>
                        </Col>
                        <Col xs={6} xl={3}>
                          <FormGroup className="mb-0">
                            <Label>
                              <FormattedMessage id="curves_generator.outlet_area" defaultMessage="Outlet area - A2" /> (m2)
                            </Label>
                            <Input type="number" step="1" className="w-75" value={outletArea} onValueChange={(value) => onChangeOutletArea(value.floatValue)} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr></hr>
                    </>
                  )}
                  <Row form>
                    <Col xs={6} xl={3}>
                      <FormGroup className="mb-0">
                        <Label>
                          <FormattedMessage id="curves_generator.motor" defaultMessage="Motor" />
                        </Label>
                        <Row>
                          {motor ? (
                            <>
                              <Col sm={9}>
                                <Input type="text" plaintext readOnly value={motor?.name || "-"} />
                              </Col>
                              <Col sm={3} className="text-left">
                                <Tooltip text={intl.formatMessage({ id: "curves_generator.remove_motor", defaultMessage: "Remove motor" })}>
                                  <i className="fas fa-times-circle text-danger" style={{ padding: "0.625rem 0" }} onClick={onRemoveMotor}></i>
                                </Tooltip>
                              </Col>
                            </>
                          ) : (
                            <Col sm={12}>
                              <Button type="button" color="primary" onClick={onBeginMotorSelection}>
                                <FormattedMessage id="app.select" defaultMessage="Select" />
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col xs={6} xl={3}>
                      <Label>
                        <FormattedMessage id="curves_generator.motor_power" defaultMessage="Motor power" /> (kW)
                      </Label>
                      <p className="pt-2">
                        {motor?.power1 ? (
                          <>
                            <FormattedNumber value={motor.power1} />
                            {!!motor?.power2 && (
                              <>
                                {" "}
                                / <FormattedNumber value={motor.power2} />
                              </>
                            )}
                          </>
                        ) : (
                          "-"
                        )}
                      </p>
                    </Col>

                    <Col xs={6} xl={3}>
                      <Label>
                        <FormattedMessage id="curves_generator.cod_modelo_motor" defaultMessage="Cod modelo motor" />
                      </Label>
                      <p className="pt-2">{motor?.id ? motor.id : "-"}</p>
                    </Col>
                  </Row>

                  <hr />

                  <Row form>
                    <Col xl={2} xs={6} className="mx-auto">
                      <FormGroup className="mb-0">
                        <Label>
                          <FormattedMessage id="curves_generator.published" defaultMessage="Published" />
                        </Label>
                        <CustomInput type="switch" id="published" className="mr-4" checked={isPublished} onChange={(e) => onChangeIsPublished(e.target.checked)} />
                      </FormGroup>
                    </Col>

                    <Col xl={2} xs={6} className="mx-auto">
                      <FormGroup className="mb-0">
                        <Label>
                          <FormattedMessage id="curves_generator.erp" defaultMessage="ErP" />
                        </Label>
                        <CustomInput type="switch" id="erp" className="mr-4" checked={hasErP} onChange={(e) => onChangeHasErP(e.target.checked)} disabled />
                      </FormGroup>
                    </Col>

                    <Col xl={2} xs={6} className="mx-auto">
                      <FormGroup className="mb-0">
                        <Label>
                          <FormattedMessage id="curves_generator.erp_norm" defaultMessage="ErP norm" />
                        </Label>
                        <CustomInput type="switch" id="erp_norm" className="mr-4" checked={isErPNorm} onChange={(e) => onChangeIsErPNorm(e.target.checked)} disabled />
                      </FormGroup>
                    </Col>

                    <Col xl={2} xs={6} className="mx-auto">
                      <FormGroup className="mb-0">
                        <Label>
                          <FormattedMessage id="curves_generator.erp_test" defaultMessage="ErP test" />
                        </Label>
                        <CustomInput type="switch" id="erp_test" className="mr-4" checked={isErPTest} onChange={(e) => onChangeIsErPTest(e.target.checked)} disabled />
                      </FormGroup>
                    </Col>

                    <Col xl={2} xs={12} className="mx-auto">
                      <FormGroup className="mb-0">
                        <Label>
                          <FormattedMessage id="curves_generator.principal_erp" defaultMessage="Principal ErP" />
                        </Label>
                        <CustomInput type="switch" id="erp_principal" checked={isPrincipalErP} onChange={(e) => onChangeIsPrincipalErP(e.target.checked)} className="mr-4" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <hr />

                  <Row form>
                    <Col xl={4} lg={5} md={6}>
                      <h4>
                        <FormattedMessage id="curves_generator.report_when_no_curve" defaultMessage="Report only if there is no curve" />
                      </h4>
                      <FormGroup row className="mb-0">
                        <Label sm={6}>
                          <FormattedMessage id="curves_generator.curve_series" defaultMessage="Curve series" />
                        </Label>
                        <Col sm={6}>
                          <Input plaintext readOnly value="-" className="p-0" />
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mb-0">
                        <Label sm={6}>
                          <FormattedMessage id="curves_generator.jde_product_code" defaultMessage="JDE product code" />
                        </Label>
                        <Col sm={6}>
                          <Input plaintext readOnly value="-" className="p-0" />
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mb-0">
                        <Label sm={6}>
                          <FormattedMessage id="curves_generator.description" defaultMessage="Description" />
                        </Label>
                        <Col sm={6}>
                          <Input plaintext readOnly value="-" className="p-0" />
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mb-0">
                        <Label sm={6}>
                          <FormattedMessage id="curves_generator.result_message" defaultMessage="Result message" />
                        </Label>
                        <Col sm={6}>
                          <Input plaintext readOnly value="-" className="p-0" />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xl={8} lg={7} md={6}>
                      <h4>
                        <FormattedMessage id="curves_generator.observations" defaultMessage="Observations" />
                      </h4>
                      <Input type="textarea" rows={10} value={observations} onChange={(e) => onChangeObservations(e.target.value)} />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DataEntry;
