import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Card, CardBody, CardHeader, FormGroup, Button, Label, Col, CustomInput, Alert } from "reactstrap";
import ProductInput from "../ProductInput";
import AerodynamicParameters from "./AerodynamicParameters";
import ElectricalParameters from "./ElectricalParameters";
import GeometricalParameters from "./GeometricalParameters";
import MotorParameters from "./MotorParameters";
import OtherParameters from "./OtherParameters";
import RegulationParameters from "./RegulationParameters";
import Spinner from "../../Spinner";
import AlertWithIcon from "../../AlertWithIcon";
import Tooltip from "../../Tooltip";
import ListerTableSelectionModal from "../../Modals/ListerTableSelectionModal";
import { useService } from "../../../context/ServiceContext";
import { isRequired, replaceTags } from "../../../products/ProductDefinitions";
import ProductInfo from "../ProductInfo";
import { replaceBlockedCharacters } from "../../../products/ProductValidation";
import { Link } from "react-router-dom";

function CurveEdit({
  curve,
  curves,
  group,
  product,
  updateGroup,
  updatePrincipal,
  updateReferenceProduct,
  cloneCurve,
  clearCurve,
  removeCurve,
  productAttributes,
  validationErrors,
  isSinCurva,
}) {
  const intl = useIntl();
  const { modeloCurveLister, modeloCurveSpecificationAccessor } = useService();
  const [curveModalOpen, setCurveModalOpen] = useState(false);
  const isProductReadOnly = product?.isReadOnly || false;
  const isGroupReadOnly = (group?.isReadOnly || false) && (group?.existingGrupoCurve || false);
  const isReadOnly = curve.isReadOnly && curve.existingCurve;
  const isPrincipalGroup = group?.principalMG;
  const modeloCurvaLocked = curve.id > 0;
  const toggleCurveModal = () => setCurveModalOpen(!curveModalOpen);

  function generateModeloCurva(c) {
    return productAttributes["modeloCurva"] ? replaceTags(productAttributes["modeloCurva"].suggestion, [c, product], [productAttributes]) : "";
  }

  function toggleModeloCurvaMode() {
    const mode = !curve.modeloCurvaAuto;
    updateCurveByObj({ ...curve, modeloCurvaAuto: mode });
  }

  async function handleCurveSelection(choice) {
    if (choice) {
      console.log(choice.id);
      const curva = await modeloCurveSpecificationAccessor.find(choice.id);
      if (curva) {
        updateCurveByObj({ ...curve, ...curva, index: curve.index, existingCurve: true });
      }
    }

    toggleCurveModal();
  }

  function updateCurve(e) {
    const key = e.target.id;
    const value = e.target.type === "checkbox" ? e.target.checked : replaceBlockedCharacters(e.target.value);

    updateCurveKeyValue(key, value);
  }

  function updateCurveByObj(curve) {
    let newCurve = { ...curve, modeloCurva: curve.modeloCurvaAuto ? generateModeloCurva(curve) : curve.modeloCurva };
    updateGroup({
      ...group,
      curves: curves.map((c) => {
        if (c.index === curve.index) {
          return newCurve;
        } else return c;
      }),
    });
  }

  function updateCurveKeyValue(key, value) {
    updateCurveByObj({ ...curve, [key]: value });
  }

  function clearCurveLink() {
    updateCurveByObj({ ...curve, id: 0, existingCurve: false, isReadOnly: false });
  }

  function clearCurveData() {
    updateCurveByObj(clearCurve(curve));
  }

  function checkValidation(attributeClass) {
    if (validationErrors === null) return "";
    let isInvalid = true;
    const error = validationErrors.groups?.find((g) => g.index === group.index)?.curves?.find((c) => c.index === curve.index);
    if (error) {
      isInvalid = error[attributeClass] !== undefined;
    }

    return isInvalid ? "is-invalid" : "is-valid";
  }

  function getErrorText(attributeClass) {
    if (validationErrors === null) return "";
    let error = validationErrors.groups?.find((g) => g.index === group.index)?.curves?.find((c) => c.index === curve.index);
    if (error) error = error[attributeClass];
    else return "";

    return error ? intl.formatMessage(error.errorFormattedMessage) : "";
  }

  return !productAttributes ? (
    <Spinner></Spinner>
  ) : (
    <>
      <Card>
        <CardHeader className="px-3 py-2">
          <div className="d-flex align-items-center justify-content-end">
            <div className="flex-grow-1 h5 m-0">
              {curve.modeloCurva}
              {curve.idEnsayo && (
                <Link className={`ml-3 badge ${curve.idEnsayo.published ? "badge-primary" : "badge-secondary"}`} to={`/curves-generator/viewer/${curve.idEnsayo.testId}/summary`}>
                  idEnsayo {curve.idEnsayo.testId} <i className="fas fa-link"></i>
                </Link>
              )}
            </div>
            {!isProductReadOnly && !isGroupReadOnly && !isSinCurva && (
              <>
                <div>
                  <Button
                    color="link"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      cloneCurve(curve.index);
                    }}
                  >
                    <i className="fas fa-clone"></i> <FormattedMessage id="app.clone" defaultMessage="Clone" />
                  </Button>
                </div>
                <div>
                  <Button
                    color="link"
                    type="button"
                    disabled={curves.length === 1}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeCurve(curve);
                    }}
                  >
                    <i className="fas fa-trash"></i> <FormattedMessage id="app.remove" defaultMessage="Remove" />
                  </Button>
                </div>
              </>
            )}
          </div>
        </CardHeader>
        <CardBody className="p-3">
          <FormGroup row>
            <Label sm={3}>
              <FormattedMessage id="product_information.principal" defaultMessage="Principal" />
            </Label>
            <Col sm={3}>
              <CustomInput
                type="switch"
                id="principal"
                name="principal"
                disabled={isProductReadOnly || isGroupReadOnly || isReadOnly || curves.length === 1}
                checked={curve["principal"]}
                onChange={updatePrincipal}
              />
            </Col>
            <Label sm={3}>
              <FormattedMessage id="product_information.referenceProduct" defaultMessage="Reference Product" />
            </Label>
            <Col sm={3}>
              <CustomInput
                type="switch"
                id="referenceProduct"
                name="referenceProduct"
                disabled={isProductReadOnly || isGroupReadOnly || isReadOnly || curves.length === 1}
                checked={curve["referenceProduct"] == 1}
                onChange={updateReferenceProduct}
              />
            </Col>
          </FormGroup>

          <GeometricalParameters
            productAttributes={productAttributes}
            curve={curve}
            updateCurve={updateCurve}
            updateCurveByObj={updateCurveByObj}
            disabled={isReadOnly}
            checkValidation={checkValidation}
            validationErrorText={getErrorText}
          ></GeometricalParameters>
          <OtherParameters productAttributes={productAttributes} curve={curve} updateCurve={updateCurve} disabled={isReadOnly}></OtherParameters>
          <ElectricalParameters
            productAttributes={productAttributes}
            curve={curve}
            updateCurve={updateCurve}
            updateCurveByObj={updateCurveByObj}
            disabled={isReadOnly}
          ></ElectricalParameters>
          {!product.codModeloMotor && <MotorParameters productAttributes={productAttributes} curve={curve} updateCurve={updateCurve} disabled={isReadOnly}></MotorParameters>}
          <AerodynamicParameters productAttributes={productAttributes} curve={curve} updateCurve={updateCurve} disabled={isReadOnly}></AerodynamicParameters>
          <RegulationParameters productAttributes={productAttributes} curve={curve} updateCurve={updateCurve} disabled={isReadOnly}></RegulationParameters>

          <FormGroup row className="mb-0 mt-4">
            <Label sm={3}>
              <FormattedMessage id="product_information.modeloCurva" defaultMessage="Modelo Curva" />
              {isRequired("modeloCurva", productAttributes)}
              {productAttributes?.modeloCurva?.informationFormattedMessage && (
                <ProductInfo formattedMessage={productAttributes.modeloCurva.informationFormattedMessage}></ProductInfo>
              )}
            </Label>
            <Col sm={9}>
              <Tooltip text={getErrorText("modeloCurva")}>
                <ProductInput
                  inputOnly={true}
                  attributeClass="modeloCurva"
                  attributes={productAttributes}
                  obj={[curve, product]}
                  update={updateCurve}
                  disabled={curve.modeloCurvaAuto || modeloCurvaLocked || isReadOnly || isSinCurva}
                  className={checkValidation("modeloCurva")}
                ></ProductInput>
              </Tooltip>
              {group.curves.some((c) => c.index !== curve.index && c.modeloCurva && c.modeloCurva?.toUpperCase() == curve.modeloCurva?.toUpperCase()) && (
                <Alert color="danger" className="mt-2">
                  <FormattedMessage id="error.duplicate_modeloCurva" defaultMessage="Warning you have duplicate ModeloCurva's" />
                </Alert>
              )}
              {!isSinCurva && /* !group.existingGrupoCurve */ !isReadOnly && (
                <div className="mt-2">
                  <button type="button" onClick={toggleCurveModal} className="btn btn-danger">
                    <FormattedMessage id="product_information.use_existing_curve" defaultMessage="Select an existing curve" />
                  </button>
                  {curve.existingCurve && (
                    <>
                      <button type="button" onClick={clearCurveData} className="btn btn-dark">
                        <FormattedMessage id="product_information.clear_curve" defaultMessage="Clear curve" />
                      </button>
                      <button type="button" onClick={clearCurveLink} className="btn btn-dark">
                        <FormattedMessage id="product_information.convert_to_new_curve" defaultMessage="Convert to new curve" />
                      </button>
                    </>
                  )}
                </div>
              )}
            </Col>
          </FormGroup>
          {!isReadOnly && !modeloCurvaLocked && !isSinCurva && (
            <>
              <FormGroup row className="my-0">
                <Col sm={{ size: 9, offset: 3 }}>
                  <div className="d-flex align-items-center">
                    <span className="mr-3">
                      <FormattedMessage id="product_information.automatic" defaultMessage="Automatic" />
                    </span>{" "}
                    <CustomInput type="switch" id="modeloCurvaAuto" checked={curve.modeloCurvaAuto || false} onChange={toggleModeloCurvaMode} />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row className="mt-0">
                <Col sm={{ size: 9, offset: 3 }}>
                  <Tooltip text={intl.formatMessage({ id: "product_information.suggested_value_structure", defaultMessage: "Suggested value structure" })}>
                    <AlertWithIcon
                      className="mt-1"
                      icon={<i className="far fa-lightbulb" title="suggestion"></i>}
                      action={
                        <Button size="sm" color="secondary" onClick={() => updateCurve({ target: { id: "modeloCurva", value: generateModeloCurva(curve) } })}>
                          <FormattedMessage id="app.apply" defaultMessage="Apply" />
                        </Button>
                      }
                    >
                      {generateModeloCurva(curve)}
                    </AlertWithIcon>
                  </Tooltip>
                </Col>
              </FormGroup>
            </>
          )}
        </CardBody>
      </Card>
      <ListerTableSelectionModal
        title={intl.formatMessage({ id: "product_information.select_a_modelocurva", defaultMessage: "Select a modelo curva" })}
        columns={[
          { Header: intl.formatMessage({ id: "curves.cod_modelo_curva", defaultMessage: "CodModeloCurva" }), accessor: "id" },
          { Header: intl.formatMessage({ id: "curves.modelo_curva", defaultMessage: "Modelo Curva" }), accessor: "name" },
          { Header: intl.formatMessage({ id: "curves.serie_curva", defaultMessage: "Serie Curva" }), accessor: "curveSeries" },
          { Header: intl.formatMessage({ id: "curves.etiqueta", defaultMessage: "Etiqueta" }), accessor: "etiqueta" },
          { Header: intl.formatMessage({ id: "curves.cuerpo", defaultMessage: "Cuerpo" }), accessor: "cuerpo" },
        ]}
        isOpen={curveModalOpen}
        toggle={toggleCurveModal}
        lister={modeloCurveLister}
        prefilter={{ curveSeriesId: product.series.codSerieCurva }}
        onSelect={handleCurveSelection}
      />
    </>
  );
}

export default CurveEdit;
