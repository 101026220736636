import React, { useEffect } from "react";
import { TextInput } from "react-bootstrap4-form-validation";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, CustomInput, Label } from "reactstrap";
import EditableDropdown from "../EditableDropdown";
import ReactTooltip from "react-tooltip";
import { SelectGroup } from "../FixedSelectGroup";
import Tooltip from "../Tooltip";
import AlertWithIcon from "../AlertWithIcon";
import { valueTypes } from "../../products/ProductAttributeValueTypes";
import { getFormulaValues, isRequired, replaceTags } from "../../products/ProductDefinitions";
import ProductInfo from "./ProductInfo";
import Input from "../Input";
import { useProduct } from "../../context/ProductContext";

function createInput(attributeClass, attributes, obj, update, className, supportingAttributes, disabled, isWizard, customOptions) {
  const attribute = attributes[attributeClass];
  if (!attribute) return <></>;

  const valueType = attribute.value.type;
  const valueOptions = attribute.value.options;
  const inverted = attribute.isInverted || false;
  const referenceObj = Array.isArray(obj) ? obj[0] : obj;

  const isReadOnly = disabled || obj.isReadOnly || obj[0]?.isReadOnly || attribute?.readOnly;

  let value = Array.isArray(obj) ? obj[0][attributeClass] : obj[attributeClass];
  value = value != undefined ? value : attribute.value.default || "";

  switch (valueType) {
    case valueTypes.TEXT:
      return (
        <Input
          type="text"
          id={attributeClass}
          name={attributeClass}
          className={`flex-grow-1 ${!isReadOnly ? "form-control" : ""} ${className || ""}${isWizard ? " squared-right" : ""}`}
          required={attribute.required}
          value={value}
          onChange={update}
          plaintext={isReadOnly}
          readOnly={isReadOnly}
        />
      );

    case valueTypes.FLOAT:
    case valueTypes.INT:
      return (
        <Input
          type="number"
          id={attributeClass}
          name={attributeClass}
          className={`flex-grow-1 ${!isReadOnly ? "form-control" : ""} ${className || ""}${isWizard ? " squared-right" : ""}`}
          required={attribute.required}
          value={value}
          plaintext={isReadOnly.toString()}
          readOnly={isReadOnly}
          onChange={update}
        />
      );

    case valueTypes.GLOBAL_TABLE:
    case valueTypes.CUSTOM_TABLE:
      return attributeClass?.toLowerCase() == "frecuencia" ? (
        <EditableDropdown
          id={attributeClass}
          required={attribute.required}
          className={`form-control flex-grow-1 ${className || ""}${isWizard ? " squared-right" : ""}`}
          options={valueOptions}
          value={value}
          onChange={update}
          disabled={isReadOnly}
        ></EditableDropdown>
      ) : (
        <SelectGroup
          id={attributeClass}
          name={attributeClass}
          className={`form-control flex-grow-1 ${className || ""}${isWizard ? " squared-right" : ""}`}
          disabled={isReadOnly}
          required={attribute.required}
          value={value}
          onChange={update}
          errorMessage={""}
        >
          {valueOptions.length > 0 && <option value="">--- Please select ---</option>}
          {valueOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.value}
            </option>
          ))}
          {customOptions?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.value}
            </option>
          ))}
        </SelectGroup>
      );

    case valueTypes.DEFINITION:
      const existing =
        ((typeof referenceObj.id === "object" && referenceObj.id !== null && referenceObj.id.value > 0) || referenceObj.id > 0) &&
        !isUndefinedOrNullOrEmpty(referenceObj[attributeClass]);
      const val = existing ? referenceObj[attributeClass] : replaceTags(attribute.value.format, obj, supportingAttributes ? [attributes, supportingAttributes] : attributes, false);
      return <Input type="text" className={className} plaintext readOnly value={val} />;

    case valueTypes.BOOL:
      let bool_value = !isUndefinedOrNullOrEmpty(obj[attributeClass]) ? obj[attributeClass] : !isUndefinedOrNullOrEmpty(value) ? value : false;
      bool_value = (bool_value !== "0" && bool_value) || false;
      if (inverted) bool_value = !bool_value;
      return (
        <CustomInput
          type="switch"
          id={attributeClass}
          checked={bool_value}
          onChange={(e) => {
            update({ target: { id: e.target.id, type: "checkbox", checked: inverted ? !e.target.checked : e.target.checked } });
          }}
          disabled={isReadOnly}
        />
      );

    case valueTypes.FORMULA: {
      const value = getFormulaValues(attributeClass, obj, supportingAttributes ? [attributes, supportingAttributes] : attributes);
      if (value) {
        obj[attributeClass] = value;
        return <Input type="text" className={className} plaintext readOnly value={value} />;
      }
    }
  }

  return <Input type="text" className={className} plaintext readOnly value={valueType} />;
}

const isUndefinedOrNullOrEmpty = (val) => val === undefined || val === null || val === "";

const expandInput = (input, tooltipText) => {
  return tooltipText ? (
    <div className="w-100">
      <Tooltip text={tooltipText}>{input}</Tooltip>
    </div>
  ) : (
    input
  );
};

function ProductInput({
  attributeClass,
  attributes,
  supportingAttributes,
  inputOnly,
  obj,
  update,
  className,
  labelProps,
  columnProps,
  disabled,
  isWizard,
  customOptions,
  tooltipText,
}) {
  const attribute = attributes[attributeClass];
  const intl = useIntl();
  const isReadOnly = disabled || obj.isReadOnly || obj[0]?.isReadOnly || attribute?.readOnly;
  return !attribute || attribute.isVisible === false ? (
    <></>
  ) : inputOnly ? (
    <>
      {expandInput(createInput(attributeClass, attributes, obj, update, className, supportingAttributes, disabled, isWizard, customOptions), tooltipText)}
      {isWizard && <CopyToAllButton attribute={attribute} update={update} />}
    </>
  ) : (
    <>
      <Label {...labelProps}>
        {intl.formatMessage(attribute.labelFormattedMessage) || attribute.labelFormattedMessage.id}
        {isRequired(attributeClass, attributes)}
        {attribute.informationFormattedMessage && attribute.informationFormattedMessage.id && <ProductInfo formattedMessage={attribute.informationFormattedMessage}></ProductInfo>}
      </Label>
      <Col {...columnProps}>
        <div className="d-flex">
          {expandInput(createInput(attributeClass, attributes, obj, update, className, supportingAttributes, disabled, isWizard, customOptions), tooltipText)}
          {isWizard && <CopyToAllButton attribute={attribute} update={update} />}
        </div>
        {!isReadOnly && attribute.suggestion && (
          <Tooltip text={intl.formatMessage({ id: "product_information.suggested_value_structure", defaultMessage: "Suggested value structure" })}>
            <AlertWithIcon
              className="mt-1"
              icon={<i className="far fa-lightbulb"></i>}
              action={
                <Button
                  size="sm"
                  color="secondary"
                  onClick={() =>
                    update({
                      target: { id: attributeClass, value: replaceTags(attribute.suggestion, obj, supportingAttributes ? [attributes, supportingAttributes] : [attributes]) },
                    })
                  }
                >
                  <FormattedMessage id="app.apply" defaultMessage="Apply" />
                </Button>
              }
            >
              {replaceTags(attribute.suggestion, obj, supportingAttributes ? [attributes, supportingAttributes] : [attributes])}
            </AlertWithIcon>
          </Tooltip>
        )}
      </Col>
    </>
  );
}

function CopyToAllButton({ attribute, update }) {
  const intl = useIntl();
  const { products } = useProduct();
  const attributeClass = attribute.name;
  const linkStyle = [valueTypes.FORMULA, valueTypes.BOOL, valueTypes.DEFINITION].includes(attribute.value.type);
  const same = products.filter((p) => p[attributeClass] === products[0][attributeClass]).length === products.length;

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  function applyToAll(e) {
    update(e, attributeClass);
  }

  return (
    <Tooltip
      text={
        same
          ? intl.formatMessage({ id: "product_information.values_match", defaultMessage: "All products have the same value" })
          : intl.formatMessage({ id: "product_information.copy_to_all", defaultMessage: "Copy to all products" })
      }
    >
      <Button color={linkStyle ? "link" : same ? "dark" : "danger"} className="px-2 squared-left" disabled={same} onClick={applyToAll}>
        <i className={`fas ${same ? "fa-file-import" : "fa-file-import"} ${linkStyle ? (same ? "text-dark" : "text-danger") : ""}`}></i>
      </Button>
    </Tooltip>
  );
}

export default ProductInput;
