import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Card, CardBody, CardHeader, FormGroup, Button, Label, Col, CustomInput, Input, Nav, NavItem, NavLink, Alert } from "reactstrap";
import ProductInput from "../ProductInput";
import CurveEdit from "./CurveEdit";
import Spinner from "../../Spinner";
import GroupOtherParameters from "./GroupOtherParameters";
import GroupElectricalParameters from "./GroupElectricalParameters";
import Tooltip from "../../Tooltip";
import AlertWithIcon from "../../AlertWithIcon";
import { isRequired, replaceTags } from "../../../products/ProductDefinitions";
import ListerTableSelectionModal from "../../Modals/ListerTableSelectionModal";
import { useService } from "../../../context/ServiceContext";
import ProductInfo from "../ProductInfo";
import { fireAlert, fireConfirm } from "../../../utils/alerts";
import ErrorSummary from "../ErrorSummary";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { replaceBlockedCharacters } from "../../../products/ProductValidation";

function GroupEdit({
  product,
  isNewProduct,
  groupIndex,
  groups,
  updateProduct,
  removeGroup,
  cloneGroup,
  updateGroupPrincipal,
  groupAttributes,
  setGroupAttributes,
  productCurveAttributesProvider,
  productCurveGroupAttributesProvider,
  conceptClasses,
  visible,
  validationErrors,
  checkCurveValidity,
}) {
  const group = groups[groupIndex];
  const intl = useIntl();

  const { modeloGroupCurveLister, modeloCurveGroupSpecificationAccessor } = useService();
  const { curves, conceptClassUnic } = group;
  const [dragging, setDragging] = useState(false);
  const [productAttributes, setProductAttributes] = useState();
  const [selectedCurve, setSelectedCurve] = useState(curves.find((c) => c.principal)?.index || curves[0].index);
  const [grupoCurvaModalOpen, setGrupoCurvaModalOpen] = useState(false);

  const isProductReadOnly = product?.isReadOnly || false;
  const isReadOnly = (group?.isReadOnly || false) && (group?.existingGrupoCurve || false);
  const isPrincipalGroup = group?.principalMG;

  const toggleGrupoCurvaModal = () => setGrupoCurvaModalOpen(!grupoCurvaModalOpen);
  const modeloGrupoCurvaLocked = group.id > 0;

  function updateGroup(curveGroup) {
    let cg = { ...curveGroup, modeloGrupoCurva: curveGroup.modeloGrupoCurvaAuto ? generateModeloGrupoCurva(curveGroup) : curveGroup.modeloGrupoCurva };
    updateProduct({
      ...product,
      curveGroups: groups.map((g) => {
        if (g.index === curveGroup.index) {
          return cg;
        } else return g;
      }),
    });
  }

  function generateModeloGrupoCurva(g) {
    if (groupAttributes && productAttributes)
      return replaceTags(groupAttributes["modeloGrupoCurva"].suggestion, [g, product, principalCurve()], [groupAttributes, productAttributes]);
  }

  function toggleModeloGrupoCurvaMode() {
    const mode = !group.modeloGrupoCurvaAuto;
    updateGroup({ ...group, modeloGrupoCurvaAuto: mode });
  }

  useEffect(() => {
    getAttributes();
  }, [conceptClassUnic]);

  async function getAttributes() {
    if (conceptClassUnic) {
      setProductAttributes(await productCurveAttributesProvider.get(conceptClassUnic));
      setGroupAttributes(await productCurveGroupAttributesProvider.get(conceptClassUnic));
    }
  }

  useEffect(() => {
    if (!productAttributes) return;
    let cs = [...curves];
    for (let i = 0; i < cs.length; i++) {
      const c = cs[i];
      if (!c.etiqueta) {
        const etiqueta = replaceTags(productAttributes["etiqueta"]?.suggestion, [c, product], [productAttributes]);
        if (etiqueta) {
          c.etiqueta = etiqueta;
        }
      }
    }
  }, [productAttributes]);

  function newCurve() {
    return {
      index: curves.length > 0 ? curves.reduce((max, n) => (n.index > max.index ? n : max)).index + 1 : 0,
      activo: true,
      principal: curves.length === 0,
      claseTension: group.codTension,
      frecuencia: group.frecuencia,
      polos: group.polos,
      cuerpo: product.cuerpo,
      tamany: product.tamanyNominal,
      flujoAire: isAHU() ? group.filterPrincipalMG : undefined,
      referenceProduct: isPrincipalGroup && curves.length === 0 ? 1 : 0,
    };
  }

  const isAHU = () => group.conceptClassUnic == conceptClasses?.find((c) => c.value.toUpperCase() == "AHU-CONFIGURABLEHRU")?.id;

  function clearCurve(curve) {
    return { ...newCurve(), index: curve.index };
  }

  function addCurve() {
    let curve = newCurve();
    updateGroup({
      ...group,
      curves: curves.concat({ ...curve }),
    });
  }

  function removeCurve(curve) {
    if (curves.length === 1) return;

    fireConfirm(
      intl.formatMessage({ id: "warnings.confirm_curve_delete", defaultMessage: "Are you sure you want to remove this curve?" }),
      intl.formatMessage({ id: "warnings.confirm_curve_delete_title", defaultMessage: "Remove curve" }),
      "question",
      intl.formatMessage({ id: "app.yes", defaultMessage: "Yes" }),
      intl.formatMessage({ id: "app.no", defaultMessage: "No" }),
      () => {
        let curveIndex = curves.indexOf(curve);
        let newCurves = curves
          .filter((c) => c.index !== curve.index)
          .map((c, i) => {
            if (curve.principal && i === 0) {
              return {
                ...c,
                principal: true,
              };
            } else {
              return {
                ...c,
              };
            }
          });

        updateGroup({
          ...group,
          curves: newCurves,
        });

        if (curveIndex > 0) curveIndex--;

        setSelectedCurve(newCurves[curveIndex].index);
      },
      () => {}
    );
  }

  async function cloneCurve(index) {
    let curve = { ...curves.find((c) => c.index === index) };

    // Check curve validity first
    var errors = await checkCurveValidity(curve, group);
    if (errors.hasErrors) {
      fireAlert(<ErrorSummary intl={intl} curveErrors={errors}></ErrorSummary>, "Incorrect attributes", "error");
      return;
    }

    curve.index = curves.length > 0 ? curves.reduce((max, n) => (n.index > max.index ? n : max)).index + 1 : 0;
    curve.principal = false;
    curve.isReadOnly = false;
    curve.existingCurve = false;
    curve.id = 0;

    updateGroup({
      ...group,
      curves: curves.concat({ ...curve }),
    });
  }

  function update(e) {
    const key = e.target.id;
    const value = e.target.type === "checkbox" ? e.target.checked : replaceBlockedCharacters(e.target.value);

    updateGroup({
      ...group,
      [key]: value,
    });
  }

  const principalCurve = () => curves.find((c) => c.principal) || curves[0];

  async function handleGrupoCurveSelection(choice) {
    if (choice) {
      console.log(choice.id);
      const curveGroup = await modeloCurveGroupSpecificationAccessor.find(choice.id);
      if (curveGroup) {
        updateGroup({
          ...curveGroup,
          index: group.index,
          existingGrupoCurve: true,
          curves: curveGroup.curves.map((c, i) => {
            return { ...c, index: i, existingCurve: true };
          }),
        });
      }
    }

    toggleGrupoCurvaModal();
  }

  function clearGrupoCurve() {
    updateGroup({ ...group, id: 0, existingGrupoCurve: false, isReadOnly: false });
  }

  function updatePrincipal(e) {
    const checked = e.target.checked;
    if (curves.indexOf(curves.find((c) => c.index === selectedCurve)) === 0 && !checked) {
      return;
    }

    updateGroup({
      ...group,
      curves: curves
        .map((c, i) => {
          const isPrincipal = c.index === selectedCurve ? checked : !checked && i === 0 ? true : false;
          return {
            ...c,
            principal: isPrincipal,
            referenceProduct: isPrincipalGroup ? (isPrincipal ? 1 : c.referenceProduct) : 0,
          };
        })
        .sort(function (a, b) {
          return a.principal ? -1 : 1;
        }),
    });

    //setSelectedCurve(0);
  }

  function updateReferenceProduct(e) {
    const checked = e.target.checked;

    updateGroup({
      ...group,
      curves: curves.map((c, i) => {
        if (c.index === selectedCurve) {
          return {
            ...c,
            referenceProduct: checked ? 1 : 0,
          };
        } else return c;
      }),
    });

    //setSelectedCurve(0);
  }

  // Curve reordering

  function onDragStart() {
    setDragging(true);
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderedItems = reorder(curves, result.source.index, result.destination.index).map((c, i) => {
      c.principal = i == 0;
      return c;
    });

    updateGroup({ ...group, curves: orderedItems });

    setDragging(false);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  function checkValidation(attributeClass) {
    if (validationErrors === null) return "";
    let isInvalid = true;
    const error = validationErrors.groups?.find((g) => g.index === groupIndex);
    if (error) {
      isInvalid = error[attributeClass] !== undefined;
    }

    return isInvalid ? "is-invalid" : "is-valid";
  }

  function getErrorText(attributeClass) {
    if (validationErrors === null) return "";
    let error = validationErrors.groups?.find((g) => g.index === groupIndex);
    if (error) error = error[attributeClass];
    else return "";

    return error ? intl.formatMessage(error.errorFormattedMessage) : "";
  }

  const isSinCurva = conceptClasses?.find((c) => c.id == conceptClassUnic)?.value == "SinCurva";

  return !visible ? (
    <></>
  ) : (
    <div className="p-3" style={{ backgroundColor: "#fafafa" }}>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{group.modeloGrupoCurva}</div>
        {!isProductReadOnly && !isSinCurva && (
          <>
            <div>
              <Button
                color="link"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  cloneGroup(group.index);
                }}
              >
                <i className="fas fa-clone"></i> <FormattedMessage id="app.clone" defaultMessage="Clone" />
              </Button>
            </div>
            <div>
              <Button
                color="link"
                type="button"
                disabled={groups.length === 1}
                onClick={(e) => {
                  e.stopPropagation();
                  removeGroup(group);
                }}
              >
                <i className="fas fa-trash"></i> <FormattedMessage id="app.remove" defaultMessage="Remove" />
              </Button>
            </div>
          </>
        )}
      </div>
      {groupAttributes && productAttributes && (
        <>
          <FormGroup row className="mb-0">
            <Label sm={3}>
              <FormattedMessage id="product_information.modeloGrupoCurva" defaultMessage="Modelo Grupa Curva" />
              {isRequired("modeloGrupoCurva", groupAttributes)}
              {groupAttributes.modeloGrupoCurva.informationFormattedMessage && (
                <ProductInfo formattedMessage={groupAttributes.modeloGrupoCurva.informationFormattedMessage}></ProductInfo>
              )}
            </Label>
            <Col sm={9}>
              <Tooltip text={getErrorText("modeloGrupoCurva")}>
                <ProductInput
                  inputOnly={true}
                  attributeClass="modeloGrupoCurva"
                  attributes={groupAttributes}
                  supportingAttributes={productAttributes}
                  obj={[group, product, principalCurve()]}
                  update={update}
                  disabled={group.modeloGrupoCurvaAuto || modeloGrupoCurvaLocked || isReadOnly || isSinCurva}
                  className={checkValidation("modeloGrupoCurva")}
                ></ProductInput>
              </Tooltip>
              {product.curveGroups.some((cg) => cg.index !== group.index && cg.modeloGrupoCurva && cg.modeloGrupoCurva?.toUpperCase() == group.modeloGrupoCurva?.toUpperCase()) && (
                <Alert color="danger" className="mt-2">
                  <FormattedMessage id="error.duplicate_modeloGrupoCurva" defaultMessage="Warning you have duplicate ModeloGrupoCurva's" />
                </Alert>
              )}
              {!isProductReadOnly && !isSinCurva && (
                <div className="mt-2">
                  <button type="button" onClick={toggleGrupoCurvaModal} className="btn btn-danger">
                    <FormattedMessage id="product_information.use_existing_group" defaultMessage="Select an existing group" />
                  </button>
                  {group.existingGrupoCurve && (
                    <button type="button" onClick={clearGrupoCurve} className="btn btn-dark">
                      <FormattedMessage id="product_information.convert_to_new_group" defaultMessage="Convert to new group" />
                    </button>
                  )}
                </div>
              )}
            </Col>
          </FormGroup>
          {!isProductReadOnly && !isReadOnly && !modeloGrupoCurvaLocked && !group.existingGrupoCurve && !isSinCurva && (
            <>
              <FormGroup row className="my-0">
                <Col sm={{ size: 9, offset: 3 }}>
                  <div className="d-flex align-items-center">
                    <span className="mr-3">
                      <FormattedMessage id="product_information.automatic" defaultMessage="Automatic" />
                    </span>{" "}
                    <CustomInput type="switch" id="modeloGrupoCurvaAuto" checked={group.modeloGrupoCurvaAuto || false} onChange={toggleModeloGrupoCurvaMode} />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row className="mt-0">
                <Col sm={{ size: 9, offset: 3 }}>
                  <Tooltip text={intl.formatMessage({ id: "product_information.suggested_value_structure", defaultMessage: "Suggested value structure" })}>
                    <AlertWithIcon
                      className="mt-1"
                      icon={<i className="far fa-lightbulb" title="suggestion"></i>}
                      action={
                        <Button size="sm" color="secondary" onClick={() => update({ target: { id: "modeloGrupoCurva", value: generateModeloGrupoCurva(group) } })}>
                          <FormattedMessage id="app.apply" defaultMessage="Apply" />
                        </Button>
                      }
                    >
                      {generateModeloGrupoCurva(group)}
                    </AlertWithIcon>
                  </Tooltip>
                </Col>
              </FormGroup>
            </>
          )}
        </>
      )}
      <FormGroup row className="mt-2">
        <Label sm={3}>
          <FormattedMessage id="product_curves_information.curve_definition" defaultMessage="Type of curves" />
        </Label>
        <Col sm={9}>
          {isProductReadOnly || isReadOnly || isSinCurva ? (
            <Input type="text" plaintext readOnly value={conceptClasses?.find((c) => c.id == conceptClassUnic)?.value || conceptClassUnic} />
          ) : (
            <Input type="select" id="conceptClassUnic" value={conceptClassUnic} disabled={isReadOnly} onChange={(e) => updateGroup({ ...group, conceptClassUnic: e.target.value })}>
              <option value="" disabled="disabled">
                {intl.formatMessage({ id: "product_information.select_an_option", defaultMessage: "Please select an option" })}
              </option>
              {conceptClasses.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.value}
                </option>
              ))}
            </Input>
          )}
        </Col>
        <Label sm={3}>
          <FormattedMessage id="product_information.principal" defaultMessage="Principal" />
        </Label>
        <Col sm={9}>
          <CustomInput
            type="switch"
            id="principalGroup"
            disabled={isProductReadOnly || isReadOnly || isSinCurva || groups.length === 1}
            checked={group["principalMG"]}
            onChange={updateGroupPrincipal}
          />
        </Col>
      </FormGroup>
      {!conceptClassUnic ? (
        <div className="text-center mt-3">
          <h3>
            <FormattedMessage id="product_information.please_select_a_type_of_curve" defaultMessage="Please select a type of curve" />
          </h3>
        </div>
      ) : !groupAttributes ? (
        <Spinner></Spinner>
      ) : (
        <>
          <GroupOtherParameters
            disabled={isReadOnly}
            group={group}
            groupAttributes={groupAttributes}
            product={product}
            update={update}
            principalCurve={principalCurve()}
            checkValidation={checkValidation}
            validationErrorText={getErrorText}
            isSinCurva={isSinCurva}
            updateGroup={updateGroup}
            conceptClasses={conceptClasses}
          ></GroupOtherParameters>
          <GroupElectricalParameters disabled={isReadOnly} group={group} groupAttributes={groupAttributes} updateGroup={updateGroup}></GroupElectricalParameters>

          <Card className="my-2">
            <CardHeader>
              <FormattedMessage id="product_information.curve_definition" defaultMessage="Curve definition" />
            </CardHeader>
            <CardBody className="p-3">
              <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onDragStart}>
                <Droppable droppableId={`dr`} direction="horizontal">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Nav tabs className="pb-2">
                        {curves.find((c) => c.index === selectedCurve) &&
                          curves.map((c, i) => (
                            <Draggable draggableId={`cv-${i}`} index={i} key={i} isDragDisabled={isProductReadOnly || isReadOnly}>
                              {(provided, snapshot) => (
                                <NavItem key={c.index} onClick={() => setSelectedCurve(c.index)}>
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    ref={provided.innerRef}
                                  >
                                    <NavLink active={selectedCurve === c.index} href="#">
                                      {c.etiqueta ? c.etiqueta : `Curve ${c.index + 1}`}{" "}
                                      {c.principal && (
                                        <span className={`badge ${selectedCurve === c.index ? "badge-danger" : "badge-dark"}`}>
                                          {intl.formatMessage({ id: "product_information.principal", defaultMessage: "Principal" })}
                                        </span>
                                      )}
                                    </NavLink>
                                  </div>
                                </NavItem>
                              )}
                            </Draggable>
                          ))}

                        {!dragging && !(isProductReadOnly || isReadOnly || isSinCurva) && (
                          <li className="nav-item">
                            <Button color="primary" type="button" onClick={addCurve} size="sm">
                              <i className="fas fa-plus"></i> <FormattedMessage id="product_information.add_curve" defaultMessage="Add curve" />
                            </Button>
                          </li>
                        )}
                      </Nav>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {curves.find((c) => c.index === selectedCurve) && (
                <CurveEdit
                  curve={curves.find((c) => c.index === selectedCurve)}
                  curves={curves}
                  group={group}
                  product={product}
                  clearCurve={clearCurve}
                  updateGroup={updateGroup}
                  cloneCurve={cloneCurve}
                  removeCurve={removeCurve}
                  productAttributes={productAttributes}
                  validationErrors={validationErrors}
                  updatePrincipal={updatePrincipal}
                  updateReferenceProduct={updateReferenceProduct}
                  isSinCurva={isSinCurva}
                ></CurveEdit>
              )}
            </CardBody>
          </Card>
          <ListerTableSelectionModal
            title={intl.formatMessage({ id: "product_information.select_a_modelo_group_curva", defaultMessage: "Select a modelo grupo curva" })}
            columns={[
              { Header: intl.formatMessage({ id: "curves.cod_modelo_grupo_curva", defaultMessage: "CodModeloGrupoCurva" }), accessor: "id" },
              { Header: intl.formatMessage({ id: "curves.modelo_grupo_curva", defaultMessage: "Modelo Grupo Curva" }), accessor: "name" },
              { Header: intl.formatMessage({ id: "curves.serie_curva", defaultMessage: "Serie Curva" }), accessor: "curveSeries" },
              { Header: intl.formatMessage({ id: "curves.filter_principal_mg", defaultMessage: "FilterPrincipalMG" }), accessor: "filterPrincipalMG" },
              { Header: intl.formatMessage({ id: "curves.frecuencia", defaultMessage: "Frecuencia" }), accessor: "frecuencia" },
              { Header: intl.formatMessage({ id: "curves.tension", defaultMessage: "Tension" }), accessor: "tension" },
            ]}
            isOpen={grupoCurvaModalOpen}
            toggle={toggleGrupoCurvaModal}
            lister={modeloGroupCurveLister}
            prefilter={{ curveSeriesId: product.series.codSerieCurva }}
            onSelect={handleGrupoCurveSelection}
          />
        </>
      )}
    </div>
  );
}

export default GroupEdit;
