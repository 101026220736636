export default function Formatter() {
  function customFormatMessage(message, values) {
    if (values) {
      for (let i = 0; i < values.length; i++) {
        const v = values[i];
        message = message.replace(`{${i}}`, v);
      }
    }
    return message;
  }

  return {
    customFormatMessage
  };
}
