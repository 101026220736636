import React from "react";
import { FormattedMessage } from "react-intl";
import { Container } from "reactstrap";
import AcousticEntry from "../../components/CurvesGenerator/Acoustic/AcousticEntry";
import Header from "../../components/Header";

function Acoustic(props) {
    return <>
        <Header>
            <h1>
                <FormattedMessage id="acoustic_aggregate.title" defaultMessage="Total Aggregate Noise" />
            </h1>
        </Header>
        
        <Container fluid>
            <AcousticEntry />
        </Container>
    </>
}

export default Acoustic;