import React from "react";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { replaceBlockedCharacters } from "../../../products/ProductValidation";

function ProductDenominationStep({ next, prev, denomination, setDenomination }) {
  return (
    <ValidationForm onSubmit={() => {}}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.product_denomination" defaultMessage="Product denomination" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage id="product_wizard.common_denomination" defaultMessage="Which is the common denomination for the products in this range?" />
          </p>
          <TextInput
            type="text"
            id="denomination"
            name="denomination"
            value={denomination}
            onChange={(e) => setDenomination(replaceBlockedCharacters(e.target.value.toUpperCase()))}
            placeholder="Example: HXM"
            className="form-control"
            style={{ maxWidth: 350 }}
            required={true}
            errorMessage={""}
          />
        </CardBody>
        <CardFooter>
          <Button className="btn btn-dark float-right" onClick={next}>
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default ProductDenominationStep;
