import axios from "axios";
import qs from "qs";
import SessionStorageCache from "./cache/SessionStorageCache";
import LocalStorageCache from "./cache/LocalStorageCache";
import HttpAccountRepository from "./services/authentication/HttpAccountRepository";
import HttpAuthorizationStore from "./services/authorization/HttpAuthorizationStore";
import ListerSuggester from "./services/suggestion/ListerSuggester";
import HttpLister from "./services/listing/HttpLister";
import HttpProvider from "./services/HttpProvider";
import HttpStore from "./services/persistence/HttpStore";
import HttpAccessor from "./services/persistence/HttpAccessor";
import MemoryCache from "./cache/MemoryCache";
import CachingProviderShim from "./services/CachingProviderShim";
import { defaults } from "react-chartjs-2";
import HttpEvaluator from "./services/HttpEvaluator";
import DependentGenerationService from "./services/curves/DependentGenerationService";
import AggregateTotalService from "./services/acoustic/AggregateTotalService";
import CurveTestService from "./services/curves/CurveTestService";
import NonResidentialErPService from "./services/erp/NonResidentialErPService";
import ResidentialErPService from "./services/erp/ResidentialErPService";
import paths from "./services/paths";

function Startup(config) {
  defaults.global = { ...defaults.global, ...config.chartjs.defaults.global };

  const http = axios.create({
    baseURL: "/api/",
    https: true,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, skipNulls: true }),
  });

  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        error.localization = error.response.data?.localization ?? {
          id: "app.general_error",
          defaultMessage: "Something went wrong. If the problem persists, please contact your administrator.",
        };
        error.traceId = error.response.data?.traceId;
        error.validation = error.response.data?.validation;
      } else if (!axios.isCancel(error)) {
        error.localization = { id: "app.network_error", defaultMessage: "A network error occurred. Please try again." };
      }
      return Promise.reject(error);
    }
  );

  const localSessionCache = new SessionStorageCache();
  const localCache = new LocalStorageCache();
  const memoryCache = new MemoryCache();
  const authorizationStore = new HttpAuthorizationStore(http, localCache);
  const accountRepository = new HttpAccountRepository(http, authorizationStore);
  const maintenanceStatusProvider = new HttpProvider(http, paths.MAINTENANCE_STATUS_PATH);

  const seriesLister = new HttpLister(http, "curve/series/list");
  const serieComercialLister = new HttpLister(http, "product/series/list");
  const serieComercialLoadingsLister = new HttpLister(http, "product/series/loadings/list");
  const serieComercialLoadingsQueueLister = new HttpLister(http, "product/series/loadings/queue/list");
  const serieComercialLoadingStatusLister = new HttpLister(http, "product/series/loadings/status/list");
  const serieSAPLister = new HttpLister(http, "product/series/sap/list");
  const serieComercialGenericProductLister = new HttpLister(http, "product/generic-product-series/list");
  const serieComercialProductLister = new HttpLister(http, "product/product-series/list");
  const productLister = new HttpLister(http, "curve/product/list");
  const curveGroupLister = new HttpLister(http, "curve/curve-group/list");
  const curveLister = new HttpLister(http, "curve/list");
  const modeloCurveLister = new HttpLister(http, "curve/modelo/list");
  const modeloGroupCurveLister = new HttpLister(http, "curve/group/list");
  const curveTestLister = new HttpLister(http, "curve/test/list");
  const curveTestProvider = new HttpProvider(http, "curve/test", (key) => ({ testId: key.testId, curveId: key.curveId }));
  const seriesSuggester = new ListerSuggester(
    seriesLister,
    (f) => ({ name: f }),
    (s) => s.name
  );
  const serieComercialSuggester = new ListerSuggester(
    serieComercialLister,
    (f) => ({ name: f }),
    (s) => s.name
  );
  const serieComercialLoadingsSuggester = new ListerSuggester(
    serieComercialLoadingsLister,
    (f) => ({ serieComercial: f }),
    (s) => s.serieComercial
  );
  const serieComercialSAPSuggester = new ListerSuggester(
    serieSAPLister,
    (f) => ({ name: f }),
    (s) => s.name
  );
  const serieComercialInternalSuggester = new ListerSuggester(
    serieComercialLister,
    (f) => ({ internalName: f }),
    (s) => s.internalName
  );
  const seriesGenericProductsSuggester = new ListerSuggester(
    serieComercialGenericProductLister,
    (f) => ({ name: f }),
    (s) => s.name
  );
  const seriesProductsSuggester = new ListerSuggester(
    serieComercialProductLister,
    (f) => ({ name: f }),
    (s) => s.name
  );
  const productSuggester = new ListerSuggester(
    productLister,
    (f) => ({ name: f }),
    (s) => s.name
  );
  const curveGroupSuggester = new ListerSuggester(
    curveGroupLister,
    (f) => ({ name: f }),
    (s) => s.name
  );
  const curveSuggester = new ListerSuggester(
    curveLister,
    (f) => ({ name: f }),
    (s) => s.name
  );
  const curveLabTestNumberSuggester = new ListerSuggester(
    curveTestLister,
    (f) => ({ labTestNumber: f }),
    (s) => s.labTestNumber
  );
  const curveTestIdSuggester = new ListerSuggester(
    curveTestLister,
    (f) => ({ curveTestId: f }),
    (s) => s.curveTestId
  );
  const curveSpecificationAccessor = new HttpAccessor(http, "curve/specification", (key) => ({ id: key }));
  const curveTestService = new CurveTestService(http);
  const modeloCurveSpecificationAccessor = new HttpAccessor(http, "curve/modelo/specification", (key) => ({ id: key }));
  const modeloCurveGroupSpecificationAccessor = new HttpAccessor(http, "curve/group/specification", (key) => ({ id: key }));
  const dependentGenerationService = new DependentGenerationService(http, "curve/test/dependents/process", 1000);

  const aeroTestLister = new HttpLister(http, "tests/aerodynamic/list");
  const acousticTestLister = new HttpLister(http, "tests/acoustic/list");
  const aggregateTotalService = new AggregateTotalService(http);

  const kitErPLister = new HttpLister(http, "erp/kit/list");
  const rvuErPStore = new HttpStore(http, "erp/rvu", (key) => ({ name: key }));
  const rvuPerformanceEvaluator = new HttpEvaluator(http, "erp/rvu/performance");
  const nonResidentialErPService = new NonResidentialErPService(http);
  const residentialErPService = new ResidentialErPService(http);

  const motorLister = new HttpLister(http, "motor/list");
  const motorProvider = new HttpProvider(http, "motor", (key) => ({ id: key }));

  const productCurveAttributesProvider = new CachingProviderShim(
    new HttpProvider(http, "product/inputs/curve/attributes", (key) => ({ concept: key })),
    memoryCache,
    "Genesis.Product.CurveAttributes"
  );
  const productConceptsProvider = new CachingProviderShim(
    new HttpProvider(http, "product/inputs/concepts", (key) => ({ codSerieComercial: key })),
    memoryCache,
    "Genesis.Product.Concepts"
  );
  const productTechnicalAttributesProvider = new CachingProviderShim(
    new HttpProvider(http, "product/inputs/technical/attributes", (key) => ({ concept: key })),
    memoryCache,
    "Genesis.Product.TechnicalAttributes"
  );
  const productCurveGroupAttributesProvider = new CachingProviderShim(
    new HttpProvider(http, "product/inputs/curve-group/attributes", (key) => ({ concept: key })),
    memoryCache,
    "Genesis.Product.CurveGroupAttributes"
  );
  const curveGroupDetailsProvider = new CachingProviderShim(
    new HttpProvider(http, "product/curve-groups", (key) => ({ codModeloProductoGenerico: key })),
    memoryCache,
    "Genesis.Product.Concepts"
  );

  const productSpecificationAccessor = new HttpAccessor(http, "product/specification", (key) => ({ id: key.id, codSerieComercial: key.seriesId, version: key.version }));
  const productSpecificationStore = new HttpStore(http, "product/specifications", (key) => ({ specifications: key.specifications }));

  const seriesLoadingProcessor = new HttpStore(http, "product/series/loadings/process", (key) => ({ seriesProcess: key.seriesProcess }));

  // Admin
  const crmBulkProcessor = new HttpAccessor(http, "admin/crm/bulk/process");
  const seriesExpressProcessor = new HttpAccessor(http, "admin/series-express/process");

  const productSAPSpecificationAccessor = new HttpAccessor(http, "product/sap/specification", (key) => ({ id: key }));

  const neededAttributesProvider = new CachingProviderShim(
    new HttpProvider(http, "product/inputs/needed-attributes", (key) => ({ concept: key })),
    memoryCache,
    "Genesis.Product.NeededAttributes"
  );
  const productExistenceProvider = new CachingProviderShim(new HttpProvider(http, "product/exists", (key) => ({ model: key })), memoryCache, "Genesis.Product.ProductExistence");
  const curveExistenceProvider = new CachingProviderShim(new HttpProvider(http, "curve/exists", (key) => ({ model: key })), memoryCache, "Genesis.Product.CurveExistence");
  const grupoCurveExistenceProvider = new CachingProviderShim(
    new HttpProvider(http, "curve/group/exists", (key) => ({ model: key })),
    memoryCache,
    "Genesis.Product.GrupoCurveExistence"
  );

  const companiesProvider = new HttpProvider(http, "product/companies");

  const plateTemplatesProvider = new HttpProvider(http, "plate/templates");
  const plateTemplateSectionsProvider = new HttpProvider(http, "plate/templates/sections", (key) => ({
    codModeloProducto: key.codModeloProducto,
    codProductoComercial: key.codProductoComercial,
    key: key.template,
  }));
  const plateSectionsCloneProvider = new HttpProvider(http, "plate/templates/sections/clone", (key) => ({
    codProductoComercial: key.codProductoComercial,
    codeTemplate: key.codeTemplate,
  }));
  const plateSectionsProvider = new HttpProvider(http, "plate/sections", (key) => ({ product: key }));
  const productTestsProvider = new HttpProvider(http, "plate/product-tests", (key) => ({ codModeloProducto: key.codModeloProducto, idEnsayo: key.idEnsayo }));
  const plateProductTestLister = new HttpLister(http, "plate/product-tests/list");
  const plateExistingTemplateLister = new HttpLister(http, "plate/existing-templates/list");
  const plateIconsProvider = new HttpProvider(http, "plate/icons", (key) => key);
  const plateProductTestsProvider = new HttpProvider(http, "plate/product-tests/ccp", (key) => key);
  const plateProductAttributesProvider = new HttpProvider(http, "plate/product/attributes", (key) => ({ id: key }));
  const plateProductAndTestAttributesProvider = new HttpProvider(http, "plate/product-tests/attributes", (key) => key);
  const plateSummaryProvider = new HttpProvider(http, "plate/summary", (key) => ({ id: key }));
  const plateUniqueAttributesProvider = new HttpProvider(http, "plate/attributes/unique");

  const plateTemplateStore = new HttpStore(http, "plate/template", (key) => ({ template: key }));
  const plateTemplatesStore = new HttpStore(http, "plate/templates", (key) => ({ template: key }));

  const certificateStore = new HttpStore(http, "certificate/save", (key) => ({ certificate: key }));

  const dashboardUnassignedProductsLister = new HttpLister(http, "dashboard/unassigned-products/list");

  return {
    localSessionCache,
    localCache,
    memoryCache,
    authorizationStore,
    accountRepository,
    maintenanceStatusProvider,
    seriesLister,
    serieComercialLister,
    serieComercialLoadingsLister,
    serieComercialLoadingsQueueLister,
    serieComercialLoadingStatusLister,
    serieSAPLister,
    serieComercialGenericProductLister,
    serieComercialProductLister,
    productLister,
    curveGroupLister,
    curveLister,
    modeloCurveLister,
    modeloGroupCurveLister,
    curveTestLister,
    curveTestProvider,
    seriesSuggester,
    serieComercialSuggester,
    serieComercialLoadingsSuggester,
    serieComercialInternalSuggester,
    seriesGenericProductsSuggester,
    seriesProductsSuggester,
    serieComercialSAPSuggester,
    productSuggester,
    curveGroupSuggester,
    curveSuggester,
    curveLabTestNumberSuggester,
    curveTestIdSuggester,
    curveSpecificationAccessor,
    curveTestService,
    modeloCurveSpecificationAccessor,
    modeloCurveGroupSpecificationAccessor,
    dependentGenerationService,
    acousticTestLister,
    aggregateTotalService,
    aeroTestLister,
    kitErPLister,
    nonResidentialErPService,
    residentialErPService,
    rvuErPStore,
    rvuPerformanceEvaluator,
    motorLister,
    motorProvider,
    productCurveAttributesProvider,
    productConceptsProvider,
    productTechnicalAttributesProvider,
    productCurveGroupAttributesProvider,
    curveGroupDetailsProvider,
    productSpecificationAccessor,
    productSpecificationStore,
    neededAttributesProvider,
    productExistenceProvider,
    curveExistenceProvider,
    grupoCurveExistenceProvider,
    plateTemplatesProvider,
    plateTemplateSectionsProvider,
    plateSectionsCloneProvider,
    plateSectionsProvider,
    productTestsProvider,
    plateProductTestLister,
    plateExistingTemplateLister,
    productSAPSpecificationAccessor,
    plateIconsProvider,
    plateProductTestsProvider,
    plateTemplateStore,
    plateTemplatesStore,
    plateProductAttributesProvider,
    plateProductAndTestAttributesProvider,
    plateSummaryProvider,
    plateUniqueAttributesProvider,
    dashboardUnassignedProductsLister,
    companiesProvider,
    seriesLoadingProcessor,
    crmBulkProcessor,
    seriesExpressProcessor,
    http,
    certificateStore,
  };
}

export default Startup;
