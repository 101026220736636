import React from "react";
import ProductAttributes, { sectionTypes } from "../../../products/ProductAttributes";
import ProductInputContainer from "../ProductInputContainer";

function SoundParameters(props) {
  const productAttributes = new ProductAttributes({ object: props.product, attributes: props.productAttributes, update: props.update });

  return <ProductInputContainer {...props} inputs={productAttributes.getAttributes(sectionTypes.SOUND)} title="product_information.sound_parameters"></ProductInputContainer>;
}

export default SoundParameters;
