import React from "react";
import { Container, Row, Col, Card, CardBody, Button, CardFooter } from "reactstrap";
import Header from "../../components/Header";
import { FormattedMessage, useIntl } from "react-intl";
import { useService } from "../../context/ServiceContext";
import { fireAlert } from "../../utils/alerts";

function AdminActions({ history }) {
  const { crmBulkProcessor, seriesExpressProcessor } = useService();
  const intl = useIntl();

  async function handleCRMBulkProcess() {
    try {
      await crmBulkProcessor.find();
      showAlert();
    } catch (e) {
      showErrorAlert();
    }
  }

  async function handleSeriesExpressProcess() {
    try {
      await seriesExpressProcessor.find();
      showAlert();
    } catch (e) {
      showErrorAlert();
    }
  }

  function showAlert() {
    fireAlert(
      intl.formatMessage({ id: "manage_loadings.successfully_set_request", defaultMessage: "Successfully set request" }),
      intl.formatMessage({ id: "app.success", defaultMessage: "Success" }),
      "success"
    ).then(() => {});
  }

  function showErrorAlert() {
    fireAlert(
      intl.formatMessage({ id: "manage_loadings.unsuccessful_set_request", defaultMessage: "A problem occurred sending the request" }),
      intl.formatMessage({ id: "app.error", defaultMessage: "Error" }),
      "error"
    ).then(() => {});
  }

  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="admin_actions.title" defaultMessage="Admin Actions" />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="card-title">
                      <FormattedMessage id="admin_actions.crm_import.title" defaultMessage="Bulk CRM Import" />
                    </h3>
                    <p className="card-text">
                      <FormattedMessage id="admin_actions.crm_import.description" defaultMessage="Click the button below if you wish to start the CRM bulk product import." />
                    </p>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button onClick={handleCRMBulkProcess} color="primary">
                  <FormattedMessage id="app.proceed" defaultMessage="Proceed" />
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="card-title">
                      <FormattedMessage id="admin_actions.series_procedure.title" defaultMessage="Series express" />
                    </h3>
                    <p className="card-text">
                      <FormattedMessage
                        id="admin_actions.series_procedure.description"
                        defaultMessage="Click the button below if you wish to execute the series express procedure."
                      />
                    </p>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button onClick={handleSeriesExpressProcess} color="primary">
                  <FormattedMessage id="app.proceed" defaultMessage="Proceed" />
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdminActions;
