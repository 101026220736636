import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Header from "../../components/Header";
import { FormattedMessage, useIntl } from "react-intl";
import SeriesLoadingsQueue from "../../components/SeriesLoadings/SeriesLoadingsQueue";
import SeriesLoadingsResults from "../../components/SeriesLoadings/SeriesLoadingsResults";
import { useService } from "../../context/ServiceContext";
import { fireAlert } from "../../utils/alerts";
import SeriesLoadingStatus from "../../components/SeriesLoadings/SeriesLoadingStatus";

function SeriesLoadings({ history }) {
  const { seriesLoadingProcessor } = useService();
  const intl = useIntl();

  async function handleSeriesLoadingRequest(seriesId) {
    await seriesLoadingProcessor.store({ seriesId: seriesId, processId: 0 });
    showAlert();
  }

  async function handleSeriesLoadingQueueRequest(seriesId, processId) {
    await seriesLoadingProcessor.store({ seriesId: seriesId, processId: processId });
    showAlert();
  }

  function showAlert() {
    fireAlert(
      intl.formatMessage({ id: "manage_loadings.successfully_set_request", defaultMessage: "Successfully set request" }),
      intl.formatMessage({ id: "app.success", defaultMessage: "Success" }),
      "success"
    ).then(() => {});
  }

  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="manage_loadings.title" defaultMessage="Manage loadings" />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col md={5}>
            <SeriesLoadingsResults onRequestClick={handleSeriesLoadingRequest}></SeriesLoadingsResults>
          </Col>
          <Col md={7}>
            <SeriesLoadingsQueue onRequestClick={handleSeriesLoadingQueueRequest}></SeriesLoadingsQueue>
          </Col>
        </Row>
        <Row>
          <Col>
            <SeriesLoadingStatus></SeriesLoadingStatus>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3"></Col>
        </Row>
        <Row>
          <Col className="mt-3"></Col>
        </Row>
      </Container>
    </>
  );
}

export default SeriesLoadings;
