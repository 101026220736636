class BrowserStorageCache {
    constructor(storage) {
        this._storage = storage;
    }

    setString(key, value) {
        if(value !== null) {
            this._storage.setItem(key, value);
        } else {
            this._storage.removeItem(key);
        }
    }

    getString(key) {
        return this._storage.getItem(key);
    }

    setObject(key, value) {
        this.setString(key, JSON.stringify(value));
    }

    getObject(key) {
        var s = this.getString(key);
        return s ? JSON.parse(s) : null;
    }
}

export default BrowserStorageCache;