import React, { useState } from "react";
import { Fragment } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardHeader, Col, CustomInput, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useService } from "../../context/ServiceContext";
import { createEmptyTest, datosPlacaModes } from "../../plates/PlateStructures";
import DependentCurveTestForm from "../DependentCurveTestForm";
import Input from "../Input";
import ListerTableSelectionModal from "../Modals/ListerTableSelectionModal";
import TestSelector from "./TestSelector";
import ProductInfo from "../ProductCreation/ProductInfo";

function TemplateAttributes({ productData, template, updateTemplate, maxTests, mode, updateMode, updateTestAttributes, updateProductTests }) {
  const intl = useIntl();

  const [isTestsModalOpen, setIsTestsModalOpen] = useState(false);

  async function toggleTestsModal() {
    // get test attributes
    if (isTestsModalOpen) {
      let tests = await updateTestAttributes();
      updateTemplate({
        ...template,
        selectedTests: tests,
      });
    }

    setIsTestsModalOpen(!isTestsModalOpen);
  }

  function updateAttribute(attributeName, value) {
    let attribute = template.attributes.find((a) => a.name == attributeName);
    attribute.value = value;

    updateTemplate({
      ...template,
      attributes: template.attributes.map((a) => {
        if (a.name === attributeName) {
          return { ...attribute };
        } else return a;
      }),
    });
  }

  function updateTest(test, attribute, value) {
    updateTemplate({
      ...template,
      selectedTests: template.selectedTests.map((t) => {
        if (t.id === test.id) {
          return { ...test, [attribute]: value };
        } else return t;
      }),
    });
  }

  function setSelectedTests(tests) {
    updateTemplate({
      ...template,
      selectedTests: tests,
    });
  }

  async function removeTest(test) {
    let tests = await updateTestAttributes(
      null,
      template.selectedTests.filter((t) => t.id !== test.id)
    );
    updateTemplate({
      ...template,
      selectedTests: tests,
    });
  }

  function addTest() {
    // if (mode === datosPlacaModes.EMPTY) {
    //   updateTemplate({
    //     ...template,
    //     selectedTests: template.selectedTests.concat(
    //       createEmptyTest(template.selectedTests.length > 0 ? template.selectedTests.reduce((max, n) => (n.id > max.id ? n : max)).id + 1 : 0)
    //     ),
    //   });
    // } else {
    toggleTestsModal();
    // }
  }

  return (
    <div>
      <hr className="my-3"></hr>
      {template.selectedTests.length === 0 ? (
        <div className="bg-secondary text-center rounded-lg p-5">
          <h3>
            <FormattedMessage id="datos_placa.no_tests_selected" defaultMessage="You do not have any tests selected." />
          </h3>
          <button type="button" className="btn btn-success" onClick={toggleTestsModal}>
            <FormattedMessage id="datos_placa.select_ensayos" defaultMessage="Select ensayo's" />
          </button>
        </div>
      ) : (
        <button type="button" className="btn btn-success" onClick={toggleTestsModal}>
          <FormattedMessage id="datos_placa.select_ensayos" defaultMessage="Select ensayo's" />
        </button>
      )}

      <Row>
        <Col>
          <div className="card-deck">
            {template.selectedTests.map((t) => (
              <TemplateTestCard
                key={t.id}
                test={t}
                tests={template.selectedTests}
                removeTest={removeTest}
                updateTest={updateTest}
                //mode={mode}
                // readonly={mode == datosPlacaModes.DEPENDENT || mode == datosPlacaModes.IDENSAYO}
              ></TemplateTestCard>
            ))}
            {mode !== datosPlacaModes.DEPENDENT &&
              mode !== datosPlacaModes.IDENSAYO &&
              mode !== datosPlacaModes.EMPTY &&
              template.selectedTests.length > 0 &&
              template.selectedTests.length < maxTests && <BlankTestCard addTest={addTest}></BlankTestCard>}
          </div>
        </Col>
      </Row>

      <hr className="my-3"></hr>

      <h4>
        <FormattedMessage id="datos_placa.input_data" defaultMessage="Input data" />{" "}
        <ProductInfo formattedMessage={{ id: "datos_placa.input_data_help", defaultMessage: "input_data_help" }}></ProductInfo>
      </h4>
      <FormGroup row style={{ maxWidth: 1500 }}>
        {template.attributes
          .filter((r) => [1, 2, 4, 5, 6, 7].includes(r.sourceType))
          .map((a, i) => (            
            <Fragment key={i}>
              <Label sm="3">{intl.formatMessage({ id: `datos_placa.${a.name}`, defaultMessage: a.name })}</Label>
              <Col sm="3">
                <Input
                  type={a.inputType}
                  value={a.value || a.originalValue || ""}
                  style={a.sourceType == 5 ? { backgroundColor: "#e9ecef" } : {}}
                  onChange={(e) => updateAttribute(a.name, e.target.value)}
                />
              </Col>
            </Fragment>
          ))}
      </FormGroup>

      <Modal isOpen={isTestsModalOpen} toggle={toggleTestsModal} size={"lg"} className={""}>
        <ModalBody className="p-0">
          <TestSelector
            testList={productData.tests}
            selectedTests={template.selectedTests}
            setSelectedTests={setSelectedTests}
            updateProductTests={updateProductTests}
            maxTests={maxTests}
            //  {/*mode={mode}*/}
            //  {/*updateMode={updateMode}*/}
          ></TestSelector>
        </ModalBody>
      </Modal>
    </div>
  );
}

function BlankTestCard({ addTest }) {
  return (
    <Card className="mt-3" style={{ maxWidth: 350, border: "3px dashed #aaa", backgroundColor: "#eee" }}>
      <CardBody className="d-flex align-items-stretch">
        <button type="button" onClick={addTest} className="btn btn-link btn-block">
          <i className="fas fa-plus" style={{ fontSize: 60 }}></i>
        </button>
      </CardBody>
    </Card>
  );
}

function TemplateTestCard({ test, tests, updateTest, readonly, removeTest }) {
  const intl = useIntl();
  const mode = test.mode;

  function updateTestAttribute(test, attribute, propertyName, value) {
    updateTest(
      test,
      "attributes",
      test.attributes.map((a) => {
        if (a.name === attribute.name) {
          return { ...a, [propertyName]: value };
        } else {
          return a;
        }
      })
    );
  }

  return (
    <Card className="mt-3" style={{ maxWidth: 350 }}>
      <CardHeader className="d-flex align-items-center">
        <div className="flex-grow-1">
          {test.description}
          {!test.custom && test.id > 0 && (
            <a href={`/curves-generator/viewer/${test.id}/summary`} target="_blank" className="badge badge-secondary">
              {test.id} <i className="fas fa-link"></i>
            </a>
          )}
        </div>
        {
          /* (mode === datosPlacaModes.DEPENDENT || test.custom) &&  tests.length > 1 && */ <button type="button" className="btn btn-danger" onClick={() => removeTest(test)}>
            <i className="fas fa-trash"></i>
          </button>
        }
      </CardHeader>
      <CardBody>
        {mode === datosPlacaModes.DEPENDENT && <DependentCurveTestForm updateTest={updateTest} test={test} />}
        {mode === datosPlacaModes.IDENSAYO && (
          <div className="mt-3">
            <h4>
              <FormattedMessage id="datos_placa.observations" defaultMessage="Observations" />
            </h4>
            <Input type="text" className="flex-grow-1" value={test.observations} onChange={(e) => updateTest(test, "observations", e.target.value)} />
          </div>
        )}

        {test.attributes?.map((a, i) => (
          <TemplateTestCardItem
            key={i}
            test={test}
            updateTest={updateTestAttribute}
            placeHolder={intl.formatMessage({ id: `datos_placa.${a.name}`, defaultMessage: a.name })}
            attribute={a}
            readonly={readonly}
          ></TemplateTestCardItem>
        ))}
      </CardBody>
    </Card>
  );
}

function TemplateTestCardItem({ test, attribute, updateTest, placeHolder, readonly }) {
  const itemProperty = attribute.name;

  return (
    <FormGroup className="d-flex my-1">
      <div className="form-label-group flex-grow-1 mr-2">
        <Input
          type={attribute.inputType ? attribute.inputType : "number"}
          value={attribute.value}
          onChange={(e) => updateTest(test, attribute, "value", e.target.value)}
          id={`input-${test.id}-${itemProperty}`}
          readOnly={readonly}
          placeholder={placeHolder}
          className={ !test.custom && (attribute.modified || attribute.originalValue != attribute.value) ? "modified" : ""}
        />
        <label htmlFor={`input-${test.id}-${itemProperty}`}>{placeHolder}</label>
      </div>
      <CustomInput
        type="switch"
        id={`custominput-${test.id}-${itemProperty}`}
        checked={attribute.visible != undefined ? attribute.visible : true}
        onChange={(e) => updateTest(test, attribute, "visible", e.target.checked)}
      />
    </FormGroup>
  );
}

export default TemplateAttributes;
