import React from "react";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { replaceBlockedCharacters } from "../../../products/ProductValidation";

function ProductDenominationParametersStep({ next, prev, denominationParameter, setDenominationParameter, customDenominationParameter, setCustomDenominationParameter }) {
  return (
    <ValidationForm onSubmit={() => {}}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.product_denomination" defaultMessage="Product denomination" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage id="product_wizard.distinctive_parameter" defaultMessage="Which is the distinctive parameter that distinguishes each of your products?" />
          </p>
          <div>
            <Button
              className={`btn btn-lg ${denominationParameter === "SIZE" ? "btn-danger" : "btn-outline-danger"} mb-2`}
              type="button"
              onClick={() => {
                setDenominationParameter("SIZE");
                setCustomDenominationParameter("");
              }}
            >
              <FormattedMessage id="product_wizard.size" defaultMessage="SIZE" />
            </Button>
            <br></br>
            <Button
              className={`btn btn-lg ${denominationParameter === "DIAMETER" ? "btn-danger" : "btn-outline-danger"} mb-2`}
              type="button"
              onClick={() => {
                setDenominationParameter("DIAMETER");
                setCustomDenominationParameter("");
              }}
            >
              <FormattedMessage id="product_wizard.diameter" defaultMessage="DIAMETER" />
            </Button>
            <br></br>
            <Button
              className={`btn btn-lg ${denominationParameter === "AIRFLOW" ? "btn-danger" : "btn-outline-danger"} mb-2`}
              type="button"
              onClick={() => {
                setDenominationParameter("AIRFLOW");
                setCustomDenominationParameter("");
              }}
            >
              <FormattedMessage id="product_wizard.airflow" defaultMessage="AIRFLOW" />
            </Button>
          </div>

          <TextInput
            type="text"
            id="customDenominationParameter"
            name="customDenominationParameter"
            value={customDenominationParameter}
            onChange={(e) => {
              setDenominationParameter("");
              setCustomDenominationParameter(replaceBlockedCharacters(e.target.value));
            }}
            placeholder="Example: PREFERRED FOOD"
            className="form-control"
            style={{ maxWidth: 350 }}
          />
        </CardBody>
        <CardFooter>
          <Button className="btn btn-dark float-right" onClick={next}>
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default ProductDenominationParametersStep;
