import React from "react";
import { Route, Switch } from "react-router-dom";
import SeriesLoadings from "./Home";

function SeriesLoadingsIndex() {
  return (
    <Switch>
      <Route
        exact={true}
        path={"/manage-loadings"}
        render={(props) => {
          return <SeriesLoadings {...props} />;
        }}
      />
    </Switch>
  );
}

export default SeriesLoadingsIndex;
