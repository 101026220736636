import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useConfig } from "../../context/ConfigContext";

function CCP() {
  const ccpRoot = useConfig("ccp.root");

  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="ccp.title" defaultMessage="CCP Codification" />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="card-title">
                      <FormattedMessage id="ccp.information" defaultMessage="What is CCP?" />
                    </h3>
                    <p className="card-text">
                      <FormattedMessage id="ccp.description" defaultMessage="CCP is used to link the final codes with the product structures created within Genesis." />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    <a href={ccpRoot} className="btn btn-dark" target="_blank">
                      <FormattedMessage id="app.proceed" defaultMessage="Proceed" />
                    </a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CCP;
