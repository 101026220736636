import Decibel from "./Decibel";
import eventify from "../eventify";

export default class SoundSample {
    constructor({ frequency, soundLevel }) {
        this.frequency = frequency;
        this.soundLevel = new Decibel(soundLevel);
    }

    // get frequency() {
    //     return this._frequency;
    // }

    // get soundLevel() {
    //     return this._soundLevel;
    // }
}