import React, { useState } from "react";
import Autosuggest from "react-autosuggest";

function SuggestiveInput({ id, suggester, inputProps, renderSuggestion, renderInputComponent }) {
  const [suggestions, setSuggestions] = useState([]);

  return (
    <Autosuggest
      suggestions={suggestions}
      inputProps={inputProps}
      renderSuggestion={renderSuggestion}
      onSuggestionsFetchRequested={async ({ value }) => setSuggestions(await suggester.getSuggestions(value))}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={suggester.resolveSuggestionValue}
      renderInputComponent={renderInputComponent}
      onSuggestionSelected={(e, { suggestionValue }) => {
        inputProps.onChange({
          target: {
            id: id,
            value: suggestionValue,
          },
        });
      }}
    />
  );
}

export default SuggestiveInput;
