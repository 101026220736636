import React from "react";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";
import UserDropdown from "../User/UserDropdown";
import { FormattedMessage } from "react-intl";
import LanguageDropdown from "../User/LanguageDropdown";

function AppNavbar(props) {
  const { logout } = useAuth();
  const { user } = useUser();

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <Navbar className="navbar-top navbar blur" expand="md" id="navbar-main">
        <Container fluid>
            <div>
                  <img alt="..." src={require("../../assets/img/brand/logo-genesis-positive.png")} className="img-fluid mr-2" style={{ width: 80 }} />
                <span className="h4 mb-0 text-uppercase d-none d-lg-inline-block">
                    <FormattedMessage id={props.brandTextId} defaultMessage={props.brandText}></FormattedMessage>
                </span>
            </div>              
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <LanguageDropdown></LanguageDropdown>
          <UserDropdown></UserDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default AppNavbar;
