import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import supportedLangauges from "../language/supportedLanguages";
import { useService } from "./ServiceContext";

const LanguageContext = React.createContext();

const defaultLocale = navigator.language;
const lang = supportedLangauges[defaultLocale] || supportedLangauges["es-ES"];

function LanguageProvider({ children }) {
  const storageKey = "Genesis.Language";
  const [locale, setLocale] = useState(lang.locale);
  const [messages, setMessages] = useState(lang.messages);
  const { localCache } = useService();

  useEffect(() => {
    const stored = localCache.getString(storageKey);
    if(stored) {
      changeLanguage(stored);
    }
  }, []);

  function changeLanguage(selectedLocale) {
    const selectedLanguage =
      supportedLangauges[selectedLocale] || supportedLangauges["es-ES"];
    localCache.setString("Genesis.Language", selectedLanguage.locale);
    setLocale(selectedLanguage.locale);
    setMessages(selectedLanguage.messages);
  }

  return (
    <LanguageContext.Provider value={{ locale, changeLanguage }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a LanguageProvider.");
  }
  return context;
}

export { LanguageProvider, useLanguage };
