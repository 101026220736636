import React from "react";
import ProductInputContainer from "../ProductInputContainer";

function MotorParameters({ productAttributes, curve, updateCurve, disabled }) {
  const inputs = {
    potMot: {
      label: "PotMot",
      labelMessageId: "product_information.potMot",
    },
    potMotMag: {
      label: "PotMotMag",
      labelMessageId: "product_information.potMotMag",
    },
    ecoMotor: {
      label: "EcoMotor",
      labelMessageId: "product_information.ecoMotor",
    },
    confMotor: {
      label: "ConfMotor",
      labelMessageId: "product_information.confMotor",
    },
    tipoMotor: {
      label: "TipoMotor",
      labelMessageId: "product_information.tipoMotor",
    },
    varHZ: {
      label: "VarHZ",
      labelMessageId: "product_information.varHZ",
    },
  };

  return (
    <ProductInputContainer
      productAttributes={productAttributes}
      product={curve}
      update={updateCurve}
      inputs={inputs}
      title="product_information.motor_parameters"
      disabled={disabled}
    ></ProductInputContainer>
  );
}

export default MotorParameters;
