export default function trackify(obj, state, changes) {
    obj._state = state;
    obj._changes = changes;

    obj._get = function(property) {
        if(!obj._state) {
            return null;
        }
        
        let value = obj._state[property];
        if(value === undefined) {
            return null;
        } else {
            return value;
        }
    };

    obj._setChangeOnly = function(arg1, arg2) {
        if (typeof arg1 === "string") {
            let property = arg1;
            let value = arg2;

            if(!obj._changes) {
                obj._changes = {};
            }

            obj._changes[property] = value;
        } else if (typeof arg1 === "object") {
            for (let property of Object.keys(arg1)) {
                let value = arg1[property];
                obj._setChangeOnly(property, value);
            }
        }
    };

    obj._setStateOnly = function(arg1, arg2) {
        if (typeof arg1 === "string") {
            let property = arg1;
            let value = arg2;

            if(!obj._state) {
                obj._state = {};
            }

            obj._state[property] = value;
        } else if (typeof arg1 === "object") {
            for (let property of Object.keys(arg1)) {
                let value = arg1[property];
                obj._setStateOnly(property, value);
            }
        }
    };

    obj._set = function(arg1, arg2) {
        if (typeof arg1 === "string") {
            let property = arg1;
            let value = arg2;

            obj._setStateOnly(property, value);
            obj._setChangeOnly(property, value);
        } else if (typeof arg1 === "object") {
            for (let property of Object.keys(arg1)) {
                let value = arg1[property];
                obj._set(property, value);
            }
        }
    };
}