import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Button, Col, CustomInput, FormGroup, Input, Row } from "reactstrap";
import { useService } from "../context/ServiceContext";
import ListerTableSelectionModal from "./Modals/ListerTableSelectionModal";

function DependentAcousticTestForm({ test, updateTest }) {
  const { curveLister, curveTestService } = useService();
  const intl = useIntl();
  const [motorModalOpen, setMotorModalOpen] = useState(false);
  const [curveModalOpen, setCurveModalOpen] = useState(false);
  const toggleMotorModal = () => setMotorModalOpen(!motorModalOpen);
  const toggleCurveModal = () => setCurveModalOpen(!curveModalOpen);
  const dependent = test.reasonForChange;

  const handleCurveSelection = async (selection) => {
    curveTestService.getSubCurveDetails(selection).then((result) => {
      addSubCurve(selection.id, selection.name, dependent.reason == "voltage" ? result?.voltage : result?.frequency);
      toggleCurveModal();
    });
  };

  function addSubCurve(curve, curveName, voltsHz) {
    updateTest(test, "reasonForChange", {
      ...dependent,
      subCurves: [
        ...dependent.subCurves,
        { curve: curve, name: curveName, voltage: dependent.reason == "voltage" ? voltsHz : null, frequency: dependent.reason == "frequency" ? voltsHz : null },
      ],
    });
  }

  function removeSubCurve(index) {
    dependent.subCurves.splice(index, 1);
    updateTest(test, "reasonForChange", {
      ...dependent,
      subCurves: [...dependent.subCurves],
    });
  }

  // function updateSubCurve(index, property, value) {
  //   dependent.subCurves[index][property] = value;
  //   updateTest(test, "reasonForChange", {
  //     ...dependent,
  //     subCurves: [...dependent.subCurves],
  //   });
  // }

  // function updateReason(reason) {
  //   if (dependent.reason === "curve" && reason !== "curve") {
  //     updateTest(test, "reasonForChange", {
  //       ...dependent,
  //       subreasons: dependent.subreasons.includes(reason) ? dependent.subreasons.filter((r) => r !== reason) : [...dependent.subreasons, reason],
  //     });
  //   } else {
  //     updateTest(test, "reasonForChange", { ...dependent, reason: dependent.reason === reason ? null : reason, subreasons: [] });
  //   }
  // }

  function reasonGivenIs(reason) {
    return dependent.reason === reason || dependent.subreasons?.includes(reason);
  }

  function isDisabled(prop, ignoreNull) {
    return (ignoreNull || dependent.reason) && dependent.reason !== "curve" && dependent.reason !== prop;
  }

  return (
    <>
      <h3>Reason for change</h3>
      {/* <Row className="mb-2">
        <Col className="col-auto">
          <CustomInput
            type="switch"
            id={`rfc-toggle-sub-freq-${test.id}`}
            checked={reasonGivenIs("frequency")}
            onChange={(e) => updateReason("frequency")}
            label="Sub. Freq."
            className={reasonGivenIs("frequency") ? "" : "text-muted"}
          />
        </Col>
        <Col>
          <CustomInput
            type="switch"
            id={`rfc-toggle-volts-${test.id}`}
            checked={reasonGivenIs("voltage")}
            onChange={(e) => updateReason("voltage")}
            label="Volts"
            className={reasonGivenIs("frequency") ? "" : "text-muted"}
          />
        </Col>
      </Row> */}

      {dependent.subCurves &&
        dependent.subCurves.map((c, index) => (
          <FormGroup row className="mb-1" index={index}>
            <Col className="d-flex">
              <Button className="w-100" color="secondary" onClick={toggleCurveModal}>
                {c.name}
              </Button>
              {/* <Col>
                <div className="d-flex mr-2 align-items-end">
                  <Input
                    type="number"
                    min="0"
                    className="flex-grow-1"
                    disabled={dependent.reason == null}
                    value={dependent.reason == "voltage" ? c?.voltage : c.frequency}
                    onChange={(e) => updateSubCurve(index, dependent.reason, e.target.value)}
                    id={`rfc-volts-${test.id}`}
                  />
                  <label className="ml-1" htmlFor={`rfc-volts-${test.id}`}>
                    {reasonGivenIs("voltage") ? "V" : reasonGivenIs("frequency") ? "Hz" : ""}
                  </label>
                </div>
              </Col> */}
              <Col className="col-auto p-0 d-flex">
                <a className="fa fa-minus-circle d-flex align-items-center" style={{ fontSize: "1.25rem" }} onClick={() => removeSubCurve(index)}></a>
              </Col>
            </Col>
          </FormGroup>
        ))}
      <hr />
      <FormGroup row className="mb-1">
        <Col className="d-flex">
          <Button className="w-100" color="secondary" onClick={toggleCurveModal}>
            <span>
              <a className={"fa fa-plus-circle align-items-center mr-1 "} style={{ fontSize: "1rem" }}></a>
              {"Add modelo curva"}
            </span>
          </Button>
          {/* <Col>
            <div className="d-flex mr-2 align-items-end">
              <Input
                type="number"
                min="0"
                className="flex-grow-1"
                disabled={curveName == null}
                value={voltsHz}
                onChange={(e) => setVoltsHz(e.target.value)}
                id={`rfc-volts-${test.id}`}
              />
              <label className="ml-1" htmlFor={`rfc-volts-${test.id}`}>
                {reasonGivenIs("voltage") ? "V" : reasonGivenIs("frequency") ? "Hz" : ""}
              </label>
            </div>
          </Col>
          <Col className="col-auto p-0 d-flex">
            <a className={"fa fa-plus-circle d-flex align-items-center " + (curveName != null ? "" : "text-muted")} style={{ fontSize: "1.25rem" }} onClick={curve != null ? addSubCurve : null} ></a>
          </Col> */}
        </Col>
      </FormGroup>
      <ListerTableSelectionModal
        title={intl.formatMessage({ id: "app.select_a_curve", defaultMessage: "Select a ModeloCurva" })}
        columns={[
          { Header: intl.formatMessage({ id: "app.curve", defaultMessage: "Curve" }), accessor: "name" },
          { Header: intl.formatMessage({ id: "app.curve_group", defaultMessage: "Curve group" }), accessor: "group" },
          { Header: intl.formatMessage({ id: "app.product", defaultMessage: "Product" }), accessor: "product" },
          { Header: intl.formatMessage({ id: "app.curve_series", defaultMessage: "Curve series" }), accessor: "curveSeries" },
        ]}
        isOpen={curveModalOpen}
        toggle={toggleCurveModal}
        lister={curveLister}
        onSelect={handleCurveSelection}
      />
    </>
  );
}

export default DependentAcousticTestForm;
