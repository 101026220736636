import React from "react";
import { Route, Switch } from "react-router-dom";
import CurvesGenerator from "./Home";
import CurveViewer from "./CurveViewer";
import CurveSearch from "./CurveSearch";
import ErPSearch from "./ErPSearch";
import ErPKitViewer from "./ErPKitViewer";
import BulkDependents from "./BulkDependents";
import Acoustic from "./Acoustic";

function CurvesGeneratorIndex() {
  return (
    <Switch>
      <Route
        exact={true}
        path="/curves-generator"
        render={(props) => {
          return <CurvesGenerator {...props} />;
        }}
      />
      <Route
        exact={true}
        path="/curves-generator/new"
        render={(props) => {
          return <CurveSearch {...props} />;
        }}
      />
      <Route
        exact={false}
        path="/curves-generator/viewer/:id/:specId?/:labTestId?"
        render={(props) => {
          return <CurveViewer {...props} />;
        }}
      />
      <Route
        exact={true}
        path="/curves-generator/erp"
        render={(props) => {
          return <ErPSearch {...props} />;
        }}
      />
      <Route
        exact={true}
        path="/curves-generator/erp/viewer/:type/:kitErP"
        render={(props) => {
          props.match.params.kitErP = decodeURIComponent(props.match.params.kitErP);
          return <ErPKitViewer {...props}/>;
        }}
      />
      <Route
        exact={true}
        path="/curves-generator/dependents/bulk"
        render={(props) => <BulkDependents {...props} />}
      />
      <Route
        exact={true}
        path="/curves-generator/acoustic"
        render={props => <Acoustic {...props} />}
      />
    </Switch>
  );
}

export default CurvesGeneratorIndex;
