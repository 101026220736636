import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";

import Login from "../views/Login";
import routes from "../routes.js";
import { ErrorBoundary } from "react-error-boundary";
import LayoutErrorFallback from "./LayoutErrorFallback";

function AuthLayout() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.area === "auth") {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  return (
    <div className="main-content full-background">
      <div className="header py-7 py-lg-8">
        <Container>
          <div className="header-body text-center mb-7">
            <img alt="..." src={require("../assets/img/brand/logo-genesis-positive.png")} className="img-fluid mb-3" style={{ width: 251 }} />
          </div>
        </Container>
      </div>
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <ErrorBoundary FallbackComponent={LayoutErrorFallback}>
            <Switch>
              {getRoutes(routes)}
              <Route path="*" component={Login} />
            </Switch>
          </ErrorBoundary>
        </Row>
      </Container>
    </div>
  );
}

export default AuthLayout;
