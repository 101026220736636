import eventify from "../eventify";
import trackify from "../trackify";

export class ProductSettings {
  constructor({ state, changes, defaultSection }) {
    trackify(this, state, changes);
    eventify(this);

    this._defaultSection = defaultSection;

    if (this.newSection === null) {
      this.newSection = defaultSection;
    }
  }

  get state() {
    return { ...this._state };
  }

  get changes() {
    return { ...this._changes };
  }

  get category() {
    return this._get("category");
  }

  set category(value) {
    this._set("category", value);
    this.fire("change");
  }

  get efficiencyType() {
    return this._get("efficiencyType");
  }

  set efficiencyType(value) {
    this._set("efficiencyType", value);
    this.fire("change");
  }

  get isVsdIncluded() {
    return this._get("isVsdIncluded");
  }

  get installationType() {
    return this._get("installationType");
  }

  set installationType(value) {
    this._set("installationType", value);
    this.fire("change");
  }

  get vsdType() {
    return this._get("vsdType");
  }

  set vsdType(value) {
    this._set("vsdType", value);
    this._set("isVsdIncluded", value.toLowerCase() !== "no");
    this.fire("change");
  }

  get isSmokeExtraction() {
    return this._get("isSmokeExtraction");
  }

  set isSmokeExtraction(value) {
    this._set("isSmokeExtraction", value);
    this.fire("change");
  }

  get isReversible() {
    return this._get("isReversible");
  }

  set isReversible(value) {
    this._set("isReversible", value);
    this.fire("change");
  }

  get outletDiameter() {
    return this._get("outletDiameter");
  }

  set outletDiameter(value) {
    this._set("outletDiameter", value);

    // if(this.installationType != "C" || this.efficiencyType != "Total")
    //{
    if (!value) {
      this._set("newSection", this._defaultSection);
    } else {
      this._set("newSection", (Math.PI * Math.pow(value / 1000, 2)) / 4);
    }
    //}

    this.fire("change");
  }

  get inletDiameter() {
    return this._get("inletDiameter");
  }

  set inletDiameter(value) {
    this._set("inletDiameter", value);
    if (this.installationType === "C" && this.efficiencyType === "Total") {
      if (!value) {
        this._set("newInletArea", 0);
      } else {
        this._set("newInletArea", (Math.PI * Math.pow(value / 1000, 2)) / 4);
      }
    }

    this.fire("change");
  }

  get newSection() {
    return this._get("newSection");
  }

  set newInletArea(value) {
    return this._set("newInletArea", value);
  }
  get newInletArea() {
    return this._get("newInletArea");
  }

//   set includeSecurityGuard(value) {
//     return this._set("includeSecurityGuard", value);
//   }
//   get includeSecurityGuard() {
//     return this._get("includeSecurityGuard");
//   }

  set guardFactor(value) {
    return this._set("guardFactor", value);
  }
  get guardFactor() {
    return this._get("guardFactor");
  }
  set thrust(value) {
    return this._set("thrust", value);
  }
  get thrust() {
    return this._get("thrust");
  }
  set electricPower(value) {
    return this._set("electricPower", value);
  }
  get electricPower() {
    return this._get("electricPower");
  }
  set outletArea(value) {
    return this._set("outletArea", value);
  }
  get outletArea() {
    return this._get("outletArea");
  }
}
