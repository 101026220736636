export default function Brent(g, left, right, tolerance, target) {
    if (tolerance <= 0) {
      throw new Error(`Tolerance must be positive. Got ${tolerance}.`);
    }
    const maxIterations = 50;
    let errorEstimate = Number.MAX_VALUE;
  
    // Standardize the problem.  To solve g(x) = target,
    // solve f(x) = 0 where f(x) = g(x) - target.
    const f = x => g(x) - target;
    let c,
      d,
      e = 0,
      fa,
      fb,
      fc,
      tol,
      m = 0,
      p,
      q,
      r;
  
    // Implementation and notation based on Chapter 4 in
    // "Algorithms for Minimization without Derivatives"
    // by Richard Brent.
    let s;
  
    // set up aliases to match Brent's notation
    let a = left;
    let b = right;
    let t = tolerance;
    let iterationsUsed = 0;
    fa = f(a);
    fb = f(b);
    if (fa * fb > 0.0) {
      return;
      //throw new Error(`Invalid starting bracket. Function must be above target on one end and below target on the other end. Target: ${target}. f(left) = ${fa}. f(right) = ${fb}`);
    }
    let internal = true;
    c = a;
    fc = fa;
    d = e = b - a;
    while (true) {
      if (internal) {
        c = a;
        fc = fa;
        d = e = b - a;
      }
      if (Math.abs(fc) < Math.abs(fb)) {
        a = b;
        b = c;
        c = a;
        fa = fb;
        fb = fc;
        fc = fa;
      }
      iterationsUsed += 1;
      let machine_epsilon = Math.pow(2.0, -53);
      tol = 2.0 * machine_epsilon * Math.abs(b) + t;
      errorEstimate = m = 0.5 * (c - b);
      if (Math.abs(m) > tol && fb != 0.0) {
        // exact comparison with 0 is OK here
        // See if bisection is forced
        if (Math.abs(e) < tol || Math.abs(fa) <= Math.abs(fb)) {
          d = e = m;
        } else {
          s = fb / fa;
          if (a == c) {
            // linear interpolation
            p = 2.0 * m * s;
            q = 1.0 - s;
          } else {
            // Inverse quadratic interpolation
            q = fa / fc;
            r = fb / fc;
            p = s * (2.0 * m * q * (q - r) - (b - a) * (r - 1.0));
            q = (q - 1.0) * (r - 1.0) * (s - 1.0);
          }
          if (p > 0.0) {
            q = -q;
          } else {
            p = -p;
          }
          s = e;
          e = d;
          if (2.0 * p < 3.0 * m * q - Math.abs(tol * q) && p < Math.abs(0.5 * s * q)) {
            d = p / q;
          } else {
            d = e = m;
          }
        }
        a = b;
        fa = fb;
        if (Math.abs(d) > tol) {
          b += d;
        } else if (m > 0.0) {
          b += tol;
        } else {
          b -= tol;
        }
        if (iterationsUsed == maxIterations) {
          return b;
        }
        fb = f(b);
        internal = fb > 0.0 && fc > 0.0 || fb <= 0.0 && fc <= 0.0;
      } else {
        return b;
      }
    }
  }