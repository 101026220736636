import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardDeck,
  CardFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";

function Series() {
  return (
    <>
      <Header>
        <h1>
          <FormattedMessage
            id="products.new_series.title"
            defaultMessage="Create a new series"
          />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
}

export default Series;
