import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export function ExportToExcel({ excelData, fileName }) {
  const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer]);
    FileSaver.saveAs(data, fileName + fileExtension);
}
