import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, UncontrolledDropdown } from "reactstrap";
import { useService } from "../../context/ServiceContext";
import { createEmptyTest, datosPlacaModes, parseStructureValues } from "../../plates/PlateStructures";
import { fireAlert, fireConfirm } from "../../utils/alerts";
import ErrorAlertContent from "../ErrorAlertContent";
import ListerTableSelectionModal from "../Modals/ListerTableSelectionModal";
import SubmitButton from "../SubmitButton";
import Spinner from "../Spinner";
import TemplateSearch from "./TemplateSearch";
import { useUser } from "../../context/UserContext";
import roles from "../../identity/roles";

function Templates({ codModeloProducto, codProductoComercial, codModeloProductoVersion, editMode, history }) {
  const intl = useIntl();

  const {
    productTestsProvider,
    plateTemplatesProvider,
    plateSectionsProvider,
    productSAPSpecificationAccessor,
    plateExistingTemplateLister,
    plateSectionsCloneProvider,
    plateTemplateSectionsProvider,
    plateTemplatesStore,
    companiesProvider,
  } = useService();
  const { user } = useUser();

  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [productTests, setProductTests] = useState({
    code: codProductoComercial || "",
    codModeloProducto: codModeloProducto || 0,
    codModeloProductoVersion: codModeloProductoVersion || 0,
    description: "",
    productReference: null,
    temperatureUnit: "°C",
    manufactureWeek: null,
    manufactureYear: null,
    tests: [],
  });
  const [mode, setMode] = useState(datosPlacaModes.PRODUCT);
  const [companies, setCompanies] = useState([]);
  const [plateSections, setPlateSections] = useState();
  const [templates, setTemplates] = useState([newTemplate(0)]);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
  const [cloneTemplateModalOpen, setCloneTemplateModalOpen] = useState(false);
  const [cloneModeAdd, setCloneModeAdd] = useState(false);
  const [productSAP, setProductSAP] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const isReadOnly = user.role !== roles.SystemAdministrator && user.role !== roles.TechnicalOffice;

  let clonedTemplateData;
  const toggleCloneTemplateModal = (add) => {
    setCloneModeAdd(add);
    setCloneTemplateModalOpen(!cloneTemplateModalOpen);
  };

  useEffect(() => {
    async function getData() {
      try {
        const availableTemplatesResult = await plateTemplatesProvider.get();
        //const sapResult = mode !== datosPlacaModes.PRODUCT ? await productSAPSpecificationAccessor.find(codProductoComercial) : undefined;
        const sapResult = codProductoComercial ? await productSAPSpecificationAccessor.find(codProductoComercial) : undefined;

        const productTestsResult =
          // mode == datosPlacaModes.PRODUCT && codModeloProducto ? await productTestsProvider.get({ codModeloProducto: codModeloProducto, idEnsayo: null }) : undefined;
          codModeloProducto ? await productTestsProvider.get({ codModeloProducto: codModeloProducto, idEnsayo: null }) : undefined;

        const companies = await companiesProvider.get();
        setCompanies(companies);

        // Get template report values
        const platesResult = codProductoComercial && editMode ? await plateSectionsProvider.get(codProductoComercial) : undefined;

        setAvailableTemplates(availableTemplatesResult);
        if (productTestsResult) {
          setProductTests({
            ...productTestsResult,
            tests: productTestsResult.tests.map((t) => {
              return { ...t, testType: datosPlacaModes.PRODUCT };
            }),
          });
        }
        if (sapResult) setProductSAP(sapResult);

        if (platesResult) setPlateSections(platesResult);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    if (!productSAP) return;
    setProductTests({ ...productTests, description: productSAP.productCodeDescription });
  }, [productSAP]);

  useEffect(() => {
    let templates = mergeExistingData(true);
    if (templates.length > 0) setTemplates(templates);
  }, [plateSections, availableTemplates]);

  function mergeExistingData(createNewTemplates) {
    let updatedTemplates = [];
    if (plateSections && productTests && availableTemplates) {
      if (createNewTemplates) {
        const distinctTemplateIds = [...new Set(plateSections.map((s) => s.templateId))];
        for (let i = 0; i < distinctTemplateIds.length; i++) {
          const templateId = distinctTemplateIds[i];
          const plateSection = plateSections.filter((s) => s.templateId === templateId);
          let template = createTemplateFromTemplateDescription(templateId, i, plateSection);
          if (template) {
            plateSection.forEach((structure) => parseStructureValues(structure, template, productTests, true, mode === datosPlacaModes.EMPTY && editMode));
            updatedTemplates.push(template);
          }
        }
      } else {
        for (let i = 0; i < templates.length; i++) {
          let template = templates[i];
          updatedTemplates.push(template);
        }
      }
    }
    return updatedTemplates;
  }

  function updateMode(newMode) {
    setMode(newMode);
  }

  function updateTemplatesWithExistingData() {
    let updatedTemplates = mergeExistingData();
    if (updatedTemplates.length > 0) setTemplates(updatedTemplates);
  }

  function updateProductTests(tests) {
    setProductTests({ ...productTests, tests: [...productTests.tests, ...tests] });
  }

  function createTemplateFromTemplateDescription(templateDescription, templateId) {
    const availableTemplate = availableTemplates.find((at) => at.id === templateDescription);
    if (!availableTemplate) return;
    let template = newTemplate(templateId, availableTemplate);
    return template;
  }

  function newTemplate(id, selectedTemplate) {
    return {
      id: id,
      name: selectedTemplate?.type.name || "",
      active: true,
      description: "",
      templateCode: selectedTemplate?.codeTemplate || "", // CodeTemplate
      templateDescription: selectedTemplate?.id || "", // idTemplate
      templateNumber: selectedTemplate?.type.id || "", // type
      templateSize: selectedTemplate?.size.id || "", // idSize
      selectedTests: mode === datosPlacaModes.EMPTY && !editMode ? [createEmptyTest(0)] : [],
      icons: [],
      attributes: [],
      changeCode: null,
    };
  }

  function addTemplate() {
    const template = newTemplate(templates.length > 0 ? templates.reduce((max, n) => (n.id > max.id ? n : max)).id + 1 : 0);
    setTemplates(templates.concat({ ...template }));
    setSelectedTemplateIndex(template.id);
  }

  function duplicateTemplate(template) {
    let duplicatedTemplate = { ...template, id: templates.length > 0 ? templates.reduce((max, n) => (n.id > max.id ? n : max)).id + 1 : 0 };
    setTemplates(templates.concat({ ...duplicatedTemplate }));
  }

  function removeTemplate(template) {
    if (templates.length === 1) return;
    let templateIndex = templates.indexOf(template);
    const newTemplates = templates.filter((t) => t.id !== template.id);
    setTemplates(newTemplates);
    setSelectedTemplateIndex(newTemplates[templateIndex > 0 ? templateIndex - 1 : templateIndex].id);
  }

  function updateTemplate(template) {
    setTemplates(
      templates.map((t) => {
        if (t.id === template.id) {
          return template;
        } else
          return {
            ...t,
            attributes: t.attributes.map((a) => {
              // Copy attribute value from updated template to all other templates so that attributes stay consistent
              let updatedTemplatesAttribute = template.attributes.find((ua) => ua.name === a.name);
              return {
                ...a,
                value: updatedTemplatesAttribute ? updatedTemplatesAttribute.value : a.value,
              };
            }),
            selectedTests: [...t.selectedTests],
          };
      })
    );
  }

  const templateTypes = (id) => {
    return availableTemplates.find((t) => t.type.id == id)?.type.name;
  };

  const getDescription = () => {
    return `${productTests?.code} - ${
      productTests?.description || intl.formatMessage({ id: "datos_placa.modelo_producto_not_found", defaultMessage: "ModeloProducto not found in BdP" })
    }`;
  };

  function handleCloneTemplateSelection(choice) {
    fireConfirm(
      intl.formatMessage({ id: "datos_placa.clone_with_values", defaultMessage: "Would you like clone the product attributes?" }),
      intl.formatMessage({ id: "datos_placa.clone_with_values_title", defaultMessage: "Clone template" }),
      "question",
      intl.formatMessage({ id: "app.yes", defaultMessage: "Yes" }),
      intl.formatMessage({ id: "app.no", defaultMessage: "No" }),
      () => {
        cloneTemplateSelection(choice, true);
      },
      () => {
        cloneTemplateSelection(choice, false);
      }
    );
  }

  async function cloneTemplateSelection(choice, withValues) {
    setIsLoading(true);
    toggleCloneTemplateModal();
    clonedTemplateData = await plateSectionsCloneProvider.get({ codProductoComercial: choice.productCode, codeTemplate: choice.codeTemplate });
    if (clonedTemplateData.length > 0) {
      const templateId = clonedTemplateData[0].templateId;

      // Get template reports data
      let productData = await plateTemplateSectionsProvider.get({
        codModeloProducto: codModeloProducto,
        codModeloProductoVersion: codModeloProductoVersion,
        codProductoComercial: codProductoComercial,
        template: templateId,
      });

      const availableTemplate = availableTemplates.find((at) => at.id === templateId);

      let clonedTemplate = {
        ...(cloneModeAdd
          ? newTemplate(templates.length > 0 ? templates.reduce((max, n) => (n.id > max.id ? n : max)).id + 1 : 0)
          : templates.find((t) => t.id === selectedTemplateIndex)),
        name: availableTemplate.type.name,
        active: true,
        templateCode: availableTemplate.codeTemplate, // CodeTemplate
        templateDescription: availableTemplate.id, // idTemplate
        templateNumber: availableTemplate.type.id, // type
        templateSize: availableTemplate.size.id, // idSize
      };

      if (withValues) {
        clonedTemplate.clonedFrom = choice.productCode;
      }

      productData.forEach((structure) => parseStructureValues(structure, clonedTemplate, productTests, true));
      if (withValues) clonedTemplateData.forEach((structure) => parseStructureValues(structure, clonedTemplate, productTests, true));

      if (cloneModeAdd) {
        setTemplates(templates.concat(clonedTemplate));
        setSelectedTemplateIndex(clonedTemplate.id);
      } else {
        updateTemplate(clonedTemplate);
      }
    }
    setIsLoading(false);
  }

  const saveDisabled = () => {
    return templates.some((t) => !t.templateDescription || t.selectedTests.length === 0);
  };

  const checkForDuplicateTemplateTypes = () => {
    var unique = templates.filter(
      (
        (set) => (f) =>
          !set.has(f.templateNumber) && set.add(f.templateNumber)
      )(new Set())
    );
    return unique.length !== templates.length;
  };

  async function saveTemplates() {
    try {
      setIsSaving(true);
      setIsLoading(true);

      if (checkForDuplicateTemplateTypes()) {
        fireAlert(
          intl.formatMessage({ id: "datos_placa.check_duplicate_template_types", defaultMessage: "You can not have duplicate template types." }),
          intl.formatMessage({ id: "error.duplicate_attribute", defaultMessage: "Duplicate" }),
          "error"
        );
        return;
      }

      let templatesToSave = [];
      for (let t = 0; t < templates.length; t++) {
        templatesToSave.push(saveTemplate(templates[t]));
      }

      await plateTemplatesStore.store(templatesToSave);

      fireAlert(
        intl.formatMessage({ id: "datos_placa.successfully_saved", defaultMessage: "Successfully saved the template" }),
        intl.formatMessage({ id: "app.success", defaultMessage: "Success" }),
        "success"
      ).then(() => {
        history.push(`/datos-placa/edit/${codProductoComercial}${codModeloProducto ? "/" + codModeloProducto + "/" + codModeloProductoVersion : ""}`);
      });
    } catch (error) {
      fireAlert(
        <ErrorAlertContent message={intl.formatMessage(error.localization)} traceId={error.traceId} />,
        intl.formatMessage({ id: "app.error", defaultMessage: "Error" }),
        "error"
      );
    } finally {
      setIsSaving(false);
      setIsLoading(false);
    }
  }

  function saveTemplate(templateToBeSaved) {
    let template = { ...templateToBeSaved };

    template.codModeloProducto = {
      value: codModeloProducto || 0,
      version: codModeloProductoVersion || 0,
    };
    template.codProductoComercial = codProductoComercial;
    template.description = productTests.description || template.attributes.find((a) => a.name.toLowerCase() == "descripcionplaca")?.value;
    template.productReference = productTests.productReference || "00000000";
    template.manufactureWeek = productTests.manufactureWeek;
    template.manufactureYear = productTests.manufactureYear;
    //template.mode = mode;
    template.createMode = editMode ? "edit" : "new";
    if (template.active == undefined) template.active = true;

    function checkAttribute(attribute) {
      if (attribute.modified) return;

      if (attribute.value && attribute.value !== attribute.originalValue) {
        attribute.modified = true;
      } else {
        attribute.modified = false;
      }
      if (!attribute.value) attribute.value = attribute.originalValue;

      if (attribute.visible == undefined) attribute.visible = true;
    }

    template.attributes.forEach((attribute) => {
      checkAttribute(attribute);
    });

    template.selectedTests.forEach((test) => {
      test.attributes.forEach((attribute) => {
        checkAttribute(attribute);
      });
    });

    for (let t = 0; t < template.selectedTests.length; t++) {
      const selectedTest = template.selectedTests[t];

      if (selectedTest.mode === datosPlacaModes.EMPTY) continue;

      selectedTest.attributes.forEach((attribute) => {
        checkAttribute(attribute);
      });
    }

    for (let d = 0; d < template.selectedTests.length; d++) {
      const selectedTest = template.selectedTests[d];
      if (selectedTest.mode !== datosPlacaModes.DEPENDENT) continue;

      const dependent = template.selectedTests[d].reasonForChange;
      const dependentKeys = Object.keys(dependent);
      const reason = dependent.reason;
      const subreasons = dependent.subreasons ?? [];
      dependentKeys.forEach((key) => {
        if (key !== reason && !subreasons.includes(key)) {
          dependent[key] = null;
        }
      });
    }

    return template;
  }

  const selectedTemplate = () => {
    return templates[selectedTemplateIndex] || templates[0];
  };

  return (
    <>
      <Row
        style={{
          position: "sticky",
          top: 85,
          zIndex: 10,
        }}
      >
        <Col>
          <Card className="mb-3 blur">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <span className="mr-3">
                    <b>
                      <FormattedMessage id="datos_placa.selected_product" defaultMessage="Selected product" />
                    </b>
                  </span>
                  <span>{getDescription()}</span>
                </div>
                {!isReadOnly && (
                  <div>
                    <SubmitButton className="btn btn-success" disabled={saveDisabled()} onClick={saveTemplates} submitting={isSaving ? 1 : 0} submitted={false}></SubmitButton>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="mb-3">
            <CardBody className="px-0 pt-2">
              <Nav tabs className="pb-2" style={{ overflow: "initial" }}>
                {templates.map((t, i) => (
                  <NavItem key={t.id} onClick={() => setSelectedTemplateIndex(t.id)}>
                    <NavLink active={templates.find((t) => t.id === selectedTemplateIndex)?.id === t.id} href="#">
                      {templateTypes(t.templateNumber) || <FormattedMessage id="datos_placa.new_plate" defaultMessage="New plate" />}
                    </NavLink>
                  </NavItem>
                ))}
                <li>
                  <UncontrolledDropdown group>
                    <Button color="primary" type="button" onClick={addTemplate} size="sm">
                      <i className="fas fa-plus"></i> <FormattedMessage id="app.add" defaultMessage="Add" />
                    </Button>
                    <DropdownToggle caret color="primary" size="sm" />
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          toggleCloneTemplateModal(true);
                        }}
                      >
                        <i className="fas fa-clone"></i>{" "}
                        <FormattedMessage id="datos_placa.clone_from_another_product_template" defaultMessage="Clone from another product template" />
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              </Nav>

              {isLoading ? (
                <>
                  <Spinner></Spinner>
                </>
              ) : (
                availableTemplates?.length > 0 && (
                  <div>
                    <TemplateSearch
                      availableTemplates={availableTemplates}
                      companies={companies}
                      templateProductData={productTests}
                      template={selectedTemplate()}
                      updateTemplate={updateTemplate}
                      templates={templates}
                      removeTemplate={removeTemplate}
                      duplicateTemplate={duplicateTemplate}
                      mode={mode}
                      editMode={editMode}
                      updateMode={updateMode}
                      updateProductTests={updateProductTests}
                      updateTemplatesWithExistingData={updateTemplatesWithExistingData}
                      codProductoComercial={codProductoComercial}
                      toggleCloneTemplateModal={toggleCloneTemplateModal}
                    ></TemplateSearch>
                  </div>
                )
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ListerTableSelectionModal
        title={intl.formatMessage({ id: "datos_placa.select_an_existing_template", defaultMessage: "Select an existing template" })}
        columns={[
          { Header: intl.formatMessage({ id: "datos_placa.productCode", defaultMessage: "Product Code" }), accessor: "productCode" },
          { Header: intl.formatMessage({ id: "datos_placa.productName", defaultMessage: "Product Name" }), accessor: "productName" },
          { Header: intl.formatMessage({ id: "datos_placa.codeTemplate", defaultMessage: "CodeTemplate" }), accessor: "codeTemplate" },
          { Header: intl.formatMessage({ id: "datos_placa.templateDefinition", defaultMessage: "Template Definition" }), accessor: "templateDefinition" },
          { Header: intl.formatMessage({ id: "datos_placa.templateType", defaultMessage: "Template Type" }), accessor: "templateType" },
          { Header: intl.formatMessage({ id: "datos_placa.width", defaultMessage: "Width" }), accessor: "width" },
          { Header: intl.formatMessage({ id: "datos_placa.height", defaultMessage: "Height" }), accessor: "height" },
          { Header: intl.formatMessage({ id: "datos_placa.idCompanyManufacturer", defaultMessage: "idCompany Manufacturer" }), accessor: "idCompanyManufacturer" },
        ]}
        isOpen={cloneTemplateModalOpen}
        toggle={toggleCloneTemplateModal}
        lister={plateExistingTemplateLister}
        onSelect={handleCloneTemplateSelection}
      />
    </>
  );
}

export default Templates;
