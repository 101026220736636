import React from "react";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";

export default function SubmitButton(props) {
  const { submitting, submitted, type } = props;

  function renderIcon({ submitting, submitted }) {
    if (submitting) {
      return <i className="fas fa-spinner fa-pulse"></i>;
    } else if (submitted) {
      return <i className="fas fa-check"></i>;
    } else {
      return <i className="fas fa-save"></i>;
    }
  }

  function renderText({ submitting, submitted }) {
    if (submitting) {
      return <FormattedMessage id="app.saving" defaultMessage="Saving..." />;
    } else if (submitted) {
      return <FormattedMessage id="app.saved" defaultMessage="Saved" />;
    } else {
      return <FormattedMessage id="app.save" defaultMessage="Save" />;
    }
  }

  function defaultChild(state) {
    return (
      <>
        {renderIcon(state)} {renderText(state)}
      </>
    );
  }

  return (
    <Button type={type ?? "button"} {...{ ...props, disabled: submitted || props.disabled ? true : false, submitted: null }}>
      {props.children ? props.children({ submitting, submitted }) : defaultChild({ submitting, submitted })}
    </Button>
  );
}
