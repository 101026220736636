import React from "react";
import { useState } from "react";
import { DateRange } from 'react-date-range';
import { FormattedDate } from "react-intl";
import { Button, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";

const DateRangeColumnFilter = ({ column: { filterValue, setFilter } }) => {
  const [dropdownOpen, setDropdownOpen] = useState();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const defaultFilterValue = () => {
    return {
      startDate: null,
      endDate: null,
      key: 'selection'
    };
  };

  filterValue = filterValue ?? defaultFilterValue();

  return <Dropdown isOpen={dropdownOpen} toggle={toggle}>
    <DropdownToggle className="btn-sm">
      <i className="fas fa-calendar-week"></i>
      {" "}
      {filterValue.startDate ? <FormattedDate value={filterValue.startDate} ptions={{ format: "short" }} /> : "From"}
      {" - "}
      {filterValue.endDate ? <FormattedDate value={filterValue.endDate} options={{ format: "short" }} /> : "To"}
      {(filterValue.startDate || filterValue.endDate) && <i className="fas fa-times-circle text-danger" onClick={() => setFilter(defaultFilterValue())}></i>}
    </DropdownToggle>
    <DropdownMenu>
      <DateRange editableDateInputs={true} moveRangeOnFirstSelection={false} ranges={[filterValue]} onChange={item => setFilter(item.selection)} />
    </DropdownMenu>
  </Dropdown>;
};

export default DateRangeColumnFilter;
