import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";

function Tooltip({ text, className, children }) {
  useEffect(() => {
    ReactTooltip.rebuild();

    return () => ReactTooltip.hide();
  });

  return <div className={`d-inline ${className ?? ""}`} data-for="generic-tooltip" data-tip={text}>
    {children}
  </div>;
}

export default Tooltip;