import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Button,
} from "reactstrap";

import { FormattedMessage } from 'react-intl'
import { useEffect } from "react";
import { useService } from "../context/ServiceContext";
import LoadedContent from "../components/LoadedContent";

function Maintenance(props) {
  const [status, setStatus] = useState(null);
  const { maintenanceStatusProvider } = useService();

  async function getStatus() {
    setStatus(null);
    try {
      let data = await maintenanceStatusProvider.get();
      if(data.isOffline) {
        setStatus(data.status);
      } else {
        props.history.push("/");
      }
    } catch(e) {
      setStatus(status);
    }
  }
  
  useEffect(() => {
    getStatus();
  }, []);

  async function handleRetry() {
    await getStatus();
  }

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          <LoadedContent loading={status === null}>
            <div className="text-center text-dark mb-4">
              <div><i className="fas fa-tools fa-3x"></i></div>
              <h1><FormattedMessage id="maintenance.title" defaultMessage="Application Offline" /></h1>
            </div>

            <p style={{whiteSpace: "pre-wrap"}}>{status}</p>

            <div className="text-center">
              <Button className="mx-auto" color="success" onClick={handleRetry}><i className="fas fa-redo-alt"></i> <FormattedMessage id="app.retry" defaultMessage="Retry" /></Button>
            </div>
          </LoadedContent>
        </CardBody>
      </Card>
    </Col>
  );
}

export default Maintenance;
