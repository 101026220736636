import React from "react";
import d2lIntl from "d2l-intl";
import { useConfig } from "../context/ConfigContext";
import { useCulture } from "../context/CultureContext";

function FormattedDate({ value, options }) {
    const defaultOptions = useConfig("genesis.defaults.formatting.dates");
    const { culture } = useCulture();
    const formatter = new d2lIntl.DateTimeFormat(culture, { ...defaultOptions, ...(options || {}) });
    
    return <>{formatter.format(value)}</>;
}

export default FormattedDate;