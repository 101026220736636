import defaults from "./config.json";
import merge from "lodash.merge";

const environment = process.env.REACT_APP_ENV;
let overrides;
try {
    overrides = require(`./config.${environment.toLowerCase()}.json`);
} catch {
    overrides = {};
}

const config = merge(defaults, overrides);

export default config;