import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import ListerTable from "../Tables/ListerTable";

function ListerTableSelectionModal(props) {
    const { title, filterSwitch, columns, isOpen, toggle, onSelect, onTableLoad, renderFooter } = props;   
    function handleSelection(e, choice) {
        e.preventDefault();
        if (onSelect) {
            onSelect(choice);
        }
    }
        

    return (
        <Modal isOpen={isOpen} toggle={toggle} size={"xl"} className={""} backdrop={"static"}>
            <ModalHeader toggle={toggle}>{title} {filterSwitch ? filterSwitch() : <></>}</ModalHeader>
            <ModalBody style={{ height: "80vh" }}>    
                <ListerTable
                    {...props}
                    className={"align-items-center table-flush"}
                    columns={columns.concat({
                        id: "select",
                        Cell: ({ cell }) => (
                            <Button type="button" className="btn btn-danger" onClick={(e) => handleSelection(e, cell.row.original)}>
                                <FormattedMessage id="app.select" defaultMessage="Select" />
                            </Button>
                        ),
                    })}
                    pageSize={6}
                    onLoad={() => onTableLoad && onTableLoad()}
                />
            </ModalBody>

            {renderFooter ? <ModalFooter>{renderFooter()}</ModalFooter> : <></>}
        </Modal>
    );
}

export default ListerTableSelectionModal;
