import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";

function InlineError({ message, traceId, onRetry }) {
    return <div className="text-center my-5 mx-4">
        <i className="fas fa-exclamation-circle text-danger fa-4x"></i>
        <h1><FormattedMessage id="app.error" defaultMessage="Error" /></h1>
        <h2 className="text-muted">{message}</h2>
        {traceId && <p className="text-muted">{traceId}</p>}
        {onRetry && <Button onClick={onRetry} className="btn-success mt-1"><i className="fas fa-redo-alt"></i> <FormattedMessage id="app.retry" defaultMessage="Retry" /></Button>}
    </div>;
}

export default InlineError;