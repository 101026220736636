import React, { useContext } from "react";
import jwt_decode from "jwt-decode";
import { useService } from "./ServiceContext";
import { useUser } from "./UserContext";

const AuthContext = React.createContext();

function AuthProvider(props) {
  const { setUser } = useUser();
  const { accountRepository, authorizationStore } = useService();

  const login = async form => {
    const response = await accountRepository.authenticate(form);
    const token = response.data;
    const claims = jwt_decode(token);

    authorizationStore.setAuthorization(token);
    setUser({ username: claims.unique_name, role: claims.role });
  };
  const logout = () => {
    authorizationStore.setAuthorization(null);
    setUser(null);
  };

  return <AuthContext.Provider value={{ login, logout }} {...props} />;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }
  return context;
}

export { AuthProvider, useAuth };
