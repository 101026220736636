import Brent from "../optimisation/brent";

export default class CatmullRom {
    constructor(xaxis, yaxis) {
        this.xpoints = [];
        this.ypoints = [];
        this.maxXFactor = 0;
        this.numberOfPointsForInterpolation = 25;
        this.splineCurve = [];

        this.setValues(xaxis, yaxis);
    }

    get MaxXFactor() {
        return this.maxXFactor;
    }

    set MaxXFactor(value) {
        this.maxXFactor = value;
    }

    get NumberOfPointsForInterpolation() {
        return this.numberOfPointsForInterpolation;
    }

    set NumberOfPointsForInterpolation(value) {
        this.numberOfPointsForInterpolation = value;
    }

    setValues(xaxis, yaxis) {
        if (xaxis[0] < xaxis[xaxis.length - 1]) {
            xaxis = [...xaxis];
            yaxis = [...yaxis];
            xaxis.reverse();
            yaxis.reverse();
        }

        this.xpoints = xaxis;
        this.ypoints = yaxis;
    }

    getValues(noOfPoints, minX, maxX) {
        this.calculate(noOfPoints, minX, maxX);
        let xs = [];
        let ys = [];
        if (this.splineCurve[0].x > this.splineCurve[this.splineCurve.length - 1].x) {
            for (let i = 0; i <= this.splineCurve.length - 1; i += 1) {
                xs.push(this.splineCurve[i].x);
                ys.push(this.splineCurve[i].y);
            }
        }
        else {
            for (let i = this.splineCurve.length - 1; i >= 0; i -= 1) {
                xs.push(this.splineCurve[i].x);
                ys.push(this.splineCurve[i].y);
            }
        }
        return { xs, ys };
    }

    getValue(xValue) {
        if (!xValue) {
            xValue = 0;
        }

        this.calculate(this.numberOfPointsForInterpolation);
        return this.interpolateX(xValue);
    }

    getValueInverse(yValue) {
        if (!yValue) {
            yValue = 0;
        }

        this.calculate(this.numberOfPointsForInterpolation);
        return this.interpolateY(yValue);
    }

    interpolateX(x) {
        let highIndex = 0, lowIndex = 0;
        if (this.splineCurve[0].x <= x) {
            highIndex = 1;
        }
        else if (this.splineCurve[this.splineCurve.length - 1].x >= x) {
            highIndex = this.splineCurve.length - 1;
        }
        else {
            for (let i = 0; i < this.splineCurve.length - 1; i++) {
                if (this.splineCurve[i].x < x || i == this.splineCurve.length - 1) {
                    highIndex = i;
                    break;
                }
            }
        }
        lowIndex = highIndex - 1;
        
        if (!this.splineCurve.length || highIndex > this.splineCurve.length - 1 || lowIndex < 0) {
            return undefined;
        }

        let interval = (x - this.splineCurve[highIndex].x) / (this.splineCurve[lowIndex].x - this.splineCurve[highIndex].x);
        return this.splineCurve[highIndex].y + ((this.splineCurve[lowIndex].y - this.splineCurve[highIndex].y) * interval);
    }

    interpolateY(y) {
        let highIndex = 0, lowIndex = 0;
        if (this.splineCurve[0].y >= y) {
            highIndex = 1;
        }
        else if (this.splineCurve[this.splineCurve.length - 1].y <= y) {
            highIndex = this.splineCurve.length - 1;
        }
        else {
            for (let i = 0; i <= this.splineCurve.length - 1; i++) {
                if (this.splineCurve[i].y > y || i == this.splineCurve.length - 1) {
                    highIndex = i;
                    break;
                }
            }
        }
        lowIndex = highIndex - 1;
        let interval = (y - this.splineCurve[lowIndex].y) / (this.splineCurve[highIndex].y - this.splineCurve[lowIndex].y);
        return this.splineCurve[lowIndex].x + ((this.splineCurve[highIndex].x - this.splineCurve[lowIndex].x) * interval);
    }

    _interpolatePos(pos, n, A, p, d, l, u, q) {
        let s, t, z, i;

        s = Math.trunc(pos) + (Math.trunc(pos) == (n + 1) ? -1 : 0);
        t = pos - s;
        for (i = 0; i <= 1; i++) {
            p[i][1] = A[i][Math.trunc(s) + 1 - 1];
            p[i][2] = A[i][Math.trunc(s) + 2 - 1];
            p[i][0] = A[i][Math.trunc(s) - (s == 0 ? -1 : 0) - 1] - (s == 0 ? -1 : 0) * (p[i][1] - p[i][2]);
            p[i][3] = A[i][Math.trunc(s) + 3 + (s == n ? -1 : 0) - 1] + (s == n ? -1 : 0) * (p[i][1] - p[i][2]);
            d[i][0] = (p[i][2] - p[i][1]) / l[i];
            d[i][1] = (p[i][2] - p[i][0]) / l[i] / 3;
            d[i][2] = (p[i][3] - p[i][1]) / l[i] / 3;
        }
        for (i = 0; i <= 2; i++) {
            u[i] = Math.pow(d[0][i], 2) + Math.pow(d[1][i], 2);
        }
        z = Math.pow((u[0] / Math.max(...u)), 0.5) / 2;
        for (i = 0; i <= 1; i++) {
            q[i] = Math.pow(t, 2) * (3 - 2 * t) * p[i][2] + Math.pow(1 - t, 2) * (1 + 2 * t) * p[i][1] + z * t * (1 - t) * (t * (p[i][1] - p[i][3]) + (1 - t) * (p[i][2] - p[i][0]));
        }
    }

    _solvePos(x, left, right, n, A, p, d, l, u, q) {
        return Brent(pos => {
            this._interpolatePos(pos, n, A, p, d, l, u, q);

            return q[0];
        }, left, right, 1e-06, x);
    }

    calculate(points, minX, maxX) {
        if (typeof minX === "undefined") {
            minX = Number.MIN_VALUE;
        }

        if (typeof maxX === "undefined") {
            maxX = Number.MAX_VALUE;
        }

        let A = [this.xpoints, this.ypoints];
        let l = [1, 1];
        let n = this.xpoints.length - 2;
        let p = Array(2).fill([]).map(() => Array(4).fill(0)), d = Array(2).fill([]).map(() => Array(3).fill(0));
        let u = Array(3).fill(0), q = Array(2).fill(0);
        let intervals = (this.xpoints.length - 1) / (points - 1);
        let minPos = 0;
        let maxPos = intervals * (points - 1);
        
        if (maxX < this.xpoints[0]) {
            minPos = this._solvePos(maxX, 0, maxPos, n, A, p, d, l, u, q);
        }
        
        if (minX > this.xpoints[this.xpoints.length - 1]) {
            maxPos = this._solvePos(minX, 0, maxPos, n, A, p, d, l, u, q);
        }
        
        this.splineCurve = Array(points).fill({ x: 0, y: 0 }).map(() => ({ x: 0, y: 0 }));
        intervals = (maxPos - minPos) / (points - 1);
        for (let cnt = 0; cnt < points; cnt++) {
            this._interpolatePos(minPos + cnt * intervals, n, A, p, d, l, u, q);
            this.splineCurve[cnt] = { x: q[0] || 0, y: q[1] || 0 };
        }
    }
}