import React from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media,
} from "reactstrap";
import { useUser } from "../../context/UserContext";
import { useLanguage } from "../../context/LanguageContext";
import supportedLangauges from "../../language/supportedLanguages";

function LanguageDropdown() {
  const { user } = useUser();
  const { locale, changeLanguage } = useLanguage();

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle className="pr-0" nav>
        <Media className="align-items-center">
          <span className="mb-0 text-sm font-weight-bold">
            {supportedLangauges[locale].name}
          </span>
        </Media>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        {Object.keys(supportedLangauges).map((locale, key) => (
          <DropdownItem key={key} onClick={() => changeLanguage(locale)}>
            <span>{supportedLangauges[locale].name}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default LanguageDropdown;
