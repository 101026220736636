import React, { useState } from "react";
import { Card, CardHeader, Collapse } from "reactstrap";

function CollapsibleCard({ title, children, className }) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <Card className={className}>
      <CardHeader onClick={(e) => toggleOpen()} className="collapse-header">
        {title}
        {open ? (
          <i className={"fas fa-chevron-up mt-1 float-right"}></i>
        ) : (
          <i className={"fas fa-chevron-down mt-1 float-right"}></i>
        )}
      </CardHeader>
      <Collapse isOpen={open}>{children}</Collapse>
    </Card>
  );
}

export default CollapsibleCard;
