import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useService } from "../../context/ServiceContext";
import ListerTable from "../Tables/ListerTable";

function SeriesLoadingStatus() {
  const { serieComercialLoadingStatusLister } = useService();
  const intl = useIntl();

  let refreshRef = useRef();
  const refreshTable = () => refreshRef.current();

  let queueColumns = [
    { Header: intl.formatMessage({ id: "manage_loadings.queueId", defaultMessage: "Queue Id" }), accessor: "queueId", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.series_id", defaultMessage: "Series id" }), accessor: "codSerieComercial", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.series_name", defaultMessage: "Series name" }), accessor: "shortNameComercial", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.status_interno", defaultMessage: "Status interno" }), accessor: "statusInterno", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.inicio_interno", defaultMessage: "Inicio interno" }), accessor: "inicioInterno", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.fin_interno", defaultMessage: "Fin interno" }), accessor: "finInterno", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.status_dev", defaultMessage: "Status dev" }), accessor: "statusDev", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.inicio_dev", defaultMessage: "Inicio dev" }), accessor: "inicioDev", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.fin_dev", defaultMessage: "Fin dev" }), accessor: "finDev", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.status_pre", defaultMessage: "Status pre" }), accessor: "statusPre", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.inicio_pre", defaultMessage: "Inicio pre" }), accessor: "inicioPre", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.fin_pre", defaultMessage: "Fin pre" }), accessor: "finPre", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.status_pro", defaultMessage: "Status pro" }), accessor: "statusPro", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.inicio_pro", defaultMessage: "Inicio pro" }), accessor: "inicioPro", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.fin_pro", defaultMessage: "Fin pro" }), accessor: "finPro", disableFilters: true },
    { Header: intl.formatMessage({ id: "manage_loadings.username", defaultMessage: "Username" }), accessor: "username", disableFilters: true },
  ];

  return (
    <Row>
      <Col>
        <Card className="mt-4">
          <CardHeader>
            <FormattedMessage id="manage_loadings.loading_status" defaultMessage="Loading Status" />
          </CardHeader>
          <CardBody className="p-0">
            <ListerTable className={"align-items-center table-flush"} refreshRef={refreshRef} columns={queueColumns} lister={serieComercialLoadingStatusLister} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default SeriesLoadingStatus;
