class HttpAccessor {
    constructor(http, path, paramReducer) {
        this._http = http;
        this._path = path;
        this._paramReducer = paramReducer;
    }

    async find(key) {
        let params;
        
        if(key) {
            params = this._paramReducer ? this._paramReducer(key) : key;
        }

        let response = await this._http.request({
            method: "get",
            url: this._path,
            params
        });

        return response.data;
    }
}

export default HttpAccessor;