import eventify from "../eventify";

export default class OutputPowerCorrectionMapper {
  constructor({ motor, enabled }) {
    this._motor = motor;
    this._enabled = enabled;
    eventify(this);
  }

  get enabled() {
    return this._enabled;
  }

  set enabled(value) {
    if(this._enabled !== value) {
      this._enabled = value;
      this.fire("change");
    }
  }

  map({ value }) {
    if(this._enabled && this._motor) {
      return { ...value, outputPower: value.outputPower * this._motor.r100 / 100 };
    } else {
      return value;
    }
  }
}