import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ValidationForm } from "../ValidationForm";
import { Alert, Button, Card, CardBody, CardHeader, Col, CustomInput, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Tooltip from "../Tooltip";
import { useProduct } from "../../context/ProductContext";
import { useService } from "../../context/ServiceContext";
import Spinner from "../Spinner";
import SuggestiveInput from "../SuggestiveInput";
import AerodynamicParameters from "./ProductInformation/AerodynamicParameters";
import ElectricalParameters from "./ProductInformation/ElectricalParameters";
import GeometricalParameters from "./ProductInformation/GeometricalParameters";
import MotorParameters from "./ProductInformation/MotorParameters";
import OtherParameters from "./ProductInformation/OtherParameters";
import SelectorParameters from "./ProductInformation/SelectorParameters";
import SoundParameters from "./ProductInformation/SoundParameters";
import RegulationParameters from "./ProductInformation/RegulationParameters";
import ProductInput from "./ProductInput";
import ProductProgress from "./ProductProgress";
import AlertWithIcon from "../AlertWithIcon";
import { isRequired, replaceTags } from "../../products/ProductDefinitions";
import { fireAlert } from "../../utils/alerts";
import ErrorSummary from "./ErrorSummary";
import { valueTypes } from "../../products/ProductAttributeValueTypes";
import ProductAttributes from "../../products/ProductAttributes";
import { replaceBlockedCharacters } from "../../products/ProductValidation";
import FinalCodesPanel from "./ProductInformation/FinalCodesPanel";

function ProductInformation({ productTechnicalAttributesProvider, productConceptsProvider, progressItems, onNext, onPrevious }) {
  const intl = useIntl();
  const { productSuggester } = useService();
  const { product, updateProduct, updateAllProducts, isWizard, isNewProduct, checkProductValidity } = useProduct();
  const { conceptClassUnic, series } = product;
  const [conceptClasses, setConceptClasses] = useState([]);
  const [productAttributes, setProductAttributes] = useState();
  const [errors, setErrors] = useState(null);
  const modeloProductoGenericoLocked = !isNewProduct && !isWizard;
  const isReadOnly = product?.isReadOnly || false;

  useEffect(() => {
    getConcepts();
  }, [series]);

  useEffect(() => {
    getAttributes();
  }, [conceptClassUnic]);

  useEffect(() => {
    checkAttributes();
  }, [productAttributes]);

  async function getConcepts() {
    if (!series) return;
    setConceptClasses(await productConceptsProvider.get(series.id));
  }

  async function getAttributes() {
    if (conceptClassUnic) {
      let attributes = await productTechnicalAttributesProvider.get(conceptClassUnic);

      if (series?.flagErpnorma === 0) {
        attributes["flagErPCompliantFixed"].isVisible = false;
        attributes["modeloKIT_ErP"].isVisible = false;
        attributes["idERPType"].isVisible = false;
      }

      setProductAttributes(attributes);
    }
  }

  const conceptClassPhase = () => (conceptClassUnic ? conceptClasses.find((c) => c.id === conceptClassUnic)?.phase : 0);

  function checkAttributes() {
    if (!productAttributes) return;

    const productAttributeKeys = Object.keys(productAttributes);
    const productAttributeConfig = new ProductAttributes({ attributes: productAttributes, update: updateProduct, object: product, intl });
    productAttributeKeys.forEach((key) => {
      const attribute = productAttributes[key];
      const valueType = attribute.value.type;
      if (valueType === valueTypes.GLOBAL_TABLE && attribute.value.options.length === 1) {
        const update = productAttributeConfig.availableAttributes[key]?.update;
        if (update) {
          update(attribute.value.options[0].id);
        } else {
          updateProduct({ ...product, key: attribute.value.options[0].id });
        }
      }
    });
  }

  function update(e, applyToAllField) {
    if (applyToAllField) {
      updateAllProducts(applyToAllField, product[applyToAllField]);
      return;
    }

    let p;
    if (e.target) {
      const key = e.target.id;
      const value = e.target.type === "checkbox" ? e.target.checked : replaceBlockedCharacters(e.target.value)?.trimStart();
      p = { ...product, [key]: value };
    } else {
      p = { ...e };
    }
    p.modeloProductoGenerico = p.modeloProductoGenericoAuto ? generateModeloProducto(p) : product.modeloProductoGenerico;
    updateProduct(p);
  }

  function generateModeloProducto(p) {
    if (productAttributes) return replaceTags(productAttributes["modeloProductoGenerico"].suggestion, [p, ...principalCurve()], [productAttributes]);
  }

  function toggleModeloProductoGenericoMode() {
    const mode = !product.modeloProductoGenericoAuto;
    update({ ...product, modeloProductoGenericoAuto: mode });
  }

  function principalCurve() {
    let products = [];
    if (product.curveGroups?.length > 0) {
      products.push(product.curveGroups[0]);
      if (product.curveGroups[0].curves?.length > 0) {
        products.push(product.curveGroups[0].curves.find((c) => c.principal) || product.curveGroups[0].curves[0]);
      }
    }
    return products;
  }

  async function validate(e) {
    e.preventDefault();

    if (isReadOnly) {
      onNext();
      return;
    }

    const errors = await checkProductValidity(product);
    setErrors(errors);
    if (!errors.hasErrors) {
      onNext();
    } else {
      fireAlert(<ErrorSummary intl={intl} errors={errors}></ErrorSummary>, "Incorrect attributes", "error");
    }
  }

  const checkValidation = (attributeClass) => (errors === null ? "" : errors[attributeClass] !== undefined ? "is-invalid" : "is-valid");
  function getErrorText(attributeClass) {
    if (errors === null) return null;
    const error = errors[attributeClass];
    if (error == null) return null;
    return intl.formatMessage(error.errorFormattedMessage);
  }

  return (
    <ValidationForm onSubmit={validate}>
      <Row>
        <Col xl={3} className="d-sm-none d-xl-block">
          <ProductProgress onNext={() => {}} onPrevious={onPrevious} items={progressItems}></ProductProgress>
        </Col>
        <Col xl={9}>
          <Card>
            <CardHeader>
              <FormattedMessage id="product_information.title" defaultMessage="Please enter the product information" />
            </CardHeader>
            <CardBody className="p-3">
              <FormGroup row className="px-4">
                <Label sm={3}>
                  <FormattedMessage id="product_information.type_of_product" defaultMessage="Type of product" />
                </Label>
                <Col sm={9}>
                  {isReadOnly ? (
                    <Input type="text" plaintext readOnly value={conceptClasses?.find((c) => c.id == conceptClassUnic)?.value || conceptClassUnic} />
                  ) : (
                    <Input type="select" id="conceptClassUnic" value={conceptClassUnic || ""} onChange={update} className={checkValidation("conceptClassUnic")}>
                      <option value="" disabled="disabled">
                        {intl.formatMessage({ id: "product_information.select_an_option", defaultMessage: "Please select an option" })}
                      </option>
                      {conceptClasses.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.value}
                        </option>
                      ))}
                    </Input>
                  )}
                </Col>
              </FormGroup>

              {!conceptClassUnic ? (
                <div className="text-center">
                  <hr></hr>
                  <h3>
                    <FormattedMessage id="product_information.please_select_a_type_of_product" defaultMessage="Please select a type of product" />
                  </h3>
                </div>
              ) : !productAttributes ? (
                <Spinner></Spinner>
              ) : (
                <>
                  <FormGroup row className="px-4 mt-0">
                    <ProductInput
                      attributeClass="cuerpo"
                      attributes={productAttributes}
                      obj={product}
                      update={update}
                      label="Cuerpo"
                      labelMessageId="product_information.cuerpo"
                      labelProps={{ sm: 3 }}
                      columnProps={{ sm: 3 }}
                      isWizard={isWizard}
                    ></ProductInput>
                  </FormGroup>

                  <GeometricalParameters productAttributes={productAttributes} product={product} update={update} isWizard={isWizard}></GeometricalParameters>
                  <MotorParameters productAttributes={productAttributes} product={product} update={update} isWizard={isWizard} conceptPhase={conceptClassPhase()}></MotorParameters>
                  <ElectricalParameters
                    productAttributes={productAttributes}
                    product={product}
                    update={update}
                    updateAllProducts={updateAllProducts}
                    isWizard={isWizard}
                  ></ElectricalParameters>

                  <OtherParameters productAttributes={productAttributes} product={product} update={update} isWizard={isWizard}></OtherParameters>
                  <AerodynamicParameters productAttributes={productAttributes} product={product} update={update} isWizard={isWizard}></AerodynamicParameters>
                  <SoundParameters productAttributes={productAttributes} product={product} update={update} isWizard={isWizard}></SoundParameters>
                  <RegulationParameters productAttributes={productAttributes} product={product} update={update} isWizard={isWizard}></RegulationParameters>

                  <FormGroup row className="px-4 mb-0 mt-4">
                    {product.originalModeloProductoGenerico && (
                      <>
                        <Label sm={3}></Label>
                        <Col sm={9}>
                          <small>
                            <FormattedMessage id="product_information.original_modeloproductogenerico" defaultMessage="Original Modelo Producto Generico" />{" "}
                            <b>{product.originalModeloProductoGenerico}</b>
                          </small>
                        </Col>
                      </>
                    )}
                    <Label sm={3}>
                      <FormattedMessage {...productAttributes.modeloProductoGenerico.labelFormattedMessage} />
                      {isRequired("modeloProductoGenerico", productAttributes)}
                    </Label>
                    <Col sm={9}>
                      <Tooltip text={getErrorText("modeloProductoGenerico")}>
                        <SuggestiveInput
                          suggester={productSuggester}
                          inputProps={{
                            value: (product.modeloProductoGenerico || "").toString(),
                            required: false,
                            onChange: (e) => {
                              updateProduct({
                                ...product,
                                modeloProductoGenerico: e.target.value?.trimStart(),
                              });
                            },
                            disabled: product.modeloProductoGenericoAuto || modeloProductoGenericoLocked,
                            className: checkValidation("modeloProductoGenerico"),
                          }}
                          renderSuggestion={(s) => <div>{productSuggester.resolveSuggestionValue(s)}</div>}
                          renderInputComponent={(p) => <Input {...p} />}
                        />
                      </Tooltip>
                    </Col>
                  </FormGroup>
                  {!modeloProductoGenericoLocked && (
                    <>
                      <FormGroup row className="px-4 my-0">
                        <Col sm={{ size: 9, offset: 3 }}>
                          <div className="d-flex align-items-center">
                            <span className="mr-3">
                              <FormattedMessage id="product_information.automatic" defaultMessage="Automatic" />
                            </span>{" "}
                            <CustomInput
                              type="switch"
                              id="modeloProductoGenericoAuto"
                              checked={product.modeloProductoGenericoAuto || false}
                              onChange={toggleModeloProductoGenericoMode}
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="px-4 mt-0">
                        <Col sm={{ size: 9, offset: 3 }}>
                          <Tooltip text={intl.formatMessage({ id: "product_information.suggested_value_structure", defaultMessage: "Suggested value structure" })}>
                            <AlertWithIcon
                              className="mt-1"
                              icon={<i className="far fa-lightbulb" title="suggestion"></i>}
                              action={
                                <Button size="sm" color="secondary" onClick={() => updateProduct({ ...product, modeloProductoGenerico: generateModeloProducto(product) })}>
                                  <FormattedMessage id="app.apply" defaultMessage="Apply" />
                                </Button>
                              }
                            >
                              {generateModeloProducto(product)}
                            </AlertWithIcon>
                          </Tooltip>
                        </Col>
                      </FormGroup>
                    </>
                  )}

                  <FormGroup row className="px-4 mt-0">
                    <ProductInput
                      attributeClass="productoGenericoComercial"
                      attributes={productAttributes}
                      obj={product}
                      update={update}
                      label="ProductoGenericoComercial"
                      labelMessageId="product_information.productoGenericoComercial"
                      labelProps={{ sm: 3 }}
                      columnProps={{ sm: 9 }}
                      isWizard={isWizard}
                    ></ProductInput>
                  </FormGroup>

                  <SelectorParameters productAttributes={productAttributes} product={product} update={update} isWizard={isWizard}></SelectorParameters>

                  {product.finalProductCodes?.length > 0 && <FinalCodesPanel product={product}></FinalCodesPanel>}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ValidationForm>
  );
}

export default ProductInformation;
