import React, { useState } from "react";
import { Row, Col, Card, CardBody, Input, InputGroup, InputGroupAddon, InputGroupText, Button, CardHeader, CardFooter, Collapse, Form, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import SuggestiveInput from "../SuggestiveInput";

function MultiSearchPanel(props) {
  const [focus, setFocus] = useState(null);

  function handleSearchChange(e, field) {
    if (e.target.value) {
      setFocus({ ...field, value: e.target.value });
    } else {
      setFocus(null);
    }
  }

  function isFieldEnabled(field) {
    return focus === null || focus.id === field.id;
  }

  function getFieldText(field) {
    if (focus === null || field.id !== focus.id) {
      return "";
    } else {
      return focus.value;
    }
  }

  function handleSearchSubmit(e) {
    e.preventDefault();
    var search = focus ? Object.assign({}, focus) : null;
    props.onSubmit(search);
  }

  function renderSuggestion(text) {
    return <span>{text}</span>;
  }

  function renderInput(label, props) {
    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>{label}</InputGroupText>
        </InputGroupAddon>
        <Input {...props} />
      </InputGroup>
    );
  }

  function clearFields() {
    setFocus(null);
    props.onSubmit(null);
  }

  return (
    <Form onSubmit={handleSearchSubmit} role="form">
      <Card>
        <CardHeader onClick={(e) => props.onToggleOpen && props.onToggleOpen()}>
          <FormattedMessage id="app.search" defaultMessage="Search" />
          {props.onToggleOpen ? props.isOpen ? <i className={"fas fa-chevron-up mt-1 float-right"}></i> : <i className={"fas fa-chevron-down mt-1 float-right"}></i> : <></>}
        </CardHeader>
        <Collapse isOpen={props.isOpen ?? true}>
          <CardBody>
            <Row>
              {props.fields.map((field, index) => {
                const inputProps = {
                  onChange: (e) => handleSearchChange(e, field),
                  disabled: !isFieldEnabled(field),
                  value: getFieldText(field),
                };

                return (
                  <Col key={index} sm="12" md={props.fields.length > 1 ? 6 : 12} className={"mt-3"}>
                    <FormGroup className="my-0">
                      {field.suggester ? (
                        <SuggestiveInput
                          suggester={field.suggester}
                          inputProps={inputProps}
                          renderSuggestion={(s) => renderSuggestion(field.suggester.resolveSuggestionValue(s))}
                          renderInputComponent={(p) => renderInput(field.label, p)}
                        />
                      ) : (
                        renderInput(field.label, inputProps)
                      )}
                    </FormGroup>
                  </Col>
                );
              })}
            </Row>
          </CardBody>

          <CardFooter>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button type="button" className={"btn-primary"} disabled={!focus} onClick={clearFields}>
                  <FormattedMessage id="app.clear" defaultMessage="Clear" />
                </Button>
                <Button className={"btn-danger"} disabled={!focus}>
                  <FormattedMessage id="app.search" defaultMessage="Search" />
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Collapse>
      </Card>
    </Form>
  );
}

export default MultiSearchPanel;
