import React from "react";
import { Route, Switch } from "react-router-dom";
import ProductCreation from "./Home";
import Product from "./Product";
import ProductCreationWizard from "./ProductCreationWizard";
import Series from "./Series";

function ProductCreationIndex() {
  return (
    <Switch>
      <Route
        exact={true}
        path={"/products"}
        render={(props) => {
          return <ProductCreation {...props} />;
        }}
      />
      <Route
        path={"/products/product/:id/:series/:version"}
        render={(props) => {
          return <Product {...props} />;
        }}
      />
      <Route
        exact={true}
        path={"/products/series"}
        render={(props) => {
          return <Series {...props} />;
        }}
      />
      <Route
        path={"/products/wizard/:id"}
        render={(props) => {
          return <ProductCreationWizard {...props} />;
        }}
      />
    </Switch>
  );
}

export default ProductCreationIndex;
