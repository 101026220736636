import React, { useEffect, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import AppNavbar from "../components/Navbars/AppNavbar.jsx";
import AppFooter from "../components/Footers/AppFooter.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import routes from "../routes.js";
import ReactTooltip from "react-tooltip";
import { useIntl } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import LayoutErrorFallback from "./LayoutErrorFallback.jsx";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { useUser } from "../context/UserContext.jsx";

function AppLayout(props) {
  const mainContent = useRef();
  const { user } = useUser();
  const intl = useIntl();
  const authorisedRoutes = routes.filter((prop) => {
    return prop.roles == null || prop.roles.length == 0 || prop.roles.includes(user.role);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let brandText = getBrandText(props.location.pathname);
    window.document.title = `Genesis - ${intl.formatMessage({ id: brandText.brandTextId, defaultMessage: brandText.brandText })}`;
  }, [props.location.pathname, intl.locale]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.area === "app") {
        if (prop.roles != null && prop.roles.length > 0 && !prop.roles.includes(user.role)) return null;
        return <Route path={prop.path} exact={prop.exact || false} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (props.location.pathname.indexOf(routes[i].path) !== -1) {
        return {
          brandText: routes[i].name,
          brandTextId: routes[i].language_key,
        };
      }
    }
    return { brandText: "Soler & Palau", brandTextId: "sp" };
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={authorisedRoutes}
        /* logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/logo.svg"),
          imgAlt: "...",
        }} */
      />
      <div className="main-content" ref={mainContent}>
        <AppNavbar {...props} {...getBrandText(props.location.pathname)} />
        <ErrorBoundary
          fallbackRender={(props) => (
            <div className="mt-8 mx-5">
              <LayoutErrorFallback {...props} />
            </div>
          )}
        >
          <ScrollToTop {...props}>
            <Switch>
              {getRoutes(authorisedRoutes)}
              <Redirect from="/" to="/home" exact />
            </Switch>
          </ScrollToTop>
        </ErrorBoundary>
        <Container fluid>
          <AppFooter />
          <span data-for="generic-tooltip" data-tip=""></span>
          <ReactTooltip id="generic-tooltip" getContent={(dataTip) => dataTip} effect="solid" />
        </Container>
      </div>
    </>
  );
}

export default AppLayout;
