import React from "react";
import { useIntl } from "react-intl";
import Tooltip from "../Tooltip";

function ProductInfo({ formattedMessage }) {
  const intl = useIntl();
  return (
    <Tooltip className="ml-1" text={intl.formatMessage(formattedMessage) || formattedMessage.id}>
      <span>
        <i className="fas fa-info-circle"></i>
      </span>
    </Tooltip>
  );
}

export default ProductInfo;
