import React, { useEffect, useState } from "react";
import ProductInputContainer from "../ProductInputContainer";
import ProductInput from "../ProductInput";
import { isUndefinedOrNullOrEmpty } from "../../../products/ProductDefinitions";
import { Button, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";

function GeometricalParameters({ children, productAttributes, curve, updateCurve, updateCurveByObj, disabled, checkValidation, validationErrorText }) {
  const inputs = {
    cuerpo: {
      label: "Cuerpo",
      labelMessageId: "product_information.cuerpo",
    },
    polos: {
      label: "Polos",
      labelMessageId: "product_information.polos",
    },
    etiqueta: {
      label: "Etiqueta",
      labelMessageId: "product_information.etiqueta",
      valueColumns: { sm: 6 },
      break: true,
    },
  };

    const [sizeMode, setSizeMode] = useState(!isUndefinedOrNullOrEmpty(curve.caudal) ? "caudal" : !isUndefinedOrNullOrEmpty(curve.tamany) ? "tamany" : "diametro");

    useEffect(() => {
        setSizeMode(!isUndefinedOrNullOrEmpty(curve.caudal) ? "caudal" : !isUndefinedOrNullOrEmpty(curve.tamany) ? "tamany" : "diametro");
    }, [curve]);

  function updateSize(e) {
    const key = e.target.id;
    const value = e.target.value;

    if (key == "caudal") {
      updateCurveByObj({ ...curve, caudal: value, tamany: null, diametro: null });
    } else if (key == "tamany") {
      updateCurveByObj({ ...curve, caudal: null, tamany: value, diametro: null });
    } else {
      updateCurveByObj({ ...curve, caudal: null, tamany: null, diametro: value });
    }
  }

  return (
    <ProductInputContainer
      productAttributes={productAttributes}
      product={curve}
      update={updateCurve}
      inputs={inputs}
      title="product_information.geometrical_parameters"
      disabled={disabled}
      checkValidation={checkValidation}
      validationErrorText={validationErrorText}
    >
      {!disabled && (
        <>
          <hr className="w-100 mt-1 mb-3"></hr>
          <Col sm={{ size: 9, offset: 3 }} className="mb-3">
            <Button outline={sizeMode != "caudal"} color="danger" onClick={() => setSizeMode("caudal")}>
              <FormattedMessage id="attribute.Caudal" />
            </Button>
            <Button outline={sizeMode != "tamany"} color="danger" onClick={() => setSizeMode("tamany")}>
              <FormattedMessage id="attribute.Tamany" />
            </Button>
            <Button outline={sizeMode != "diametro"} color="danger" onClick={() => setSizeMode("diametro")}>
              <FormattedMessage id="attribute.Diametro" />
            </Button>
          </Col>
        </>
      )}
      <div className="w-100"></div>
      {sizeMode === "caudal" && (
        <ProductInput
          attributeClass="caudal"
          attributes={productAttributes}
          obj={curve}
          update={updateSize}
          labelProps={{ sm: 3 }}
          columnProps={{ sm: 9 }}
          disabled={disabled}
        ></ProductInput>
      )}
      {sizeMode === "tamany" && (
        <ProductInput
          attributeClass="tamany"
          attributes={productAttributes}
          obj={curve}
          update={updateSize}
          labelProps={{ sm: 3 }}
          columnProps={{ sm: 9 }}
          disabled={disabled}
        ></ProductInput>
      )}
      {sizeMode === "diametro" && (
        <ProductInput
          attributeClass="diametro"
          attributes={productAttributes}
          obj={curve}
          update={updateSize}
          labelProps={{ sm: 3 }}
          columnProps={{ sm: 9 }}
          disabled={disabled}
        ></ProductInput>
      )}
      {children}
    </ProductInputContainer>
  );
}

export default GeometricalParameters;
