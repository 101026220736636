import React from "react";
import { BaseFormControl } from "react-bootstrap4-form-validation";

export class SelectGroup extends BaseFormControl {
  static defaultProps = {
    ...BaseFormControl.defaultProps,
    className: "form-control",
  };
  render() {
    let domProps = this.filterProps();
    return (
      <>
        <select className={this.props.className} {...domProps} ref={this.inputRef} onChange={this.handleChange} onBlur={this.handleBlur} value={this.props.value}>
          {this.props.children}
        </select>
        {this.displayErrorMessage()}
        {this.displaySuccessMessage()}
      </>
    );
  }
}
