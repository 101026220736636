import resolvePath from "object-resolve-path";
import React, { useContext } from "react";

const ConfigContext = React.createContext();

function ConfigProvider(props) {
  return <ConfigContext.Provider value={props.config} {...props} />;
}

function useConfig(path) {
  const context = useContext(ConfigContext);
  
  if (context === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider.");
    }
    
  if(path === undefined) {
    return context;
  } else {
    return resolvePath(context, path);
  }
}

export { ConfigProvider, useConfig };
