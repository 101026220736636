import React from "react";
import { FormattedMessage } from "react-intl";
import { 
    Pagination,
    PaginationItem,
    PaginationLink } from "reactstrap";

function Pager({ pageCount, currentPage, canNextPage, canPreviousPage, onPageChange }) {
  function handlePageChange(e, page) {
    e.preventDefault();
    onPageChange && onPageChange(page);
  }

  function renderNumberedItems() {
    let pages = [];
    let surrounding = 2;
    var offset = 1;
    
    pages.push(currentPage);
    for(var offset; pages.length < surrounding * 2 + 1; offset++) {
        var front = currentPage - offset;
        var back = currentPage + offset;
        var added = false;

        if(front >= 1) {
          pages.unshift(currentPage - offset);
          added = true;
        }

        if(back <= pageCount) {
          pages.push(back);
          added = true;
        }

        if(!added) {
          break;
        }
    }

    return pages.map(i =>
      <PaginationItem key={i} className={`${currentPage === i ? "active" : ""}`}>
        <PaginationLink
          href="#pablo"
          onClick={e => handlePageChange(e, i)}
        >
          {i}
        </PaginationLink>
      </PaginationItem>
    );
  }

  return (
    <nav aria-label="...">
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem>
          <PaginationLink
            href="#pablo"
            onClick={e => handlePageChange(e, 1)}
            tabIndex="-1"
          >
            <i className="fas fa-angle-double-left" />
            <span className="sr-only"><FormattedMessage id="app.previous" defaultMessage="Previous" /></span>
          </PaginationLink>
        </PaginationItem>

        <PaginationItem disabled={!canPreviousPage}>
          <PaginationLink
            href="#pablo"
            onClick={e => handlePageChange(e, currentPage - 1)}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only"><FormattedMessage id="app.previous" defaultMessage="Previous" /></span>
          </PaginationLink>
        </PaginationItem>

        {renderNumberedItems()}
        
        <PaginationItem disabled={!canNextPage}>
          <PaginationLink
            href="#pablo"
            onClick={e => handlePageChange(e, currentPage + 1)}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only"><FormattedMessage id="app.next" defaultMessage="Next" /></span>
          </PaginationLink>
        </PaginationItem>

        <PaginationItem>
          <PaginationLink
            href="#pablo"
            onClick={e => handlePageChange(e, pageCount)}
          >
            <i className="fas fa-angle-double-right" />
            <span className="sr-only"><FormattedMessage id="app.next" defaultMessage="Next" /></span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </nav> 
  );
}

export default Pager;