import React from "react";
import { ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import CurveOptionList from "./CurveOptionList";

function CurveVoltageDefinitionStep({ next, prev, groups, voltages, customVoltages, products, setProducts }) {
  return (
    <ValidationForm onSubmit={() => {}}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.curve_voltages_definition" defaultMessage="Curve voltages definition" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage id="product_wizard.select_subfrequencies_each_curve" defaultMessage="Select subfrequencies for each product’s curves:" />
          </p>
          <CurveOptionList
            groups={groups}
            selectedOptions={voltages.some((v) => v.name === "custom") ? customVoltages : voltages}
            products={products}
            setProducts={setProducts}
            optionsFieldName="voltages"
          ></CurveOptionList>
        </CardBody>
        <CardFooter>
          <Button className="btn btn-dark float-right" onClick={next}>
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default CurveVoltageDefinitionStep;
