import React, { useEffect, useState } from "react";

function Validated({ children, errors, name }) {
    const [invalid, setInvalid] = useState();
    
    useEffect(() => {
        setInvalid(errors.some(e => e.propertyName == name));
    }, [errors]);

    return <>
        {React.Children.map(children, child => {
            return React.cloneElement(child, { 
                invalid,
                onChange: e => {
                    setInvalid(false);
                    child.props.onChange && child.props.onChange(e);
                }
            });
        })}
    </>
}

export default Validated;