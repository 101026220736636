class DependentGenerationService {
    constructor(http, path, delay) {
        this._http = http;
        this._path = path;
        this._delay = delay ?? 1000;
    }

    async process(onProgressChanged) {
        await this._http.request({
            method: "put",
            url: this._path
        });

        await this.monitorProgress(onProgressChanged);
    }

    async monitorProgress(onProgressChanged) {
        let progress;
        do {
            progress = await this.getProgress();
            
            if(onProgressChanged)
            {
                onProgressChanged(progress);
            }

            await new Promise(r => setTimeout(r, this._delay));
        } while(progress && !progress.isFinished && !progress.isFailed);
    }

    async getProgress() {
        let response = await this._http.request({
            method: "get",
            url: `${this._path}/progress`
        });

        return response.data;
    }
}

export default DependentGenerationService;