import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
  Alert,
} from "reactstrap";

import { useAuth } from "../context/AuthContext";
import { useIntl, FormattedMessage } from 'react-intl'

function Login(props) {
  const [pending, setPending] = useState(false);
  const [alert, setAlert] = useState(null);
  const [displayPassword, setDisplayPassword] = useState(false);
  const { login } = useAuth();
  const intl = useIntl();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { username, password } = event.target.elements;
    try {
      setAlert(null);
      setPending(true);
      await login({ username: username.value, password: password.value });
    } catch (e) {
      let problem = e.response.data;
      if(problem.localization) {
        setAlert(intl.formatMessage(problem.localization));
      }
      setPending(false);
    }
  };

  const togglePasswordVisibility = e => {
    e.preventDefault();
    setDisplayPassword(!displayPassword);
  }

  return (
    <Col lg="5" md="7">
          <Card style={{ border: "1px solid #e7e7e7" }}>
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-dark mb-4">
            <small><FormattedMessage id="login.title" defaultMessage="Sign in with credentials" /></small>
          </div>
          <Alert color="danger" className="text-center" hidden={alert == null}>
            {alert}
          </Alert>
          <Form onSubmit={handleSubmit} role="form">
            <fieldset disabled={pending}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-user text-dark" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="username" 
                    placeholder={intl.formatMessage({ id: "login.username", defaultMessage: "Username" })}
                    type="text" />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open text-dark" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="password"
                    placeholder={intl.formatMessage({ id: "login.password", defaultMessage: "Password" })} 
                    type={displayPassword ? "text" : "password"} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText onClick={togglePasswordVisibility} style={{ width: '3rem' }}>
                      {displayPassword ?
                        <i className="fa fa-eye-slash mx-auto" aria-hidden="true"></i> :
                        <i className="fa fa-eye mx-auto" aria-hidden="true"></i>}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-dark"><FormattedMessage id="login.remember_me" defaultMessage="Remember me" /></span>
                </label>
              </div>
              <div className="text-center">
                {pending ? <Spinner color="primary" className="mt-3" /> : null}
              </div>
              <div className="text-center">
                <Button className="my-4" color="danger" type="submit">
                  <FormattedMessage id="login.sign_in" defaultMessage="Sign in" />
                </Button>
              </div>
            </fieldset>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
}

export default Login;
