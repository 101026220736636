import SoundPowerCorrection from "./SoundPowerCorrection";

export default class DefaultAggregateRegion {
    constructor(key, tests) {
        this._key = key;
        this._tests = tests;
        this._correction = new SoundPowerCorrection(tests);

    }

    get key() {
        return this._key;
    }

    get tests() {
        return [...this._tests];
    }

    get correction() {
        return this._correction;
    }

    get count() {
        return this._tests.length;
    }

    get items() {
        // return this.tests;
        return [...this.tests];

    }

    get results() {
        return this.tests.map(t => t.soundPower.correct(this.correction));
    }

    removeItem(index){
        this._tests = this._tests.splice(index,1)
    }
}