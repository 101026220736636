import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FormattedMessage, useIntl } from "react-intl";
import SeriesProductSearch from "../../components/ProductCreation/SeriesProductSearch";
import { dismissAlert, fireAlert } from "../../utils/alerts";
import CurveTestSearch from "../../components/CurvesGenerator/CurveTestSearch";
import { useConfig } from "../../context/ConfigContext";

function DatosPlaca({ history }) {
  function onProductSelect(value) {
    //history.push(`/datos-placa/${value.templateType ? "edit" : "new"}/${value.codProductoComercial}${value.id ? "/" + value.id + "/" + value.version : ""}`);
    history.push(`/datos-placa/edit/${value.codProductoComercial}${value.id ? "/" + value.id + "/" + value.version : ""}`);
  }

  const features = useConfig("genesis.features.gdec") ?? {};

  console.log(features);

  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="datos_placa.title" defaultMessage="Create or modify a name plate" />
        </h1>
      </Header>
      <Container fluid>
        {features.serachExistingNamePLate &&
                <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <h3 className="card-title">
                            <FormattedMessage id="datos_placa.select_product" defaultMessage="Select an existing product" />
                          </h3>
                          <p className="card-text">
                            <FormattedMessage id="datos_placa.description" defaultMessage="Please use the options below to search and select a product." />
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3">
                          <SeriesProductSearch useGeneric={false} productSelectText="Select" onProductSelect={onProductSelect}></SeriesProductSearch>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
        }

        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="card-title">
                      <FormattedMessage id="datos_placa.select_test" defaultMessage="Check Datos Placa by idEnsayo" />
                    </h3>
                    <p className="card-text">
                      <FormattedMessage id="datos_placa.test_description" defaultMessage="Please use the options below to search and select a idEnsayo to view a summary." />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    <CurveTestSearch onCurveTestSelect={(id) => history.push(`/datos-placa/summary/${id}`)}></CurveTestSearch>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DatosPlaca;
