import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useService } from "../../context/ServiceContext";
import ListerTable from "../Tables/ListerTable";

function SeriesLoadingsQueue({ onRequestClick }) {
  const { serieComercialLoadingsQueueLister } = useService();
  const intl = useIntl();

  let refreshRef = useRef();
  const refreshTable = () => refreshRef.current();

  async function handleQueueSelection(value, processId) {
    if (onRequestClick) {
      await onRequestClick(value, processId);
      refreshTable();
    }
  }

  function renderSmallTableHeader({ column, children }) {
    return (
      <th {...column.getHeaderProps()} style={{ width: "1%" }}>
        {children}
      </th>
    );
  }

  let queueColumns = [
    { Header: intl.formatMessage({ id: "manage_loadings.series_id", defaultMessage: "Series id" }), accessor: "codSerieComercial" },
    { Header: intl.formatMessage({ id: "manage_loadings.series_name", defaultMessage: "Series name" }), accessor: "serieComercial" },
    { Header: intl.formatMessage({ id: "manage_loadings.process_id", defaultMessage: "Status" }), accessor: "status" },
    { Header: intl.formatMessage({ id: "manage_loadings.begin_process", defaultMessage: "Begin process" }), accessor: "beginProcessPre" },
    { Header: intl.formatMessage({ id: "manage_loadings.end_process", defaultMessage: "End process" }), accessor: "endProcessPre" },
  ];

  queueColumns.push({
    id: "select_ok_in_pre",
    TableHeader: renderSmallTableHeader,
    Cell: ({ cell }) => {
      //if (cell.row.original.statusId == 1) {
      return (
        <Button className="btn btn-danger float-right" onClick={() => handleQueueSelection(cell.row.original.codSerieComercial, 2)}>
          <FormattedMessage id="manage_loadings.ok_in_pre" defaultMessage="Ok in PRE" />
        </Button>
      );
      //} else {
      //  return <></>;
      //}
    },
  });

  queueColumns.push({
    id: "select_not_ok_in_pre",
    TableHeader: renderSmallTableHeader,
    Cell: ({ cell }) => {
      //if (cell.row.original.statusId == 1) {
      return (
        <Button className="btn btn-danger float-right" onClick={() => handleQueueSelection(cell.row.original.codSerieComercial, 6)}>
          <FormattedMessage id="manage_loadings.ok_in_pre" defaultMessage="Not Ok in PRE" />
        </Button>
      );
      //} else {
      //  return <></>;
      //}
    },
  });

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <FormattedMessage id="manage_loadings.pre_validation" defaultMessage="PRE Validation" />
          </CardHeader>
          <CardBody className="p-0">
            <ListerTable className={"align-items-center table-flush"} refreshRef={refreshRef} columns={queueColumns} lister={serieComercialLoadingsQueueLister} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default SeriesLoadingsQueue;
