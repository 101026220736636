import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import AppTable from "./AppTable";
import InlineError from "../InlineError";
import { useAsyncDebounce } from "react-table";

function ListerTable(props) {
  const { lister, prefilter, onLoad, initialFilter, initialPage, onNoResults, onFilterChange, onPageChange } = props;

  const intl = useIntl();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [pageNumber, setPageNumber] = useState(initialPage ?? 1);
  const [filter, setFilter] = useState(initialFilter);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const debounce = useAsyncDebounce(loadData, 500);
  const pageSize = props.pageSize ?? 10;

  const refresh = () => debounce(pageNumber, [prefilter, filter]);
  useEffect(() => {
    if (!props.refreshRef) return;

    props.refreshRef.current = refresh;

    return () => {
      props.refreshRef.current = null;
    };
  });

  useEffect(() => {
    debounce(pageNumber, [prefilter, filter]);
  }, [debounce, pageNumber, prefilter, filter]);

  useEffect(() => {
    setFilter(initialFilter);
  }, [initialFilter]);

  useEffect(() => {
    setPageNumber(1);
  }, [prefilter, filter]);

  useEffect(() => {
    if (data.length === 0 && !loading && onNoResults !== undefined) {
      onNoResults(filter);
    }
  }, [data, loading]);

  function handleFilterChange(newFilter) {
    setFilter(newFilter);
    onFilterChange && onFilterChange(newFilter);
  }

  function handlePageChange(page) {
    setPageNumber(page);
    onPageChange && onPageChange(page);
  }

  function handleRetry(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    loadData(pageNumber, [prefilter, filter]);
  }

  async function loadData(pageNumber, filters) {
    filters = filters.filter((f) => f && Object.keys(f).length);
    setLoading(true);

    try {
      let result = await lister.getList(filters, pageNumber ?? 1, pageSize);
      setPageCount(Math.ceil(result.total / pageSize));
      setData(result.items);
      onLoad && onLoad();
    } catch (e) {
      if (e.localization) {
        setError({ localization: e.localization, traceId: e.traceId });
      }
    } finally {
      setLoading(false);
    }
  }

  if (!error) {
    return (
      <AppTable
        {...props}
        data={data}
        manualFilters={true}
        autoResetFilters={false}
        onFilterChange={handleFilterChange}
        enablePagination={true}
        manualPagination={true}
        currentPage={pageNumber}
        pageCount={pageCount}
        onPageChange={handlePageChange}
        loading={loading}
      />
    );
  } else {
    return <InlineError message={intl.formatMessage(error.localization)} traceId={error.traceId} onRetry={handleRetry} />;
  }
}

export default ListerTable;
