import trackify from "../trackify";
import ConfiguredInterpolatedCurve from "./ConfiguredInterpolatedCurve";
import ConfiguredSelectionCurve from "./ConfiguredSelectionCurve";
import OutputPowerCorrectionMapper from "./OutputPowerCorrectionMapper";
import PointCalculationMapper from "./PointCalculationMapper";
import ProductCharacteristics from "./ProductCharacteristics";
import TransmissionNormalisationMapper from "./TransmissionNormalisationMapper";
import TransmissionOptions from "./TransmissionOptions";
import TransmissionOutputPowerMapper from "./TransmissionOutputPowerMapper";

export default class CurveTest {
    constructor({ state, changes, config }) {
    const self = this;
    trackify(this, state, changes);

    this._product = new ProductCharacteristics(state.product, changes.product);
    this._transmission = new TransmissionOptions(state.transmission, changes.transmission);

    this._transmissionNormMapper = new TransmissionNormalisationMapper({
      enabled: this._transmission.enabled,
      normalisation: this._transmission.normalisation
    });
    
    this._outputPowerMapper = new OutputPowerCorrectionMapper({
      motor: this.motor,
      enabled: this.isOutputPowerCalculated
    });

    this._transmissionOutputPowerMapper = new TransmissionOutputPowerMapper({
      motor: this.motor,
      enabled: this.isOutputPowerCalculated
    });

    this._pointCalculationMapper = new PointCalculationMapper(!!this.product.settings.newSection ? this.product.settings.newSection : this.product.section);

    this.product.settings.on("change", () => self._pointCalculationMapper.outletArea = !!this.product.settings.newSection ? this.product.settings.newSection : this.product.section);

    this._interpolation = new ConfiguredInterpolatedCurve({
      data: state.points,
      variable: "airflow",
      columns: config.columns.interpolated,
      state: state.interpolation,
      changes: changes.interpolation,
      mappers: { transmissionNorm: this._transmissionNormMapper, outputPower: this._outputPowerMapper, transmissionOutputPower: this._transmissionOutputPowerMapper, pointCalculation: this._pointCalculationMapper }
    });

    this._selection = new ConfiguredSelectionCurve({
      data: this._interpolation.points,
      mappers: { pointCalculation: this._pointCalculationMapper },
      variable: "airflow",
      columns: config.columns.interpolated,
      config: config.selection,
      state: state.selection,
      changes: changes.selectionSettings
    });

    if(this.id === null) {
      this._setChangeOnly("origin", { testId: { value: this._state.laboratoryTest.id, version: 0 }, curveId: this._state.curve.id });
    }

    const updateSelectionPoints = () => {
      if(self.selection.settings.isInterpolationDisabled) {
        self.selection.data = self.state.points;
      } else {
        self.selection.data = self.interpolation.points;
      }
    };

    const handleTransmissionChange = () => {
      self._transmissionNormMapper.enabled = self.transmission.enabled;
      self._transmissionNormMapper.normalisation = self.transmission.normalisation;
    };

    this.interpolation.on("change", () => updateSelectionPoints());
    this.selection.settings.on("change", () => updateSelectionPoints());
    this.transmission.on("change", handleTransmissionChange);
  }

  get id() {
    return this._get("id");
  }

  get isNew() {
      return this.id === null;
  }

  get state() {
    return { 
      ...this._state,
      product: this.product.state,
      transmission: this.transmission.state,
      interpolation: this.interpolation.state,
      selection: this.selection.state
    };
  }

  get changes() {
    return {
      ...this._changes,
      product: this.product.changes,
      transmission: this.transmission.changes,
      interpolation: this.interpolation.changes,
      selectionSettings: this.selection.changes
    };
  }

  get erPCompliance() {
    return this._get("erPCompliance");
  }

  set erPCompliance(value) {
    return this._set("erPCompliance", value);
  }

  get product() {
    return this._product;
  }

  get isPublished() {
    return this._get("isPublished");
  }

  set isPublished(value) {
    this._set("isPublished", value);
  }

  get hasErP() {
    return this._get("hasErP");
  }

  set hasErP(value) {
    this._set("hasErP", value);
  }

  get isErPNorm() {
    return this._get("isErPNorm");
  }

  set isErPNorm(value) {
    this._set("isErPNorm", value);
  }

  get isErPTest() {
    return this._get("isErPTest");
  }

  set isErPTest(value) {
    this._set("isErPTest", value);
  }

  get isCatalogue() {
    return this._get("isCatalogue");
  }

  set isCatalogue(value) {
    this._set("isCatalogue", value);
  }

  get isPrincipalErP() {
    return this._get("isPrincipalErP");
  }

  set isPrincipalErP(value) {
    this._set("isPrincipalErP", value);
  }

  get isOutputPowerCaculated() {
    return this._get("isOutputPowerCalculated");
  }

  set isOutputPowerCaculated(value) {
    this._outputPowerMapper.enabled = value;
    this._transmissionOutputPowerMapper.enabled = value;
    this._set("isOutputPowerCalculated", value);
  }

  get observations() {
    return this._get("observations");
  }

  set observations(value) {
    this._set("observations", value);
  }

  get motor() {
    return this._get("motor");
  }

  set motor(value) {
    this._setStateOnly("motor", value);
    this._setChangeOnly("motorId", value?.id);
  }

  get transmission() {
    return this._transmission;
  }

  get interpolation() {
    return this._interpolation;
  }

  get selection() {
    return this._selection;
  }
};