import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import MultiSearchPanel from "../Search/MultiSearchPanel";
import SeriesProductWizard from "./SeriesProductWizard";
import { useService } from "../../context/ServiceContext";

function SeriesProductSearch({ onProductSelect, onSeriesSelect, productSelectText, seriesSelectText, onNoResults, useGeneric }) {
  const {
    serieComercialLister,
    serieSAPLister,
    serieComercialGenericProductLister,
    serieComercialProductLister,
    serieComercialSuggester,
    serieComercialSAPSuggester,
    seriesGenericProductsSuggester,
    seriesProductsSuggester,
  } = useService();
  const intl = useIntl();

  let searchFields = [
    {
      id: "series",
      label: intl.formatMessage({
        id: "app.series",
        defaultMessage: "Series",
      }),
      value: "",
      suggester: useGeneric ? serieComercialSuggester : serieComercialSAPSuggester,
    },
    {
      id: "product_model",
      label: intl.formatMessage({
        id: "app.product",
        defaultMessage: "Product",
      }),
      value: "",
      suggester: useGeneric ? seriesGenericProductsSuggester : seriesProductsSuggester,
    },
  ];

  if (!useGeneric) {
    searchFields.push({
      id: "product_code",
      label: intl.formatMessage({
        id: "app.product_code",
        defaultMessage: "Product Code",
      }),
      value: "",
    });
  }

  const searchHandles = {
    series: {
      lister: useGeneric ? serieComercialLister : serieSAPLister,
      step: "series",
      filter: (value) => ({ name: value }),
    },
    series_id: {
      lister: serieSAPLister,
      step: "series",
      filter: (value) => ({ seriesIdCCP: value }),
    },
    series_description: {
      lister: serieSAPLister,
      step: "series",
      filter: (value) => ({ seriesDescriptionCCP: value }),
    },
    product_model: {
      lister: useGeneric ? serieComercialGenericProductLister : serieComercialProductLister,
      step: "product",
      filter: (value) => (useGeneric ? { name: value } : { productoComercial: value }),
    },
    product_code: {
      lister: serieComercialProductLister,
      step: "product",
      filter: (value) => ({ codProductoComercial: value }),
    },
  };

  const [submittedSearch, setSubmittedSearch] = useState(null);

  async function handleSearchSubmit(search) {
    if (search !== null) {
      let handle = searchHandles[search.id];
      setSubmittedSearch({
        step: handle.step,
        filter: handle.filter(search.value),
      });
    } else {
      setSubmittedSearch(null);
    }
  }

  return (
    <>
      <Row>
        <Col>
          <MultiSearchPanel fields={searchFields} onSubmit={handleSearchSubmit} isOpen={true} onToggleOpen={() => {}} />
        </Col>
      </Row>
      {submittedSearch && (
        <Row className="mt-3">
          <Col>
            <Card>
              <CardHeader>Results</CardHeader>
              <CardBody className="p-0">
                <SeriesProductWizard
                  listers={{
                    series: useGeneric ? serieComercialLister : serieSAPLister,
                    product: useGeneric ? serieComercialGenericProductLister : serieComercialProductLister,
                  }}
                  search={submittedSearch}
                  onSeriesSelect={onSeriesSelect}
                  //onSeriesSAPSelect={onSeriesSAPSelect}
                  onProductSelect={onProductSelect}
                  seriesSelectText={seriesSelectText}
                  //seriesSAPSelectText={seriesSelectText}
                  productSelectText={productSelectText}
                  onNoResults={onNoResults}
                  useGeneric={useGeneric}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default SeriesProductSearch;
