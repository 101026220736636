import React, { useEffect, useState } from "react";
import { Card, CardBody, Modal, ModalBody } from "reactstrap";
import { useService } from "../../context/ServiceContext";
import { processStructure } from "../../plates/PlateStructures";
import IconSelector from "./IconSelector";

function setBackground(image) {
  return image
    ? {
        backgroundImage: `url(https://easyventtest.solerpalau.com/images/icons/${image})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }
    : undefined;
}

function PlaqueIcons({ productData, template, reportTemplate, updateTemplate }) {
  const plateData = reportTemplate.map((rt) => processStructure(rt, template, productData));
  const iconsData = plateData.filter((pd) => pd.icon);
  const [theIcons, setTheIcons] = useState([]);
  const { plateIconsProvider } = useService();

  useEffect(() => {
    async function getIcons() {
      const icons = [];

      // Lookup icons
      let nombreFicheros = await plateIconsProvider.get({
        codModeloProducto: productData.codModeloProducto || 0,
        codModeloProductoVersion: productData.codModeloProductoVersion || 0,
        keyCompany: template.keyCompany,
        codModeloMotor: 0,
        //referenciaDocumento: iconData.referenciaDocumento,
      });

      for (let i = 0; i < iconsData.length; i++) {
        const iconData = iconsData[i];

        // Initialise new icon array with None value
        icons.push([{ name: "None", nombreFichero: "", checked: iconData.value == null }]);

        const filteredIconData = nombreFicheros.filter((i) => i.reference == iconData.referenciaDocumento);

        if (filteredIconData.length > 0) {
          filteredIconData.forEach((n) => {
            icons[i].push({ name: n.icon, nombreFichero: n.icon, checked: iconData.value == n.icon });
          });
        }
      }

      // Check if there is only one value (excluding None) if so select it by default
      if (icons.some((i) => i.length == 2)) {
        updateTemplate({
          ...template,
          icons: template.icons.map((templateIcon) => {
            const index = iconsData.findIndex((i) => i.position == templateIcon.position);
            if (icons[index]?.length == 2) {
              return { ...templateIcon, value: icons[index][1].nombreFichero };
            } else return templateIcon;
          }),
        });
      }

      setTheIcons(icons);
    }
    getIcons();
  }, []);

  console.log("icons");

  return (
    <>
      <div style={{ position: "relative" }}>
        {theIcons.length > 0 &&
          iconsData.map((pd, i) => {
            return <IconSelector key={i} icon={pd} availableIcons={theIcons[i]} template={template} productData={productData} updateTemplate={updateTemplate}></IconSelector>;
          })}
      </div>
    </>
  );
}

export default PlaqueIcons;
