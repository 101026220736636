import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, CardHeader } from "reactstrap";

function FinalCodesPanel({ product }) {
  return (
    <Card className="mt-2">
      <CardHeader>
        <FormattedMessage id="product_information.final_codes" defaultMessage="Final product(s)" />
      </CardHeader>
      <CardBody>
        <table className="table">
          <thead>
            <tr>
              <th>ModeloProducto</th>
              <th>CodProductoComercial</th>
            </tr>
          </thead>
          <tbody>
            {product.finalProductCodes.map((p) => (
              <tr key={p.codProductoComercial}>
                <td>{p.modeloProducto}</td>
                <td>{p.codProductoComercial}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
}

export default FinalCodesPanel;
