import React from "react";

const Filter = ({ column }) => {
  return (
    <div style={{marginTop: 5}}>
      { column.canFilter && column.render("Filter") }
    </div>
  );
};

export default Filter;