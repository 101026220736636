var mode = "light";
var fonts = {
  base: "Roboto",
};

var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#E51A28",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

function chartOptions() {
  var options = {
    global: {
      responsive: true,
      maintainAspectRatio: false,
      defaultColor: mode === "dark" ? colors.gray[700] : colors.gray[600],
      defaultFontColor: mode === "dark" ? colors.gray[700] : colors.gray[600],
      defaultFontFamily: fonts.base,
      defaultFontSize: 13,
      layout: {
        padding: 0,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          usePointStyle: true,
          padding: 16,
        },
      },
      elements: {
        point: {
          radius: 0,
          backgroundColor: colors.theme["primary"],
        },
        line: {
          tension: 0.4,
          borderWidth: 4,
          borderColor: colors.theme["primary"],
          backgroundColor: colors.transparent,
          borderCapStyle: "rounded",
        },
        rectangle: {
          backgroundColor: colors.theme["warning"],
        },
        arc: {
          backgroundColor: colors.theme["primary"],
          borderColor: mode === "dark" ? colors.gray[800] : colors.white,
          borderWidth: 4,
        },
      },
      tooltips: {
        enabled: true,
        mode: "index",
        intersect: false,
      },
    },
  };

  return options;
}

let chartExample1 = {
  options: {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[900],
            zeroLineColor: colors.gray[900],
          },
          ticks: {
            callback: function (value) {
              if (!(value % 10)) {
                return "$" + value + "k";
              }
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          var label = data.datasets[item.datasetIndex].label || "";
          var yLabel = item.yLabel;
          var content = "";

          if (data.datasets.length > 1) {
            content += label;
          }

          content += "$" + yLabel + "k";
          return content;
        },
      },
    },
  },
  data1: (canvas) => {
    return {
      labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Performance",
          data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
        },
      ],
    };
  },
  data2: (canvas) => {
    return {
      labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Performance",
          data: [0, 20, 5, 25, 10, 30, 15, 40, 40],
        },
      ],
    };
  },
};

export { chartOptions, chartExample1 };
