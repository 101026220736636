import React from "react";
import {
    Chart as ChartJS,
    Legend,
  } from 'chart.js';
import { Scatter } from "react-chartjs-2";
import { useIntl } from "react-intl";

const colors = [
    "rgba(94,114,228,1)",
    "rgba(251,99,64,1)",
    "rgba(45,206,137,1)",
    "rgba(17,205,239,1)",
    "rgba(255,214,0,1)",
    "rgba(220,53,69,1)",
];

ChartJS.plugins.register(Legend);

function ResidentialVentilationUnitChart({ curves, options, precision, efficiencyType, referencePoint, maximumPoint, referenceCurve }) {
    const intl = useIntl();
    const efficiencyTypeKey = efficiencyType?.toLowerCase();
    const defaults = {
        legend: {
            display: true,
            position: "right"
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: intl.formatMessage({ id: "curves_generator.airflow", defaultMessage: "Airflow" }) + " (m³/h)"
                }
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: intl.formatMessage({ id: `curves_generator.${efficiencyTypeKey}_pressure`, defaultMessage: `${efficiencyType} pressure` }) + " (Pa)"
                }
            }]
        },
        maintainAspectRatio: false
    };
    const series = [
        {
            label: intl.formatMessage({ id: "curves_generator.vuerp.maxmimum_point", defaultMessage: "Maximum point" }),
            data: [{ x: maximumPoint.airflow, y: maximumPoint.pressure }]
        },
        {
            label: intl.formatMessage({ id: "curves_generator.vuerp.reference_point", defaultMessage: "Reference point" }),
            data: [{ x: referencePoint.airflow, y: referencePoint.pressure }]
        },
        {
            label: intl.formatMessage({ id: "curves_generator.vuerp.reference_curve", defaultMessage: "Reference curve" }),
            data: referenceCurve
        }
    ].concat(curves.map(curve => ({
        label: curve.name,
        data: curve.points.map(point => ({ x: point.airflow, y: point[efficiencyTypeKey + "Pressure"] }))
    })));
    
    options = options ?? {};
    precision = precision ?? 2;
    
    defaults.tooltips = defaults.tooltips ?? {
        mode: "nearest",
        callbacks: {
          label: tooltipItem => {
            var x = `${tooltipItem.xLabel.toFixed(precision)} m³/h`;
            var y = `${tooltipItem.yLabel.toFixed(precision)} Pa`;
      
            return `(${x}, ${y})`;
          }
        }
      };

    return <>
        <Scatter
            data={{
                datasets: series.map((set, index) => ({
                    label: set.label,
                    showLine: true,
                    fill: false,
                    borderColor: colors[index],
                    backgroundColor: "rgba(75,192,192,0.4)",
                    pointBorderColor: colors[index],
                    pointBackgroundColor: colors[index],
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "rgba(75,192,192,1)",
                    pointHoverBorderColor: "rgba(220,220,220,1)",
                    pointHoverBorderWidth: 2,
                    pointRadius: 3,
                    pointHitRadius: 10,
                    data: set.data
                }))
            }}
            height={600}
            options={{ ...defaults, ...options }}
        />
    </>;
}

export default ResidentialVentilationUnitChart;