import React from "react";
import d2lIntl from "d2l-intl";
import { useConfig } from "../context/ConfigContext";
import { useCulture } from "../context/CultureContext";
import { toNumber } from "lodash";

function FormattedNumber({ value, options, defaultValue }) {
  value = toNumber(value);
  const defaultOptions = useConfig("genesis.defaults.formatting.numbers");
  const { culture } = useCulture();
  const formatter = new d2lIntl.NumberFormat(culture, { ...defaultOptions, ...(options || {}) });
  defaultValue = defaultValue || "-";
  return <>{typeof value != "number" || isNaN(value) ? defaultValue : formatter.format(value)}</>;
}

export default FormattedNumber;
