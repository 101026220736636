import React from "react";
import { Route, Switch } from "react-router-dom";
import DatosPlacaEdit from "./Edit";
import DatosPlaca from "./Home";
import DatosPlacaSummary from "./Summary";

function DatosPlacaIndex() {
  return (
    <Switch>
      <Route
        exact={true}
        path={"/datos-placa"}
        render={(props) => {
          return <DatosPlaca {...props} />;
        }}
      />
      <Route
        path={"/datos-placa/summary/:idEnsayo"}
        render={(props) => {
          return <DatosPlacaSummary {...props} />;
        }}
      />
      <Route
        path={"/datos-placa/:createMode/:codProductoComercial/:codModeloProducto?/:codModeloProductoVersion?"}
        render={(props) => {
          return <DatosPlacaEdit {...props} />;
        }}
      />
    </Switch>
  );
}

export default DatosPlacaIndex;
