import React from "react";
import ProductInputContainer from "../ProductInputContainer";

function AerodynamicParameters({ productAttributes, curve, updateCurve, disabled }) {
  const inputs = {
    ensayoAero: {
      label: "EnsayoAero",
      labelMessageId: "product_information.ensayoAero",
    },
    idEnsayo: {
      label: "idEnsayo",
      labelMessageId: "product_information.idEnsayo",
    },
    parentModeloCurva: {
      label: "ParentModeloCurva",
      labelMessageId: "product_information.parentModeloCurva",
    },
    curveId: {
      label: "CurveId",
      labelMessageId: "product_information.curveId",
    },
    datosPlaca: {
      label: "DatosPlaca",
      labelMessageId: "product_information.datosPlaca",
    },
  };

  return (
    <ProductInputContainer
      productAttributes={productAttributes}
      product={curve}
      update={updateCurve}
      inputs={inputs}
      title="product_information.aerodynamic_parameters"
      disabled={curve.existingCurve}
    ></ProductInputContainer>
  );
}

export default AerodynamicParameters;
