import React, { useState, useContext } from "react";

const UserContext = React.createContext();

function UserProvider(props) {
  const storageKey = "Genesis.User";
  const stored = JSON.parse(localStorage.getItem(storageKey));
  const [user, set] = useState(stored);

  const setUser = (user) => {
    if (user !== null) {
      localStorage.setItem(storageKey, JSON.stringify(user));
    } else {
      localStorage.removeItem(storageKey);
    }
    set(user);
  };

  return <UserContext.Provider value={{ user, setUser }} {...props} />;
}

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider.");
  }
  return context;
}

export { UserProvider, useUser };
