import React from "react";
import Spinner from "./Spinner";

function LoadedContent({ loading, overlay, children, style, className }) {
    if(overlay) {
        return <div style={{ position: "relative", ...style }} className={className}>
            {loading && (
                <div className="d-flex align-items-center" style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, backgroundColor: "#ffffff66", zIndex: 1 }}>
                <Spinner className="mx-auto" />
                </div>
            )}
            {children}
        </div>;
    } else {
        return loading ?
            <Spinner style={style} className={className} />
            : children;
    }
}

export default LoadedContent;