export default class SystemCurve {
    get points() {
        if(this._requiresCalculation) {
            let sc = this._systemConstant;
            let qmax = this._qmax;
            let points = [];

            let step = qmax / this._definition;
            for (let point = 0; point <= this._definition; point++) {
                var q = point * step;
                var p = sc * Math.pow(q, 2);
                points.push({ x: q, y: p });
            }

            this._points = points;
        }

        this._requiresCalculation = false;

        return this._points;
    }

    get systemConstant() {
        return this._systemConstant;
    }

    set systemConstant(value) {
        this._systemConstant = value;
        this._requireCalculation();
    }

    get maximumFlowRate() {
        return this._qmax;
    }

    set maximumFlowRate(value) {
        this._qmax = value;
        this._requireCalculation();
    }

    get definition() {
        return this._definition;
    }

    set definition(value) {
        this._definition = value;
        this._requireCalculation();
    }

    constructor(systemConstant, maximumFlowRate, definition) {
        this._systemConstant = systemConstant;
        this._qmax = maximumFlowRate;
        this._definition = definition;
        this._requiresCalculation = true;
    }

    _requireCalculation() {
        this._requiresCalculation = true;
    }
}

// function PressureLoss(systemConstant, qmax, pmax, points) {
//     var self = this;

//     this.systemConstant = function(value) {
//         if (!value) {
//             return self._systemConstant;
//         } else {
//             self._systemConstant = value;
//             self._calculate();
//         }
//     }

//     this.calculated = function() {
//         return self._results;
//     }

//     this.intersection = function(other) {
//         var calculated = self.calculated();
//         var xs = [];
//         var ys = [];
//         for (var i = 0; i < calculated.length; i++) {
//             xs.push(calculated[i].q);
//             ys.push(calculated[i].p);
//         }

//         var spline = new LIMSSpline(xs, ys);
//         var intersection = spline.intersection(other, 1000);
//         if (intersection.x > self._qmax || intersection.y > self._pmax) {
//             return null;
//         } else {
//             return intersection;
//         }
//     }

//     this._calculate = function() {
//         var sc = self._systemConstant;
//         var qmax = self._qmax;
//         var pmax = self._pmax;
//         var results = [];

//         // Fit curve into max pressure if pressure at max volume is greater than max pressure
//         var patqmax = sc * Math.pow(qmax, 2);
//         var qatpmax = Math.sqrt(pmax / sc, 2);
//         if (patqmax > pmax) {
//             qmax = qatpmax;
//         }

//         var step = qmax / self._points;
//         for (var point = 0; point <= self._points; point++) {
//             var q = point * step;
//             var p = sc * Math.pow(q, 2);
//             results.push({ q: q, p: p });
//         }

//         self._results = results;
//     }

//     this._systemConstant = systemConstant;
//     this._qmax = qmax;
//     this._pmax = pmax;
//     this._points = points;
//     this._results = [];
//     this._calculate();
// }