import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Col, Row } from "reactstrap";
import { useProduct } from "../../context/ProductContext";
import { useService } from "../../context/ServiceContext";
import { fireConfirm } from "../../utils/alerts";
import ListerTableSelectionModal from "../Modals/ListerTableSelectionModal";
import ProductProgress from "./ProductProgress";
import SeriesProductSearch from "./SeriesProductSearch";

function SelectSeries({ onNext, progressItems }) {
  const { cloneProduct, newProduct } = useProduct();
  const { serieComercialLister } = useService();
  const [seriesModalOpen, setSeriesModalOpen] = useState(false);
  const [selectedClonedParameters, setSelectedClonedParameters] = useState(null);
  const intl = useIntl();

  const toggleSeriesModal = () => setSeriesModalOpen(!seriesModalOpen);

  function onSeriesSelect(value) {
    newProduct(value);
    onNext();
  }

  async function onProductSelect(value) {
    fireConfirm(
      intl.formatMessage({ id: "select_series.clone_curves", defaultMessage: "Would you like to maintain the modeloGroup curves?" }),
      intl.formatMessage({ id: "select_series.clone_curves_title", defaultMessage: "Maintain curves" }),
      "question",
      intl.formatMessage({ id: "app.yes", defaultMessage: "Yes" }),
      intl.formatMessage({ id: "app.no", defaultMessage: "No" }),
      () => {
        handleCloneProduct(value, true);
      },
      () => {
        handleCloneProduct(value, false);
      }
    );
  }

  async function handleCloneProduct(value, preserveGroups) {
    fireConfirm(
      intl.formatMessage({ id: "select_series.clone_maintain_series", defaultMessage: "Would you like to maintain the same series?" }),
      intl.formatMessage({ id: "select_series.clone_maintain_series_title", defaultMessage: "Maintain series" }),
      "question",
      intl.formatMessage({ id: "app.yes", defaultMessage: "Yes" }),
      intl.formatMessage({ id: "app.no", defaultMessage: "No" }),
      () => {
        handleCloneProductSeries(value, preserveGroups, true);
      },
      () => {
        handleCloneProductSeries(value, preserveGroups, false);
      }
    );
  }

  async function handleCloneProductSeries(value, preserveGroups, preserveSeries) {
    if (!preserveSeries) {
      // Show series selector
      toggleSeriesModal();
      setSelectedClonedParameters({ id: value.id, seriesId: value.seriesId, preserveGroups: preserveGroups });
    } else {
      await cloneProduct(value.id, value.seriesId, value.version, preserveGroups);
      onNext();
    }
  }

  async function handleSeriesSelection(choice) {
    if (choice) {
      await cloneProduct(selectedClonedParameters.id, selectedClonedParameters.seriesId, selectedClonedParameters.version, selectedClonedParameters.preserveGroups, choice);
      toggleSeriesModal();
      onNext();
    } else {
      toggleSeriesModal();
    }
  }

  return (
    <Row>
      <Col xl={3} className="d-sm-none d-xl-block">
        <ProductProgress onNext={onNext} items={progressItems}></ProductProgress>
      </Col>
      <Col xl={9}>
        <SeriesProductSearch
          useGeneric={true}
          onSeriesSelect={onSeriesSelect}
          onProductSelect={onProductSelect}
          productSelectText="Clone"
          seriesSelectText={intl.formatMessage({
            id: "app.select",
            defaultMessage: "Select",
          })}
        ></SeriesProductSearch>
        <ListerTableSelectionModal
          title={intl.formatMessage({ id: "product_information.select_a_series", defaultMessage: "Select a series" })}
          columns={[
            { Header: intl.formatMessage({ id: "curves_generator.series_name", defaultMessage: "Series name" }), accessor: "internalName" },
            { Header: intl.formatMessage({ id: "curves_generator.series_short_name", defaultMessage: "Series short name" }), accessor: "name" },
          ]}
          isOpen={seriesModalOpen}
          toggle={toggleSeriesModal}
          lister={serieComercialLister}
          onSelect={handleSeriesSelection}
        />
      </Col>
    </Row>
  );
}

export default SelectSeries;
