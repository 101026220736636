import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, Row } from "reactstrap";
import CertificateSeriesProductSearch from "./CertificateSeriesProductSearch";
import { useService } from "../../context/ServiceContext";

function CertificateProductSelection() {
  const intl = useIntl();
  const { certificateStore } = useService();

  const [dopName, setDoPName] = useState("");

  async function saveCertificate(dataPackage) {
    // Validate

    // Send to server

    const formData = new FormData();
    dataPackage.files.forEach((file) => {
      formData.append("Files", file);
    });
    formData.append("Name", dopName);
    formData.append("Products", JSON.stringify(dataPackage.products));
    formData.append("Legislations", JSON.stringify(dataPackage.legislations));
    formData.append("Regulations", JSON.stringify(dataPackage.regulations));

    await certificateStore.store(formData, { "Content-Type": "multipart/form-data" });
  }

  return (
    <>
      <Row>
        <Col sm="6">
          <h3 className="card-title">
            <FormattedMessage id="certificates.declaration_of_performance" defaultMessage="Create a declaration of Performance" />
          </h3>
          <p className="card-text">
            <FormattedMessage id="certificates.description" defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, ut eget molestie velit." />
          </p>
        </Col>
        <Col sm="6">
          <div className="form-group row">
            <label htmlFor="dopName" className="col-sm-2 col-form-label">
              DoP Name
            </label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="dopName" onKeyUp={(e) => setDoPName(e.target.value)} />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <CertificateSeriesProductSearch useGeneric={true} saveCertificate={saveCertificate}></CertificateSeriesProductSearch>
        </Col>
      </Row>
    </>
  );
}

export default CertificateProductSelection;
