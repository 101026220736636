import React from "react";
import { Route, Switch } from "react-router-dom";
import LaboratoryTests from "./Home";

function LaboratoryTestsIndex() {
  return (
    <Switch>
      <Route
        exact={true}
        path={"/laboratory-tests"}
        render={(props) => {
          return <LaboratoryTests {...props} />;
        }}
      />
    </Switch>
  );
}

export default LaboratoryTestsIndex;
