import React from "react";

function ErrorSummary({ errors, groupErrors, curveErrors, intl }) {
  const ignore = ["groups", "curves", "hasErrors", "name"];
  const groupIgnore = ["index", "curves", "name"];
  const errorKeys = Object.keys(errors || groupErrors || curveErrors).filter((k) => !ignore.includes(k));

  function renderGroupsCurves(collection, prepend, oneBasedIndex) {
    return (
      <li>
        <ul>
          {collection.map((g, j) => (
            <li key={j}>
              <b>
                {prepend} {g.index + (oneBasedIndex ? 0 : 1)} {g.name ? "- (" + g.name + ")" : ""}
              </b>{" "}
              <ul>
                {Object.keys(g)
                  .filter((gk) => !groupIgnore.includes(gk))
                  .map((e, i) => (
                    <li key={i}>
                      <b>{intl.formatMessage(g[e].formattedMessage)}</b>
                      <span className="badge text-danger text-wrap text-left">{intl.formatMessage(g[e].errorFormattedMessage)}</span>
                    </li>
                  ))}
              </ul>
              <ul className="list-unstyled">{g.curves && renderGroupsCurves(g.curves, intl.formatMessage({ id: "errors_summary.curve", defaultMessage: "Curve" }), true)}</ul>
            </li>
          ))}
        </ul>
      </li>
    );
  }

  return (
    <div className="text-left">
      {errors && <b>{intl.formatMessage({ id: "errors_summary.product", defaultMessage: "Product" })}</b>}
      {groupErrors && <b>{intl.formatMessage({ id: "errors_summary.group", defaultMessage: "Group" })}</b>}
      {curveErrors && <b>{intl.formatMessage({ id: "errors_summary.curve", defaultMessage: "Curve" })}</b>}
      <ul className="list-unstyled">
        <li>
          <ul>
            {errorKeys.map((e, i) => (
              <li key={i}>
                <b>{intl.formatMessage((errors || groupErrors || curveErrors)[e].formattedMessage)}</b>
                <span className="badge text-danger">{intl.formatMessage((errors || groupErrors || curveErrors)[e].errorFormattedMessage)}</span>
              </li>
            ))}
          </ul>
        </li>
        <hr className="my-2"></hr>
        {errors?.groups && renderGroupsCurves(errors.groups, intl.formatMessage({ id: "errors_summary.group", defaultMessage: "Group" }))}
        {groupErrors?.curves && renderGroupsCurves(groupErrors.curves, intl.formatMessage({ id: "errors_summary.curve", defaultMessage: "Curve" }))}
      </ul>
    </div>
  );
}

export default ErrorSummary;
