import axios from "axios";

class HttpLister {
  constructor(http, path) {
    this._http = http;
    this._path = path;
    this._cancelSource = null;
  }

  async getList(filters, pageNumber, pageSize) {
    if(pageNumber < 1) {
      throw new Error("`pageNumber` must be positive.")
    }

    if(pageSize < 1) {
      throw new Error("`pageSize` must be positive.")
    }

    if(this._cancelSource)
    {
      this._cancelSource.cancel();
    }

    this._cancelSource = axios.CancelToken.source();

    let response = await this._http.request({
      method: "get",
      url: this._path,
      params: {
        filters: filters.filter(f => f),
        page: {
          pageNumber,
          pageSize
        }
      },
      cancelToken: this._cancelSource.token
    });

    return {
      items: response.data.items,
      page: {
        pageNumber,
        isLastPage: response.data.items.length < pageSize
      },
      total: response.data.total
    };
  }
}

export default HttpLister;