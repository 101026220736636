import React from "react";
import ProductInputContainer from "../ProductInputContainer";

function RegulationParameters({ productAttributes, curve, updateCurve, disabled }) {
  const inputs = {
    normaUL: {
      label: "NormaUL",
      labelMessageId: "product_information.normaUL",
    },
  };

  return (
    <ProductInputContainer
      productAttributes={productAttributes}
      product={curve}
      update={updateCurve}
      inputs={inputs}
      title="product_information.regulations"
      disabled={disabled}
    ></ProductInputContainer>
  );
}

export default RegulationParameters;
