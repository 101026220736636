import eventify from "../eventify";
import trackify from "../trackify";

export default class TransmissionOptions {
    constructor(state, changes) {
        trackify(this, state, changes);
        eventify(this);    
    }

    get state() {
        return this._state;
    }

    get changes() {
        return this._changes;
    }

    get isEnabled() {
        return this._get("isEnabled");
    }

    set isEnabled(value) {
        this._set("isEnabled", value);
        this.fire("change");
    }

    get normalisation() {
        return this._get("normalisation");
    }

    set normalisation(value) {
        this._set("normalisation", value);
        this.fire("change");
    }
}