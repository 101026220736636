class HttpAuthorizationStore {
  constructor(http, cache) {
    this.http = http;
    this.cache = cache;
    this.storageKey = "Genesis.Authorization";
    this.headerKey = "Authorization";
    this.http.defaults.headers.common[this.headerKey] = `Bearer ${this.cache.getString(this.storageKey)}`;
  }

  setAuthorization(token) {
    if (token !== undefined && token !== null) {
      this.http.defaults.headers.common[this.headerKey] = `Bearer ${token}`;
      this.cache.setString(this.storageKey, token);
    } else if(token === null) {
      delete this.http.defaults.headers.common[this.headerKey];
      this.cache.setString(this.storageKey, null);
    }
  }
}

export default HttpAuthorizationStore;