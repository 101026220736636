import React from "react";
import { ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import CustomList from "./CustomList";

function SpeedsStep({ next, prev, availableSpeeds, speeds, setSpeeds, customSpeeds, setCustomSpeeds }) {
  return (
    <ValidationForm onSubmit={next}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.speeds" defaultMessage="Speeds" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage id="product_wizard.choose_group_of_speeds" defaultMessage="Choose the group of speeds that you use to distinguish your curves:" />
          </p>
          <div style={{ display: "inline-block", minWidth: 500 }}>
            <CustomList
              list={availableSpeeds}
              values={speeds}
              setValues={setSpeeds}
              customValues={customSpeeds}
              setCustomValues={setCustomSpeeds}
              addButtonText="product_wizard.speeds_personalized"
              examplePlaceholder="Example: Vlow"
            ></CustomList>
          </div>
        </CardBody>
        <CardFooter>
          <Button className="btn btn-dark float-right">
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default SpeedsStep;
