import eventify from "../eventify";
import trackify from "../trackify";
import CubicSplineCurveInterpolator, { StepGenerator } from "./CubicSplineCurveInterpolator";
import CatmullRomCurveInterpolator from "./CatmullRomCurveInterpolator";
import { MultiAxisSpline } from "./MultiAxisSpline";
import { CatmullRomAdaptor, CubicSplineAdaptor } from "./Spline";

export default class ConfiguredSelectionCurve {
    constructor({ data, mappers, variable, columns, config, state, changes }) {
        const self = this;

        trackify(this, state, null);
        eventify(this);

        this._data = data;
        this._config = config;
        this._variable = variable;
        this._columns = columns;
        this._generator = new StepGenerator({
            min: state?.settings?.min?.airflow || 0,
            max: state?.settings?.max?.airflow || 0
        });
        this._settingsChanges = changes;

        this._handleSettingsChange = () => {
            const sender = self.settings;

            if (this._isInterplationDisabledPrevious !== sender.isInterpolationDisabled) {
                self._respline(sender.isInterpolationDisabled);
                self._resetSelectionLimits();
            }
        }

        this._handleLimitChange = (limit) => {
            return () => {
                const sender = self.settings;
                
                if (sender.isInterpolationDisabled) {
                    self._interpolator[limit] = sender[limit].airflow;
                } else {
                    self._generator[limit] = sender[limit].airflow;
                }
                
                self._recalculate();
            };
        };

        this._curve = { mappers: Object.values(mappers) };
        eventify(this._curve);
        this._respline(this._isInterplationDisabledPrevious = state?.settings?.isInterpolationDisabled);
        this._ignoreSettingsChangeEvents = false;

        for(let mapper of this._curve.mappers) {
            if(mapper.on) {
                mapper.on("change", () => self._recalculate());
            }
        }
    }

    _respline(isInterpolationDisabled) {
        const smoothingFactory = isInterpolationDisabled ? (xs, ys) => new CatmullRomAdaptor(xs, ys) : (xs, ys) => new CubicSplineAdaptor(xs, ys);
        this._curve.spline = new MultiAxisSpline({ 
            data: this._data,
            variable: this._variable,
            columns: this._columns,
            smoothingFactory
        });
        
        this._curve.fire("change");

        if (!this._settings) {
            this._settings = new CurveSelectionSettings({
                state: this._state?.settings,
                changes: this.settings?._changes ?? this._settingsChanges,
                curve:  this._curve,
                mappers: this._curve.mappers
            });
            
            this._settings.on("change", this._handleSettingsChange);
            this._settings.min.on("change", this._handleLimitChange("min"));
            this._settings.max.on("change", this._handleLimitChange("max"));
        }
        
        if(!isInterpolationDisabled) {
            this._interpolator = new CubicSplineCurveInterpolator({
                data: this._data,
                variable: this._variable,
                columns: this._columns,
                generator: this._generator,
                mappers: this._curve.mappers
            });
        } else {
            this._interpolator = new CatmullRomCurveInterpolator({
                data: this._data,
                variable: this._variable,
                columns: this._columns,
                mappers: this._curve.mappers,
                min: this.settings.min.airflow,
                max: this.settings.max.airflow
            });
        }
        
        this._isInterplationDisabledPrevious = isInterpolationDisabled;
    }

    _recalculate() {
        const interpolated = this._interpolator.interpolate({
            points: this._data.length <= 1 ? this._data.length : this._config.points
        });
        
        if(interpolated.length && interpolated[0][this._variable] < interpolated[interpolated.length - 1][this._variable]) {
            interpolated.reverse();
        }

        this._setStateOnly("points", interpolated);
        this.fire("change");
    }

    _resetSelectionLimits() {
        const airflows = this._data.map(p => p.airflow);
        
        // Always update the selection limits according to the inputs
        this.settings.min.airflow = airflows.length && Math.min.apply(Math, airflows);
        this.settings.max.airflow = airflows.length && Math.max.apply(Math, airflows);
    }

    get state() {
        return { ...this._state, settings: this.settings.state };
    }

    get changes() {
        return { ...this.settings.changes };
    }

    get settings() {
        return this._settings;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._interpolator.data = this._data = value;
        this._respline(this.state?.settings?.isInterpolationDisabled);
        this._resetSelectionLimits();
        this._recalculate();
    }

    get points() {
        return this._get("points");
    }
}

export class CurveSelectionSettings {
    constructor({ state, changes, curve }) {
        trackify(this, state, changes);
        eventify(this);

        this._min = new CurveSelectionLimit({ curve, state: state?.min, changes: this._changes?.min });
        this._max = new CurveSelectionLimit({ curve, state: state?.max, changes: this._changes?.max });
    }

    get state() {
        return { ...this._state, min: this.min.state, max: this.max.state };
    }

    get changes() {
        return { ...this._changes, min: this.min.changes, max: this.max.changes };
    }

    get min() {
        return this._min;
    }

    get max() {
        return this._max;
    }

    get isInterpolationDisabled() {
        return this._get("isInterpolationDisabled");
    }

    set isInterpolationDisabled(value) {
        this._set("isInterpolationDisabled", value);
        this.fire("change");
    }
}

export class CurveSelectionLimit {
    constructor({ curve, state, changes }) {
        const self = this;

        trackify(this, state, changes);
        eventify(this);

        this._curve = curve;
        this._findOptimalPoint = () => {
            return self._map(self._curve.spline.maxima(point => { 
                let efficiency = self._map(point).inputStaticEfficiency;
    
                if (efficiency < 0) {
                    return 0;
                } else {
                    return efficiency;
                }
            }, 2000));
        }
        this._curve.on("change", () => {
            self._optimalPoint = self._findOptimalPoint();
            self.airflow = self.airflow;
        });
        this._optimalPoint = this._findOptimalPoint();
        this._setStateOnly("efficiencyPercentage", this.efficiencyPercentage);
    }

    _map(point) {
        return this._curve.mappers.reduce((p, m) => m.map({ value: p }), point)
    }

    get state() {
        return this._state;
    }

    get point() {
        return this._state;
    }

    get changes() {
        return this._changes;
    }

    get spline() {
        return this._curve.spline;
    }

    get airflow() {
        return this._get("airflow");
    }

    set airflow(value) {
        this._set(this._map(this._curve.spline.at(value)));
        this._set("airflow", value);
        this._setStateOnly("efficiencyPercentage", this.efficiencyPercentage);
        this.fire("change");
    }

    get staticPressure() {
        return this._get("staticPressure");
    }

    set staticPressure(value) {
        this._set(this._map(this._curve.spline.find("staticPressure", value)));
        this._setStateOnly("staticPressure", value);
        this._setStateOnly("efficiencyPercentage", this.efficiencyPercentage);
        this._setChangeOnly("airflow", this._get("airflow"));

        this.fire("change");
    }

    get efficiencyPercentage() {
        let optimalEfficiency = this._optimalPoint.inputStaticEfficiency;
        let currentEfficiency = this._state?.inputStaticEfficiency;
        if(optimalEfficiency === 0) {
            return 0;
        } else {
            return currentEfficiency / optimalEfficiency * 100;
        }
    }

    set efficiencyPercentage(value) {
        let inputStaticEfficiency = value / 100 * this._optimalPoint.inputStaticEfficiency;
        this._set(this._map(this._curve.spline.find(p => this._map(p).inputStaticEfficiency, inputStaticEfficiency)));
        this._setStateOnly("efficiencyPercentage", value);
        this._setChangeOnly("airflow", this._get("airflow"));
    
        this.fire("change");
    }

    get totalPressure() {
        return this._get("totalPressure");
    }

    get eletricalCurrent() {
        return this._get("eletricalCurrent");
    }

    get inputPower() {
        return this._get("inputPower");
    }

    get speed() {
        return this._get("speed");
    }

    get specificFanPower() {
        return this._get("specificFanPower");
    }

    get inputStaticEfficiency() {
        return this._get("inputStaticEfficiency");
    }
}