import React from "react";
import { useProduct } from "../../../context/ProductContext";
import ProductInputContainer from "../ProductInputContainer";

function OtherParameters({ productAttributes, curve, updateCurve, disabled }) {
  const { isWizard } = useProduct();
  let customSpeeds = [];
  if (isWizard) {
    // Check to see if any custom speeds
    const speeds = sessionStorage.getItem("Genesis.ProductWizard.CustomSpeeds");
    if (speeds) {
      customSpeeds = JSON.parse(speeds);
    }
  }

  const inputs = {
    activo: {
      label: "activo",
      labelMessageId: "product_information.activo",
    },
    posVelocidad: {
      label: "Pos velocidad",
      labelMessageId: "product_information.posVelocidad",
      customOptions: customSpeeds.map((s) => {
        return { id: s.name, value: s.name };
      }),
    },
    velocidad: {
      label: "velocidad",
      labelMessageId: "product_information.velocidad",
    },
    especial: {
      label: "especial",
      labelMessageId: "product_information.especial",
    },
    palet: {
      label: "palet",
      labelMessageId: "product_information.palet",
    },
    flujoAire: {
      label: "flujoAire",
      labelMessageId: "product_information.flujoAire",
    },
  };

  return (
    <ProductInputContainer
      productAttributes={productAttributes}
      product={curve}
      update={updateCurve}
      inputs={inputs}
      title="product_information.other_parameters"
      disabled={disabled}
    ></ProductInputContainer>
  );
}

export default OtherParameters;
