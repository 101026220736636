import paths from "../paths";

export default class NonResidentialErPService {
    constructor(http) {
        this._http = http;
    }

    async getErPKit(name) {
        let response = await this._http.request({
            method: "get",
            url: `${paths.NRVU_PATH}/${encodeURIComponent(name)}`
        });

        return response.data;
    }

    async getCurveTest(id) {
        let response = await this._http.request({
            method: "get",
            url: `${paths.NRVU_PATH}/curve/test/${id}`
        });

        return response.data;
    }

    async storeErPKit({ name, inputs }) {
        await this._http.request({
            method: "post",
            url: `${paths.NRVU_PATH}/${encodeURIComponent(name)}`,
            data: inputs
        });
    }
}