import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useService } from "../../context/ServiceContext";
import ListerTableSelectionModal from "../Modals/ListerTableSelectionModal";
import DateCell from "../Tables/DateCell";
import DateRangeColumnFilter from "../Tables/DateRangeColumnFilter";
import TrueFalseCell from "../Tables/TrueFalseCell";
import TrueFalseColumnFilter from "../Tables/TrueFalseColumnFilter";
import CurveSearch from "./CurveSearch";

function CurveTestSearchByCurve({ onCurveTestSelect }) {
    const intl = useIntl();
    const { curveTestLister } = useService();
    const [selectedCurve, setSelectedCurve] = useState(null);
    const [curveTestModalOpen, setCurveTestModalOpen] = useState(false);

    const toggleCurveTestModal = () => setCurveTestModalOpen(!curveTestModalOpen);

    function handleCurveSelection(curve) {
        setSelectedCurve(curve);
        toggleCurveTestModal();
    }

    function renderRow(row) {
        const className = row.original.isPublished ? "" : "bg-light";

        return <tr {...row.getRowProps()} className={className}>
            {row.cells.map(cell => <td {...cell.getCellProps()} >{cell.render("Cell")}</td>)}
        </tr>;
    }

    function renderFooter() {
        return <p><strong>Key:</strong> <div class="d-inline-block border border-dark p-2 bg-white"></div> Published, <div class="d-inline-block border border-dark p-2 bg-light"></div> Unpublished</p>;
    }

    return <>
        <CurveSearch
            onCurveSelect={handleCurveSelection} />
        
        <ListerTableSelectionModal
            title={intl.formatMessage({ id: "curves_generator.select_curve_test", defaultMessage: "Select a curve test" })}
            columns={[
                { Header: intl.formatMessage({ id: "curve_tests.curve_test_id", defaultMessage: "ID ensayo" }), accessor: "curveTestId" },
                { Header: intl.formatMessage({ id: "curve_tests.lab_test_number", defaultMessage: "Lab test num." }), accessor: "labTestNumber" },
                { Header: intl.formatMessage({ id: "curve_tests.efficiency_type", defaultMessage: "Eff. type" }), accessor: "efficiencyType" },
                { Header: intl.formatMessage({ id: "curve_tests.installation_type", defaultMessage: "Install. type" }), accessor: "installationType" },
                { Header: intl.formatMessage({ id: "curve_tests.motor_code", defaultMessage: "Motor code" }), accessor: "motorCode" },
                { Header: intl.formatMessage({ id: "curve_tests.valid_to", defaultMessage: "Valid to" }), accessor: "validTo", Cell: DateCell, Filter: DateRangeColumnFilter },
                { Header: intl.formatMessage({ id: "curve_tests.category", defaultMessage: "Cat." }), accessor: "category" },
                { Header: intl.formatMessage({ id: "curve_tests.maximum_efficiency", defaultMessage: "Max eff." }), accessor: "maximumEfficiency" },
                { Header: intl.formatMessage({ id: "curve_tests.norma", defaultMessage: "Norma" }), accessor: "norma" },
                { Header: intl.formatMessage({ id: "curve_tests.updated_on", defaultMessage: "Updated on" }), accessor: "updatedAt", Cell: DateCell, Filter: DateRangeColumnFilter },
                { Header: intl.formatMessage({ id: "curve_tests.motor_power", defaultMessage: "Pot. motor" }), accessor: "motorPower" },
                { Header: intl.formatMessage({ id: "curve_tests.motor_efficiency", defaultMessage: "Eff. motor" }), accessor: "motorEfficiency" }
                
            ]}
            isOpen={curveTestModalOpen}
            toggle={toggleCurveTestModal}
            lister={curveTestLister}
            prefilter={{ curveId: selectedCurve?.id }}
            onSelect={curveTest => onCurveTestSelect(curveTest.curveTestId)}
            className="mh-100"
            renderRow={renderRow}
            renderFooter={renderFooter} />
    </>;
}

export default CurveTestSearchByCurve;