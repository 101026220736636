import React from "react";
import { Container, Row, Col, Card, CardBody, CardDeck, CardFooter, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";
import CurveTestSearch from "../../components/CurvesGenerator/CurveTestSearch";
import CurveTestSearchByCurve from "../../components/CurvesGenerator/CurveTestSearchByCurve";
import { useUser } from "../../context/UserContext";
import roles from "../../identity/roles";
import { useConfig } from "../../context/ConfigContext";

function CurvesGenerator(props) {
  const { user } = useUser();
  const features = useConfig("genesis.features.gdec") ?? {};

  console.log(features);

  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="curves_generator.start.title" defaultMessage="What would you like to create in GdeC?" />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col>
            <CardDeck>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h3 className="card-title">
                        <FormattedMessage id="curves_generator.new_curve_test.title" defaultMessage="New idEnsayo" />
                      </h3>
                      <p className="card-text">
                        <FormattedMessage id="curves_generator.new_curve_test.description" defaultMessage="Click the button below if you wish to create a new idEnsayo" />
                      </p>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Link to="/curves-generator/new" className="btn btn-dark">
                    <FormattedMessage id="app.proceed" defaultMessage="Proceed" />
                  </Link>
                </CardFooter>
              </Card>

              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h3 className="card-title">
                        <FormattedMessage id="curves_generator.acoustic.title" defaultMessage="New acoustic calculation" />
                      </h3>
                      <p className="card-text">
                        <FormattedMessage id="curves_generator.acoustic.description" defaultMessage="Click the button below to perform a new acoustic calculation" />
                      </p>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  { !features.acoustic &&
                    <Button className="btn btn-dark" disabled>
                      <FormattedMessage id="app.coming_soon" defaultMessage="Coming soon" />
                    </Button>
                  }

                  { features.acoustic && 
                    <Link to="/curves-generator/acoustic" className="btn btn-dark">
                      <FormattedMessage id="app.proceed" defaultMessage="Proceed" />
                    </Link>
                  }
                </CardFooter>
              </Card>

              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h3 className="card-title">
                        <FormattedMessage id="curves_generator.erp_data.title" defaultMessage="ErP data" />
                      </h3>
                      <p className="card-text">
                        <FormattedMessage
                          id="curves_generator.erp_data.description"
                          defaultMessage="Click the button below if you would like to define ErP data for an existing product"
                        />
                      </p>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Link to="/curves-generator/erp" className="btn btn-dark">
                    <FormattedMessage id="app.proceed" defaultMessage="Proceed" />
                  </Link>
                </CardFooter>
              </Card>

              {user.role === roles.SystemAdministrator &&
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <h3 className="card-title">
                          <FormattedMessage id="curves_generator.bulk_dependents_generator" defaultMessage="Bulk dependents generator" />
                        </h3>
                        <p className="card-text">
                          <FormattedMessage
                            id="curves_generator.bulk_dependents_generator.description"
                            defaultMessage="Click the button below to generate queued dependents in bulk"
                          />
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Link to="/curves-generator/dependents/bulk" className="btn btn-dark">
                      <FormattedMessage id="app.proceed" defaultMessage="Proceed" />
                    </Link>
                  </CardFooter>
                </Card>
              }
            </CardDeck>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="my-4">
              <FormattedMessage id="curves_generator.edit_view.title" defaultMessage="View / edit an idEnsayo in GdeC?" />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="card-title">
                      <FormattedMessage id="curves_generator.open_curve_test.title" defaultMessage="Edit an existing idEnsayo" />
                    </h3>
                    <p className="card-text">
                      <FormattedMessage id="curves_generator.open_curve_test.description" defaultMessage="If you would like to open a idEnsayo, please use the search below." />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    <CurveTestSearch onCurveTestSelect={(id) => props.history.push(`/curves-generator/viewer/${id}/summary`)}></CurveTestSearch>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="card-title">
                      <FormattedMessage id="curves_generator.open_by_curve.title" defaultMessage="Edit an existing idEnsayo by the associated curve" />
                    </h3>
                    <p className="card-text">
                      <FormattedMessage id="curves_generator.open_by_curve.description" defaultMessage="If you would like to open an idEnsayo, please search for a curve below." />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    <CurveTestSearchByCurve onCurveTestSelect={(id) => props.history.push(`/curves-generator/viewer/${id}/summary`)}></CurveTestSearchByCurve>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CurvesGenerator;
