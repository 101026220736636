import React from "react";
import { Container } from "reactstrap";

const Header = ({ children }) => (
  <div className="header pb-3 pt-3 pt-md-7">
    <Container fluid>
      <div className="header-body">{children}</div>
    </Container>
  </div>
);

export default Header;
