import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { defaults } from "react-chartjs-2";
import merge from "lodash.merge";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "./assets/vendor/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/styles.scss";
import "./assets/css/autosuggest.css";
import "./assets/css/custom.css";

import AppProviders from "./context/AppProviders";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { chartOptions } from "./variables/charts";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

// hide translation errors
const consoleError = console.error.bind(console);
let missingLanguages = {};
// eslint-disable-next-line
console.error = (error, ...args) => {
  if (
    typeof error.message === 'string' &&
    error.message.includes('@formatjs/intl')
  ) {
    if (error.descriptor) missingLanguages[error.descriptor.id] = error.descriptor.defaultMessage;
    return;
  }
  consoleError(error, ...args);
};

window.getMissingLanguages = () => console.log(JSON.stringify(missingLanguages));

// set defaults up for charting
merge(defaults, chartOptions());

ReactDOM.render(
  <AppProviders>
    <BrowserRouter basename={baseUrl}>
      <Switch>
        <Route from="*" render={(props) => <App {...props} />} />
      </Switch>
    </BrowserRouter>
  </AppProviders>,
  rootElement
);

registerServiceWorker();
