import React from "react";
import { lookupValueInAttributeOptionsById } from "../../../products/ProductDefinitions";
import ProductInputContainer from "../ProductInputContainer";

function GroupElectricalParameters({ groupAttributes, group, updateGroup, disabled }) {
  const inputs = {
    codTension: {
      label: "CodTension",
      labelMessageId: "product_information.tension",
      update: updateCodTension,
    },
  };

  function updateCodTension(e) {
    updateGroup({
      ...group,
      curves: group.curves.map((c) => {
        return { ...c, codTension: e.target.value, claseTension: e.target.value };
      }),
      codTension: e.target.value,
      tension: lookupValueInAttributeOptionsById(groupAttributes, "codTension", e.target.value, "voltage"),
      codFrecuencia: lookupValueInAttributeOptionsById(groupAttributes, "codTension", e.target.value, "frequencyCode"),
    });
  }

  return (
    <ProductInputContainer
      productAttributes={groupAttributes}
      product={group}
      inputs={inputs}
      title="product_information.electrical_parameters"
      disabled={disabled}
    ></ProductInputContainer>
  );
}

export default GroupElectricalParameters;
