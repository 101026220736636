import { checkForRequiredAttributes, getGroupName, replaceTags } from "./ProductDefinitions";

export const setErrorType = (messageId, defaultMessage) => {
  return {
    errorFormattedMessage: {
      id: messageId,
      defaultMessage: defaultMessage || messageId,
    },
  };
};

export async function modelExists(model, existenceProvider) {
  const modelCheck = await existenceProvider.get(model);
  return modelCheck.exists;
}

export async function getProductErrors(product, productTechnicalAttributesProvider, productExistenceProvider, excludeHasErrors) {
  let errors = {};

  if (!product.conceptClassUnic) {
    errors.conceptClassUnic = { ...setErrorType("error.missing_attribute"), formattedMessage: { id: "attribute.ConceptClassUnic", defaultMessage: "conceptClassUnic" } };
  }

  // check if modelo producto already exists for new products
  if (product.id.value === 0 && product.modeloProductoGenerico) {
    if (await modelExists(product.modeloProductoGenerico, productExistenceProvider)) {
      errors.modeloProductoGenerico = {
        ...setErrorType("error.already_taken_attribute"),
        formattedMessage: { id: "attribute.ModeloProductoGenerico", defaultMessage: "modeloProductoGenerico" },
      };
    }
  }

  // check product level attributes
  await checkForRequiredAttributes(productTechnicalAttributesProvider, product.conceptClassUnic, product, errors);

  if (!excludeHasErrors) errors.hasErrors = Object.keys(errors).length > 0;

  return errors;
}

export async function getGroupErrors(group, productCurveGroupAttributesProvider, grupoCurveExistenceProvider, excludeHasErrors) {
  let groupErrors = {};

  // check for required category class
  if (!group.conceptClassUnic) {
    groupErrors.conceptClassUnic = { ...setErrorType("error.missing_attribute"), formattedMessage: { id: "attribute.ConceptClassUnic", defaultMessage: "conceptClassUnic" } };
  }

  // check if modelo curva already exists for new curves
  if ((!group.id || group.id === 0) && group.modeloGrupoCurva) {
    if ((await modelExists(group.modeloGrupoCurva, grupoCurveExistenceProvider)) && group.conceptClassUnic != "aa677f76-9e7c-4d16-9046-1a690e1437e9") {
      groupErrors.modeloGrupoCurva = {
        ...setErrorType("error.already_taken_attribute"),
        formattedMessage: { id: "attribute.ModeloGrupoCurva", defaultMessage: "modeloGrupoCurva" },
      };
    }
  }

  // check group level attributes
  await checkForRequiredAttributes(productCurveGroupAttributesProvider, group.conceptClassUnic, group, groupErrors);

  if (!excludeHasErrors) groupErrors.hasErrors = Object.keys(groupErrors).length > 0;

  groupErrors.name = await getGroupName(group, productCurveGroupAttributesProvider, group.conceptClassUnic);

  return groupErrors;
}

export async function getCurveErrors(curve, parentGroup, productCurveAttributesProvider, curveExistenceProvider, excludeHasErrors) {
  let curveErrors = {};

  // check if modelo curva already exists for new curves
  if ((!curve.id || curve.id === 0) && curve.modeloCurva) {
    if ((await modelExists(curve.modeloCurva, curveExistenceProvider)) && parentGroup.conceptClassUnic != "aa677f76-9e7c-4d16-9046-1a690e1437e9") {
      curveErrors.modeloCurva = { ...setErrorType("error.already_taken_attribute"), formattedMessage: { id: "attribute.ModeloCurva", defaultMessage: "modeloCurva" } };
    }
  }

  // check curve level attributes
  await checkForRequiredAttributes(productCurveAttributesProvider, parentGroup.conceptClassUnic, curve, curveErrors);

  if (!excludeHasErrors) curveErrors.hasErrors = Object.keys(curveErrors).length > 0;

  curveErrors.name = curve.etiqueta;

  return curveErrors;
}

export async function getGroupAndCurvesErrors(
  group,
  productCurveGroupAttributesProvider,
  productCurveAttributesProvider,
  curveExistenceProvider,
  grupoCurveExistenceProvider,
  excludeHasErrors,
  product
) {
  let groupErrors = await getGroupErrors(group, productCurveGroupAttributesProvider, grupoCurveExistenceProvider, true);

  let curveGroups = product?.curveGroups || [group];

  // modeloCurva
  if (
    group.modeloGrupoCurva &&
    curveGroups.some((cg) => cg.index !== group.index && cg.modeloGrupoCurva && group.modeloGrupoCurva && cg.modeloGrupoCurva.toUpperCase() == group.modeloGrupoCurva.toUpperCase())
  ) {
    groupErrors.modeloGrupoCurva = { ...setErrorType("error.duplicate_attribute"), formattedMessage: { id: "attribute.ModeloGrupoCurva", defaultMessage: "modeloGrupoCurva" } };
  }

  // Check for duplicate FilterPrincipalMG
  if (
    group.filterPrincipalMG?.toLowerCase() != "none" &&
    group.filterPrincipalMG &&
    curveGroups.some((cg) => cg.index !== group.index && cg.filterPrincipalMG && cg.filterPrincipalMG.toUpperCase() == group.filterPrincipalMG.toUpperCase())
  ) {
    groupErrors.filterPrincipalMG = { ...setErrorType("error.duplicate_attribute"), formattedMessage: { id: "attribute.FilterPrincipalMG", defaultMessage: "FilterPrincipalMG" } };
  }

  if (group.filterPrincipalMG?.toLowerCase() == "none" && curveGroups.length > 1) {
    groupErrors.filterPrincipalMG = {
      ...setErrorType("error.invalid_none_attribute"),
      formattedMessage: { id: "attribute.FilterPrincipalMG", defaultMessage: "FilterPrincipalMG" },
    };
  }

  let curves = [];

  for (let c = 0; c < group.curves.length; c++) {
    const curve = group.curves[c];
    let curveErrors = await getCurveErrors(curve, group, productCurveAttributesProvider, curveExistenceProvider, true);

    // modeloCurva

    var modeloCurvaCheck = curveGroups.map((cg) =>
      cg.curves.some((c) => c !== curve && c.modeloCurva && curve.modeloCurva && c.modeloCurva.toUpperCase() == curve.modeloCurva.toUpperCase())
    );

    if (curve.modeloCurva && modeloCurvaCheck.includes(true)) {
      curveErrors.modeloCurva = { ...setErrorType("error.duplicate_attribute"), formattedMessage: { id: "attribute.ModeloCurva", defaultMessage: "modeloCurva" } };
    }

    // etiqueta
    if (curve.etiqueta && group.curves.some((c) => c.index !== curve.index && c.etiqueta && c.etiqueta.toUpperCase() == curve.etiqueta.toUpperCase())) {
      curveErrors.etiqueta = { ...setErrorType("error.duplicate_attribute"), formattedMessage: { id: "attribute.Etiqueta", defaultMessage: "etiqueta" } };
    }

    // only add if neccessary
    if (Object.keys(curveErrors).length > 1) {
      curveErrors.index = curve.index;
      curves.push(curveErrors);
    }
  }

  if (curves.length > 0) {
    groupErrors.curves = curves;
  }

  if (!excludeHasErrors) {
    let groupKeys = Object.keys(groupErrors);
    groupErrors.hasErrors = groupKeys.length > 1;
  }

  return groupErrors;
}

export async function getAllCurveGroupErrors(
  product,
  productCurveGroupAttributesProvider,
  productCurveAttributesProvider,
  curveExistenceProvider,
  grupoCurveExistenceProvider,
  excludeHasErrors
) {
  let errors = {};

  if (product.curveGroups.length > 0) {
    let groups = [];

    for (let g = 0; g < product.curveGroups.length; g++) {
      const group = product.curveGroups[g];

      let groupErrors = await getGroupAndCurvesErrors(
        group,
        productCurveGroupAttributesProvider,
        productCurveAttributesProvider,
        curveExistenceProvider,
        grupoCurveExistenceProvider,
        true,
        product
      );

      // only add if neccessary
      if (Object.keys(groupErrors).length > 1) {
        groupErrors.index = group.index;
        groups.push(groupErrors);
      }
    }

    if (groups.length > 0) {
      errors.groups = groups;
    }
  }

  if (!excludeHasErrors) errors.hasErrors = Object.keys(errors).length > 0;

  return errors;
}

export async function getErrors(
  product,
  productTechnicalAttributesProvider,
  productCurveGroupAttributesProvider,
  productCurveAttributesProvider,
  productExistenceProvider,
  curveExistenceProvider,
  grupoCurveExistenceProvider
) {
  let errors = {};

  // check for required category class
  if (!product.conceptClassUnic) {
    errors.conceptClassUnic = { ...setErrorType("error.missing_attribute"), formattedMessage: { id: "attribute.ConceptClassUnic", defaultMessage: "conceptClassUnic" } };
  } else {
    errors = await getProductErrors(product, productTechnicalAttributesProvider, productExistenceProvider, true);
    let curveGroups = await getAllCurveGroupErrors(
      product,
      productCurveGroupAttributesProvider,
      productCurveAttributesProvider,
      curveExistenceProvider,
      grupoCurveExistenceProvider,
      true
    );
    errors = { ...errors, ...curveGroups };
  }

  errors.hasErrors = Object.keys(errors).length > 0;

  return errors;
}

export function replaceBlockedCharacters(value) {
  return value.replace(/[!"#@%&\$\)\(\?¿¡\*\^\+\{\}\[\]]/g, "");
}

export var blockedCharacters = ["!", '"', "#", "@", "%", "&", "$", ")", "(", "?", "¿", "¡", "*", "^", "+", "{", "}", "[", "]"];
