import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Header from "../../components/Header";
import ListerTable from "../../components/Tables/ListerTable";
import { useService } from "../../context/ServiceContext";
import useHistoryState from "../../utils/useHistoryState";

function ErPSearch(props) {
  const intl = useIntl();
  const { kitErPLister } = useService();
  const [page, setPage] = useHistoryState("ErPSearch.Page", 1);
  const [filter, setFilter] = useHistoryState("ErPSearch.Filter", {});

  function handleSelection(kitErP) {
    props.history.push(`/curves-generator/erp/viewer/${kitErP.isResidential ? "rvu" : "nrvu"}/${encodeURIComponent(kitErP.name)}`);
  }

  function renderSmallTableHeader({ column, children }) {
    return (
      <th {...column.getHeaderProps()} style={{ width: "1%" }}>
        {children}
      </th>
    );
  }

  function handlePageChange(page) {
    setPage(page);
  }

  function handleFilterChange(filter) {
    setFilter(filter);
  }

  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="curves_generator.erp_data.title" defaultMessage="ErP data" />
        </h1>
      </Header>
      <Container fluid>
        <Card>
          <CardBody>
            <Row className="mb-3">
              <Col>
                <h3 className="card-title">
                  <FormattedMessage id="curves_generator.search_erp.title" defaultMessage="Search for an ErP kit" />
                </h3>
                <p className="card-text">
                  <FormattedMessage id="curves_generator.search_erp.description" defaultMessage="To view or define ErP data, start by searching for an ErP kit." />
                </p>
              </Col>
            </Row>

            <ListerTable
              className={"align-items-center table-flush"}
              columns={[
                { id: "icon", TableHeader: renderSmallTableHeader, Cell: () => <i className="fas fa-toolbox"></i> },
                { Header: intl.formatMessage({ id: "curve_generator.erp.kitErP", defaultMessage: "Modelo kit ErP" }), accessor: "name" },
                {
                  id: "select",
                  TableHeader: renderSmallTableHeader,
                  Cell: ({ cell }) => (
                    <Button className="btn btn-primary float-right" onClick={() => handleSelection(cell.row.original)}>
                      <FormattedMessage id="app.select" defaultMessage="Select" />
                    </Button>
                  ),
                },
              ]}
              lister={kitErPLister}
              initialFilter={filter}
              initialPage={page}
              onFilterChange={handleFilterChange}
              onPageChange={handlePageChange}
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default ErPSearch;
