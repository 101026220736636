import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Prompt } from "react-router-dom";
import { Row, Col, Card, CardBody, CardHeader, FormGroup, Label, Input, Container, Button, Form } from "reactstrap";
import NonResidentialErPKitViewer from "../../components/CurvesGenerator/VuErP/NonResidentialErPKitViewer";
import ResidentialErPKitViewer from "../../components/CurvesGenerator/VuErP/ResidentialErPKitViewer";
import ErPKitViewerHeader from "../../components/CurvesGenerator/VuErP/ErPKitViewerHeader";
import Header from "../../components/Header";
import InlineError from "../../components/InlineError";
import LoadedContent from "../../components/LoadedContent";
import ListerTableSelectionModal from "../../components/Modals/ListerTableSelectionModal";
import Tooltip from "../../components/Tooltip";
import { useVuErP, VuErPProvider } from "../../context/curves/VuErPContext";
import { useService } from "../../context/ServiceContext";

function ErPKitViewerContent({ kitErP }) {
  const vuErP = useVuErP();
  // const { isSaving, isSaved, fields: { useEcCalculations, setUseEcCalculations, active, setActive, useManualCalculation, setUseManualCalculation } } = useVuErP();

  const intl = useIntl();
  const vuType = vuErP.isResidential ? "RVU" : "NRVU";
  const useManualCalculation = vuErP.fields.useManualCalculation;
  const { curveTestLister, acousticTestLister } = useService();

  const [curveTestModalOpen, setCurveTestModalOpen] = useState(false);
  const toggleCurveTestModal = () => setCurveTestModalOpen(!curveTestModalOpen);

  const [acousticTestModalOpen, setAcousticTestModalOpen] = useState(false);
  const toggleAcousticTestModal = () => setAcousticTestModalOpen(!acousticTestModalOpen);

  function renderFieldIdentifier(vuType) {
    return (id) => (
      <span className="text-muted align-right" style={{ fontSize: "0.75rem" }}>
        {`${vuType}_${id}`}
      </span>
    );
  }

  function renderCurveTestInput() {
    return (
      <Row>
        {typeof vuErP.fields.curveTestId === "number" ? (
          <>
            <Col sm={9}>
              <Input type="text" plaintext readOnly value={vuErP.fields.curveTestId ?? "-"} />
            </Col>
            {!useManualCalculation && (
              <Col sm={3} className="text-left">
                <Tooltip text={intl.formatMessage({ id: "app.remove", defaultMessage: "Remove" })}>
                  <i className="fas fa-times-circle text-danger" style={{ padding: "0.625rem 0" }} onClick={() => vuErP.fields.setCurveTestId(undefined)}></i>
                </Tooltip>
              </Col>
            )}
          </>
        ) : (
          <Col sm={12}>
            <Button color="primary" onClick={toggleCurveTestModal}>
              <FormattedMessage id="app.select" defaultMessage="Select" />
            </Button>
          </Col>
        )}
      </Row>
    );
  }

  function renderLabTestReference() {
    return (
      <Input
        type="text"
        plaintext={true}
        readOnly={true}
        value={vuErP.fields.labTestReference || "-"}
        onChange={!useManualCalculation ? null : (e) => vuErP.fields.setLabTestReference(e.target.value)}
      />
    );
  }

  function renderAcousticTestInput() {
    return (
      <Row>
        {vuErP.fields.acousticTestReference ? (
          <>
            <Col sm={9}>
              <Input type="text" plaintext readOnly value={vuErP.fields.acousticTestReference ?? "-"} />
            </Col>
            <Col sm={3} className="text-left">
              <Tooltip text={intl.formatMessage({ id: "app.remove", defaultMessage: "Remove" })}>
                <i className="fas fa-times-circle text-danger" style={{ padding: "0.625rem 0" }} onClick={() => vuErP.fields.setAcousticTestReference(undefined)}></i>
              </Tooltip>
            </Col>
          </>
        ) : (
          <Col sm={12}>
            <Button color="primary" onClick={toggleAcousticTestModal}>
              <FormattedMessage id="app.select" defaultMessage="Select" />
            </Button>
          </Col>
        )}
      </Row>
    );
  }

  function handleCurveTestSelection(curveTest) {
    vuErP.fields.setCurveTestId(curveTest.curveTestId);
    toggleCurveTestModal();
  }

  function handleAcousticTestSelection(acousticTest) {
    vuErP.fields.setAcousticTestReference(acousticTest.testNumber);
    toggleAcousticTestModal();
  }

  function handleSubmit(e) {
    e.preventDefault();
    vuErP.save();
  }

  return (
    <>
      <Prompt message={() => intl.formatMessage({ id: "app.unsavedchanges", defaultMessage: "Are you sure you want to continue? You may lose any unsaved changes." })} />
      <Header>
        <h1>Modify {vuType} ErP</h1>
      </Header>
      <Container fluid>
        <div className="px-4">
          {!vuErP.error ? (
            <LoadedContent loading={vuErP.loading} overlay={true}>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Card className="mb-3">
                      <CardBody>
                        <ErPKitViewerHeader value={vuErP} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>ErP details</CardHeader>
                      <CardBody>
                        <Row>
                          <Col xl={3} md={6}>
                            <FormGroup>
                              <Label>IdEnsayo {renderFieldIdentifier(vuType)("id000")}</Label>
                              {renderCurveTestInput()}
                            </FormGroup>
                          </Col>
                          <Col xl={3} md={6}>
                            <FormGroup>
                              <Label>Performance test {renderFieldIdentifier(vuType)("id002")}</Label>
                              {renderLabTestReference()}
                            </FormGroup>
                          </Col>
                          <Col xl={3} md={6}>
                            <FormGroup>
                              <Label>Acoustic test {renderFieldIdentifier(vuType)("id003")}</Label>
                              {renderAcousticTestInput()}
                            </FormGroup>
                          </Col>
                          <Col xl={3} md={6}>
                            <FormGroup>
                              <Label>Identifier {renderFieldIdentifier(vuType)("id004")}</Label>
                              <Input type="text" className="w-auto" value={vuErP.fields.identifier ?? ""} onChange={(e) => vuErP.fields.setIdentifier(e.target.value)} />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {vuErP.isResidential ? (
                      <ResidentialErPKitViewer renderFieldIdentifier={renderFieldIdentifier(vuType)} />
                    ) : (
                      <NonResidentialErPKitViewer renderFieldIdentifier={renderFieldIdentifier(vuType)} />
                    )}
                  </Col>
                </Row>
              </Form>
            </LoadedContent>
          ) : (
            <InlineError message={intl.formatMessage(vuErP.error.localization)} traceId={vuErP.error.traceId} onRetry={vuErP.retry} />
          )}
        </div>
      </Container>

      <ListerTableSelectionModal
        title={intl.formatMessage({ id: "curves_generator.select_curve_test", defaultMessage: "Select a curve test" })}
        columns={[
          { Header: intl.formatMessage({ id: "curve_tests.curve_test_id", defaultMessage: "ID ensayo" }), accessor: "curveTestId" },
          { Header: intl.formatMessage({ id: "curve_tests.lab_test_number", defaultMessage: "Lab test number" }), accessor: "labTestNumber" },
          { Header: intl.formatMessage({ id: "curve_tests.efficiency_type", defaultMessage: "Eff. type" }), accessor: "efficiencyType" },
          { Header: intl.formatMessage({ id: "curve_tests.intallation_type", defaultMessage: "Install type" }), accessor: "installationType" },
          { Header: intl.formatMessage({ id: "curve_tests.modelo_curva", defaultMessage: "Modelo curva" }), accessor: "modeloCurva" },
          {
            id: "Published",
            Header: intl.formatMessage({ id: "curve_tests.is_published", defaultMessage: "Is published?" }),
            Cell: (cell) => (cell.row.original.isPublished ? <FormattedMessage id="app.yes" defaultMessage="Yes" /> : <FormattedMessage id="app.no" defaultMessage="No" />),
          },
        ]}
        isOpen={curveTestModalOpen}
        toggle={toggleCurveTestModal}
        lister={curveTestLister}
        onSelect={handleCurveTestSelection}
        prefilter={{ kitErP }}
      />

      <ListerTableSelectionModal
        title={intl.formatMessage({ id: "curves_generator.select_acoustic_test", defaultMessage: "Select an acoustic test" })}
        columns={[
          { Header: intl.formatMessage({ id: "tests.lab_name", defaultMessage: "Lab name" }), accessor: "laboratoryName" },
          { Header: intl.formatMessage({ id: "tests.lab_test_number", defaultMessage: "Lab test number" }), accessor: "testNumber" },
          { Header: intl.formatMessage({ id: "tests.request_number", defaultMessage: "Request number" }), accessor: "testRequestNumber" },
          { Header: intl.formatMessage({ id: "tests.brand", defaultMessage: "Brand" }), accessor: "brandName" },
          { Header: intl.formatMessage({ id: "tests.model", defaultMessage: "Model" }), accessor: "productModelName" },
          { Header: intl.formatMessage({ id: "tests.project_name", defaultMessage: "Project name" }), accessor: "projectName" },
        ]}
        isOpen={acousticTestModalOpen}
        toggle={toggleAcousticTestModal}
        lister={acousticTestLister}
        onSelect={handleAcousticTestSelection}
      />
    </>
  );
}

function ErPKitViewer(props) {
  const { type, kitErP } = props.match.params;

  return (
    <VuErPProvider type={type} kitErP={kitErP}>
      <ErPKitViewerContent {...props} kitErP={kitErP} />
    </VuErPProvider>
  );
}

export default ErPKitViewer;
