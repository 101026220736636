import React from "react";
import { FormattedNumber, useIntl } from "react-intl";
import { Button, Table } from "reactstrap";
import Input from "../Input";

const editableColumns = [0, 1, 2, 4, 5, 6, 7];

const testPointColumns = [
  "airflow",
  "staticPressure",
  "dynamicPressure",
  "totalPressure",
  "electricalCurrent",
  "inputPower",
  "outputPower",
  "speed",
  "inputStaticEfficiency",
  "inputTotalEfficiency",
  "outputStaticEfficiency",
  "outputTotalEfficiency",
];

export default function CurvesTable({ dataSet, columns, onUpdateRow, onInsertRow, onDeleteRow, enableEditing }) {
  const intl = useIntl();
  dataSet = dataSet ?? [];

  function dataField(row, key, value) {
    const columnIndex = testPointColumns.indexOf(key);
    return enableEditing && editableColumns.indexOf(columnIndex) > -1 ? (
      <Input type="number" step="any" size="sm" className="form-control-sm" value={value ?? ""} onValueChange={(values, sourceInfo) => sourceInfo.source === "event" && onUpdateRow(row, key, values.floatValue) }></Input>
    ) : (
      <FormattedNumber value={value} />
    );
  }

  return <Table bordered responsive size="sm" className={`mb-5 curve-points ${enableEditing ? "editing" : ""}`}>
    <thead className="thead-light">
      <tr>
        {columns.map((data, i) => (
          <th key={i}>{intl.formatMessage({ id: `curve_viewer.titles.${data}`, defaultMessage: data })}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {dataSet.map((data, i) => (
        <tr key={i}>
          {columns.map((key, j) => (
            <td key={j} className="align-middle">
              {enableEditing && j === 0 && (
                <>
                  <Button
                    type="button"
                    size="sm"
                    color="link"
                    className="floating-button insert-row m-0"
                    onClick={() => onInsertRow(i)}
                  >
                    <i className="fas fa-plus-circle"></i>
                  </Button>
                  <Button
                    type="button"
                    size="sm"
                    color="link"
                    className="floating-button delete-row m-0"
                    onClick={() => onDeleteRow(i)}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </>
              )}
              {dataField(i, key, data[key])}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </Table>;
}