import React from "react";
import { ValidationForm } from "react-bootstrap4-form-validation";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader, CustomInput } from "reactstrap";

function ProductDefinitionStep({ next, prev, availablePoles, poles, productPoles, setProductPoles, denomination, availableFrequencies }) {
  function updateProductPoles(id, poleId, state) {
    let productPole = productPoles.find((pp) => pp.id === id);
    if (state && !productPole.poles.includes((p) => p === poleId)) {
      productPole.poles.push(poleId);
    } else if (!state) {
      productPole.poles = productPole.poles.filter((p) => p !== poleId);
    }
    setProductPoles(
      productPoles.map((p) => {
        if (p.id === productPole.id) {
          return { ...productPole };
        } else return p;
      })
    );
  }

  return (
    <ValidationForm onSubmit={() => {}}>
      <Card className="mt-2">
        <CardHeader>
          <FormattedMessage id="product_wizard.products_definition" defaultMessage="Products definition" />
        </CardHeader>
        <CardBody>
          <p>
            <FormattedMessage id="product_wizard.select_possible_combinations" defaultMessage="Select all possible combinations to define your new products:" />
          </p>
          <table className="table" style={{ minWidth: 600, width: "auto" }}>
            <thead className="thead-light">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                {poles.map((p, i) => (
                  <th key={i}>{availablePoles.find((pole) => pole.id === p)?.pole} poles</th>
                ))}
              </tr>
            </thead>
            {productPoles.map((p, i) => (
              <tr key={i}>
                <td>{denomination}</td>
                <td>{p.denominationParameter}</td>
                <td>{availableFrequencies.find((f) => f.id === p.frequency)?.frequency}Hz</td>
                {poles.map((pole, j) => (
                  <td key={j} className="pt-0">
                    <CustomInput type="switch" id={`productPoles-${i}-${j}`} checked={p.poles.includes(pole)} onChange={(e) => updateProductPoles(p.id, pole, e.target.checked)} />
                  </td>
                ))}
              </tr>
            ))}
          </table>
        </CardBody>
        <CardFooter>
          <Button className="btn btn-dark float-right" onClick={next}>
            <FormattedMessage id="app.next" defaultMessage="Next" />
          </Button>
          <Button className="btn btn-outline-danger" onClick={prev}>
            <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
          </Button>
        </CardFooter>
      </Card>
    </ValidationForm>
  );
}

export default ProductDefinitionStep;
