class HttpEvaluator {
    constructor(http, path, paramReducer) {
        this._http = http;
        this._path = path;
        this._paramReducer = paramReducer;
    }

    async evaluate(inputs) {
        let data;
        
        if(inputs) {
            data = this._paramReducer ? this._paramReducer(inputs) : inputs;
        }

        let response = await this._http.request({
            method: "post",
            url: this._path,
            data
        });

        return response.data;
    }
}

export default HttpEvaluator;