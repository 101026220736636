import React from "react";
import { useAuth } from "./context/AuthContext";
import { useService } from "./context/ServiceContext";
import { useUser } from "./context/UserContext";
import AppLayout from "./layouts/AppLayout";
import AuthLayout from "./layouts/AuthLayout";

function App(props) {
  const { user } = useUser();
  const { logout } = useAuth();
  const { http } = useService();

  http.interceptors.response.use(response => response, error => {
    if(error.response && error.response.status === 503 && error.response.headers["x-application-offline"] === "1") {
      logout();
      props.history.push("/maintenance");
    } else if(error.response && error.response.status === 401) {
      logout();
    }

    return Promise.reject(error);
  });

  return user !== null ? <AppLayout {...props} /> : <AuthLayout {...props} />;
}

export default App;
