import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from "reactstrap";
import { useVuErP } from "../../../context/curves/VuErPContext";
import { useConfig } from "../../../context/ConfigContext";
import { round } from "../../../utils/math";
import Validated from "../../Validated";
import AlertWithIcon from "../../AlertWithIcon";
import FormattedNumber from "../../FormattedNumber";
import ResidentialVentilationUnitChart from "./ResidentialVentilationUnitChart";
import Input from "../../Input";


function ResidentialErPKitViewer({ renderFieldIdentifier }) {
  const intl = useIntl();
  const vuErP = useVuErP();
  const useManualCalculation = vuErP.fields.useManualCalculation;
  const columnClass = "mb-4";
  // const columnClass = "mb-4 mt-auto";
  const patterns = useConfig("genesis.validation.patterns");
  const {
    identifiers,
    classification: { colors },
  } = useConfig("genesis.erp");

  const onClimatePerformanceChange = (name, property, value) => {
    let index = vuErP.climaticPerformance.findIndex((v) => v.name == name);
    if (index > -1) {
      let newClimatePerformance = [...vuErP.climaticPerformance];
      newClimatePerformance[index][property] = value;
      vuErP.setClimaticPerformance(newClimatePerformance);
    }
  };

  function renderClimatePerformanceCard({ name, title, fields: { classification, sec, aec, ahs }, onClimatePerformanceChange }) {
    return (
      <Card>
        <CardHeader>{title}</CardHeader>
        <CardBody>
          <FormGroup row>
            <Col lg={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.sec_class" defaultMessage="SEC class" />
                <br />
                {renderFieldIdentifier(classification.identifier)}
              </Label>
              {!useManualCalculation ? (
                <Input
                  type="text"
                  value={classification.value}
                  style={{ backgroundColor: colors[classification.value], color: "white", fontWeight: "bold", textAlign: "center", borderRadius: "30px 0px", width: "50px" }}
                  readOnly
                  plaintext
                />
              ) : (
                <Validated errors={vuErP.validationErrors} name="typeOfDrive">
                  <Input type="select" value={classification.value} onChange={(e) => onClimatePerformanceChange(name, "specificEnergyConsumptionClassification", e.target.value)}>
                    <option value="">{intl.formatMessage({ id: "app.please_select", defaultMessage: "Please select" })}</option>
                    <option value="A+">A+</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="NA">NA</option>
                  </Input>
                </Validated>
              )}
            </Col>

            <Col lg={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.sec" defaultMessage="SEC" /> (kW/(m&sup2;.an))
                <br />
                {renderFieldIdentifier(sec.identifier)}
              </Label>
              <p>
                {!useManualCalculation ? (
                  <FormattedNumber value={sec.value} options={{ maximumFractionDigits: 1 }} />
                ) : (
                  <Input type="number" value={sec.value} onValueChange={(value) => onClimatePerformanceChange(name, "specificEnergyConsumption", value.floatValue)} />
                  // <Input type="number" value={sec.value} onChange={(e) => onClimatePerformanceChange(name, "specificEnergyConsumption", e.target.value)} />
                )}
              </p>
            </Col>

            <Col lg={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.annual_electricity_consumption" defaultMessage="Annual electricity consumption" /> (kWh/a)
                <br />
                {renderFieldIdentifier(aec.identifier)}
              </Label>
              <p>
                {!useManualCalculation ? (
                  <FormattedNumber value={aec.value} options={{ maximumFractionDigits: 0 }} />
                ) : (
                  <Input type="number" value={aec.value} onValueChange={(value) => onClimatePerformanceChange(name, "annualElectricityConsumption", value.floatValue)} />
                  // <Input type="number" value={aec.value} onChange={(e) => onClimatePerformanceChange(name, "annualElectricityConsumption", e.target.value)} />
                )}
              </p>
            </Col>

            <Col lg={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.annual_heating_saved" defaultMessage="Annual heating saved" /> (kWh/a)
                <br />
                {renderFieldIdentifier(ahs.identifier)}
              </Label>
              <p>
                {!useManualCalculation ? (
                  <FormattedNumber value={ahs.value} options={{ maximumFractionDigits: 0 }} />
                ) : (
                  <Input type="number" value={ahs.value} onValueChange={(value) => onClimatePerformanceChange(name, "annualHeatingSaved", value.floatValue)} />
                  // <Input type="number" value={ahs.value} onChange={(e) => onClimatePerformanceChange(name, "annualHeatingSaved", e.target.value)} />
                )}
              </p>
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }

  const setLwa = (value) => {
    vuErP.fields.setLwa(value);
  };

  return (
    <>
      {vuErP.evaluationError && (
        <AlertWithIcon icon={<i className="fas fa-exclamation-triangle"></i>} className="alert-warning mt-4">
          <FormattedMessage id={vuErP.evaluationError.id} defaultMessage={vuErP.evaluationError.defaultMessage} values={vuErP.evaluationError.values} />
        </AlertWithIcon>
      )}

      {vuErP.evaluationWarnings?.map((w) => (
        <AlertWithIcon icon={<i className="fas fa-exclamation-triangle"></i>} className="alert-warning mt-4">
          <FormattedMessage id={w.id} defaultMessage={w.defaultMessage} values={w.values} />
        </AlertWithIcon>
      ))}

      {/* {vuErP.climaticPerformance && (!vuErP.evaluationError) && ( */}
      <>
        {vuErP.climaticPerformance && (useManualCalculation || !vuErP.evaluationError) && (
          <Card className="mt-4">
            <CardHeader>
              <FormattedMessage id="curves_generator.vuerp.climate_performance" defaultMessage="Climate performance" />
            </CardHeader>
            <CardBody>
              <Row>
                {vuErP.climaticPerformance.map((p, i) => (
                  <Col xl={4} lg={6} md={12} className={columnClass} key={i}>
                    {renderClimatePerformanceCard({
                      name: p.name,
                      title: intl.formatMessage({ id: `curves_generator.vuerp.${p.name}_climate`, defaultMessage: `${p.name} climate` }),
                      fields: {
                        classification: { value: p.specificEnergyConsumptionClassification || "NA", identifier: identifiers.climate[p.name].classification },
                        sec: { value: p.specificEnergyConsumption, identifier: identifiers.climate[p.name].sec },
                        aec: { value: p.annualElectricityConsumption, identifier: identifiers.climate[p.name].aec },
                        ahs: { value: p.annualHeatingSaved, identifier: identifiers.climate[p.name].ahs },
                      },
                      onClimatePerformanceChange: onClimatePerformanceChange,
                    })}
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        )}

        {!!vuErP.performanceCurves?.length && !vuErP.evaluationError && (
          <Card className="mt-4">
            <CardHeader>
              <FormattedMessage id="curves_generator.vuerp.curves" defaultMessage="Curves" />
            </CardHeader>
            <CardBody>
              <Row>
                <ResidentialVentilationUnitChart
                  curves={vuErP.performanceCurves}
                  efficiencyType={vuErP.fields.efficiencyType}
                  referencePoint={{ airflow: vuErP.fields.referenceFlowRate, pressure: vuErP.fields.referencePressureDifference }}
                  maximumPoint={{ airflow: vuErP.fields.maximumFlowRate, pressure: vuErP.fields.maximumPressureDifference }}
                  referenceCurve={vuErP.fields.referenceCurve}
                />
              </Row>
            </CardBody>
          </Card>
        )}
      </>
      {/* //)} */}

      <Card className="mt-4">
        <CardHeader>
          <FormattedMessage id="curves_generator.vuerp.configuration" defaultMessage="Configuration" />
        </CardHeader>
        <CardBody>
          <FormGroup row>
            <Col lg={6} className={columnClass}>
              <Row>
                <Col xs={5} className="mt-auto">
                  <Label>
                    <FormattedMessage id="curves_generator.vuerp.ducted_non_ducted" defaultMessage="Ducted/non-ducted" /> {renderFieldIdentifier("id010")}
                  </Label>
                  <Validated errors={vuErP.validationErrors} name="ducting">
                    <Input id="ducting" type="select" value={vuErP.fields.ducting} onChange={(e) => vuErP.fields.setDucting(e.target.value)}>
                      <option value="">{intl.formatMessage({ id: "app.please_select", defaultMessage: "Please select" })}</option>
                      <option value="D">D</option>
                      <option value="ND">ND</option>
                    </Input>
                  </Validated>
                </Col>
                <Col xs={2} className="text-center my-auto">
                  <i className="fas fa-arrow-right"></i>
                </Col>
                <Col xs={5} className="mt-auto">
                  <Label>
                    <FormattedMessage id="curves_generator.vuerp.misc" defaultMessage="MISC" /> {renderFieldIdentifier("id011")}
                  </Label>
                  <Input type="text" className="w-auto" readOnly plaintext value={vuErP.fields.ductingFactor ?? "-"} />
                </Col>
              </Row>
            </Col>
            <Col lg={6} className={columnClass}>
              <Row>
                <Col xs={5} className="mt-auto">
                  <Label>
                    <FormattedMessage id="curves_generator.vuerp.type_of_drive" defaultMessage="Type of drive" /> {renderFieldIdentifier("id012")}
                  </Label>
                  <Validated errors={vuErP.validationErrors} name="typeOfDrive">
                    <Input id="drive" type="select" value={vuErP.fields.typeOfDrive} onChange={(e) => vuErP.fields.setTypeOfDrive(e.target.value)}>
                      <option value="">{intl.formatMessage({ id: "app.please_select", defaultMessage: "Please select" })}</option>
                      <option value="1V">1V</option>
                      <option value="2V">2V</option>
                      <option value="3V">3V</option>
                      <option value="MSD">MSD</option>
                      <option value="VSD">VSD</option>
                    </Input>
                  </Validated>
                </Col>
                <Col xs={2} className="text-center my-auto">
                  <i className="fas fa-arrow-right"></i>
                </Col>
                <Col xs={5} className="mt-auto">
                  <Label>
                    <FormattedMessage id="curves_generator.vuerp.x_value" defaultMessage="x-value" /> {renderFieldIdentifier("id013")}
                  </Label>
                  <Input type="text" className="w-auto" readOnly plaintext value={vuErP.fields.driveFactor} />
                </Col>
              </Row>
            </Col>

            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.typology" defaultMessage="Typology" /> {renderFieldIdentifier("id009")}
              </Label>
              <Validated errors={vuErP.validationErrors} name="typology">
                <Input id="typology" type="select" value={vuErP.fields.typology} onChange={(e) => vuErP.fields.setTypology(e.target.value)}>
                  <option value="">{intl.formatMessage({ id: "app.please_select", defaultMessage: "Please select" })}</option>
                  <option value="UVU">UVU</option>
                  <option value="BVU">BVU</option>
                </Input>
              </Validated>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.type_of_hrc" defaultMessage="Type of HRC" /> {renderFieldIdentifier("id014")}
              </Label>
              <Validated errors={vuErP.validationErrors} name="typeOfHrc">
                <Input id="hrc" type="select" value={vuErP.fields.typeOfHrc} onChange={(e) => vuErP.fields.setTypeOfHrc(e.target.value)}>
                  <option value="">{intl.formatMessage({ id: "app.please_select", defaultMessage: "Please select" })}</option>
                  <option value="NO">NO</option>
                  <option value="RC">RC</option>
                  <option value="RG">RG</option>
                  <option value="OT">OT</option>
                </Input>
              </Validated>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.thermal_efficiency" defaultMessage="Thermal efficiency" /> (%) {renderFieldIdentifier("id015")}
              </Label>
              <Validated errors={vuErP.validationErrors} name="thermalEfficiency">
                <Input
                  id="thermal-efficiency"
                  type="number"
                  className="w-auto"
                  value={vuErP.fields.thermalEfficiency ?? ""}
                  onChange={(e) => vuErP.fields.setThermalEfficiency(e.target.value)}
                  pattern={patterns.optionalNumeric}
                />
              </Validated>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.lwa" defaultMessage="LWA" /> dB(A) {renderFieldIdentifier("id018")}
              </Label>
              <Input id="lwa" type="number" className="w-auto" value={vuErP.fields.lwa ?? ""} onValueChange={(value) => vuErP.fields.setLwa(value.floatValue)} />
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.maximum_internal_leakage_for_bvu" defaultMessage="Maximum internal leakage for BVU" /> (%){" "}
                {renderFieldIdentifier("id026")}
              </Label>
              <Validated errors={vuErP.validationErrors} name="maximumInternalLeakageForBvu">
                <Input
                  id="milb"
                  type="text"
                  className="w-auto"
                  value={vuErP.fields.maximumInternalLeakageForBvu}
                  onChange={(e) => vuErP.fields.setMaximumInternalLeakageForBvu(e.target.value)}
                />
              </Validated>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.maximum_external_leakage_for_bvu_and_uvu" defaultMessage="Maximum external leakage for BVU and UVU" /> (%){" "}
                {renderFieldIdentifier("id027")}
              </Label>
              <Validated errors={vuErP.validationErrors} name="maximumInternalLeakageForBvuAndUvu">
                <Input
                  id="milbu"
                  type="text"
                  className="w-auto"
                  value={vuErP.fields.maximumInternalLeakageForBvuAndUvu}
                  onChange={(e) => vuErP.fields.setMaximumInternalLeakageForBvuAndUvu(e.target.value)}
                />
              </Validated>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.mixing_rate_for_bvu_without_duct_connection" defaultMessage="Mixing rate for BVU without duct connection" /> (%){" "}
                {renderFieldIdentifier("id028")}
              </Label>
              <Validated errors={vuErP.validationErrors} name="mixingRateForBvuWithoutDuctConnection">
                <Input
                  id="mrbwodc"
                  type="text"
                  className="w-auto"
                  value={vuErP.fields.mixingRateForBvuWithoutDuctConnection}
                  onChange={(e) => vuErP.fields.setMixingRateForBvuWithoutDuctConnection(e.target.value)}
                  pattern={patterns.optionalNumeric}
                />
              </Validated>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.airflow_sensitivity_to_pressure_variation" defaultMessage="Airflow sensitivity to pressure variation" />{" "}
                {renderFieldIdentifier("id033")}
              </Label>
              <Validated errors={vuErP.validationErrors} name="airflowSensitivityToPressureVariation">
                <Input
                  id="astpv"
                  type="text"
                  className="w-auto"
                  value={vuErP.fields.airflowSensitivityToPressureVariation}
                  onChange={(e) => vuErP.fields.setAirflowSensitivityToPressureVariation(e.target.value)}
                  pattern={patterns.optionalNumeric}
                />
              </Validated>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.indoor_outdoor_air_tightness" defaultMessage="Indoor/outdoor air tightness" /> (m&sup3;/h){" "}
                {renderFieldIdentifier("id034")}
              </Label>
              <Validated errors={vuErP.validationErrors} name="indoorOutdoorAirTightness">
                <Input
                  id="ioat"
                  type="text"
                  className="w-auto"
                  value={vuErP.fields.indoorOutdoorAirTightness}
                  onChange={(e) => vuErP.fields.setIndoorOutdoorAirTightness(e.target.value)}
                  pattern={patterns.optionalNumeric}
                />
              </Validated>
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
      <Card className="mt-4">
        <CardHeader>
          <FormattedMessage id="curves_generator.vuerp.calculations" defaultMessage="Calculations" />
        </CardHeader>
        <CardBody>
          <FormGroup row>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.maximum_flow_rate" defaultMessage="Maximum flow rate" /> (m&sup3;/h) {renderFieldIdentifier("id016")}
              </Label>
              <p>
                {!useManualCalculation ? (
                  <FormattedNumber value={vuErP.fields.maximumFlowRate} options={{ maximumFractionDigits: 0 }} />
                ) : (
                  <Input className="w-auto" type="number" value={vuErP.fields.maximumFlowRate} onValueChange={(value) => vuErP.fields.setMaximumFlowRate(value.floatValue)} />
                  // <Input className="w-auto" type="number" value={vuErP.fields.maximumFlowRate} onChange={(e) => vuErP.fields.setMaximumFlowRate(e.target.value)} />
                )}
              </p>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.electrical_power_input_at_maximum_flow_rate" defaultMessage="Input power at maximum flow rate" /> (W){" "}
                {renderFieldIdentifier("id017")}
              </Label>
              <p>
                {!useManualCalculation ? (
                  <FormattedNumber value={vuErP.fields.electricalPowerInputAtMaximumFlowRate} options={{ maximumFractionDigits: 1 }} />
                ) : (
                  <Input
                    className="w-auto"
                    type="number"
                    value={vuErP.fields.electricalPowerInputAtMaximumFlowRate}
                    onValueChange={(value) => vuErP.fields.setElectricalPowerInputAtMaximumFlowRate(value.floatValue)}
                    // onChange={(e) => vuErP.fields.setElectricalPowerInputAtMaximumFlowRate(e.target.value)}
                  />
                )}
              </p>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.reference_flow_rate" defaultMessage="Reference flow rate" /> (m&sup3;/h) {renderFieldIdentifier("id019")}
              </Label>
              <p>
                {!useManualCalculation ? (
                  <FormattedNumber value={vuErP.fields.referenceFlowRate} options={{ maximumFractionDigits: 0 }} />
                ) : (
                  <Input className="w-auto" type="number" value={vuErP.fields.referenceFlowRate} onValueChange={(value) => vuErP.fields.setReferenceFlowRate(value.floatValue)} />
                  // <Input className="w-auto" type="number" value={vuErP.fields.referenceFlowRate} onChange={(e) => vuErP.fields.setReferenceFlowRate(e.target.value)} />
                )}
              </p>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.reference_flow_rate" defaultMessage="Reference flow rate" /> (m&sup3;/s) {renderFieldIdentifier("id020")}
              </Label>
              <p>
                {!useManualCalculation ? (
                  <FormattedNumber value={vuErP.fields.referenceFlowRate / 3600} options={{ maximumFractionDigits: 3 }} />
                ) : (
                  <Input
                    className="w-auto"
                    type="number"
                    value={vuErP.fields.referenceFlowRate / 3600}
                    onValueChange={(value) => vuErP.fields.setReferenceFlowRate(value.floatValue * 3600)}
                    // onChange={(e) => vuErP.fields.setReferenceFlowRate(e.target.value * 3600)}
                  />
                )}
              </p>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.reference_pressure_difference" defaultMessage="Reference pressure difference" /> (Pa) {renderFieldIdentifier("id021")}
              </Label>
              <p>
                {!useManualCalculation ? (
                  <FormattedNumber value={vuErP.fields.referencePressureDifference} options={{ maximumFractionDigits: 0 }} />
                ) : (
                  <Input
                    className="w-auto"
                    type="number"
                    value={vuErP.fields.referencePressureDifference}
                    onValueChange={(value) => vuErP.fields.setReferencePressureDifference(value.floatValue)}
                    // onChange={(e) => vuErP.fields.setReferencePressureDifference(e.target.value)}
                  />
                )}
              </p>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.input_power_at_reference_flow_rate" defaultMessage="Input power at reference flow rate" /> (W){" "}
                {renderFieldIdentifier("id022")}
              </Label>
              <p>
                {!useManualCalculation ? (
                  <FormattedNumber value={vuErP.fields.electricalPowerInputAtReferenceFlowRate} options={{ maximumFractionDigits: 1 }} />
                ) : (
                  <Input
                    className="w-auto"
                    type="number"
                    value={vuErP.fields.electricalPowerInputAtReferenceFlowRate}
                    onValueChange={(value) => vuErP.fields.setElectricalPowerInputAtReferenceFlowRate(value.floatValue)}
                    // onChange={(e) => vuErP.fields.setElectricalPowerInputAtReferenceFlowRate(e.target.value)}
                  />
                )}
              </p>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.specific_power_input" defaultMessage="SPI" /> (W/m3/h) {renderFieldIdentifier("id023")}
              </Label>
              <p>
                {!useManualCalculation ? (
                  <FormattedNumber value={vuErP.fields.specificPowerInput} options={{ maximumFractionDigits: 3 }} />
                ) : (
                  <Input className="w-auto" type="number" value={vuErP.fields.specificPowerInput} onValueChange={(value) => vuErP.fields.setSpecificPowerInput(value.floatValue)} />
                )}
              </p>
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
      <Card className="mt-4">
        <CardHeader>
          <FormattedMessage id="curves_generator.vuerp.controls_and_installation" defaultMessage="Controls and installation" />
        </CardHeader>
        <CardBody>
          <FormGroup row>
            <Col xl={8} md={12} className={columnClass}>
              <Row>
                <Col xs={5} className="mt-auto">
                  <Label>
                    <FormattedMessage id="curves_generator.vuerp.control_typology" defaultMessage="Control typology" /> {renderFieldIdentifier("id025")}
                  </Label>
                  <Validated errors={vuErP.validationErrors} name="controlTypology">
                    <Input id="control-topology" type="select" value={vuErP.fields.controlTypology} onChange={(e) => vuErP.fields.setControlTypology(e.target.value)}>
                      <option value="">{intl.formatMessage({ id: "app.please_select", defaultMessage: "Please select" })}</option>
                      <option value="MAN">MAN</option>
                      <option value="CLOCK">CLOCK</option>
                      <option value="CDC">CDC</option>
                      <option value="LDC">LDC</option>
                    </Input>
                  </Validated>
                </Col>
                <Col xs={2} className="text-center my-auto">
                  <i className="fas fa-arrow-right"></i>
                </Col>
                <Col xs={5} className="mt-auto">
                  <Label>
                    <FormattedMessage id="curves_generator.vuerp.control_factor" defaultMessage="Control factor" /> {renderFieldIdentifier("id024")}
                  </Label>
                  <Input type="text" className="w-auto" readOnly plaintext value={vuErP.fields.controlFactor} />
                </Col>
              </Row>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.position_of_filter_warning" defaultMessage="Position of filter warning" /> {renderFieldIdentifier("id029")}
              </Label>
              <Input id="pofw" type="select" value={vuErP.fields.positionOfFilterWarning} onChange={(e) => vuErP.fields.setPositionOfFilterWarning(e.target.value)}>
                <option value="RemoteControl">RemoteControl</option>
                <option value="NA">NA</option>
              </Input>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.description_of_visual_filter_warning" defaultMessage="Description of visual filter warning" />{" "}
                {renderFieldIdentifier("id030")}
              </Label>
              <Input
                id="dovfw"
                type="select"
                value={vuErP.fields.descriptionOfVisualFilterWarning}
                onChange={(e) => vuErP.fields.setDescriptionOfVisualFilterWarning(e.target.value)}
              >
                <option value="PilotLight">PilotLight</option>
                <option value="Symbol">Symbol</option>
                <option value="NA">NA</option>
              </Input>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.instructions_to_install_supply_grilles" defaultMessage="Instructions to install supply grilles" />{" "}
                {renderFieldIdentifier("id031")}
              </Label>
              <Input
                id="itisg"
                type="select"
                value={vuErP.fields.instructionsToInstallSupplyGrilles}
                onChange={(e) => vuErP.fields.setInstructionsToInstallSupplyGrilles(e.target.value)}
              >
                <option value="NA">NA</option>
                <option value="F&W Leaflet">F&amp;W Leaflet</option>
              </Input>
            </Col>
            <Col xl={4} md={6} className={columnClass}>
              <Label>
                <FormattedMessage id="curves_generator.vuerp.instructions_to_install_exhaust_grilles" defaultMessage="Instructions to install exhaust grilles" />{" "}
                {renderFieldIdentifier("id032")}
              </Label>
              <Input
                id="itieg"
                type="select"
                value={vuErP.fields.instructionsToInstallExhaustGrilles}
                onChange={(e) => vuErP.fields.setInstructionsToInstallExhaustGrilles(e.target.value)}
              >
                <option value="NA">NA</option>
                <option value="F&W Leaflet">F&amp;W Leaflet</option>
              </Input>
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
    </>
  );
}

export default ResidentialErPKitViewer;
