import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Collapse, CardHeader } from "reactstrap";
import MultiSearchPanel from "../../components/Search/MultiSearchPanel";
import { useIntl } from "react-intl";
import { useService } from "../../context/ServiceContext";
import CurveSearchWizard from "../../components/CurvesGenerator/CurveSearchWizard";
import useHistoryState from "../../utils/useHistoryState";

function CurveSearch({ onCurveSelect }) {
  const intl = useIntl();
  const { serieComercialLister, productLister, curveGroupLister, curveLister, serieComercialInternalSuggester, productSuggester, curveGroupSuggester, curveSuggester } =
    useService();
  const [wizardState, setWizardState] = useHistoryState("CurveSearch.Wizard");
  const [submittedSearch, setSubmittedSearch] = useState(null);
  const [searchOpen, setSearchOpen] = useState(true);
  const [resultsOpen, setResultsOpen] = useState(!!wizardState);

  const toggleSearchOpen = () => setSearchOpen(!searchOpen);
  const toggleResultsOpen = () => setResultsOpen(!resultsOpen);

  const searchFields = [
    {
      id: "series",
      label: intl.formatMessage({
        id: "curve_generator.series",
        defaultMessage: "Series",
      }),
      value: "",
      suggester: serieComercialInternalSuggester,
    },
    {
      id: "product_model",
      label: intl.formatMessage({
        id: "curves_generator.product",
        defaultMessage: "Product",
      }),
      value: "",
      suggester: productSuggester,
    },
    {
      id: "curve_group_model",
      label: intl.formatMessage({
        id: "curves_generator.curve_group",
        defaultMessage: "Curve group",
      }),
      value: "",
      suggester: curveGroupSuggester,
    },
    {
      id: "curve_model",
      label: intl.formatMessage({
        id: "curves_generator.curve",
        defaultMessage: "Curve",
      }),
      value: "",
      suggester: curveSuggester,
    },
  ];

  const searchHandles = {
    series: {
      lister: serieComercialLister,
      step: "series",
      filter: (value) => ({ internalName: value }),
    },
    product_model: {
      lister: productLister,
      step: "product",
      filter: (value) => ({ name: value }),
    },
    curve_group_model: {
      lister: curveGroupLister,
      step: "curve_group",
      filter: (value) => ({ name: value }),
    },
    curve_model: {
      lister: curveLister,
      step: "curve",
      filter: (value) => ({ name: value }),
    },
  };

  function handleSearchSubmit(search) {
    if (search !== null) {
      let handle = searchHandles[search.id];
      setSearchOpen(false);
      setResultsOpen(true);
      setSubmittedSearch({
        step: handle.step,
        filter: handle.filter(search.value),
      });
    }
  }

  function handleSelection(value) {
    onCurveSelect && onCurveSelect(value);
  }

  return (
    <>
      <Row>
        <Col>
          <MultiSearchPanel fields={searchFields} onSubmit={handleSearchSubmit} isOpen={searchOpen} onToggleOpen={toggleSearchOpen} />
        </Col>
      </Row>

      {(submittedSearch || !!wizardState) && (
        <Row className="mt-3">
          <Col>
            <Card>
              <CardHeader onClick={(e) => toggleResultsOpen()}>
                Results
                {resultsOpen ? <i className={"fas fa-chevron-up mt-1 float-right"}></i> : <i className={"fas fa-chevron-down mt-1 float-right"}></i>}
              </CardHeader>
              <Collapse isOpen={resultsOpen}>
                <CardBody>
                  <CurveSearchWizard
                    listers={{
                      series: serieComercialLister,
                      product: productLister,
                      curve_group: curveGroupLister,
                      curve: curveLister,
                    }}
                    search={submittedSearch}
                    onSelect={handleSelection}
                    initialState={wizardState}
                    onStateChange={setWizardState}
                  />
                </CardBody>
              </Collapse>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default CurveSearch;
