import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FormattedMessage, useIntl } from "react-intl";
import { useService } from "../../context/ServiceContext";
import Spinner from "../../components/Spinner";
import PlateSummary from "../../components/DatosPlaca/PlateSummary";

function DatosPlacaSummary({ match }) {
  const { idEnsayo } = match.params;
  const { plateSummaryProvider } = useService();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      const availableTemplatesResult = await plateSummaryProvider.get(idEnsayo);
      setData(availableTemplatesResult);
      setIsLoading(false);
    }
    getData();
  }, []);

  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="datos_placa.summary" defaultMessage="Plate Summary" />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col>
            {isLoading ? (
              <>
                <Spinner></Spinner>
              </>
            ) : (
              <PlateSummary data={data} />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DatosPlacaSummary;
