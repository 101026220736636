class HttpAccountRepository {
  constructor(http, authorizationStore) {
    this.http = http;
    this.authorizationStore = authorizationStore;
  }

  async authenticate({ username, password }) {
    var response = await this.http.post("account/authenticate", {
      username: username,
      password: password,
    });

    var token = response.data;
    this.authorizationStore.setAuthorization(token);
    return response;
  }
}

export default HttpAccountRepository;
