import React from "react";

const Spinner = ({ className }) => {
  return (
    <div className={`text-center my-4${className ? " " + className : ""}`}>
      <div className="lds-dual-ring" />
    </div>
  );
};

export default Spinner;
