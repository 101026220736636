import React from "react";
import { useIntl } from "react-intl";
import SearchWizard from "../Search/SearchWizard";

function CurveSearchWizard({ listers, search, onSelect, initialState, onStateChange }) {
  const intl = useIntl();

  function renderSmallTableHeader({ column, children }) {
    return <th {...column.getHeaderProps()} style={{ width: "1%" }}>{children}</th>;
  }

  return <SearchWizard
    steps={[
      {
        id: "series",
        title: intl.formatMessage({ id: "curves_generator.series", defaultMessage: "Series" }),
        columns: [
          { id: "icon", TableHeader: renderSmallTableHeader, Cell: () => (<i className="fas fa-boxes"></i>) },
            { Header: intl.formatMessage({ id: "curves_generator.series_name", defaultMessage: "Series name" }), accessor: "internalName" },
            { Header: intl.formatMessage({ id: "curves_generator.series_short_name", defaultMessage: "Series short name" }), accessor: "name" }
        ],
        lister: listers.series,
        map: series => ({ commercialSeriesId: series.id })
      },
      {
        id: "product",
        title: intl.formatMessage({ id: "curves_generator.product", defaultMessage: "Product" }),
        columns: [
          { id: "icon", TableHeader: renderSmallTableHeader, Cell: () => (<i className="fas fa-box"></i>) },
          { Header: intl.formatMessage({ id: "curves_generator.product_name", defaultMessage: "Product name" }), accessor: "name" },
          { Header: intl.formatMessage({ id: "app.curve_series", defaultMessage: "Curve series" }), accessor: "curveSeries" }
        ],
        lister: listers.product,
        map: product => ({ productId: product.id })
      },
      {
        id: "curve_group",
        title: intl.formatMessage({ id: "curves_generator.curve_group", defaultMessage: "Curve group" }),
        columns: [
          { id: "icon", TableHeader: renderSmallTableHeader, Cell: () => (<i className="fas fa-list"></i>) },
          { Header: intl.formatMessage({ id: "app.curve_group", defaultMessage: "Curve group" }), accessor: "name" },
          { Header: intl.formatMessage({ id: "app.curve_series", defaultMessage: "Curve series" }), accessor: "curveSeries" }
        ],
        lister: listers.curve_group,
        map: curveGroup => ({ curveGroupId: curveGroup.id })
      },
      {
        id: "curve",
        title: intl.formatMessage({ id: "curves_generator.curve", defaultMessage: "Curve" }),
        columns: [
          { 
            id: "icon",
            TableHeader: renderSmallTableHeader, 
            Cell: cell => { 
              if(cell.row.original.curveTestCount === 0) {
                return <i class="far fa-times-circle text-danger"></i>
              } else {
                  return <i class="fas fa-chart-line"></i>;
              }
            }
          },
          { Header: intl.formatMessage({ id: "app.curve", defaultMessage: "Curve" }), accessor: "name" },
          { Header: intl.formatMessage({ id: "app.curve_group", defaultMessage: "Curve group" }), accessor: "group" },
          { Header: intl.formatMessage({ id: "app.curve_series", defaultMessage: "Curve series" }), accessor: "curveSeries" }
        ],
        lister: listers.curve,
        map: curve => curve
      }
    ]}
    search={search}
    onSelect={onSelect}
    initialState={initialState}
    onStateChange={onStateChange}
  />;
}

export default CurveSearchWizard;