import { SolerPalauCubicSpline } from "./CubicSpline";
import CatmullRom from "./CatmullRom";

export class CubicSplineAdaptor {
    constructor(xs, ys) {
        this._spline = new SolerPalauCubicSpline(xs, ys);
    }

    at(x) {
        return this._spline.getValue(x);
    }

    find(y) {
        return this._spline.getValueInverse(y);
    }
}

export class CatmullRomAdaptor {
    constructor(xs, ys) {
        this._spline = new CatmullRom(xs, ys);
    }

    at(x) {
        return this._spline.getValue(x);
    }

    find(y) {
        return this._spline.getValueInverse(y);
    }
}