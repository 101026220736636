export function parseStructureValues(structureData, data, productTests, overwriteExistingValues, autoTests) {
  const structure = structureData.initialValue || structureData.structure;
  const re = /\[([^\]]+)\]/g;
  const matches = structure.match(re);
  const visible = structureData.showViewer;

  if (!matches) return;

  let keymagnitud = matches.find((e) => e.includes("keymagnitud"));
  if (keymagnitud) keymagnitud = keymagnitud.replace("[", "").replace("]", "").split(":")[1];

  matches.forEach((element) => {
    // Make sure tests are selected by whats defined in values
    if (element.toUpperCase().includes("IDENSAYO") && productTests) {
      const testElement = element.replace("[", "").replace("]", "").split("=");
      const idEnsayo = parseInt(testElement[1]);
      if (!isNaN(idEnsayo) && !data.selectedTests.some((t) => t.id === idEnsayo)) {
        let idx = parseInt(testElement[0].substring(testElement[0].length - 1)) - 1;
        let existingTest = productTests.tests?.find((t) => t.id === idEnsayo);

        if (!existingTest && autoTests) {
          existingTest = createEmptyTest(idEnsayo);
        }

        if (existingTest) {
          existingTest.index = idx;
          data.selectedTests.push(existingTest);
        }
      }
    }

    const propertyWithValue = element.replace("[", "").replace("]", "").split(":");
    const property = propertyWithValue[0].toUpperCase();
    const value = propertyWithValue[1];
    if (value == undefined && property !== "REFIMAGE") return;

    let attribute = data.attributes.find((a) => a.name.toUpperCase() == property);

    if (attribute) {
      attribute.originalValue = overwriteExistingValues ? value : attribute.originalValue || value;
      if (overwriteExistingValues) attribute.value = attribute.originalValue;
    } else {
      switch (property) {
        case "PRODUCTREFERENCE":
          data.productReference = overwriteExistingValues ? value : data.productReference || value;
          break;
        case "WEEK_MANUF":
          data.manufactureWeek = overwriteExistingValues ? value : data.manufactureWeek || value;
          break;
        case "YEAR_MANUF":
          data.manufactureYear = overwriteExistingValues ? value : data.manufactureYear || value;
          break;
        case "REFIMAGE":
          let icon = data.icons.find((i) => i.position === structureData.position);
          if (!icon) {
            icon = { position: structureData.position, referenciaDocumento: structureData.referenciaDocumento };
            data.icons.push(icon);
          }
          icon.value = overwriteExistingValues ? value : icon.value || value;
          break;
      }
    }

    if (property.includes("IDENSAYO")) {
      const testElement = property.replace("[", "").replace("]", "").split(".");
      const testName = testElement[0];
      const testProperty = testElement[1];
      const testNumber = parseInt(testName.substring(testName.length - 1)) - 1;
      let selectedTest = data.selectedTests.find((t) => t.index === testNumber);
      if (selectedTest) {
        let property = null;
        switch (testProperty.toUpperCase()) {
          case "VOLTAGET":
            property = selectedTest.voltage;
            break;
          case "FRECUENCIA":
            property = selectedTest.frequency;
            break;
          case "POTENCIACERTIFICADA":
            property = selectedTest.power;
            break;
          case "INTENSIDADCERTIFICADA":
            property = selectedTest.current;
            break;
          case "RPM":
            property = selectedTest.maximumRPM;
            break;
          case "RENDMAX":
            property = selectedTest.efficiencyMax;
            break;
          case "INSTALATION_TYPE":
            property = selectedTest.installationType;
            break;
          case "EFICIENCY_TYPE":
            property = selectedTest.efficiencyType;
            break;
          case "FMEG":
            property = selectedTest.fmeg;
            break;
          case "TIPOREGUL":
            property = selectedTest.regulationType;
            break;
        }
        if (property) {
          //if (property.originalValue == null) property.originalValue = property.value;
          property.value = value;
          property.visible = visible;
          if (keymagnitud) property.unit = keymagnitud;
        }
      }
    }
  });
}

export function processStructure(structureData, data, productData) {
  let structure = structureData.structure;
  const re = /\[([^\]]+)\]/g;
  const matches = structure.match(re);

  if (matches) {
    let hasValues = false;

    matches.forEach((element) => {
      const property = element.replace("[", "").replace("]", "").toUpperCase();

      let attribute = data.attributes.find((a) => a.name.toUpperCase() == property);
      if (attribute) {
        let value = attribute.value || attribute.originalValue || "";
        if (value) hasValues = true;

        structure = structure.replace(element, value);
        //if (structure.includes("[keymagnitud]")) structure = structure.replace("[keymagnitud]", attribute.unit || "");
      } else {
        switch (property) {
          case "PRODUCTREFERENCE":
            structure = structure.replace(element, data.productReference || productData.productReference || "");
            break;
          case "WEEK_MANUF":
            structure = structure.replace(element, data.manufactureWeek || productData.manufactureWeek || "");
            break;
          case "YEAR_MANUF":
            structure = structure.replace(element, data.manufactureYear || productData.manufactureYear || "");
            break;
          case "REFIMAGE":
            structure = structure.replace(
              element,
              data.icons.find((i) => i.position === structureData.position)?.value /* || (structureData.referenciaDocumento + ".png") */ || ""
            );
            break;
        }
      }

      if (property.includes("IDENSAYO")) {
        const testElement = property.replace("[", "").replace("]", "").split(".");
        const testName = testElement[0];
        const testProperty = testElement[1];
        const testNumber = parseInt(testName.substring(testName.length - 1)) - 1;
        let selectedTest = data.selectedTests[testNumber];
        if (selectedTest) {
          let attribute = selectedTest.attributes?.find((a) => a.name.toUpperCase() == testProperty.toUpperCase());
          if (attribute) {
            const showValue = attribute.visible != undefined ? attribute.visible : true;
            const showAnything = data.selectedTests.some((t) => t.attributes.some((a) => a.name == attribute.name && (a.visible == undefined || a.visible)));

            let value = attribute.value || attribute.originalValue || "";
            if (value) hasValues = true;

            structure = structure.replace(element, showValue ? value : "");
            if (!showAnything) structure = "";
            else if (structure.includes("[keymagnitud]")) structure = structure.replace("[keymagnitud]", attribute.unit);
          } else {
            switch (testProperty.toUpperCase()) {
              case "VOLTAGET":
                structure = setTestAttibute(element, selectedTest, "voltage");
                break;
              case "FRECUENCIA":
                structure = setTestAttibute(element, selectedTest, "frequency");
                break;
              case "POTENCIACERTIFICADA":
                structure = setTestAttibute(element, selectedTest, "power");
                break;
              case "INTENSIDADCERTIFICADA":
                structure = setTestAttibute(element, selectedTest, "current");
                break;
              case "RPM":
                structure = setTestAttibute(element, selectedTest, "maximumRPM");
                break;
              case "RENDMAX":
                structure = setTestAttibute(element, selectedTest, "efficiencyMax");
                break;
              case "INSTALATION_TYPE":
                structure = setTestAttibute(element, selectedTest, "installationType");
                break;
              case "EFICIENCY_TYPE":
                structure = setTestAttibute(element, selectedTest, "efficiencyType");
                break;
              case "FMEG":
                structure = setTestAttibute(element, selectedTest, "fmeg");
                break;
              case "TIPOREGUL":
                structure = setTestAttibute(element, selectedTest, "regulationType");
                break;
            }
          }
        } else {
          structure = structure.replace(element, "");
        }
      }
    });

    function setTestAttibute(element, test, attribute) {
      const showValue = test[attribute]?.visible;
      const showAnything = data.selectedTests.some((t) => t[attribute]?.visible);
      structure = structure.replace(element, showValue ? test[attribute].value : "");
      if (structure.includes("[keymagnitud]")) structure = structure.replace("[keymagnitud]", showAnything ? test[attribute].unit : "");
      return structure;
    }

    if (!hasValues && !structureData.structure.toUpperCase().includes("REFIMAGE")) {
      structure = structureData.showIfValueNull;
    }
  }

  return { ...structureData, value: cleanValue(structure), icon: structureData.structure.toUpperCase().includes("REFIMAGE") };
}

export function cleanValue(value) {
  let trimmed = value.trim();
  return trimmed.match(/[:|/| ]/g)?.length === trimmed.length ? "" : [":", "/"].includes(trimmed.substring(trimmed.length - 1)) ? trimmed.substring(0, trimmed.length - 1) : value;
}

export const datosPlacaModes = {
  DEPENDENT: "dependent",
  IDENSAYO: "idensayo",
  EMPTY: "empty",
  PRODUCT: "product",
  EDIT: "edit",
};

export function createEmptyTest(id) {
  return {
    id: id,
    custom: true,
    description: "New test",
    attributes: [],
    voltage: { value: "", unit: "V", visible: true, modified: true },
    frequency: { value: "", unit: "Hz", visible: true, modified: true },
    power: { value: "", unit: "W", visible: true, modified: true },
    current: { value: "", unit: "A", visible: true, modified: true },
    grade: { value: "", unit: "", visible: true, modified: true },
    maximumRPM: { value: "", unit: "", visible: true, modified: true },
    intMax: { value: "", unit: "", visible: true, modified: true },
    reasonForChange: null,
    mode:"empty"
  };
}
